import { find } from 'lodash';

export function matchProjectsWithHarvestData(projects, harvestData) {
  const matchingProjects = [];

  harvestData.forEach((harvestProject) => {
    const matchingHarvestProject = find(projects, {
      harvestId: String(harvestProject.projectId),
    });

    if (matchingHarvestProject) {
      matchingHarvestProject['totalHours'] = harvestProject.totalHours;
      matchingProjects.push(matchingHarvestProject);
    }
  });

  return matchingProjects;
}

export async function handleProjectsAssignments(
  assignments,
  projects,
  { onRemove, onUpdate, onCreate },
) {
  const hasNoMatchingHarvestProject = !projects.length;

  for (const item of assignments) {
    const { assignment, invoiceItem, taskBreakdownSummedByCategory } = item;

    if (
      !assignment?._project?.canSubmitHoursInvoices ||
      !taskBreakdownSummedByCategory ||
      (assignment?._project.harvestId && hasNoMatchingHarvestProject)
    ) {
      if (invoiceItem) {
        await onRemove({ invoiceItem });
      }

      continue;
    }

    for (const project of projects) {
      if (project._assignments.some((x) => x.id === assignment.id)) {
        const quantity = parseFloat(project.totalHours).toFixed(2);
        const rate = assignment.rate;

        if (invoiceItem) {
          await onUpdate({ invoiceItem, quantity });
        } else {
          await onCreate({
            rate,
            quantity,
            _project: {
              id: assignment._project.id,
              name: assignment._project.name,
            },
          });
        }
      }
    }
  }
}
