const optional = true;
const paymentAccountOwnerName = { label: 'Account Owner Name', type: 'text' };
const paymentAccountOwnerAddress = { label: 'Account Owner Street', type: 'text' };
const paymentAccountOwnerCity = { label: 'Account Owner City', type: 'text' };
const paymentAccountOwnerZipcode = { label: 'Account Owner ZIP code', type: 'text' };
const paymentAccountOwnerCountry = { label: 'Account Owner Country', type: 'dropdown' };
const paymentSwiftCode = { label: 'Swift Code', type: 'text' };
const paymentBankAddress = { label: 'Bank Address', type: 'text' };
const paymentAccountNumber = { label: 'Account Number', type: 'text' };
const paymentBankName = { label: 'Bank Name', type: 'text' };
const paymentBankCountryCode = { label: 'Bank Country Code', type: 'dropdown' };
const paymentRoutingNumber = { label: 'Routing Number (U.S.)', type: 'text' };
const paymentAccountIban = { label: 'Bank Account IBAN', type: 'text' };
const paymentBsbNumber = { label: 'BSB Number', type: 'text' };
const paymentEmail = { label: 'Paypal Email Address', type: 'email' };

const SHARED_ACCOUNT_FIELDS = {
  paymentAccountOwnerName,
  paymentAccountOwnerAddress,
  paymentAccountOwnerCity,
  paymentAccountOwnerZipcode,
  paymentAccountOwnerCountry,
};

export type PaymentType = 'airwallex' | 'non-airwallex';
export const paymentData = {
  paypal: {
    label: 'PayPal - not supported',
    paymentVia: 'non-airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentEmail,
    },
  },
  australian_local_bank_transfer: {
    label: 'Australian Local Payment (AUD)',
    paymentVia: 'airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentBsbNumber,
      paymentAccountNumber,
      paymentBankName,
      paymentBankAddress,
    },
  },
  us_local_bank_transfer: {
    label: 'US Local Payment (USD)',
    paymentVia: 'airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber,
      paymentBankName,
      paymentBankAddress,
      paymentRoutingNumber,
    },
  },
  us_international_wired_transfer: {
    label: 'International Wire Payment (USD)',
    paymentVia: 'airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentBankCountryCode,
      paymentAccountNumber,
      paymentBankName,
      paymentBankAddress,
      paymentSwiftCode,
    },
  },
  european_local_bank_transfer: {
    label: 'EUR Local Payment (EU Only)',
    paymentVia: 'airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentBankCountryCode,
      paymentBankName,
      paymentBankAddress,
      paymentSwiftCode,
      paymentAccountIban,
    },
  },
  other_aud: {
    label: 'Other Payment (AUD)',
    paymentVia: 'airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber,
      paymentBankCountryCode,
      paymentBankName,
      paymentBankAddress,
      paymentSwiftCode,
    },
  },
  other_usd: {
    label: 'Other Payment (USD)',
    paymentVia: 'non-airwallex',
    fields: {
      ...SHARED_ACCOUNT_FIELDS,
      paymentAccountNumber,
      paymentBankCountryCode,
      paymentBankName,
      paymentBankAddress,
    },
    paymentSwiftCode: { ...paymentSwiftCode, optional },
  },
};

type PaymentDefinitionType = typeof paymentData;

export type PaymentMethodName = keyof PaymentDefinitionType;
export type PaymentMethod = PaymentDefinitionType[PaymentMethodName];

export type PaymentMethodFieldNames = keyof PaymentMethod['fields'];
export type PaymentMethodFields = Record<PaymentMethodFieldNames, string>;
export type PaymentMethodField = PaymentMethod['fields'][PaymentMethodFieldNames];
export type PaymentMethodFieldWithName = {
  name: keyof PaymentMethodFields;
  optional?: boolean;
} & PaymentMethodField;

export type CommonPaymentFields = {
  paymentType?: {
    name: PaymentMethodName;
    id: number;
  };
  lastEdited?: number;
  notes?: string;
};

export type DataFields = Partial<PaymentMethodFields & CommonPaymentFields>;
