import { ChangeEvent } from 'react';

import {
  ContainerInfo,
  Input,
  Label,
  EditableParagraph,
  ProjectAccordion,
  Paragraph,
} from 'design-system';
import { get } from 'lodash';

import { INVOICE_CATEGORY } from 'consts/constants';
import { Grid, Column, Row } from 'design-system/Templates';
import { Assignment, CurrencyName, InvoiceItem } from 'types/types';

import styles from './HourForm.module.scss';

export type HourFormProps = {
  assignments?: Assignment[];
  harvestAssignments?: Assignment[];
  productiveAssignments?: Assignment[];
  invoiceItems: InvoiceItem[];
  currency: CurrencyName;
  businessDays: number;
  updateInvoices: (invoiceItems: InvoiceItem[]) => void;
  overTimeApprover?: string;
  toggleOverLimitModal: () => void;
};

export const HourForm = ({
  assignments,
  harvestAssignments,
  productiveAssignments,
  invoiceItems,
  currency,
  updateInvoices,
  overTimeApprover,
  toggleOverLimitModal,
}: HourFormProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number, assignment?: Assignment) => {
    const hours = parseFloat(e.target.value || '0');
    const shouldCreateInvoiceItem = index < 0;
    const invoiceItems_ = [...invoiceItems];

    if (shouldCreateInvoiceItem) {
      const newInvoiceItemIndex = invoiceItems_.findIndex(
        (item) =>
          (!item._category?.name || item._category?.name === INVOICE_CATEGORY.HOURS_WORKED) &&
          item._project?.id === assignment?._projectId,
      );
      const newInvoiceItemData = {
        quantity: hours,
        rate: Number(assignment?.rate),
        _project: assignment?._project,
      };
      newInvoiceItemIndex >= 0
        ? (invoiceItems_[newInvoiceItemIndex].quantity = hours)
        : invoiceItems_.push(newInvoiceItemData as InvoiceItem);
    } else {
      invoiceItems_[index].quantity = hours;
    }

    updateInvoices(invoiceItems_);
  };

  const renderRow = (assignment: Assignment) => {
    const invoiceItemIndex = invoiceItems.findIndex(
      (item) => !!item.id && item._project?.id === assignment?._projectId,
    );
    const totalHours = invoiceItems?.[invoiceItemIndex]?.quantity || 0;
    const hasProductiveAssignments = productiveAssignments?.some(
      (item) => item._project?.productiveId,
    );
    const isProductive =
      invoiceItems?.[invoiceItemIndex]?._project?.productiveId || hasProductiveAssignments;

    return (
      <Row key={assignment?.id}>
        <Column xs={6} sm={5}>
          <ContainerInfo
            text={get(assignment, '_project.name')}
            textAlignment={'left'}
            classNames={styles.nowrap}
          />
        </Column>
        <Column xs={6} sm={5}>
          {isProductive ? (
            <Paragraph
              data-testid="invoiceItemHours"
              className={styles.labelProjectHours}
              variant="ui-regular"
            >
              {String(totalHours)}
            </Paragraph>
          ) : (
            <Input
              data-testid="TestId__TOTAL_HOURS_INPUT"
              layout="fluid"
              mode="writable"
              type={'number'}
              value={String(totalHours)}
              onChange={(e) => onChange(e, invoiceItemIndex, assignment)}
              placeholder=" "
              state="focused"
              className={styles.inputStyle}
            />
          )}
        </Column>
        <Column sm={3} md={2}>
          <ContainerInfo text={`${assignment.rate} ${currency}/hr`} textAlignment={'center'} />
        </Column>
      </Row>
    );
  };

  const renderHarvestRow = (assignment: Assignment) => {
    return (
      <Row key={assignment?.id}>
        <ProjectAccordion
          rate={assignment.rate}
          details={{
            projectId: Number(assignment?._project?.id),
            projectName: String(assignment?._project?.name),
            totalHours: String(assignment?.totalHours),
            taskBreakdownSummedByCategory: assignment?.taskBreakdownSummedByCategory || [],
          }}
          currency={currency}
        />
      </Row>
    );
  };

  return (
    <>
      <Grid classNames={styles.grid}>
        <Row>
          <Column xs={6} sm={5}>
            <Label
              text={'Project Name'}
              showBackground={false}
              type={'common'}
              interactive={false}
              wrapperClassName={styles.header}
            />
          </Column>
          <Column xs={6} md={5}>
            <Label
              text={'Total Hours'}
              showBackground={false}
              type={'common'}
              interactive={false}
              wrapperClassName={styles.header}
            />
          </Column>
          <Column sm={3} md={2} />
        </Row>
        {assignments?.map(renderRow)}
        {harvestAssignments?.map(renderHarvestRow)}
        {productiveAssignments?.map(renderHarvestRow)}

        {overTimeApprover && (
          <EditableParagraph onEdit={() => toggleOverLimitModal()}>
            <span>
              Overtime authorized by <b>{overTimeApprover}</b>
            </span>
          </EditableParagraph>
        )}
      </Grid>
    </>
  );
};
