import { useCallback, useMemo, useState } from 'react';

import c from 'classnames';

import Label from 'components/Label';
import Row from 'components/Row';
import Button from 'components/buttons/Button';
import InvoiceDatePicker from 'components/invoices/InvoiceDatePicker';
import EditStep from 'components/steps/EditStep';
import Footer from 'components/steps/StepFooter';
import Title from 'components/steps/StepTitle';
import Wrap from 'components/steps/StepWrap';
import Step from 'components/steps/SubmitStep';
import { Invoice, User } from 'types/types';
import { DateRange, DateRangeSelectOption, createRange, normalizeUTCDate } from 'utils/datesHelper';
import { formatDate } from 'utils/helpers';

import styles from './Step1.module.scss';

const STEP = 1;

function Step1(props: Step1Props) {
  const {
    currentStep,
    harvestEnabled,
    productiveEnabled,
    invoice,
    submittedInvoicesDateRanges,
    user,
    getHarvestAPITimeEntries,
    selectStep,
    updateInvoice,
    getProductiveAPITimeEntries,
  } = props;

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(() => {
    const { dateFrom, dateTo } = invoice;
    return dateFrom && dateTo ? createRange(dateFrom, dateTo) : null;
  });

  const isDateRangeSubmitted = useCallback(
    (dateRange: DateRange) => {
      return submittedInvoicesDateRanges.some(
        (submittedInvoiceDateRange: { start: Date; end: Date }) => {
          const isStartDateInSubmittedRange =
            dateRange.start >= submittedInvoiceDateRange.start &&
            dateRange.start <= submittedInvoiceDateRange.end;

          const isEndDateInSubmittedRange =
            dateRange.end >= submittedInvoiceDateRange.start &&
            dateRange.end <= submittedInvoiceDateRange.end;

          return isStartDateInSubmittedRange || isEndDateInSubmittedRange;
        },
      );
    },
    [submittedInvoicesDateRanges],
  );

  const validate = useMemo(() => {
    const hasSubmitted =
      submittedInvoicesDateRanges && selectedDateRange && isDateRangeSubmitted(selectedDateRange);

    return !hasSubmitted;
  }, [selectedDateRange, submittedInvoicesDateRanges, isDateRangeSubmitted]);

  const handleDateChange = async (value: DateRange) => {
    setSelectedDateRange(value);
    if (harvestEnabled) {
      await getHarvestAPITimeEntries({ harvestId: user.harvestId, value });
    }
    if (productiveEnabled) {
      await getProductiveAPITimeEntries({ productiveId: user.productiveId, value }, true);
    }

    await updateInvoice({
      dateFrom: normalizeUTCDate(value.start).toISOString(),
      dateTo: normalizeUTCDate(value.end).toISOString(),
    });
  };

  const disableDateRangeOption = (dateRangeOption: DateRangeSelectOption) => {
    const { dateRange } = dateRangeOption;

    if (submittedInvoicesDateRanges?.length) {
      const normalizedDateRange = {
        ...dateRange,
        start: normalizeUTCDate(dateRange.start),
        end: normalizeUTCDate(dateRange.end),
      };
      return isDateRangeSubmitted(normalizedDateRange);
    }
    return false;
  };

  const isActive = currentStep === 1;

  return (
    <Step>
      <Title step={STEP} isActive={isActive}>
        Invoice Setup
        <EditStep
          isSubmit
          onClick={selectStep(STEP)}
          showSortUpIcon={currentStep === STEP}
          showEditIcon={currentStep > STEP}
        />
      </Title>

      <Wrap isHidden={!isActive}>
        <Row isCollapsable noPadding>
          <div className={styles.Column}>
            <Label className={styles.EditLabel}>
              Invoice Number:
              <div className={c(styles.Value, styles.ValueInline)}>{invoice.number}</div>
            </Label>
          </div>
          <div className={styles.Column}>
            <Label className={styles.EditLabel}>Date Range:</Label>
            <div data-testid="invoiceDatePickerContainer" className={styles.dateRangeWrap}>
              <InvoiceDatePicker
                isFullWidth
                onChange={handleDateChange}
                value={selectedDateRange?.id}
                isOptionDisabled={disableDateRangeOption}
              />
            </div>
          </div>
        </Row>
        <Footer>
          <Button
            isFormBtn
            submitNext
            onClick={selectStep(STEP + 1, selectedDateRange)}
            disabled={!validate}
          >
            Next
          </Button>
        </Footer>
      </Wrap>
      <Wrap isHidden={isActive}>
        <Row noPadding className={styles.SummaryRow}>
          <span className={styles.SummaryLabel}>Invoice Number: </span>
          <span className={styles.SummaryValue} data-testid="invoiceNumber">
            {invoice.number}
          </span>
        </Row>
        <Row noPadding className={styles.SummaryRow}>
          <span className={styles.SummaryLabel}>Date Range:</span>
          <span className={styles.SummaryValue} data-testid="dateRangePickerDates">
            {formatDate(invoice.dateFrom)} - {formatDate(invoice.dateTo)}
          </span>
        </Row>
      </Wrap>
    </Step>
  );
}

export type Step1Props = {
  currentStep: number;
  invoice: Invoice;
  user: User;
  harvestEnabled: boolean;
  productiveEnabled: boolean;
  submittedInvoicesDateRanges: DateRange[];
  selectStep: (step: number, dataRange?: DateRange | null) => void;
  getHarvestAPITimeEntries: (data: { harvestId?: string; value: DateRange }) => Promise<void>;
  updateInvoice: (updateData: Partial<Invoice>) => Promise<void>;
  getProductiveAPITimeEntries: (
    data: { productiveId?: string; value: DateRange },
    hasChangedDatesStepOne?: boolean,
  ) => Promise<void>;
};

export default Step1;
