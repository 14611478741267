import { useRef, SyntheticEvent } from 'react';

import classnames from 'classnames';

import { useIsMobile, useOnClickOutside } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import { ReactComponent as CloseIcon } from '../Close.svg';
import { ReactComponent as PencilIcon } from '../Pencil.svg';
import { ReactComponent as TrashIcon } from '../Trash.svg';

import styles from './FeedPostMenu.module.scss';

const FeedPostMenu = (props: FeedPostMenuProps) => {
  const { onCloseMenu, onMenuEdit, onMenuRemove, canManage } = props;

  const menuRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  const onClickEditPost = (event: SyntheticEvent) => {
    event.stopPropagation();
    onCloseMenu?.();
    onMenuEdit();
  };

  const onClickRemovePost = (event: SyntheticEvent) => {
    event.stopPropagation();
    onCloseMenu?.();
    onMenuRemove();
  };

  const onMobileClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    onCloseMenu?.();
  };

  useOnClickOutside(menuRef, onCloseMenu);

  if (isMobile) {
    const RemoveButtonStyle = classnames(styles.ActionButtonMobile, styles.RemoveButton);
    const DefaultButtonStyle = classnames(styles.ActionButtonMobile, styles.DefaultButton);

    return (
      <div
        className={styles.FeedPostMenuMobile}
        onClick={onMobileClose}
        onKeyUp={(e) => isKeyboardEvent(e) && onMobileClose(e)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.FeedPostMenuMobileMenu}>
          {canManage && (
            <>
              <button className={DefaultButtonStyle} onClick={onClickEditPost}>
                <PencilIcon /> Edit post
              </button>
              <button className={RemoveButtonStyle} onClick={onClickRemovePost}>
                <TrashIcon /> Remove post
              </button>
              <button className={DefaultButtonStyle} onClick={onMobileClose}>
                <CloseIcon /> Close
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={menuRef} className={styles.FeedPostMenu}>
      {canManage && (
        <>
          <button className={styles.DefaultButton} onClick={onClickEditPost}>
            <PencilIcon /> Edit post
          </button>
          <button className={styles.RemoveButton} onClick={onClickRemovePost}>
            <TrashIcon /> Remove post
          </button>
        </>
      )}
    </div>
  );
};

export interface FeedPostMenuProps {
  onCloseMenu?: () => void;
  onMenuEdit: () => void;
  onMenuRemove: () => void;
  canManage?: boolean;
}

export default FeedPostMenu;
