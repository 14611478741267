import { useEffect, useMemo, useState, useRef, useLayoutEffect } from 'react';

import { Paragraph, Toast, Modal, Heading, Input } from 'design-system';

import { DEBOUNCE_WAIT_INTERVAL } from 'consts/constants';
import { ActiveState, Assignment, InvoiceItem } from 'types/types';
import { useDebounce } from 'utils/hooks';

import styles from './useValiteInvoiceItems.module.scss';

const defaultErrors = {
  isOverProjectLimit: null,
  isOverTotalLimit: false,
  isBelowMinimumHours: false,
};

type Errors = {
  isOverTotalLimit: boolean;
  isBelowMinimumHours: boolean;
  isOverProjectLimit: {
    name: string;
    hoursLimit: number;
  } | null;
};

export const popupMessage = {
  title: 'The limit of 8h per day has exceeded!',
  message: 'I acknowledge that the total amount of hours exceeds the limit.',
  authorized: 'This has been authorized by:',
};

export type ValidationState = {
  projectHoursExceeded?: {
    name: string;
    hoursLimit: number;
  } | null;
  showOverHourLimit?: boolean;
  showHoursNotDays?: boolean;
  isValid: boolean;
};

export type ValiteHoursType = {
  hrSum: number;
  invoiceSum: number;
  errors: Errors;
};

export function validateHours({
  businessDays,
  invoiceItems,
  assignments,
  minimumHours,
  overTimeApprover: approver,
  hoursInvoiceCategoryId,
}: {
  businessDays: number;
  invoiceItems: InvoiceItem[];
  assignments: Assignment[];
  minimumHours: number;
  overTimeApprover: string;
  hoursInvoiceCategoryId: number | undefined;
}): ValiteHoursType {
  const expectedHours = businessDays * 8;
  const hoursInvoiceItems = invoiceItems.filter(
    (item) => item._category?.id === hoursInvoiceCategoryId,
  );
  const length = hoursInvoiceItems.length - 1;
  const calculateEntry = (
    { hrSum, invoiceSum, errors }: ValiteHoursType,
    invoiceItem: InvoiceItem,
    index: number,
  ) => {
    const { quantity = 0, rate, _project: { id: projectId } = {} } = invoiceItem;
    const overTimeApprover = invoiceItem.overTimeApprover || approver;

    const { hoursLimit } =
      assignments?.find(({ _project: { id } = {} }) => id && id === projectId) ?? {};

    hrSum += quantity;

    if (hoursLimit && hoursLimit < quantity && !overTimeApprover)
      errors.isOverProjectLimit = { name: invoiceItem.name || '', hoursLimit };
    if (hrSum > expectedHours && !overTimeApprover) errors.isOverTotalLimit = true;
    if (length === index && quantity > 0 && hrSum < minimumHours) errors.isBelowMinimumHours = true;

    return {
      hrSum,
      invoiceSum: invoiceSum + quantity * rate,
      errors,
    };
  };

  return hoursInvoiceItems.reduce(calculateEntry, {
    hrSum: 0,
    invoiceSum: 0,
    errors: Object.create(defaultErrors),
  });
}

export function getValidationState(
  { hrSum, errors }: ValiteHoursType,
  componentJustMount: boolean,
): ValidationState {
  const isValid = hrSum >= 0 && !errors.isOverTotalLimit && !errors.isOverProjectLimit;

  if (errors.isOverTotalLimit) {
    return {
      projectHoursExceeded: null,
      showOverHourLimit: true,
      isValid,
    };
  } else if (errors.isOverProjectLimit) {
    return {
      projectHoursExceeded: errors.isOverProjectLimit,
      isValid,
    };
  } else if (errors.isBelowMinimumHours) {
    return {
      showHoursNotDays: !componentJustMount,
      isValid: isValid || componentJustMount,
    };
  } else {
    return {
      projectHoursExceeded: null,
      isValid: isValid,
    };
  }
}

export type UseValiteInvoiceItems = {
  invoiceItems: InvoiceItem[];
  amount: number;
  businessDays: number;
  assignments: Assignment[];
  setAmount: (amount: number) => void;
  state: ActiveState;
  harvestEnabled: boolean;
  hoursInvoiceCategoryId: number | undefined;
  productiveEnabled: boolean;
};

export default function useValiteInvoiceItems({
  invoiceItems,
  amount,
  businessDays,
  assignments,
  setAmount,
  state,
  harvestEnabled,
  hoursInvoiceCategoryId,
}: UseValiteInvoiceItems) {
  const initialOverTimeApprover =
    invoiceItems?.find((item) => !!item.overTimeApprover)?.overTimeApprover ?? '';
  const [overTimeApprover, setOverTimeApprover] = useState<string>(initialOverTimeApprover);
  const debouncedAmount = useDebounce(amount, DEBOUNCE_WAIT_INTERVAL + 250);
  const [validationState, setValidationState] = useState<ValidationState>({
    isValid: false,
    showHoursNotDays: false,
    projectHoursExceeded: null,
    showOverHourLimit: false,
  });
  const { projectHoursExceeded, showOverHourLimit, showHoursNotDays } = validationState;
  const componentDidMount = useRef(true);

  const calculatedData = useMemo(
    () =>
      validateHours({
        businessDays,
        invoiceItems,
        assignments,
        overTimeApprover,
        minimumHours: 31,
        hoursInvoiceCategoryId,
      }),
    [invoiceItems, assignments, businessDays, overTimeApprover, hoursInvoiceCategoryId],
  );

  const toastMessage = projectHoursExceeded
    ? `The value of hours submitted in ${projectHoursExceeded?.name} exceeds the limit agreed of ${projectHoursExceeded?.hoursLimit} hours. Make sure it's the correct value and that it was confirmed with your manager`
    : null;

  const toggleOverLimitModal = () => {
    setValidationState({ ...validationState, showOverHourLimit: !showOverHourLimit });
  };

  const hideHoursNotDaysModal = () => {
    setValidationState({ ...validationState, showHoursNotDays: false });
  };

  const clearProjectHoursExceeded = () => {
    setValidationState({ ...validationState, projectHoursExceeded: null });
  };

  function onOverTimeApproverChange() {
    setValidationState(getValidationState(calculatedData, componentDidMount.current));
  }

  useEffect(() => {
    componentDidMount.current = false;
  }, []);

  useEffect(() => {
    const { invoiceSum } = calculatedData;
    setAmount(invoiceSum);
  }, [calculatedData, invoiceItems, setAmount]);

  useLayoutEffect(() => {
    if (state === 'active') {
      setValidationState(getValidationState(calculatedData, componentDidMount.current));
    } else {
      setValidationState({
        isValid: true,
        showHoursNotDays: false,
        projectHoursExceeded: null,
        showOverHourLimit: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount, state]);

  const renderWarningModals = () => {
    return (
      <>
        <Toast
          shouldShow={!!projectHoursExceeded}
          message={toastMessage}
          autoHide={true}
          onClose={clearProjectHoursExceeded}
          placement="TOP_CENTER"
          type="ERROR"
          durationMS={4000}
        />
        {showOverHourLimit && !harvestEnabled && (
          <Modal
            onClose={toggleOverLimitModal}
            onSubmit={onOverTimeApproverChange}
            isButtonDisabled={!overTimeApprover}
          >
            <Heading level="h5" className={styles.ModalTitle}>
              {popupMessage.title}
            </Heading>
            <Paragraph variant="regular" className={styles.popupItem}>
              {popupMessage.message}
              <br />
              {popupMessage.authorized}
            </Paragraph>
            <div className={styles.popupItem}>
              <Input
                layout="fluid"
                mode="writable"
                type={'string'}
                value={overTimeApprover}
                onChange={(e) => setOverTimeApprover(e.target.value)}
                placeholder="Enter approver name"
                state="focused"
                inputTestId="TestId__USEVALIDATEINVOICE__OVERTIMEAPPROVER"
                className={styles.inputStyle}
              />
            </div>
          </Modal>
        )}
        {showHoursNotDays && (
          <Modal
            primaryButtonContent="Close"
            onClose={hideHoursNotDaysModal}
            onSubmit={hideHoursNotDaysModal}
            secondaryButtonClassName={styles.secondaryButton}
          >
            <Heading level="h5" className={styles.ModalTitle}>
              Are you sure you input hours and not days worked?
            </Heading>
          </Modal>
        )}
      </>
    );
  };

  return {
    renderWarningModals,
    toggleOverLimitModal,
    overTimeApprover,
    validationState,
  };
}
