import { useState, useCallback, useRef } from 'react';

import { PromptPopup } from 'design-system';

export const usePromptBeforeExit = (showPrompt: boolean, onClose: (...params: any[]) => void) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const onCloseParams = useRef<any[]>([]);

  const handlePromptClose = useCallback(() => {
    setIsPromptOpen(false);
  }, []);

  const handlePromptSubmit = useCallback(() => {
    setIsPromptOpen(false);
    onClose(...onCloseParams.current);
  }, [onClose]);

  const promptBeforeExitCallback = useCallback(
    (...params) => {
      if (showPrompt) {
        onCloseParams.current = params;
        return setIsPromptOpen(true);
      }
      onClose(...params);
    },
    [onClose, showPrompt],
  );

  const renderPrompt = useCallback(() => {
    if (!isPromptOpen) return null;
    return <PromptPopup onCancel={handlePromptClose} onConfirm={handlePromptSubmit} />;
  }, [handlePromptClose, handlePromptSubmit, isPromptOpen]);

  return {
    renderPrompt,
    onClose: promptBeforeExitCallback,
  };
};
