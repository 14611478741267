import { addBreadcrumb, configureScope } from '@sentry/browser';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { version } from 'config';
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_DATA_UPDATED } from 'consts/constants';

import { updateLocation } from './location';
import makeRootReducer from './reducers';

const browserHistory = createBrowserHistory();

function updateSentryUser(scope, user = {}) {
  scope.setUser({
    id: user.id,
    username: user.email,
    email: user.email,
  });
}

const sentryContextMiddleware = (store) => (next) => (action) => {
  if (!action) {
    return;
  }

  addBreadcrumb({
    message: action.type,
    category: 'redux action',
    level: 'info',
    data: {
      payload: action.payload,
    },
  });

  const result = next(action);
  const state = store.getState();

  configureScope((scope) => {
    switch (action.type) {
      case USER_LOGGED_IN:
      case USER_DATA_UPDATED:
        updateSentryUser(scope, state.session.data?._user);
        break;
      case USER_LOGGED_OUT:
        updateSentryUser(scope, undefined);
        break;
      default:
        break;
    }
  });
  return result;
};

const createXhqStore = (initialState = 'Cypress' in window ? window.__chr__initialState__ : {}) => {
  window.version = version;

  const middleware = [sentryContextMiddleware, thunk];

  const enhancers = [];
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(
    makeRootReducer(initialState),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  );

  store.unsubscribeHistory = browserHistory.listen(updateLocation(store));

  // Connect with the Cypress helper
  if ('Cypress' in window) {
    const w = window;
    w.__chr__store__ = store;
  }

  return store;
};

export default createXhqStore;
