import { Fragment } from 'react';

import classnames from 'classnames';
import { Image, Video, Placeholder } from 'cloudinary-react';

import { BREAKPOINTS } from 'consts/constants';

import { ReactComponent as MultiIcon } from '../Layers.svg';
import { ReactComponent as PlayIcon } from '../PlayArrow.svg';

import styles from './MultiPostMedia.module.scss';

const MultiPostMedia = ({ numberOfMedia, media }: Props) => {
  let multiClassname = styles.Media;
  const isMobile = window.innerWidth <= BREAKPOINTS.MD;

  switch (numberOfMedia) {
    case 3:
      multiClassname = classnames(styles.MediaThreeCol, styles.Media);
      break;
    case 2:
      multiClassname = classnames(styles.MediaTwoCol, styles.Media);
      break;
    default:
      multiClassname = styles.Media;
  }

  const renderIcons = (showMulti: boolean, isPrimaryVideo: boolean) => {
    return (
      <div className={styles.OverIconContainer}>
        {showMulti && (
          <div>
            <MultiIcon width="18px" />
          </div>
        )}
        {isPrimaryVideo && (
          <div>
            <PlayIcon width="11px" />
          </div>
        )}
      </div>
    );
  };

  const renderMedia = (index: number, type: string, className: string, publicId: string) => {
    const computedClassName = classnames(className, { [styles.IsMobile]: isMobile });
    const showMulti = isMobile && numberOfMedia > 1;

    switch (type) {
      case 'image':
        if (index === 3 && media.length > 4 && !isMobile) {
          //if media has more than 4, the last image should have an overlay
          return (
            <div key={index} className={styles.LastMediaOverlayContainer}>
              <Image
                key={index}
                className={styles.Media}
                loading="lazy"
                publicId={publicId}
                alt="Upload widget"
              >
                <Placeholder type="pixelate" />
              </Image>
              <span className={styles.RemainingMediaText}>+{media.length - 4}</span>
              <div className={styles.LastMediaOverlay}></div>
            </div>
          );
        }
        return (
          <Fragment key={index}>
            {isMobile && renderIcons(showMulti, false)}
            <Image
              className={computedClassName}
              loading="lazy"
              publicId={publicId}
              alt="Upload widget"
            >
              <Placeholder type="pixelate" />
            </Image>
          </Fragment>
        );
      case 'video':
        return (
          <Fragment key={index}>
            {isMobile && renderIcons(showMulti, true)}
            <Video
              className={computedClassName}
              publicId={publicId}
              controls={{ playerControls: true }}
            />
          </Fragment>
        );
    }
  };

  return (
    <>
      {isMobile
        ? renderMedia(0, media[0].type, styles.Media, media[0].publicId)
        : Array(numberOfMedia)
            .fill(null)
            .map((_, i) => {
              if (media[i]) {
                if (numberOfMedia < 4) {
                  // render by dynamic column in a row
                  return renderMedia(i, media[i].type, multiClassname, media[i].publicId);
                }

                return i === 0 // render 1st image first, then the remaining media
                  ? renderMedia(
                      i,
                      media[i].type,
                      classnames(styles.Media, styles.TopMedia),
                      media[i].publicId,
                    )
                  : renderMedia(i, media[i].type, styles.MediaThreeColBelow, media[i].publicId);
              }

              return <></>;
            })}
    </>
  );
};

type Media = {
  type: string;
  publicId: string;
};

type Props = {
  numberOfMedia: number;
  media: Array<Media>;
};

export default MultiPostMedia;
