import { SyntheticEvent, useContext, useState, useMemo } from 'react';

import { marked } from 'marked';
import { FeedContext } from 'react-activity-feed';
import Avatar from 'react-avatar';
import sanitizeHtml from 'sanitize-html';

import coinAnimation from 'assets/coin.gif';
import { isAdmin, isCommunityTeam, isPeopleOps, isSuperAdmin } from 'components/Auth/authHelpers';
import { DATE_FORMAT_MONTH_DAY_LABEL } from 'consts/constants';
import { UserApiModel } from 'types/types';
import { formatDate, getFeedUserId } from 'utils/helpers';
import { useAllowedRoles, useIsMobile } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import { Activity, FeedCtx, FeedActivityProps, RefreshFeedCallbackFunction } from '../Feed.types';
import FeedPostMenu from '../FeedPostMenu';
import { ReactComponent as MenuIcon } from '../MenuIcon.svg';
import PostMedia from '../PostMedia';
import PostReactions from '../PostReactions';
import TeamIcon from '../TeamIcon';

import styles from './FeedPost.module.scss';

const FeedPost = (props: FeedPostProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const roleThatCanManage = useAllowedRoles([isAdmin, isSuperAdmin, isCommunityTeam, isPeopleOps]);
  const feedContext = useContext<FeedCtx>(FeedContext);
  const isMobile = useIsMobile();

  const {
    activity: {
      actor,
      publisherAvatar,
      publisherName,
      verb,
      message,
      bountyTitle,
      bountyCoinValue,
      teamName,
      teamIconLocation,
      time,
    },
    user,
    onClickPost,
  } = props;

  const coinIcon = useMemo(
    () => <img src={coinAnimation} className={styles.CoinAnimation} alt="coin animation" />,
    [],
  );

  const bountyTitleComponent = useMemo(
    () => <span className={styles.TitleBounty}>{bountyTitle}</span>,
    [bountyTitle],
  );

  const postAction = useMemo(() => {
    if (!bountyTitle) {
      return null;
    }
    if (bountyTitle && bountyCoinValue) {
      return (
        <span>
          {' '}
          was granted {coinIcon} {bountyCoinValue} coin{bountyCoinValue > 1 ? 's' : ''} for claiming{' '}
          {bountyTitleComponent} bounty
        </span>
      );
    } else {
      return <span> was granted a {bountyTitleComponent} bounty</span>;
    }
  }, [bountyCoinValue, bountyTitle, coinIcon, bountyTitleComponent]);

  const onClickPostContainer = () => {
    onClickPost(props.activity, feedContext.refresh);
  };

  const onClickMenu = (event: SyntheticEvent) => {
    event.stopPropagation();
    setIsMenuVisible(true);
  };

  const onCloseMenu = () => {
    setIsMenuVisible(false);
  };

  const onPostEdit = () => {
    props.onPostEdit(props.activity);
  };

  const onPostRemove = () => {
    props.onPostRemove(props.activity);
  };

  if (verb !== 'post') {
    return null;
  }

  const isAuthor = getFeedUserId(user.email) === actor?.id;
  const canManage = isAuthor || roleThatCanManage;

  return (
    <div className={styles.FeedPost}>
      <div className={styles.FeedPostContent}>
        <div
          data-testid="postContainer"
          onClick={onClickPostContainer}
          onKeyUp={(e) => isKeyboardEvent(e) && onClickPostContainer()}
          role="button"
          tabIndex={0}
        >
          <div className={styles.PostTop}>
            <div className={styles.AvatarContainer}>
              <Avatar
                round
                src={publisherAvatar}
                name={publisherName || actor?.data.name}
                maxInitials={2}
                size="32px"
              />
            </div>
            <div className={styles.TopCenterContainer}>
              <div className={styles.Title}>
                <span className={styles.TitleName}>{publisherName || actor?.data.name}</span>
                <TeamIcon teamName={teamName} teamIconLocation={teamIconLocation} />
                {postAction}
              </div>
              <div className={styles.Date}>{formatDate(time, DATE_FORMAT_MONTH_DAY_LABEL)}</div>
            </div>
            <div className={styles.TopRightContainer}>
              {(canManage || isMobile) && (
                <>
                  <button
                    data-type="feed-menu-button"
                    className={styles.MenuIcon}
                    onClick={onClickMenu}
                  >
                    <MenuIcon />
                  </button>
                  {isMenuVisible && (
                    <div className={styles.PostMenu}>
                      <FeedPostMenu
                        onMenuEdit={onPostEdit}
                        onMenuRemove={onPostRemove}
                        onCloseMenu={onCloseMenu}
                        canManage={canManage}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.PostContent}>
            <div
              className={styles.FeedPostText}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(marked(message || '', { breaks: true })),
              }}
            />
          </div>
          <PostMedia activity={props?.activity} />
        </div>
        <div className={styles.PostActivityContainer}>
          <PostReactions activity={props.activity} showText />
        </div>
      </div>
    </div>
  );
};

export default FeedPost;

export interface FeedPostProps extends FeedActivityProps {
  user: Partial<UserApiModel>;
  onPostEdit: (activity: Activity) => void;
  onPostRemove: (activity: Activity) => void;
  onClickPost: (activity: Activity, refreshFeed?: RefreshFeedCallbackFunction) => void;
  isAllFilter?: boolean;
}
