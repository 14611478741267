import { ReactNode, useState } from 'react';

import cx from 'classnames';

import { Button } from 'design-system/Atoms/Button';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './Accordion.module.scss';

export type AccordionProps = {
  children: ReactNode;
  leftText?: string;
  rightText?: string;
  classNames?: string;
};

export const Accordion = (props: AccordionProps) => {
  const { children, leftText, rightText, classNames } = props;

  const [isOpened, setIsOpened] = useState(false);

  const onClickHandle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div data-testid="TestId__ACCORDIONWRAPPER" className={cx(styles.Accordion, classNames)}>
      <div className={styles.HeaderContainer}>
        <div className={styles.Header}>
          {leftText && (
            <Paragraph
              data-testid="TestId__ACCORDIONPARAGRAPHLEFT"
              variant="ui-regular"
              className={styles.LeftText}
            >
              {leftText}
            </Paragraph>
          )}
          {rightText && (
            <Paragraph
              data-testid="TestId__ACCORDIONPARAGRAPHRIGHT"
              variant="ui-regular"
              className={styles.RightText}
            >
              {rightText}
            </Paragraph>
          )}
        </div>
        <Button
          as="button"
          variant="icon"
          iconName={isOpened ? 'chevron-up' : 'chevron-down'}
          layout="autolayout"
          data-testid="TestId__ACCORDIONBUTTONCHEVRON"
          className={styles.Icon}
          onClick={onClickHandle}
        />
      </div>
      {isOpened && <div className={styles.Content}>{children}</div>}
    </div>
  );
};
