import { SyntheticEvent, useState } from 'react';

import c from 'classnames';
import { CommentList, SinglePost, LoadMorePaginator, useFeedContext } from 'react-activity-feed';

import FluidButton from 'components/buttons/FluidButton';
import { FEED } from 'consts/constants';
import { useIsMobile, useCurrentUser } from 'utils/hooks';

import { ReactComponent as CommentIcon } from '../Comment.svg';
import {
  Activity,
  DefaultAT,
  POST_TYPES,
  FeedActivityProps,
  Comment,
  LoadMorePaginatorProps,
  LoadMoreButtonProps,
  SimpleActivity,
  LoadMorePaginatorChildrenType,
} from '../Feed.types';
import FeedComment from '../FeedComment';
import { ReactComponent as LikeIcon } from '../Like.svg';
import { PostComment } from '../PostComment';

import styles from './PostReactions.module.scss';

const PostReactions = (props: PostLikesProps) => {
  const feed = useFeedContext();

  const {
    activity: { id, reaction_counts, own_reactions, type, mediaImages = [], mediaVideos = [] },
    showText,
  } = props;

  const isPinnedPost = type === POST_TYPES.PINNED_POST;
  const medias = [
    ...mediaVideos.map((v) => ({ type: 'video', publicId: v })),
    ...mediaImages.map((i) => ({ type: 'image', publicId: i })),
  ];
  const hasMedia = medias.length > 0;
  const isMobile = useIsMobile();
  const user = useCurrentUser();

  const [isUpdating, setIsUpdating] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [currentComments, setCurrentComments] = useState(reaction_counts?.comment ?? 0);
  const hasLiked = !!own_reactions?.like?.length;
  const likesCount = reaction_counts?.like ?? 0;
  const commentsCount = currentComments ? currentComments : reaction_counts?.comment ?? 0;
  const hasComments = commentsCount > 0;

  const onClickLikeButton = async (event: SyntheticEvent) => {
    event.stopPropagation();
    if (isUpdating) return;

    const newActivity = {
      ...props.activity,
      actor: props.activity?.actor.data.name,
    } as SimpleActivity;

    setIsUpdating(true);
    if (feed?.onAddReaction) {
      if (!hasLiked) {
        await feed.onAddReaction(
          'like',
          newActivity,
          {
            actor: user?.displayName,
            actorAvatar: user?.avatarUrl,
            verb: 'like',
            object: props.activity.id,
            time: new Date().toISOString(),
          },
          {
            targetFeeds: [`notification:${props.activity.actor.id}`],
          },
        );
      } else {
        const [reaction] = own_reactions?.like || [];
        await feed?.onRemoveReaction('like', newActivity, reaction.id);
      }
      await feed?.refresh();
    }

    setIsUpdating(false);
  };

  const onClickCommentsButton = (event: SyntheticEvent) => {
    event.stopPropagation();
    setShowComments(!showComments);
  };

  const likeButtonClass = c(styles.PostLikes, { [styles.Active]: hasLiked });
  const commentsButtonClass = c(styles.PostLikes, { [styles.Active]: showComments });
  const showButtonsText = !isMobile && showText;
  const feedGroup = isPinnedPost ? FEED.PINNED_FEED : FEED.GLOBAL_FEED;

  return (
    <div>
      <div className={styles.ReactionIcons}>
        <div className={styles.CommentIconWrapper}>
          <button
            className={commentsButtonClass}
            onClick={onClickCommentsButton}
            disabled={isUpdating}
            data-testid="commentButton"
          >
            <CommentIcon className={styles.CommentIcon} />
            <span>
              {commentsCount}
              {showButtonsText && (commentsCount === 1 ? ' comment' : ' comments')}
            </span>
          </button>
        </div>
        <button
          className={likeButtonClass}
          onClick={onClickLikeButton}
          disabled={isUpdating}
          data-testid="likeButton"
        >
          <LikeIcon className={styles.LikeIcon} />
          <span>
            {likesCount}
            {showButtonsText && (likesCount === 1 ? ' like' : ' likes')}
          </span>
        </button>
      </div>

      {showComments && !isPinnedPost && (
        <div>
          <SinglePost
            activityId={id}
            feedGroup={feedGroup}
            Activity={(feedProps: FeedActivityProps) => {
              const commentCounter = feedProps.activity.reaction_counts?.comment || 5;
              return (
                <>
                  <PostComment
                    activity={props.activity}
                    onSuccess={() => setCurrentComments(currentComments + 1)}
                    emojiPickerPosition={hasMedia || (!hasComments && !isMobile) ? 'top' : 'bottom'}
                  />
                  <CommentList
                    activityId={id}
                    CommentItem={(commentProps: Comment) => {
                      return <FeedComment comment={commentProps.comment} />;
                    }}
                    Paginator={(paginatorProps: LoadMorePaginatorProps) => {
                      let commentsLeft = 0;
                      commentsLeft =
                        commentCounter -
                        (paginatorProps?.children as LoadMorePaginatorChildrenType)?.size;
                      return (
                        <LoadMorePaginator
                          {...paginatorProps}
                          LoadMoreButton={(loadButtonProps: LoadMoreButtonProps) => {
                            if (paginatorProps.hasNextPage) {
                              return (
                                <FluidButton
                                  className={styles.LoadMoreButton}
                                  level="tertiary"
                                  {...loadButtonProps}
                                  onClick={() => {
                                    if (loadButtonProps.onClick) loadButtonProps.onClick();
                                  }}
                                >
                                  View {commentsLeft} more comments
                                </FluidButton>
                              );
                            } else return null;
                          }}
                        />
                      );
                    }}
                  />
                </>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

interface PostLikesProps {
  activity: Activity<DefaultAT>;
  showText?: boolean;
}

export default PostReactions;
