import { memo, ReactNode } from 'react';

import { Face, Paragraph, Tile, moodMap, Mood } from 'design-system';
import { Link } from 'react-router-dom';

import styles from './MoodPicker.module.scss';

export type MoodPickerProps = {
  selectedMood?: Mood;
  handleMoodPick?: (mood: Mood) => void;
};
export const MoodPicker = memo<MoodPickerProps>((props) => {
  const pickable = !!props.handleMoodPick;

  const injectHelpMessage = (element: ReactNode) =>
    pickable ? (
      <div>
        {element}
        <div>
          {['verySad', 'sad'].includes(props.selectedMood || 'neutral') && (
            <Paragraph variant="ui-regular">
              Consider taking advantage of your <Link to="/unleash">Unleash+</Link> budget to help
              get more energized, relax more, do more of what you love, get more active/healthy,
              utilize therapy or meditation, or more ways we can financially support your wellbeing.
            </Paragraph>
          )}
        </div>
      </div>
    ) : (
      <>{element}</>
    );

  return injectHelpMessage(
    <div data-testid="TestId__MOODPICKERWRAPPER" className={styles.MoodPicker}>
      {pickable
        ? Object.keys(moodMap).map((mood, index) => (
            <Tile
              key={index}
              active={props.selectedMood === mood}
              onClick={() => props.handleMoodPick?.(mood as Mood)}
              tabIndex={index + 1}
            >
              <Face mood={mood as Mood} />
            </Tile>
          ))
        : !!props.selectedMood && (
            <Tile active>
              <Face mood={props.selectedMood} />
            </Tile>
          )}
    </div>,
  );
});
