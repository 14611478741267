import { queryCache } from 'react-query';

import { operations } from 'types/apiTypes';
import { Definitions, File, Get, UserApiModel } from 'types/types';

import { getApiMutationHook, XhqFetcherOptionsParam } from './xhqFetcher';

// -------- Helper functions ------- BEGIN
// These helper functions are to improve readability and follow "DRY" concept
export const invalidateUserGroups = () => {
  queryCache.invalidateQueries(['/user-groups']);
};

export const refetchUserGroups = () => {
  queryCache.refetchQueries(['/user-groups']);
};

export const refetchInvoices = () => {
  queryCache.refetchQueries(['/invoices']);
};

export const invalidateCurrentInvoiceAnswers = () => {
  queryCache.invalidateQueries(['/invoices/current']);
  queryCache.invalidateQueries(['/answers/current']);
};

export const invalidateLegends = () => {
  queryCache.invalidateQueries(['/legends'], { refetchInactive: true });
};

export const invalidateLegacy = () => {
  queryCache.invalidateQueries(['/legacy'], { refetchInactive: true });
};

export const unleashCorrectionOnSuccess = (_: unknown, variables: XhqFetcherOptionsParam) => {
  const userId = variables?.params.userId;
  queryCache.invalidateQueries(['/users/:userId/unleash-budget', { params: { userId } }]);
  queryCache.invalidateQueries(['/users/:userId/invoices/unleash', { params: { userId } }]);
  queryCache.invalidateQueries(['/me/unleash-budget']);
};

export const unleashBonusGroupOnSuccess = (response: unknown) => {
  (response as { count: number; users: { userId: string; name: string }[] }).users.forEach(
    ({ userId }) => {
      unleashCorrectionOnSuccess(undefined, { params: { userId } });
    },
  );
};

export const addOrRemoveUsersFromGroupChatOnSuccess = (
  _: unknown,
  variables: XhqFetcherOptionsParam,
) => {
  const userGroupId = variables?.params.userGroupId;
  queryCache.invalidateQueries([
    '/user-groups/:groupId/chat/members',
    { params: { groupId: userGroupId } },
  ]);
};
export const manageMultipleUsersToGroupOnSuccess = (
  _: unknown,
  variables: XhqFetcherOptionsParam,
) => {
  const userGroupId = variables?.params.userGroupId;
  queryCache.invalidateQueries(['/user-groups/:groupId', { params: { groupId: userGroupId } }]);
  queryCache.invalidateQueries([
    '/user-groups/:groupId/members',
    { params: { groupId: userGroupId } },
  ]);

  invalidateUserGroups();
};

export const onError = (error: any) => {
  // throw "new Error" instead of throwing only the "error"
  // Ref: https://basarat.gitbook.io/typescript/type-system/exceptions#always-use-error
  throw new Error(error);
};
// -------- Helper functions ------- END

export const usePatchRateRequestUpdateMutation = getApiMutationHook<
  Get<Definitions, ['patchRateUpdateRequestRequestIdStatusResponse']>,
  { requestId: number; status: string }
>(
  '/rate-update-request/:requestId/:status',
  { method: 'PATCH' },
  { useErrorBoundary: false, throwOnError: true, suspense: false },
);

export const useUnleashCorrectionMutation = getApiMutationHook<unknown, { userId: number }>(
  '/users/:userId/unleash-budget/correction',
  { method: 'POST' },
  {
    onSuccess: unleashCorrectionOnSuccess,
  },
);

export const useProductiveEntriesMutation = getApiMutationHook<
  unknown,
  undefined,
  { productiveId: string; start: string; end: string }
>('/productive_time_entries', { method: 'POST' });

export const useStringFlagAvailableValuesMutation = getApiMutationHook<
  Get<Definitions, ['postStringFlagAvailableValuesResponse']>,
  undefined,
  { flagName: string }
>('/string_flag_available_values', { method: 'POST' });

export const useStringFlagSetValueMutation = getApiMutationHook<
  string,
  undefined,
  { flagName: string; value: string }
>('/string_flag_set_value', { method: 'PATCH' });

export const useUnleasGroupBonusMutation = getApiMutationHook<unknown, { groupId: number }>(
  '/groups/:groupId/unleash-budget/bonus',
  { method: 'POST' },
  {
    onSuccess: unleashBonusGroupOnSuccess,
  },
);

export const usePickTeamMutation = getApiMutationHook(
  '/me/teams/pick',
  { method: 'PATCH' },
  { suspense: false },
);

export const useSendAuthEmail = getApiMutationHook<
  undefined,
  undefined,
  Get<Definitions, ['postAuthSendEmailPayload']>
>('/sessions/send-email', { method: 'POST' });

export const useValidateEmailTokenLink = getApiMutationHook<
  Get<Definitions, ['postAuthEmailResponse']>,
  undefined,
  Get<Definitions, ['postAuthEmailPayload']>
>('/sessions/email', { method: 'POST' });

export const useFeedPost = getApiMutationHook<
  unknown,
  undefined,
  Get<Definitions, ['feedPostSubmit']>
>('/feed/post', { method: 'POST' });

export const useFeedUserPostDeleteMutation = getApiMutationHook<unknown, { postId: string }>(
  '/feed/post/:postId',
  { method: 'DELETE' },
);

export const useFeedPostUpdateMutation = getApiMutationHook<
  unknown,
  { postId: string },
  { text: string }
>('/feed/post/:postId', { method: 'PUT' });

export const useFeedPinnedPostDeleteMutation = getApiMutationHook<unknown, { postId: string }>(
  '/feed/pinned/:postId',
  { method: 'DELETE' },
);

export const useFeedPinnedPostUpdateMutation = getApiMutationHook<
  unknown,
  { postId: string },
  Get<Definitions, ['feedPinnedPostUpdate']>
>('/feed/pinned/:postId', { method: 'PUT' });

export const useGenerateCloudinaryAuthSignature = getApiMutationHook<
  Get<Definitions, ['postFeedMediaAuthResponse']>
>('/feed/media/auth', { method: 'POST' });

export const useModifyTeamMembersMutation = getApiMutationHook<
  unknown,
  { teamId: number; operation: 'add' | 'remove' }
>(
  '/teams/:teamId/members/:operation',
  { method: 'PATCH' },
  { useErrorBoundary: false, throwOnError: true },
);

export const useCreateUserGroupMutation = getApiMutationHook<
  unknown,
  undefined,
  { name: string; _adminId: number; _membersIds: number[] }
>(
  '/user-groups',
  { method: 'POST' },
  {
    onSuccess: invalidateUserGroups,
    onError,
  },
);

export const useUpdateUserGroup = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { name: string; _adminId: number }
>(
  '/user-groups/:userGroupId',
  { method: 'PUT' },
  {
    onSuccess: invalidateUserGroups,
    onError,
  },
);

export const useCreateChat = getApiMutationHook<unknown, { userGroupId: number }>(
  '/user-groups/:userGroupId/chat',
  { method: 'POST' },
  {
    onSuccess: refetchUserGroups,
    onError,
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const useGetUserGroupChatMemberList = getApiMutationHook<
  Get<Definitions, ['getUserGroupsUserGroupIdChatMembersResponse']>,
  { userGroupId: number }
>(
  '/user-groups/:userGroupId/chat/members',
  { method: 'GET' },
  {
    onError,
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const useAddOrRemoveUsersFromGroupChat = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { operation: 'add' | 'remove'; membersList: number[] }
>(
  '/user-groups/:userGroupId/chat/members',
  { method: 'PATCH' },
  {
    onSuccess: addOrRemoveUsersFromGroupChatOnSuccess,
  },
);

export const useDeleteUserGroup = getApiMutationHook<unknown, { userGroupId: number }>(
  '/user-groups/:userGroupId',
  { method: 'DELETE' },
  {
    onSuccess: invalidateUserGroups,
  },
);

export const useAddRemoveUserToGroup = getApiMutationHook<
  unknown,
  { userGroupId: number; userId: number },
  { operation: 'add' | 'remove' }
>(
  '/user-groups/:userGroupId/members/:userId',
  { method: 'PATCH' },
  {
    onSuccess: invalidateUserGroups,
  },
);

export const useManageMultipleUsersToGroupMutation = getApiMutationHook<
  unknown,
  { userGroupId: number },
  { operation: 'add' | 'remove'; membersList: number[] }
>(
  '/user-groups/:userGroupId/members',
  { method: 'PATCH' },
  {
    onSuccess: manageMultipleUsersToGroupOnSuccess,
  },
);

export const useRunInvoicesPaymentMutation = getApiMutationHook<
  Get<Definitions, ['putRunInvoicesPaymentResponse']>
>(
  '/invoices/run-payment',
  { method: 'PUT' },
  {
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: refetchInvoices,
  },
);

export const useUpdateInvoiceMutation = getApiMutationHook<
  Get<Definitions, ['putInvoicesInvoiceIdResponse']>,
  { invoiceId: number }
>(
  '/invoices/:invoiceId',
  { method: 'PUT' },
  {
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: refetchInvoices,
  },
);

export const useDeleteInvoiceItemMutation = getApiMutationHook<unknown, { invoiceItemId: number }>(
  '/invoice-items/:invoiceItemId',
  { method: 'DELETE' },
);

export const useUpdateUserMutation = getApiMutationHook<
  Get<Definitions, ['putUsersUserIdResponse']>,
  { userId: number },
  { userData?: Partial<UserApiModel>; updateSource?: 'accounting' | 'profile' }
>('/users/:userId', { method: 'PUT' }, { suspense: false });

export const usePutInvoiceItemMutation = getApiMutationHook<
  Get<Definitions, ['putInvoiceItemsInvoiceItemIdResponse']>,
  { invoiceItemId: number },
  Get<Definitions, ['putInvoiceItemsInvoiceItemIdPayload']>
>('/invoice-items/:invoiceItemId', { method: 'PUT' });

export const usePostInvoiceItemMutation = getApiMutationHook<
  Get<Definitions, ['postInvoiceItemsResponse']>,
  undefined,
  Get<Definitions, ['postInvoiceItemsPayload']>
>(
  '/invoice-items',
  {
    method: 'POST',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePostUserBountyListMutation = getApiMutationHook<
  Get<Definitions, ['postUsersUserIdBountyListResponse']>,
  { userId: number | undefined }
>(
  '/users/:userId/bounty-list',
  {
    method: 'POST',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePutUserBountyListMutation = getApiMutationHook<
  Get<Definitions, ['putUsersUserIdBountyListBountyListIdResponse']>,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId',
  {
    method: 'PUT',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePutUserBountyListBountiesMutation = getApiMutationHook<
  Get<Definitions, ['putUsersUserIdBountyListBountyListIdBountiesResponse']>,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId/bounties',
  {
    method: 'PUT',
  },
  { suspense: false, useErrorBoundary: false },
);

export const useDeletetUserBountyListMutation = getApiMutationHook<
  unknown,
  { userId: number | undefined; bountyListId: number | undefined }
>(
  '/users/:userId/bounty-list/:bountyListId',
  {
    method: 'DELETE',
  },
  { suspense: false, useErrorBoundary: false },
);

export const usePostLegendsMutation = getApiMutationHook<
  Get<Definitions, ['postLegendsResponse']>,
  undefined,
  Get<Definitions, ['postLegendsPayload']>
>(
  '/legends',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegends,
  },
);

export const usePutLegendsMutation = getApiMutationHook<
  Get<Definitions, ['putLegendsLegendIdResponse']>,
  { legendId: number },
  Get<Definitions, ['putLegendsLegendIdPayload']>
>(
  '/legends/:legendId',
  {
    method: 'PUT',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegends,
  },
);

export const useDeleteLegendsMutation = getApiMutationHook<unknown, { legendId: number }>(
  '/legends/:legendId',
  {
    method: 'DELETE',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegends,
  },
);

export const usePostLegacyMutation = getApiMutationHook<
  Get<Definitions, ['postLegacyResponse']>,
  undefined,
  Get<Definitions, ['postLegacyPayload']>
>(
  '/legacy',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegacy,
  },
);

export const usePutLegacyMutation = getApiMutationHook<
  Get<Definitions, ['putLegacyLegendIdResponse']>,
  { legacyId: number }
>(
  '/legacy/:legacyId',
  {
    method: 'PUT',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegacy,
  },
);

export const useDeleteLegacyMutation = getApiMutationHook<unknown, { legacyId: number }>(
  '/legacy/:legacyId',
  {
    method: 'DELETE',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateLegacy,
  },
);

export const useGetFeedPost = getApiMutationHook<
  { activity: Get<Definitions, ['activity']> },
  { postId: string }
>('/feed/post/:postId', {
  method: 'GET',
});

export const useGetPostParticipants = getApiMutationHook<
  { participantIds: Get<Definitions, ['participantIds']> },
  { postId: string }
>('/feed/post/:postId/participants', {
  method: 'GET',
});

export const requestAwStatus = getApiMutationHook<
  { updated: boolean },
  { invoiceId: string | number }
>(
  '/invoices/update-airwallex-invoice-status/:invoiceId',
  {
    method: 'GET',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: refetchInvoices,
  },
);

export const useAddressFinder = getApiMutationHook<
  Get<Definitions, ['postAddressFindResponse']>,
  undefined
>('/address/find', { method: 'POST' }, { suspense: false });

export const useGetSearch = getApiMutationHook<Get<Definitions, ['getSearchResponse']>, undefined>(
  '/search',
  { method: 'GET' },
  { suspense: false },
);

export const usePostGroupMessageMutation = getApiMutationHook<
  { text: string; attachments: File[] },
  { groupId: number }
>('/user-groups/:groupId/members/notify', {
  method: 'POST',
});

export const usePostAnswersMutation = getApiMutationHook<
  Get<Definitions, ['postAnswersResponse']>,
  undefined
>(
  '/answers',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateCurrentInvoiceAnswers,
  },
);

export const usePutAnswersMutation = getApiMutationHook<
  Get<Definitions, ['putAnswersResponse']>,
  { answerId: number }
>(
  '/answers/:answerId',
  {
    method: 'PUT',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
    onSuccess: invalidateCurrentInvoiceAnswers,
  },
);

export const usePatchCurrentInvoiceMutation = getApiMutationHook<
  Get<Definitions, ['patchInvoicesCurrentResponse']>,
  undefined
>(
  '/invoices/current',
  {
    method: 'PATCH',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const usePostReferralMutation = getApiMutationHook<
  Get<Definitions, ['postReferralResponse']>,
  undefined
>(
  '/referral',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const usePostRateUpdateRequest = getApiMutationHook<
  Get<Definitions, ['postRateupdaterequest']>,
  undefined
>(
  '/rate-update-request',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: true,
  },
);

export const usePostProjectsMutation = getApiMutationHook<
  Get<Definitions, ['postProjectsResponse']>,
  undefined,
  Get<operations, ['postProjects', 'parameters', 'body', 'body']>
>(
  '/projects',
  {
    method: 'POST',
  },
  {
    suspense: false,
    useErrorBoundary: false,
    throwOnError: false,
  },
);
