import { memo } from 'react';

import { Link } from 'react-router-dom';

import BountyCard from 'components/bounties/BountyCard';
import { BountyTypes } from 'pages/Bounties/Bounties.types';
import { useGetMyUnlockableBounties, useGetSettings } from 'utils/apiQueryHooks';
import { getSettingByName } from 'utils/helpers';

import styles from './HomeBounties.module.scss';

const HomeBounties = memo(() => {
  const { resolvedData: myUnlockableBounties } = useGetMyUnlockableBounties();
  const { resolvedData: settings } = useGetSettings();
  const bounties = myUnlockableBounties?.data as BountyTypes[];

  if (!bounties?.length) {
    return null;
  }
  const seasonSettings = getSettingByName(settings?.data, 'season');
  const activeSeason = seasonSettings?.number || 1;

  return (
    <section className={styles.Bounties}>
      <h3 className={styles.sectionTitle}>Choose your own adventure</h3>
      <Link to={`/bounties?season=${activeSeason}`} className={styles.titleLink}>
        All Bounties
      </Link>
      <div className={styles.bountiesGrid}>
        {bounties.map((bounty) => (
          <BountyCard
            className={styles.BountyItem}
            bounty={bounty}
            showFooter={false}
            showActions={false}
          />
        ))}
      </div>
    </section>
  );
});

export default HomeBounties;
