import { memo } from 'react';

import { UnleashCategory } from 'types/types';
import { useGetUnleashCategories } from 'utils/apiQueryHooks';

import { ApiQueryProps, ApiQuerySelect, OptionFields } from '../ApiQuerySelect';

const globalOption: OptionFields = {
  id: -1,
  name: 'Global',
};

function UnleashCategorySelect<
  CategoryType extends UnleashCategory | UnleashCategoryWithSpecialOptions = UnleashCategory
>(props: Props<CategoryType>) {
  const { isReimbursement } = props;
  return (
    <ApiQuerySelect
      placeholder="Select Unleash+ Category"
      useGetQuery={useGetUnleashCategories}
      processOptions={(options) =>
        isReimbursement ? [...options] : [globalOption as CategoryType, ...options]
      }
      {...props}
    />
  );
}

export default memo(UnleashCategorySelect) as typeof UnleashCategorySelect;

export type UnleashCategoryWithSpecialOptions = Omit<UnleashCategory, 'id'> & {
  id: number | 'GLOBAL';
};

export type Props<CategoryType> = Omit<ApiQueryProps<CategoryType, false>, 'useGetQuery'> & {
  useGetQuery?: any;
  isReimbursement?: boolean;
};
