import { useState } from 'react';

import c from 'classnames';
import { useFormik, FormikProps } from 'formik';
import { EmojiPicker } from 'react-activity-feed';
import Avatar from 'react-avatar';
import TextareaAutosize from 'react-textarea-autosize';
import * as Yup from 'yup';
import 'react-activity-feed/dist/index.css';

import Button from 'components/buttons/Button';
import { ReactComponent as EmojiIcon } from 'design-system/assets/Icon/Media/emoji.svg';

import styles from './CreateComment.module.scss';

// Emojis types taken from node_modules/react-activity-feed/src/components/EmojiPicker.tsx

export type EmojiSkin = 1 | 2 | 3 | 4 | 5 | 6;
export interface BaseEmoji {
  id: string;
  name: string;
  colons: string;
  emoticons: string[];
  unified: string;
  skin: EmojiSkin | null;
  native: string;
}
export interface CustomEmoji {
  id?: string;
  colons?: string;
  name: string;
  short_names: string[];
  emoticons?: string[];
  keywords?: string[];
  imageUrl: string;
  native?: string;
}
export type Emoji = BaseEmoji | CustomEmoji;

const EMOJI_ID = 'xhq_emoji_button';

const schema = Yup.object({
  text: Yup.string().trim().required('Required'),
});

type UserCommentProps = {
  avatarUrl?: string;
  displayName?: string;
  maxInitials: number;
};

export type CreatePostProps = {
  formik: FormikProps<{ text: string }>;
  emojiPickerPosition?: 'top' | 'bottom';
  userProps: UserCommentProps;
};

const CreatePost = ({ formik, userProps, emojiPickerPosition }: CreatePostProps) => {
  const [isTextAreaFocused, setTextAreaFocus] = useState(false);
  return (
    <>
      <TextareaAutosize
        name="text"
        value={formik.values.text}
        onFocus={() => setTextAreaFocus(true)}
        onBlur={(e) => {
          if ((e.relatedTarget as HTMLTextAreaElement)?.id === EMOJI_ID) return;
          setTextAreaFocus(false);
        }}
        className={c({
          [styles.FocusTextarea]: isTextAreaFocused || formik.values.text.length > 0,
        })}
        placeholder="Add comment..."
        onChange={formik.handleChange}
      />
      {userProps && (
        <div className={styles.AvatarContainer}>
          <Avatar
            round
            src={userProps?.avatarUrl}
            name={userProps?.displayName}
            maxInitials={2}
            size="32px"
          />
        </div>
      )}
      <div className={styles.Icon}>
        <EmojiIcon data-testid="TEST_ID_EMOJI-ICON" onClick={() => setTextAreaFocus(true)} />
      </div>
      <div
        id={EMOJI_ID}
        className={c(
          styles.EmojiPickerButton,
          emojiPickerPosition === 'top' && styles.TopEmojiPicker,
        )}
        role="button"
        tabIndex={0}
        data-testid="TEST_ID_EMOJI-PICKER"
        onClick={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <EmojiPicker
          onSelect={({ native }: Emoji) =>
            formik.setFieldValue('text', formik.values.text + native, true)
          }
        />
      </div>
      <Button
        className={styles.PostButton}
        type="submit"
        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
      >
        Post
      </Button>
    </>
  );
};

export type CreateCommentProps = {
  onSubmitComment: (text: string) => void;
  emojiPickerPosition?: 'top' | 'bottom';
  userProps: UserCommentProps;
};

export const CreateComment = ({
  userProps,
  onSubmitComment,
  emojiPickerPosition = 'bottom',
}: CreateCommentProps) => {
  const formik = useFormik({
    initialValues: { text: '' },
    validationSchema: schema,
    async onSubmit(values, helper) {
      const { text } = values;
      await onSubmitComment(text);
      helper.resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.CreateComment}>
      <div className={styles.Field}>
        <CreatePost
          formik={formik}
          userProps={userProps}
          emojiPickerPosition={emojiPickerPosition}
        />
      </div>
    </form>
  );
};
