import { memo } from 'react';

import { Panel, Columns, ColumnsFullRow, getPaymentMethod } from 'design-system';

import { getPaymentMethodFields, getFieldValue, DataFields } from 'design-system/utils';

import { ViewEntry } from './ViewEntry';

export const PaymentView = memo((props: { data: DataFields }) => {
  const paymentMethodFields = getPaymentMethodFields(props.data?.paymentType?.name);
  const pickedPayment = getPaymentMethod(props.data?.paymentType?.name);

  if (!pickedPayment) {
    return null;
  }

  return (
    <Panel variant="spaced">
      <Columns>
        <ViewEntry title="Payment via">{pickedPayment.label}</ViewEntry>
        {paymentMethodFields.map((field) => (
          <ViewEntry key={field.name} title={field.label}>
            {getFieldValue(field.name, props.data)}
          </ViewEntry>
        ))}
        <ColumnsFullRow>
          <ViewEntry title="Notes">{props.data?.notes}</ViewEntry>
        </ColumnsFullRow>
      </Columns>
    </Panel>
  );
});
