import { useEffect } from 'react';

import { AvatarGroup, useFeedContext } from 'react-activity-feed';

import { SnackbarAlert, SNACKBAR_ALERT_TYPES } from 'components/SnackbarAlert';

import { NotifierProps, Activity, UserFromActivityProps, DefaultAT } from '../Feed.types';

import styles from '../Notifier/Notifier.module.css';

export interface AutoNotifierProps extends NotifierProps {
  showToast?: boolean;
  type?: SNACKBAR_ALERT_TYPES;
  content?: string;
}

// Workaround to auto refresh pinned posts
export const AutoNotifier = ({
  showToast = true,
  type = SNACKBAR_ALERT_TYPES.error,
  content,
  ...props
}: AutoNotifierProps) => {
  const feed = useFeedContext();

  useEffect(() => {
    if (props?.shouldRefresh) {
      feed.refresh();
      if (props?.triggerFeedRefresh) {
        props.triggerFeedRefresh(false);
      }
    }
  }, [props, props.shouldRefresh, props?.adds?.length, props.onClick, feed]);

  const activityWord = props.adds?.length === 1 ? 'activity' : 'activities';

  if (!props.adds?.length || !showToast) return null;

  return (
    <SnackbarAlert onClick={props.onClick} isOpened={props.adds?.length > 0} type={type}>
      {content && content}
      {!content && (
        <div className={styles.Content}>
          <div className={styles.ActivitiesInfo} data-testid="activities-text">
            You have <b>{`${props.adds.length} new `}</b>
            {activityWord}
          </div>
          <div className={styles.AvatarsContainer}>
            <AvatarGroup
              style={{ display: 'flex', alignItems: 'center' }}
              limit={3}
              users={props.adds?.map(getUserFromActivity)}
            />
          </div>
        </div>
      )}
    </SnackbarAlert>
  );
};

export function getUserFromActivity(activity: Activity<DefaultAT>): UserFromActivityProps {
  return {
    created_at:
      typeof activity?.actor === 'string' ? activity.actor : activity.actor?.created_at || '',

    updated_at:
      typeof activity?.actor === 'string' ? activity.actor : activity.actor?.updated_at || '',
    id: activity.id,
    data: {
      name: activity.publisherName,
      profileImage: activity.publisherAvatar,
    },
  };
}
