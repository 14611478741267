import { MouseEvent, ReactNode } from 'react';

import styles from './inputResult.module.scss';

export interface DataList {
  id: string | number;
  value: string;
  icon?: ReactNode;
}

export interface InputResultProps {
  items: DataList[] | undefined;
  value: string;
  onItemSelected: (event: MouseEvent<HTMLDivElement>, itemSelected: DataList) => void;
  inMemoryFilter?: boolean;
  highlightMatchResult?: boolean;
}

export const InputResults = (props: InputResultProps) => {
  const onItemSelectedHandler = (event: MouseEvent<HTMLDivElement>, itemSelected: DataList) => {
    props.onItemSelected(event, itemSelected);
  };

  let itemList = props.items ? [...props.items] : undefined;
  if (props.inMemoryFilter && props.items && props.items.length > 0) {
    itemList = props.items.filter((item) =>
      item.value.toLowerCase().trim().includes(props.value.toLowerCase().trim()),
    );
  }

  const iconFound = itemList?.find((item) => item.icon);
  const resultItemLabelClass = iconFound
    ? styles['resultItemLabel-with-icon']
    : styles['resultItemLabel-without-icon'];

  const listItems =
    itemList && itemList.length > 0 ? (
      itemList.map((item) => {
        let displayName = item.value;

        if (props.highlightMatchResult) {
          const regex = new RegExp(props.value.trim(), 'ig');
          const match = props.value && item.value ? item.value.trim().match(regex) : null;
          if (item.value && props.value && match && match.length > 0) {
            displayName = item.value.replace(regex, `<b>${match[0]}</b>`);
          }
        }
        return (
          <div
            key={item.id}
            className={styles.inputResultItemContainer}
            data-testid={`input-result-item-${item.id}`}
            tabIndex={0}
            role="button"
            onKeyPress={() => undefined}
            onMouseDown={(event: MouseEvent<HTMLDivElement>) => onItemSelectedHandler(event, item)}
            onClick={(event: MouseEvent<HTMLDivElement>) => onItemSelectedHandler(event, item)}
          >
            {iconFound && <div className={styles.iconContainer}>{item.icon}</div>}

            <div
              className={resultItemLabelClass}
              dangerouslySetInnerHTML={{ __html: displayName }}
              key={item.id}
            />
          </div>
        );
      })
    ) : (
      <div>No results</div>
    );

  return (
    <div className={styles.inputResultContainer} data-testid="INPUT_RESULTS">
      {listItems}
    </div>
  );
};
