import { memo } from 'react';

import cx from 'classnames';

import styles from './Divider.module.scss';

export interface DividerProps {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
}

export const Divider = memo<DividerProps>(({ className, orientation = 'horizontal' }) => {
  const classes = cx(
    {
      [styles.Horizontal]: orientation === 'horizontal',
      [styles.Vertical]: orientation === 'vertical',
    },
    className,
  );
  return <div data-testid="TestId__DIVIDER" className={classes}></div>;
});
