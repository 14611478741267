import { HTMLProps, memo, PropsWithChildren } from 'react';

import { Paragraph } from 'design-system/Atoms';
import { DateRangeCard, DateRangeCardType } from 'design-system/Molecules';

import styles from './MonthDateRanges.module.scss';

export interface DateRangesData {
  type: DateRangeCardType;
  disabled: boolean;
}

export type SelectedCardType = {
  key: string;
  rangeType: string;
  month: string;
};
export interface MonthDateRangesProps extends HTMLProps<HTMLDivElement> {
  month: string;
  className?: string;
  dateRangesData: DateRangesData[];
  selectedCard: SelectedCardType;
  setSelectedCard: (arg0: SelectedCardType) => void;
  setDateRange?: (arg0: { dateFrom: Date; dateTo: Date }) => void;
}

export const MonthDateRanges = memo<PropsWithChildren<MonthDateRangesProps>>(
  ({
    className,
    month,
    dateRangesData,
    selectedCard,
    setSelectedCard,
    setDateRange,
  }: PropsWithChildren<MonthDateRangesProps>) => {
    return (
      <div className={className}>
        <Paragraph variant={'ui-bold'}>{month}</Paragraph>
        <div>
          <div className={styles.CardsContainer}>
            {dateRangesData.map((range) => {
              const key = month + '-' + range.type;
              const state = range.disabled
                ? 'disabled'
                : selectedCard.key === key
                ? 'selected'
                : 'default';
              return (
                <DateRangeCard
                  key={key}
                  month={month}
                  state={state}
                  type={range.type}
                  setDateRange={setDateRange}
                  onClick={() => {
                    setSelectedCard({
                      key,
                      rangeType: range.type,
                      month,
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  },
);
