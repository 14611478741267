import { MouseEventHandler } from 'react';

import cx from 'classnames';

import { Icon } from 'design-system/Atoms/Icon';
import { mimicKeyboardClick } from 'utils/keyboardEvent';

import styles from './NotificationIndicator.module.scss';

export type NotificationIndicatorProps = {
  hasUnread?: boolean;
  state: 'active' | 'inactive';
  onClick: MouseEventHandler;
};

export const NotificationIndicator = (props: NotificationIndicatorProps) => {
  const iconClasses = cx(styles.Icon, {
    [styles.Active]: props.state === 'active',
    [styles.Inactive]: props.state === 'inactive',
  });

  return (
    <div
      data-testid="TestId__NOTIFICATIONINDICATOR__WRAPPER"
      tabIndex={0}
      role="button"
      className={styles.Wrapper}
      onKeyUp={mimicKeyboardClick(props.onClick)}
      onClick={props.onClick}
    >
      {props.hasUnread ? (
        <div data-testid="TestId__NOTIFICATIONINDICATOR__UNREAD" className={styles.IconWrapper}>
          <span className={styles.UnreadIndicator} />
          <Icon className={iconClasses} name="notification" />
        </div>
      ) : (
        <Icon className={iconClasses} name="notification" />
      )}
    </div>
  );
};
