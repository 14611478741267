import { useState } from 'react';

import FluidButton from 'components/buttons/FluidButton';
import Input from 'components/inputs/Input';
import Modal from 'components/modals/ModalFullScreen';

import styles from './ZeroUnleashConfirmModal.module.scss';

const ZeroUnleashConfirmModal = ({
  onCancel,
  onConfirm,
  approver = '',
  exitClickingOutside = true,
  ...props
}: Props) => {
  const [approverName, setApproverName] = useState<string>(approver);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConfirm(approverName);
  };

  return (
    <Modal
      {...props}
      onClose={onCancel}
      modalClassName={styles.Modal}
      show
      exitClickingOutside={exitClickingOutside}
    >
      <form onSubmit={handleFormSubmit}>
        <div>
          <h4 className={styles.H4}>Unleash+ has been submitted for an invoice with 0 hours! </h4>
          <p className={styles.MainText}>
            I acknowledge that the total Unleash+ reimbursement amount is for an invoice with a
            number of 0 hours. This has been authorized by
          </p>
          <Input
            fullwidth
            placeholder="Enter approver name"
            name="approverName"
            className={styles.ApproverInput}
            value={approverName}
            onChange={(e) => setApproverName(e.target.value)}
            required
          />
        </div>

        <div className={styles.ButtonContainer}>
          <FluidButton level="secondary" type="button" onClick={onCancel}>
            Cancel
          </FluidButton>

          <FluidButton disabled={!approverName} type="submit">
            Confirm
          </FluidButton>
        </div>
      </form>
    </Modal>
  );
};

type Props = {
  onCancel: () => void;
  onConfirm: (name: string) => void;
  approver?: string;
  exitClickingOutside?: boolean;
};

export default ZeroUnleashConfirmModal;
