import { HTMLProps, memo, PropsWithChildren } from 'react';

import cx from 'classnames';
import { Card, Icon, Paragraph } from 'design-system';

import { SUBMIT_INVOICE_PERIODS } from 'consts/constants';
import { SelectionState } from 'types/types';
import {
  formatDate,
  getBusinessDaysOfDateRange,
  getFirstBimonthRange,
  getFullMonthRange,
  getSecondBimonthRange,
} from 'utils/datesHelper';

import styles from './DateRangeCard.module.scss';

export const DateRangeCardTypes = ['default', 'firstHalf', 'secondHalf'] as const;
export type DateRangeCardType = typeof DateRangeCardTypes[number];

export interface DateRangeCardProps extends HTMLProps<HTMLDivElement> {
  month: string;
  type?: DateRangeCardType;
  className?: string;
  state?: SelectionState;
  onClick: () => void;
  setDateRange?: (arg0: { dateFrom: Date; dateTo: Date }) => void;
}

export const DateRangeCard = memo<PropsWithChildren<DateRangeCardProps>>(
  ({
    className,
    month,
    type = 'default',
    state = 'default',
    onClick,
    setDateRange,
  }: PropsWithChildren<DateRangeCardProps>) => {
    const { FIRST_HALF, SECOND_HALF, FULL_MONTH } = SUBMIT_INVOICE_PERIODS;
    const DateRangeCardClasses = cx(styles.CardContainer, className, {
      [styles.Selected]: state === 'selected',
      [styles.DisabledCard]: state === 'disabled',
    });
    const disabledParagraphStyle = cx({ [styles.DisabledParagraph]: state === 'disabled' });
    const selectedParagraphStyle = cx({ [styles.SelectedParagraph]: state === 'selected' });

    const date = new Date(month);
    const daysFormat = 'do';

    const dateRangesToSelectCard = (
      date: Date,
      period: 'default' | 'firstHalf' | 'secondHalf' = FULL_MONTH,
    ) => {
      let periodDatesRange: { id: string; start: Date; end: Date };
      let description: string;

      if (period === FIRST_HALF) {
        periodDatesRange = getFirstBimonthRange(date);
        description = '1st half of the month';
      } else if (period === SECOND_HALF) {
        periodDatesRange = getSecondBimonthRange(date);
        description = '2nd half of the month';
      } else {
        periodDatesRange = getFullMonthRange(date);
        description = 'Full month';
      }

      const { start, end } = periodDatesRange;

      return {
        description,
        days: `${getBusinessDaysOfDateRange(start, end)} working days`,
        dateRange: {
          dateFrom: new Date(start),
          dateTo: new Date(end),
        },
        datesRangeFormatted: `${formatDate(start, daysFormat)} - ${formatDate(end, daysFormat)}`,
      };
    };

    const types = {
      firstHalf: {
        icon: state === 'disabled' ? 'calendar-grey-first-half' : 'calendar-colored-first-half',
        ...dateRangesToSelectCard(date, 'firstHalf'),
      },
      secondHalf: {
        icon: state === 'disabled' ? 'calendar-grey-second-half' : 'calendar-colored-second-half',
        ...dateRangesToSelectCard(date, 'secondHalf'),
      },
      default: {
        icon: state === 'disabled' ? 'calendar-grey-full' : 'calendar-colored-full',
        ...dateRangesToSelectCard(date),
      },
    };

    const handleClick = () => {
      onClick();
      if (setDateRange) {
        setDateRange(types[type]?.dateRange);
      }
    };

    return (
      <Card
        data-testid="TestId__DATE_RANGE_CARD"
        className={DateRangeCardClasses}
        type={state === 'selected' ? 'highlighted' : 'default'}
        onClick={handleClick}
      >
        <Icon name={types[type].icon} />
        <Paragraph className={disabledParagraphStyle} variant={'ui-bold'}>
          {types[type].datesRangeFormatted}
        </Paragraph>
        <Paragraph className={selectedParagraphStyle} variant={'ui-regular'}>
          {types[type].description}
        </Paragraph>
        <Paragraph className={selectedParagraphStyle} variant={'ui-regular'}>
          {types[type].days}
        </Paragraph>
      </Card>
    );
  },
);
