import { useState } from 'react';

import FluidButton from 'components/buttons/FluidButton';
import Input from 'components/inputs/Input';
import Modal from 'components/modals/ModalFullScreen';
import { UnleashCategory } from 'types/types';

import styles from './OverUnleashConfirmModal.module.scss';

const OverUnleashConfirmModal = ({
  categoryName,
  categoryLimit,
  monthlyLimit,
  availableBudget,
  onCancel,
  onConfirm,
  approver = '',
  exitClickingOutside = true,
  ...props
}: Props) => {
  const [approverName, setApproverName] = useState<string>(approver);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConfirm(approverName);
  };

  return (
    <Modal
      {...props}
      onClose={onCancel}
      modalClassName={styles.Modal}
      exitClickingOutside={exitClickingOutside}
      show
    >
      <form onSubmit={handleFormSubmit}>
        <div>
          <h4 className={styles.H4}>
            The Unleash+ {categoryLimit ? 'category ' : 'monthly'} limit has been exceeded!{' '}
          </h4>
          {categoryLimit ? (
            <p className={styles.MainText}>
              I acknowledge that the total Unleash+ reimbursement amount for the {categoryName}{' '}
              exceeds the monthly limit of {categoryLimit}. This has been authorized by
            </p>
          ) : monthlyLimit ? (
            <p className={styles.MainText}>
              I acknowledge that the total Unleash+ reimbursement amount for all categories exceeds
              the monthly limit of {monthlyLimit}. This has been authorized by
            </p>
          ) : (
            <p className={styles.MainText}>
              I acknowledge that the total Unleash+ reimbursement amount for the {categoryName}{' '}
              exceeds the available budget of {availableBudget}. This has been authorized by
            </p>
          )}
          <Input
            fullwidth
            placeholder="Enter approver name"
            name="approverName"
            className={styles.ApproverInput}
            value={approverName}
            onChange={(e) => setApproverName(e.target.value)}
            required
          />
        </div>

        <div className={styles.ButtonContainer}>
          <FluidButton level="secondary" type="button" onClick={onCancel}>
            Cancel
          </FluidButton>

          <FluidButton disabled={!approverName} type="submit">
            Confirm
          </FluidButton>
        </div>
      </form>
    </Modal>
  );
};

type Props = {
  categoryName?: string;
  categoryLimit?: UnleashCategory['limit'];
  monthlyLimit?: number;
  availableBudget?: number;
  onCancel: () => void;
  onConfirm: (name: string) => void;
  approver?: string;
  exitClickingOutside?: boolean;
};

export default OverUnleashConfirmModal;
