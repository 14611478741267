import { CreateComment } from 'design-system';
import { useFeedContext } from 'react-activity-feed';

import { useGetPostParticipants } from 'utils/apiMutationHooks';
import { useCurrentUser } from 'utils/hooks';

import { Activity, DefaultAT, SimpleActivity } from '../Feed.types';

const targetFeeds = (
  activity: Activity,
  email?: string,
  participantIds: ReadonlyArray<string | undefined | null> = [],
) => {
  return participantIds
    .filter(
      (participantId) =>
        participantId !== activity.actor.id &&
        participantId !== email?.replace('@', '').replace('.', ''),
    )
    .map((participantId) => `notification:${participantId}`);
};

export const PostComment = ({ activity, onSuccess, emojiPickerPosition = 'bottom' }: Props) => {
  const [getParticipants] = useGetPostParticipants();
  const user = useCurrentUser();
  const feed = useFeedContext();

  const newActivity = {
    ...activity,
    actor: activity?.actor.data.name,
  } as SimpleActivity;

  const onSubmit = async (text: string) => {
    if (feed?.onAddReaction) {
      await feed.onAddReaction(
        'comment',
        newActivity,
        {
          text,
          publisherAvatar: user?.avatarUrl,
          teamName: user?._team?.name,
          teamIconLocation: user?._team?.icon?.location,
          // notification properties
          actor: user?.displayName,
          actorAvatar: user?.avatarUrl,
          object: activity.id,
          time: new Date().toISOString(),
          verb: 'comment',
        },
        {
          targetFeeds: [`notification:${activity.actor.id}`],
        },
      );

      const postParticipants = await getParticipants({ params: { postId: activity.id } });

      await feed.onAddReaction(
        'notification',
        newActivity,
        {
          // notification properties
          actor: user?.displayName,
          actorAvatar: user?.avatarUrl,
          object: activity.id,
          time: new Date().toISOString(),
          verb: 'post_user_commented',
        },
        {
          targetFeeds: targetFeeds(activity, user?.email, postParticipants?.participantIds || []),
        },
      );
      await feed?.refresh();
    }

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <div data-testid="TEST_ID_CREATE_POST_CONTAINER">
      <CreateComment
        onSubmitComment={(text) => onSubmit(text)}
        userProps={{
          avatarUrl: user?.avatarUrl,
          displayName: user?.displayName,
          maxInitials: 2,
        }}
        emojiPickerPosition={emojiPickerPosition}
      />
    </div>
  );
};

export type Props = {
  activity: Activity<DefaultAT>;
  onSuccess?: () => void;
  emojiPickerPosition?: 'top' | 'bottom';
};
