import cx from 'classnames';

import { Icon } from 'design-system/Atoms/Icon';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './ProgressBar.module.scss';

export type ProgressBarProps = {
  limitPeopleProgress?: number;
  currentPeopleProgress?: number;
  isLocked: boolean;
  paragraphClassName?: string;
  iconColor: 'black' | 'white';
  completedMessage?: string;
  iconClassName?: string;
  suffix?: string;
};

const isBlack = (color: string) => color === 'black';
const isWhite = (color: string) => color === 'white';

export const ProgressBar = ({
  limitPeopleProgress = 0,
  currentPeopleProgress = 0,
  completedMessage,
  iconColor = 'black',
  suffix = 'people',
  ...props
}: ProgressBarProps) => {
  const widthNumber = (currentPeopleProgress / limitPeopleProgress) * 100;
  const widthNumberWithValidation = currentPeopleProgress > limitPeopleProgress ? 100 : widthNumber;
  const paragraphClasses = cx(styles.Text, props.paragraphClassName);
  const iconClasses = cx(styles.Icon, props.iconClassName, {
    [styles.IconBlack]: isBlack(iconColor),
    [styles.IconWhite]: isWhite(iconColor),
  });

  return (
    <div data-testid="TestId__PROGRESSBARWRAPPER" className={styles.Wrapper}>
      <div className={styles.TextArea}>
        {props.isLocked && <Icon name="lock" className={iconClasses} />}
        {currentPeopleProgress >= limitPeopleProgress && completedMessage ? (
          <Paragraph
            data-testid="TestId__PROGRESSBAR__TEXT"
            variant="ui-bold"
            className={styles.TextCompleted}
          >
            {completedMessage}
          </Paragraph>
        ) : (
          <Paragraph
            data-testid="TestId__PROGRESSBAR__TEXT"
            variant="ui-bold"
            className={paragraphClasses}
          >
            {`${String(currentPeopleProgress || 0)} out of ${String(
              limitPeopleProgress || 0,
            )} ${suffix}`}
          </Paragraph>
        )}
      </div>

      <div className={styles.IndicatorArea}>
        <span
          data-testid="TestId__PROGRESSBAR__SPAN"
          className={styles.IndicatorCurrent}
          style={{ width: `${widthNumberWithValidation || 0}%` }}
        />
      </div>
    </div>
  );
};
