import { HEALTH_CHECK_INTERVAL } from 'consts/constants';
import { Answer, Assignment, Definitions, Get, InvoiceItem, Question } from 'types/types';
import {
  getFirstDayOfCurrentMonth,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  handleRequestError,
} from 'utils/helpers';

import { useCurrentUser } from './hooks';
import { getApiQueryHook } from './xhqFetcher';

export const useGetHealthCheck = getApiQueryHook<void>(
  '/health-check',
  {},
  {
    onError: handleRequestError,
  },
);

export const useGetCurrentUserBySession = getApiQueryHook<
  Get<Definitions, ['getSessionsMeResponse']>
>('/sessions/me');

export const useGetMyUnlockableBounties = getApiQueryHook<
  Get<Definitions, ['getMeUnlockableBountiesResponse']>
>('/me/unlockable-bounties');

export const useGetUserBounties = getApiQueryHook<Get<Definitions, ['getBountiesResponse']>>(
  '/bounties',
);

export const useGetUnlockedForBounty = getApiQueryHook<
  Get<Definitions, ['getUnlockedBountiesBountyIdResponse']>,
  { bountyId: number }
>('/unlocked-bounties/:bountyId');

export const useGetUnlockedByUser = getApiQueryHook<
  Get<Definitions, ['getUnlockedBountiesUserUserIdResponse']>,
  { userId?: number }
>('/unlocked-bounties/user/:userId');

export const useGetUserFeedToken = getApiQueryHook<Get<Definitions, ['getFeedTokenResponse']>>(
  '/feed/token',
);

export const useGetMyHomeVaultItems = getApiQueryHook<
  Get<Definitions, ['getMeHomeVaultItemsResponse']>
>('/me/home-vault-items');

export const useGetVaultItems = getApiQueryHook<Get<Definitions, ['getVaultItemsResponse']>>(
  '/vault-items',
  { baseQuery: { isActive: true, inStock: true, stockZero: true } },
);

export const useGetAllVaultItemsCollection = getApiQueryHook<
  Get<Definitions, ['getVaultCollectionsResponse']>
>('/vault/collections');

export const useGetUserUnlockItems = getApiQueryHook<
  Get<Definitions, ['getUsersUserIdUnlockedVaultItemsResponse']>,
  { userId: number }
>('/users/:userId/unlocked-vault-items');

export const useGetRateUpdateRequests = getApiQueryHook<
  Get<Definitions, ['getRateUpdateRequestsResponse']>
>('/rate-update-requests');

export const useGetEvents = getApiQueryHook<Get<Definitions, ['getEventsResponse']>>('/events');

export const useGetUserUnleashInvoiceItems = getApiQueryHook<
  Get<Definitions, ['getUsersUserIdInvoicesUnleashResponse']>,
  { userId: number }
>('/users/:userId/invoices/unleash');

export const useGetUserUnleashInvoiceItemsOfCurrentMonth = (xhqOptions?: { suspense: boolean }) => {
  const currentMonthTimestamp = getFirstDayOfCurrentMonth().getTime();
  const currentUser = useCurrentUser();
  const { resolvedData: unleashInvoicesData } = useGetUserUnleashInvoiceItems(
    {
      params: { userId: Number(currentUser?.id) },
    },
    xhqOptions,
  );

  const currentMonthUnleashInvoices = unleashInvoicesData?.data?.filter(
    ({ _invoice, approvedAt }) => {
      // When filtering invoices from the current month, take into account
      // their invoicing date, and only use the approved date as a fallback.
      const comparisonDate = _invoice?.dateFrom || approvedAt;

      if (!comparisonDate) {
        return false;
      }

      const invoiceDate = new Date(comparisonDate);
      const utcInvoiceDate = new Date(invoiceDate.getUTCFullYear(), invoiceDate.getUTCMonth());

      return utcInvoiceDate.getTime() >= currentMonthTimestamp;
    },
  );

  return currentMonthUnleashInvoices as InvoiceItem[];
};

export const useGetUserUnleashInvoiceItemsOfCurrentMonthByCategoryId = (
  categoryId: number | undefined,
  options?: { suspense: boolean },
) => {
  const currentMonthUnleashInvoices = useGetUserUnleashInvoiceItemsOfCurrentMonth(options);

  if (!categoryId) return [];

  return currentMonthUnleashInvoices?.filter(
    (invoiceItem) => invoiceItem?._unleashCategory?.id === categoryId,
  );
};

export const useGetUserUnleashInvoiceItemsOfMonthByCategoryId = (
  date: Date | undefined,
  categoryId: number | undefined,
) => {
  const unleashInvoices = useGetUserUnleashInvoiceItemsOfMonth(date);
  if (!categoryId || !date) return [];
  return unleashInvoices?.filter((invoiceItem) => invoiceItem?._unleashCategory?.id === categoryId);
};

export const useGetUserUnleashInvoiceItemsOfMonth = (date: Date | undefined) => {
  const currentUser = useCurrentUser();
  const { resolvedData: unleashInvoicesData } = useGetUserUnleashInvoiceItems({
    params: { userId: Number(currentUser?.id) },
  });

  if (!date) return [];

  const firstDateTimestamp = getFirstDayOfMonth(date).getTime();
  const lastDateTimestamp = getLastDayOfMonth(date).getTime();
  const items =
    unleashInvoicesData?.data?.filter(({ _invoice, approvedAt }) => {
      // When filtering invoices from the current month, take into account
      // their invoicing date, and only use the approved date as a fallback.
      const comparisonDate = _invoice?.dateFrom || approvedAt;

      if (!comparisonDate) {
        return false;
      }

      const invoiceDate = new Date(comparisonDate);
      const utcInvoiceDate = new Date(invoiceDate.getUTCFullYear(), invoiceDate.getUTCMonth());

      return (
        utcInvoiceDate.getTime() >= firstDateTimestamp &&
        utcInvoiceDate.getTime() <= lastDateTimestamp
      );
    }) ?? [];

  return items as InvoiceItem[];
};

export const useGetUserUnleashBudget = getApiQueryHook<
  Get<Definitions, ['getUsersUserIdUnleashBudgetResponse']>,
  { userId: number }
>('/users/:userId/unleash-budget');

export const useGetMyUnleashBudget = getApiQueryHook<
  Get<Definitions, ['getMeUnleashBudgetResponse']>
>('/me/unleash-budget');

export const useGetUsers = getApiQueryHook<Get<Definitions, ['getUsersResponse']>>('/users', {
  baseQuery: { isPaginated: false },
});
export const useGetUsersPaginated = getApiQueryHook<
  Get<Definitions, ['getUsersPaginatedResponse']>
>('/users');

export const useGetUser = getApiQueryHook<
  Get<Definitions, ['getUserResponse']>,
  { userId: number }
>('/users/:userId');

export const useGetUsersBasic = getApiQueryHook<Get<Definitions, ['getUsersResponse']>>(
  '/users/basic',
);

export const useGetReferrals = getApiQueryHook<Get<Definitions, ['getReferralsResponse']>>(
  '/referrals',
);

export const useGetUsersForAppreciation = getApiQueryHook<Get<Definitions, ['getUsersResponse']>>(
  '/users',
  { baseQuery: { isForAppreciation: true, isPaginated: false, fast: true } },
);

export const useGetUsersForCommunity = getApiQueryHook<Get<Definitions, ['getUsersResponse']>>(
  '/users',
  { baseQuery: { isCommunityPage: true, isPaginated: false } },
);

export const useGetUsersManagers = getApiQueryHook<Get<Definitions, ['getUsersManagersResponse']>>(
  '/users/managers',
);

export const useGetUsersCSMs = getApiQueryHook<Get<Definitions, ['getUsersCSMsResponse']>>(
  '/users/csms',
);

export const useGetUsersBountyCreators = getApiQueryHook<
  Get<Definitions, ['getUsersBountyCreatorsResponse']>
>('/users/bounty-creators');

export const useGetRecommendedUsers = getApiQueryHook<
  Get<Definitions, ['getUsersRecommendedUserIdResponse']>,
  { userId: number }
>('/users/recommended/:userId');

export const useGetUsersXTCReps = getApiQueryHook<
  Get<Definitions, ['getUsersAssignedxtcrepsResponse']>
>('/users/assigned-xtc-reps');

export const useGetAnswersInvoicesResponse = getApiQueryHook<
  Get<Definitions, ['getAnswersInvoicesResponse']>
>('/answers-invoices');

const CSVFileSymbol = Symbol('CSVFile');
type CSVFile = { [CSVFileSymbol]: typeof CSVFileSymbol };

export const useGetGenerateDocumentAnswers = getApiQueryHook<CSVFile>('/generate-document/answers');

export const useGetGenerateDocumentQuestionAnswers = getApiQueryHook<CSVFile>(
  '/generate-document/question-answers',
);

export const useGetQuestions = getApiQueryHook<{ data: Question[] }>(
  '/questions',
  { baseQuery: { isActive: true, includeQuestionsByGroup: true } },
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetCurrentInvoice = getApiQueryHook<
  Get<Definitions, ['postInvoicesCurrentResponse']>
>(
  '/invoices/current',
  {
    method: 'POST',
  },
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetCurrentInvoiceAnswers = getApiQueryHook<{ data: Answer[] }>(
  '/answers/current',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetSettings = getApiQueryHook<Get<Definitions, ['getSettingsResponse']>>(
  '/settings',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetCurrencies = getApiQueryHook<Get<Definitions, ['getCurrenciesResponse']>>(
  '/currencies',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetProjectTypes = getApiQueryHook<{ data: { id: string }[] }>(
  '/projects/types',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetUnleashCategories = getApiQueryHook<
  Get<Definitions, ['getUnleashCategoriesResponse']>
>('/unleash-categories', { baseQuery: { isActive: true } });

export const useGetTeamsSimplified = getApiQueryHook<
  Get<Definitions, ['getTeamsSimplifiedResponse']>
>('/teams/simplified');

export const useGetTeamsAndGroups = getApiQueryHook<
  Get<Definitions, ['getTeamsAndGroupsResponse']>
>('/teamsandgroups');

export const useGetEmploymentTypes = getApiQueryHook<
  Get<Definitions, ['getEmploymentTypesResponse']>
>('/employment-types', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetPaymentTypes = getApiQueryHook<Get<Definitions, ['getPaymentTypesResponse']>>(
  '/payment-types',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetPrintfulProducts = getApiQueryHook<
  Get<Definitions, ['getPrintfulProductsResponse']>
>('/printful/products', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetPrintfulCountries = getApiQueryHook<
  Get<Definitions, ['getPrintfulCountriesResponse']>
>('/printful/countries', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetChartsActiveUsers = getApiQueryHook<
  Get<Definitions, ['getChartsActiveUsersResponse']>
>('/charts/active-users');

export const useGetChartsReferralsSubmitted = getApiQueryHook<
  Get<Definitions, ['getChartsReferralsSubmittedResponse']>
>('/charts/referrals-submitted');

export const useGetChartsTopReferrers = getApiQueryHook<
  Get<Definitions, ['getChartsTopReferrersResponse']>
>('/charts/top-referrers');

export const useGetChartsMostPopularVaultItems = getApiQueryHook<
  Get<Definitions, ['getChartsMostPopularVaultItemsResponse']>
>('/charts/most-popular-vault-items');

export const useGetChartsVaultItemsPurchased = getApiQueryHook<
  Get<Definitions, ['getChartsVaultItemsPurchasedResponse']>
>('/charts/vault-items-purchased');

export const useGetChartsBountiesCompleted = getApiQueryHook<
  Get<Definitions, ['getChartsBountiesCompletedResponse']>
>('/charts/bounties-completed');

export const useGetChartsPopularBounties = getApiQueryHook<
  Get<Definitions, ['getChartsPopularBountiesResponse']>
>('/charts/popular-bounties');

export const useGetChartsTopUnleashCategories = getApiQueryHook<
  Get<Definitions, ['getChartsTopUnleashCategoriesResponse']>
>('/charts/top-unleash-categories');

export const useGetChartsUnleashSpent = getApiQueryHook<
  Get<Definitions, ['getChartsUnleashSpentResponse']>
>('/charts/unleash-spent');

export const useGetAllUserGroups = getApiQueryHook<Get<Definitions, ['getUserGroupsResponse']>>(
  '/user-groups',
);

export const useGetAllUserGroupsDropdown = getApiQueryHook<
  Get<Definitions, ['getUserGroupsResponse']>
>('/user-groups', { baseQuery: { isDropdown: true } });

export const useGetUserGroupDetails = getApiQueryHook<
  Get<Definitions, ['getUserGroupsUserGroupIdResponse']>,
  { groupId: number }
>('/user-groups/:groupId');

export const useGetUserGroupMemberList = getApiQueryHook<
  Get<Definitions, ['getUserGroupsUserGroupIdMembersResponse']>,
  { groupId: number }
>('/user-groups/:groupId/members');

export const useGetUserGroupChatMemberList = getApiQueryHook<
  Get<Definitions, ['getUserGroupsUserGroupIdChatMembersResponse']>,
  { groupId: number }
>('/user-groups/:groupId/chat/members');

export const useGetInvoices = getApiQueryHook<Get<Definitions, ['getInvoicesResponse']>>(
  '/invoices',
  {},
  { refetchInterval: HEALTH_CHECK_INTERVAL },
);

export const useGetHoursInvoiceCategory = getApiQueryHook<
  Get<Definitions, ['getInvoiceCategoriesHoursResponse']>
>('/invoice-categories/hours', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetJobOpenings = getApiQueryHook<Get<Definitions, ['getJobOpeningsResponse']>>(
  '/job-openings',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: false,
  },
);

export const useGetUserRoles = getApiQueryHook<Get<Definitions, ['getUserRolesResponse']>>(
  '/user-roles',
  {},
  {
    cacheTime: Infinity,
    staleTime: Infinity,
  },
);

export const useGetReferralInvoiceCategory = getApiQueryHook<
  Get<Definitions, ['getInvoiceCategoriesReferralResponse']>
>('/invoice-categories/referral', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetUserAssignments = getApiQueryHook<{ data: Assignment[] }, { userId: number }>(
  '/users/:userId/assignments',
);

export const useGetProjectsSimplified = getApiQueryHook<
  Get<Definitions, ['getProjectsSimplifiedResponse']>
>('/projects-simplified');

export const useGetInvoiceCategoriesHours = getApiQueryHook<
  Get<Definitions, ['getInvoiceCategoriesHoursResponse']>
>('/invoice-categories/hours', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetInvoiceCategoriesUnleashPlus = getApiQueryHook<
  Get<Definitions, ['getInvoiceCategoriesUnleashPlusResponse']>
>('/invoice-categories/unleash-plus', {}, { cacheTime: Infinity, staleTime: Infinity });

export const useGetUserBountyList = getApiQueryHook<
  Get<Definitions, ['getUsersUserIdBountyListResponse']>,
  { userId: number | undefined }
>('/users/:userId/bounty-list');

export const useGetUserGroups = getApiQueryHook<
  Get<Definitions, ['getUserUserGroupsUserIdResponse']>,
  { userId: number }
>('/user/user-groups/:userId');

export const useGetUsersGroupProject = getApiQueryHook<
  Get<Definitions, ['getUsersGroupsProjects']>,
  { groupId: number }
>('/user-groups/:groupId/projects');

export const useGetUserTickets = getApiQueryHook<
  Get<Definitions, ['getUsersUserIdTicketsResponse']>,
  { userId: number }
>('/users/:userId/tickets');

export const useGetBountyCategories = getApiQueryHook<
  Get<Definitions, ['getBountyCategoriesResponse']>
>('/bounty-categories');

export const useGetBountySubCategory = getApiQueryHook<
  Get<Definitions, ['getBountySubCategoriesResponse']>
>('/bounty-sub-categories');

export const useGetLegends = getApiQueryHook<Get<Definitions, ['getLegendsResponse']>>('/legends');

export const useGetAirwallexTokenStatus = getApiQueryHook<void>('/invoices/airwallex-token', {});

export const useGetLegacy = getApiQueryHook<Get<Definitions, ['getLegacyResponse']>>('/legacy');

export const useGetInvoiceStatuses = getApiQueryHook<{ data: { id: string; name: string }[] }>(
  '/invoice-statuses',
);

export const useGetInvoiceCategories = getApiQueryHook<
  Get<Definitions, ['getInvoiceCategoriesResponse']>
>('/invoice-categories');

export const useGetCompanies = getApiQueryHook<
  Get<Definitions, ['getCompaniesCompanyIdProjectsResponse']>
>('/companies');

export const useGetChartsApprovalsConst = getApiQueryHook<
  Get<Definitions, ['getChartsApprovalsCostResponse']>
>('/charts/approvals/cost');

export const useGetChartsApprovalsHoursWorked = getApiQueryHook<
  Get<Definitions, ['getChartsApprovalsHoursWorkedResponse']>
>('/charts/approvals/hours-worked');

export const useGetChartsApprovalsInvoiceTypeCosts = getApiQueryHook<
  Get<Definitions, ['getChartsApprovalsInvoiceItemTypeCostsResponse']>
>('/charts/approvals/invoice-item-type-costs');

export const useGetChartsApprovalsUniqueSubmitters = getApiQueryHook<
  Get<Definitions, ['getChartsApprovalsUniqueSubmittersResponse']>
>('/charts/approvals/unique-submitters');

export const useGetProjects = getApiQueryHook<Get<Definitions, ['getProjectsResponse']>>(
  '/projects',
);

export const useGetProjectOwners = getApiQueryHook<Get<Definitions, ['getProjectsAdminsResponse']>>(
  '/projects/admins',
);

export const useGetGenerateDocumentProjects = getApiQueryHook<Response>(
  '/generate-document/projects',
);

export const useGetProjectStatuses = getApiQueryHook<
  Get<Definitions, ['getProjectStatusesResponse']>
>('/project-statuses');

export const useGetDepartments = getApiQueryHook<Get<Definitions, ['getDepartmentsResponse']>>(
  '/departments',
);

export const useGetDepartmentExpenseCategories = getApiQueryHook<
  Get<Definitions, ['getDepartmentExpenseCategoriesResponse']>,
  { departmentId: number }
>('/departments/:departmentId/expense-categories');
