const External = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6396 2.02527H8.01807V0.0252686H15.0181V7.02527H13.0181V3.47528L8.10416 8.38918L6.68995 6.97497L11.6396 2.02527Z"
      fill="#9A9D9E"
    />
    <path
      d="M6.98193 1.97473H0.981934V13.9747H12.9819V7.97473H10.9819V11.9747H2.98193V3.97473H6.98193V1.97473Z"
      fill="#9A9D9E"
    />
  </svg>
);

export default External;
