import { PropsWithChildren } from 'react';

import classNames from 'classnames';
import { Button, Heading } from 'design-system';
import { FocusOn } from 'react-focus-on';

import { ReactComponent as ThinLeftArrow } from 'design-system/assets/Icon/Arrows/thin-left-arrow.svg';
import { usePromptBeforeExit } from 'design-system/utils/hooks/usePromptBeforeExit';
import { ToastMessage } from 'types/types';

import styles from './Modal.module.scss';

export function Modal({
  modalClassName,
  contentClassName,
  goBackClassName,
  footerClassName,
  isButtonDisabled,
  primaryButtonContent = 'Confirm',
  secondaryButtonContent = 'Cancel',
  overlayClassName,
  onClose,
  onSubmit,
  onSecondaryButtonClick,
  secondaryButtonClassName,
  primaryButtonClassName,
  closeButtonClassName,
  hideSecondaryButton = false,
  isFocusOnEnabled = true,
  promptBeforeExit,
  layout = 'center',
  children,
  title,
  titleClassName,
  testId,
}: PropsWithChildren<ModalProps>) {
  const { onClose: onClosePromptBeforeExit, renderPrompt } = usePromptBeforeExit(
    !!promptBeforeExit,
    onClose,
  );

  const promptBeforeExitComponent = renderPrompt();

  const overlayClassNames = classNames(styles.Overlay, overlayClassName, {
    [styles.LayoutCenter]: !layout || layout === 'center',
    [styles.LayoutRight]: layout === 'right',
  });
  const modalClassNames = classNames(styles.Modal, modalClassName);
  const goBackClassNames = classNames(styles.GoBack, goBackClassName);
  const titleClassNames = classNames(styles.Title, titleClassName);
  const contentClassNames = classNames(styles.Content, contentClassName);
  const footerClassNames = classNames(styles.Footer, footerClassName);
  const primaryButton = classNames(styles.PrimaryButton, primaryButtonClassName);
  const secondaryButton = classNames(styles.SecondaryButton, secondaryButtonClassName);
  const closeButton = classNames(styles.Close, closeButtonClassName);

  return (
    <div className={overlayClassNames} id="MODAL_OVERLAY" data-test-id="MODAL_OVERLAY">
      {promptBeforeExitComponent}
      <FocusOn
        onEscapeKey={onClosePromptBeforeExit}
        onClickOutside={onClosePromptBeforeExit}
        enabled={isFocusOnEnabled}
      >
        {!promptBeforeExitComponent && (
          <Button
            layout="autolayout"
            as="button"
            variant="icon"
            className={closeButton}
            iconName="close-big"
            onClick={onClosePromptBeforeExit}
            data-testid="CLOSE_MODAL_BUTTON"
          />
        )}
        <Button
          variant="secondary"
          layout="fluid"
          as="button"
          type="button"
          onClick={onClosePromptBeforeExit}
          className={goBackClassNames}
          data-testid="close-modal-box"
        >
          <ThinLeftArrow />
          <span>Go back</span>
        </Button>
        <div data-testid={testId} className={modalClassNames}>
          {title && (
            <Heading level="h5" className={titleClassNames}>
              {title}
            </Heading>
          )}
          <div className={contentClassNames}>{children}</div>
          {onSubmit && (
            <div className={footerClassNames}>
              {!hideSecondaryButton && (
                <Button
                  variant="secondary"
                  layout="fluid"
                  as="button"
                  type="button"
                  onClick={onSecondaryButtonClick || onClosePromptBeforeExit}
                  className={secondaryButton}
                  data-testid="MODAL_SECONDARY_BUTTON"
                >
                  {secondaryButtonContent}
                </Button>
              )}
              <Button
                as="button"
                type="button"
                variant="primary"
                layout="fluid"
                className={primaryButton}
                onClick={onSubmit}
                disabled={isButtonDisabled}
                data-testid="MODAL_PRIMARY_BUTTON"
              >
                {primaryButtonContent}
              </Button>
            </div>
          )}
        </div>
      </FocusOn>
    </div>
  );
}

export interface ModalProps {
  onClose: () => void;
  modalClassName?: string;
  contentClassName?: string;
  promptBeforeExit?: boolean;
  goBackClassName?: string;
  closeButtonClassName?: string;
  footerClassName?: string;
  isButtonDisabled?: boolean;
  primaryButtonClassName?: string;
  secondaryButtonClassName?: string;
  primaryButtonContent?: string;
  secondaryButtonContent?: string;
  hideSecondaryButton?: boolean;
  overlayClassName?: string;
  onSubmit?: () => void;
  onSecondaryButtonClick?: () => void;
  toasts?: ToastMessage[];
  isFocusOnEnabled?: boolean;
  layout?: 'center' | 'right';
  title?: string;
  titleClassName?: string;
  testId?: string;
}
