import { Dispatch } from 'react';

import invariant from 'invariant';
import { mapValues, isString, isEmpty, isUndefined } from 'lodash';
import * as pathToRegexp from 'path-to-regexp';
import QueryString from 'query-string';
import { DefaultRootState } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { apiUrl } from 'config';

// eslint-disable-next-line import/no-cycle
import { logOutActionCreator, unauthorizedAccessActionCreator } from './actionCreators';

export type ActionCreatorReturnType<R> = { type: string; payload?: R };
export interface UserRoleSpecificEndpoint {
  userRole: string;
  endpoint: string;
}

async function getJSON<R>(res: Response) {
  const contentType = res.headers.get('Content-Type');
  const emptyCodes = [204, 205];

  if (!emptyCodes.includes(res.status) && contentType && contentType.includes('json')) {
    return res.json() as Promise<R>;
  } else {
    return res.text();
  }
}

const cachedRequests: Record<string, any> = {};
export const apiFactory = <R>(
  request: {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    endpoint: string;
    headers?: Record<string, string>;
    baseQuery?: Record<string, any> | null;
    userRoleEndpoint?: UserRoleSpecificEndpoint[];
  },
  options: {
    preventLogoutOnUnauthorized?: boolean;
    showToastOnUnauthorized?: boolean;
    tag?: string;
  } = {},
) => {
  invariant(request.endpoint, 'Missing endpoint!');
  invariant(
    ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].includes(request.method),
    `Incorrect method: ${request.method}!`,
  );

  const tag = options.tag ? `:${options.tag}` : '';

  const REQUEST = `@@${request.method}:${request.endpoint}${tag}:REQUEST`;
  const SUCCESS = `@@${request.method}:${request.endpoint}${tag}:SUCCESS`;
  const FAILURE = `@@${request.method}:${request.endpoint}${tag}:FAILURE`;
  const CLEAR = `@@${request.method}:${request.endpoint}${tag}:CLEAR`;

  const endpointCompiler = pathToRegexp.compile(request.endpoint);
  const endpointSpecificCompiler: { userRole: string; compiler: pathToRegexp.PathFunction }[] = [];
  if (!isUndefined(request.userRoleEndpoint)) {
    request.userRoleEndpoint.forEach((roleEndpoint) => {
      const tempEndpointCompiler = pathToRegexp.compile(roleEndpoint.endpoint);
      endpointSpecificCompiler.push({
        userRole: roleEndpoint.userRole,
        compiler: tempEndpointCompiler,
      });
    });
  }

  const actionCreator = ({
    params = {},
    body = undefined,
    query = null,
    sessionId = null,
    useCache = false,
    onResponse = undefined,
    userRole = undefined,
  }: {
    params?: Record<string, any> | null;
    body?: typeof FormData | Record<string, any> | Record<string, any>[] | any;
    query?: Record<string, any> | null;
    sessionId?: string | null;
    useCache?: boolean;
    onResponse?: (arg: Response) => any | undefined;
    userRole?: string;
  } = {}): ThunkAction<
    Promise<ActionCreatorReturnType<R>>,
    DefaultRootState,
    unknown,
    Action<string>
  > => (dispatch: Dispatch<any>, getState: () => any) => {
    const stringifiedParams = mapValues(params, (value) =>
      isString(value) ? value : JSON.stringify(value),
    );
    const combinedQuery = request.baseQuery || query ? { ...request.baseQuery, ...query } : null;

    const stringifiedQuery = !isEmpty(combinedQuery)
      ? '?' + QueryString.stringify(combinedQuery as any, { arrayFormat: 'none' })
      : '';

    const isFormData = body instanceof FormData;
    const shouldUseCache = useCache && request.method === 'GET';

    const endpointWithoutUserRole = apiUrl + endpointCompiler(stringifiedParams) + stringifiedQuery;
    let endpointWithUserRole = '';
    endpointSpecificCompiler.forEach((roleEndpoint) => {
      if (roleEndpoint.userRole === userRole) {
        endpointWithUserRole = apiUrl + roleEndpoint.compiler(stringifiedParams) + stringifiedQuery;
      }
    });
    const endpoint = isUndefined(userRole) ? endpointWithoutUserRole : endpointWithUserRole;

    if (shouldUseCache && cachedRequests[endpoint]) {
      return cachedRequests[endpoint];
    }

    const state = getState();
    const isLoggedIn = sessionId || (state.session?.data && state.session.data.id);
    if (isLoggedIn) {
      const token = sessionId || state.session.data.id;
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    dispatch({ type: REQUEST });
    const abortController = new AbortController();
    const signal = abortController.signal;
    const promise = window
      .fetch(endpoint, {
        ...request,
        mode: 'cors',
        headers: {
          ...(!isFormData && { 'Content-Type': 'application/json' }),
          ...request.headers,
        },
        body: isFormData ? ((body as unknown) as Blob) : JSON.stringify(body),
        signal,
      })
      .then(async (res: Response) => {
        if (onResponse) {
          return onResponse(res);
        }

        const shouldLogOutIfUnauthorized = !options.preventLogoutOnUnauthorized;
        const shouldShowToastIfUnauthorized = options.showToastOnUnauthorized;
        const responseStatusIsUnauthorized = res.status === 401;

        if (shouldLogOutIfUnauthorized && responseStatusIsUnauthorized) {
          return dispatch(logOutActionCreator());
        }
        if (responseStatusIsUnauthorized && shouldShowToastIfUnauthorized) {
          dispatch(unauthorizedAccessActionCreator());
          return {};
        }

        const json = await getJSON<R>(res);
        if (res.status >= 400) {
          throw json;
        }
        return json;
      })
      .then((json) => {
        const action = { type: SUCCESS, payload: json as R };
        dispatch(action);
        return action;
      })
      .catch((err) => {
        if (shouldUseCache) {
          delete cachedRequests[endpoint];
        }

        if (err?.name === 'AbortError') {
          const action = { type: CLEAR };
          dispatch(action);
          return action;
        }

        const action = { type: FAILURE, payload: err };
        dispatch(action);
        throw action;
      });

    promise.abortController = abortController;

    if (shouldUseCache) {
      cachedRequests[endpoint] = promise;
    } else {
      promise.clearOnExit = () => {
        dispatch({ type: CLEAR });
      };
    }

    return promise;
  };

  return {
    actionCreator,
    reducer(
      state = { response: null, isLoading: false, error: null },
      action: Action & { payload: any },
    ) {
      switch (action.type) {
        case REQUEST:
          return { response: state.response, isLoading: true, error: null };
        case SUCCESS:
          return { response: action.payload, isLoading: false, error: null };
        case FAILURE:
          return { response: null, isLoading: false, error: action.payload };
        case CLEAR:
          return { response: null, isLoading: false, error: null };
        default:
          return state;
      }
    },
  };
};
