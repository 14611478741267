import { useMemo, useState, useCallback } from 'react';

export const useTotalAmount = (): [number, (index: number) => (amount: number) => void] => {
  const [amounts, setAmounts] = useState<number[]>([]);

  const setAmount = useCallback(
    (index: number) => (amount: number) => {
      if (amounts[index] !== amount) {
        const newAmounts = [...amounts];
        newAmounts[index] = amount;
        setAmounts(newAmounts);
      }
    },
    [amounts],
  );

  const totalAmount = useMemo<number>(
    () => amounts.filter(Boolean).reduce((sum: number, amount: number) => sum + amount, 0),
    [amounts],
  );

  return [totalAmount, setAmount];
};
