import { Component, DragEvent, MouseEvent } from 'react';

import classNames from 'classnames';
import { get, isArray } from 'lodash';
import Dropzone from 'react-dropzone';
import { connect, HandleThunkActionCreator } from 'react-redux';
import { compose } from 'redux';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/x-delete.svg';
import { addModalToast, addToast } from 'store/actionCreators';
import {
  uploadBountiesCollectionIconApiActionCreator,
  uploadBountyImageApiActionCreator,
  uploadDocumentFileApiActionCreator,
  uploadInvoiceItemFileApiActionCreator,
  uploadLegacyImageApiActionCreator,
  uploadLegendImageApiActionCreator,
  uploadSettingsImageApiActionCreator,
  uploadSlackMessageFileApiActionCreator,
  uploadTeamIconApiActionCreator,
  uploadVaultCollectionIconApiActionCreator,
  uploadVaultImageApiActionCreator,
  uploadVaultItemSizeGuideApiActionCreator,
  uploadUserNotesFileApiActionCreator,
} from 'store/reduxRestApi';
import { File as ApiFileType } from 'types/types';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './InputFile.module.scss';

type ActionCreatorType =
  | typeof uploadInvoiceItemFileApiActionCreator
  | typeof uploadBountyImageApiActionCreator
  | typeof uploadVaultImageApiActionCreator
  | typeof uploadVaultItemSizeGuideApiActionCreator
  | typeof uploadSettingsImageApiActionCreator
  | typeof uploadDocumentFileApiActionCreator
  | typeof uploadBountiesCollectionIconApiActionCreator
  | typeof uploadTeamIconApiActionCreator
  | typeof uploadLegendImageApiActionCreator
  | typeof uploadVaultCollectionIconApiActionCreator
  | typeof uploadLegacyImageApiActionCreator;

export type Props = Partial<{
  onFileChanged: (files: ReadonlyArray<ApiFileType>, isRemoving?: boolean) => void;
  label: string;
  files: ReadonlyArray<ApiFileType> | null;
  multiple: boolean;
  accept: string;
  isModal: boolean;
  className: string;
  toastType: 'page' | 'modal';
  name: string;
  id: string;
}> & {
  actionCreator: HandleThunkActionCreator<ActionCreatorType>;
  addToast: typeof addToast;
  addModalToast: typeof addModalToast;
};

type State = {
  updating: boolean;
  files: ReadonlyArray<ApiFileType>;
  dragover: boolean;
};

export class InputFile extends Component<Props, State> {
  state = {
    updating: false,
    files: this.props.files
      ? isArray(this.props.files)
        ? this.props.files
        : [this.props.files]
      : [],
    dragover: false,
  };

  onMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    window.addEventListener('paste', this.onPaste as EventListener);
  };
  onMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    window.removeEventListener('paste', this.onPaste as EventListener);
  };
  removeFile = (file: ApiFileType) => () => {
    if (this.state.updating) return null;

    const { onFileChanged } = this.props;
    const { files } = this.state;

    this.setState({ updating: true }, async () => {
      const newFiles = files.filter((currentFile) => currentFile !== file);

      const isRemoving = true;

      if (onFileChanged) {
        onFileChanged(newFiles, isRemoving);
        this.setState({ files: newFiles, updating: false });
      }
    });
  };

  removeFileOnKeyUp(file: ApiFileType) {
    if (this.state.updating) return null;

    const { onFileChanged } = this.props;
    const { files } = this.state;

    this.setState({ updating: true }, async () => {
      const newFiles = files.filter((currentFile) => currentFile !== file);

      const isRemoving = true;

      if (onFileChanged) {
        onFileChanged(newFiles, isRemoving);
        this.setState({ files: newFiles, updating: false });
      }
    });
  }

  onPaste = (event: ClipboardEvent) => {
    const items = event.clipboardData?.items;
    if (!items) {
      return;
    }

    const files = [];
    for (const item of items) {
      if (item.kind === 'file') {
        const blob = item.getAsFile();
        if (!blob) {
          continue;
        }
        const ext = item.type.split('/').pop();
        const file = new File([blob], new Date().getTime() + '-clipboard.' + ext, {
          type: blob.type,
          lastModified: Date.now(),
        });
        files.push(file);
      }
    }
    this.onDrop(files);
  };

  onDrop = (files: ReadonlyArray<File>) => {
    const { toastType = 'page' } = this.props;
    if (!Array.isArray(files) || files.length === 0) return;

    const formData = new window.FormData();
    files.forEach((file) => formData.append('files', file));
    const { onFileChanged, actionCreator } = this.props;

    this.setState({ updating: true }, async () => {
      if (!actionCreator) {
        return;
      }

      try {
        const res = await actionCreator({ body: (formData as unknown) as Record<string, any> });
        const payload = res.payload as { data: ReadonlyArray<ApiFileType> };
        const newFiles = [...payload.data, ...this.state.files];

        this.setState(
          {
            files: newFiles,
            updating: false,
          },
          () => {
            if (onFileChanged) {
              onFileChanged(newFiles);
            }
          },
        );
      } catch (error) {
        const text = get(error, 'payload.message') || 'Failed to upload';
        if (toastType === 'page') {
          this.props.addToast({
            text,
          });
        } else {
          this.props.addModalToast({
            text,
          });
        }

        this.setState({
          updating: false,
        });
      }
    });
  };

  renderFilePathsOrLabel(files: ReadonlyArray<ApiFileType>, label?: string) {
    return files.length ? (
      <ul data-testid="fileList" className={styles.DropZoneList}>
        {files.map((file) => (
          <li key={file.key} className={styles.DropZoneListItem}>
            <div
              onClick={this.removeFile(file)}
              onKeyUp={(e) => isKeyboardEvent(e) && this.removeFileOnKeyUp(file)}
              role="button"
              tabIndex={0}
              className={styles.DeleteIconContainer}
            >
              <DeleteIcon
                title={'Delete file - ' + file.originalName}
                className={styles.deleteIcon}
              />
            </div>
            <a
              href={file.location}
              rel="noopener noreferrer"
              target="_blank"
              title={file.originalName}
            >
              {file.originalName}
            </a>
          </li>
        ))}
      </ul>
    ) : (
      <span>{label}</span>
    );
  }
  onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ dragover: true });
  };
  onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ dragover: false });
  };

  render() {
    const {
      isModal = false,
      multiple = false,
      accept,
      label,
      className = '',
      id,
      name,
    } = this.props;
    const { updating, files, dragover } = this.state;
    const wrapClasses = classNames(styles.Wrap, isModal && styles.Wrap_modal, className);
    const dropZoneClasses = classNames(styles.DropZone, dragover && styles.dragover);
    return (
      <div
        data-testid="dropzone-wrapper"
        className={wrapClasses}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Dropzone
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          accept={accept}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps }) => {
            let uploadText;
            if (updating) {
              uploadText = <span> ...updating file list </span>;
            } else {
              uploadText = '';
            }

            return (
              <>
                <div
                  data-testid="dropzone"
                  className={classNames(dropZoneClasses, styles.DropZone)}
                  {...getRootProps()}
                >
                  <DownloadIcon className={styles.DownloadIcon} title="Drop files" />
                  <input {...getInputProps()} data-testid="dropzone-input" id={id} name={name} />
                  <p className={styles.hintText}>
                    Choose a file <b>or</b> drag it <b>or</b> copy and paste it here
                  </p>
                </div>
                <aside>
                  <h4>Files: {uploadText}</h4>
                  {this.renderFilePathsOrLabel(files, label)}
                </aside>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

const makeFileUploadComponent = (actionCreator: ActionCreatorType) => {
  return compose(
    connect(null, {
      actionCreator,
      addToast,
      addModalToast,
    }),
  )(InputFile);
};

export const UploadInvoiceItem = makeFileUploadComponent(uploadInvoiceItemFileApiActionCreator);
export const UploadBountyImage = makeFileUploadComponent(uploadBountyImageApiActionCreator);
export const UploadVaultImage = makeFileUploadComponent(uploadVaultImageApiActionCreator);
export const UploadVaultSizeGuide = makeFileUploadComponent(
  uploadVaultItemSizeGuideApiActionCreator,
);
export const UploadSettingsImage = makeFileUploadComponent(uploadSettingsImageApiActionCreator);
export const UploadDocumentFile = makeFileUploadComponent(uploadDocumentFileApiActionCreator);
export const UploadBountiesCollectionIcon = makeFileUploadComponent(
  uploadBountiesCollectionIconApiActionCreator,
);
export const UploadTeamIcon = makeFileUploadComponent(uploadTeamIconApiActionCreator);
export const UploadLegendImage = makeFileUploadComponent(uploadLegendImageApiActionCreator);
export const UploadLegacyImage = makeFileUploadComponent(uploadLegacyImageApiActionCreator);
export const UploadCollectionImage = makeFileUploadComponent(
  uploadVaultCollectionIconApiActionCreator,
);
export const UploadSlackMessageFile = makeFileUploadComponent(
  uploadSlackMessageFileApiActionCreator,
);
export const UploadUserNotesFile = makeFileUploadComponent(uploadUserNotesFileApiActionCreator);
