import classnames from 'classnames';

import Button, { ButtonProps } from '../Button';

import styles from './EditIconButton.module.css';

export default function EditIconButton(props: ButtonProps) {
  const classes = classnames(styles.PencilButton, props.className);

  return (
    <Button {...props} onClick={props.onClick} className={classes}>
      <span className={styles.PencilIcon} />
    </Button>
  );
}
