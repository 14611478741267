const SvgComponent = (props) => {
  return (
    <svg width="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 10.5C29.7999 10.8 29.5999 10.8 29.2999 10.8C28 10.8 26.5999 10.8 25.2999 10.8C25.1999 10.8 25.1 10.8 25 10.8C25 10.9 25 11.1 25 11.2C25 15.7 25 20.1 25 24.6C25 26.3001 24.2999 27.5 22.7999 28.2001C22.1999 28.5 21.5 28.6 20.7999 28.6C15.9 28.6 10.9 28.6 5.99995 28.6C3.69995 28.6 2.19995 26.8 2.19995 24.8C2.19995 24.1 2.19995 23.4 2.19995 22.6C2.19995 22.2001 2.39995 22 2.79995 22C3.79995 22 4.79995 22 5.89995 22C5.99995 22 6.09995 22 6.19995 22C6.19995 21.9 6.19995 21.8001 6.19995 21.6C6.19995 16.5 6.29995 11.3 6.29995 6.20005C6.29995 4.70005 7.29995 3.60005 8.69995 3.30005C8.89995 3.30005 9.09995 3.30005 9.19995 3.30005C15.1 3.30005 21 3.30005 27 3.30005C28.6 3.30005 29.7 4.20005 30.1 5.80005C30.1 5.80005 30.1 5.80005 30.1 5.90005C30 7.50005 30 9.00005 30 10.5ZM24.9 4.30005C24.7999 4.30005 24.7 4.30005 24.6 4.30005C19.5 4.30005 14.3 4.30005 9.19995 4.30005C8.99995 4.30005 8.69995 4.30005 8.49995 4.40005C7.59995 4.60005 6.99995 5.30005 6.99995 6.30005C6.99995 11.5 6.99995 16.7 6.89995 21.8C6.89995 21.9 6.89995 22 6.89995 22.1C7.09995 22.1 7.19995 22.1 7.29995 22.1C10.7 22.1 14.2 22.1 17.6 22.1C18.1 22.1 18.2 22.2001 18.2 22.7001C18.2 23.3001 18.2 23.9 18.2 24.6C18.2 25.6 18.6 26.5 19.4 27.1C20.5 28 22.2999 27.8001 23.2999 26.7001C23.7999 26.1 24.1 25.4 24.1 24.6C24.1 18.6 24.1 12.6 24.1 6.50005C24.1 5.90005 24.2 5.30005 24.5 4.80005C24.6 4.70005 24.6999 4.50005 24.9 4.30005ZM2.99995 23C2.99995 23.8 2.89995 24.5 2.99995 25.3C3.19995 26.6 4.39995 27.8001 5.99995 27.7001C10.1 27.7001 14.1999 27.7001 18.2999 27.7001C18.4 27.7001 18.5 27.7001 18.6 27.7001C18.5 27.6 18.5 27.6 18.5 27.6C18 27.2001 17.7 26.6 17.5 26C17.3 25.4 17.3 24.7001 17.3 24C17.3 23.7001 17.3 23.4 17.3 23C12.5 23 7.79995 23 2.99995 23ZM29.1 9.90005C29.1 8.60005 29.2 7.30005 29.1 6.00005C29 4.80005 27.7 4.00005 26.6 4.30005C25.6 4.60005 25 5.30005 25 6.40005C25 7.50005 25 8.60005 25 9.70005C25 9.80005 25 9.80005 25 9.90005C26.4 9.90005 27.7 9.90005 29.1 9.90005Z"
        fill="#5A5665"
      />
      <path
        d="M19.3 10.3C19.3 11 19.3 11.7 19.2 12.3C19.1 13.2 18.6 14 17.8 14.5C17.1 15 16.4 15.5 15.7 16C15.4 16.2 15.2 16.2 15 16C14.3 15.5 13.6 15 12.8 14.5C11.8 13.8 11.4 12.9 11.3 11.7C11.3 10.7 11.3 9.70005 11.3 8.70005C11.3 8.40005 11.4 8.20005 11.7 8.10005C12.8 7.60005 13.9 7.00005 15 6.50005C15.2 6.40005 15.4 6.40005 15.7 6.50005C16.8 7.00005 17.9 7.60005 19 8.10005C19.3 8.20005 19.4 8.40005 19.4 8.70005C19.2 9.20005 19.2 9.80005 19.3 10.3ZM12.2 10.5C12.2 10.9 12.2 11.3 12.2 11.7C12.2 12.6 12.6 13.3 13.3 13.8C13.9 14.2 14.5 14.6 15 15C15.2 15.1 15.3 15.1 15.4 15C16 14.6 16.6 14.2 17.1 13.8C17.8 13.3 18.2 12.6 18.2 11.7C18.2 10.8 18.2 9.90005 18.2 9.10005C18.2 8.90005 18.2 8.90005 18 8.80005C17.1 8.40005 16.2 7.90005 15.3 7.50005C15.2 7.50005 15.1 7.50005 15 7.50005C14.1 7.90005 13.2 8.40005 12.3 8.90005C12.2 8.90005 12.1 9.10005 12.1 9.20005C12.2 9.50005 12.2 10 12.2 10.5Z"
        fill="#FF5964"
      />
      <path
        d="M15.5 20.4C13.8 20.4 12 20.4 10.3 20.4C10.2 20.4 10.1 20.4 10.1 20.4C9.79995 20.4 9.69995 20.2 9.69995 20C9.69995 19.8 9.89995 19.6 10.1 19.5C10.2 19.5 10.3 19.5 10.3 19.5C13.8 19.5 17.2 19.5 20.7 19.5H20.8C21.2 19.5 21.4 19.7 21.4 20C21.4 20.3 21.2 20.5 20.8 20.5C20 20.5 19.2 20.5 18.4 20.5C17.4 20.4 16.4 20.4 15.5 20.4Z"
        fill="#FF5964"
      />
      <path
        d="M15.5001 17.4C17.2001 17.4 19.0001 17.4 20.7001 17.4C20.8001 17.4 20.9001 17.4 21.0001 17.4C21.2001 17.4 21.4001 17.6 21.4001 17.8C21.4001 18 21.3001 18.2 21.0001 18.2C20.9001 18.2 20.8001 18.2 20.7001 18.2C17.2001 18.2 13.8001 18.2 10.3001 18.2C10.2001 18.2 10.1001 18.2 10.0001 18.2C9.8001 18.2 9.6001 18 9.6001 17.8C9.6001 17.6 9.8001 17.4 10.0001 17.4C10.1001 17.4 10.2001 17.4 10.3001 17.4C12.0001 17.4 13.7001 17.4 15.5001 17.4Z"
        fill="#FF5964"
      />
      <path
        d="M14.8 11.5C15.3 11 15.7 10.6 16.2 10.1C16.3 10 16.4 9.90002 16.5 9.90002C16.7 9.90002 16.9 9.90002 17 10C17.2 10.1 17.2 10.3 17.1 10.5C17.1 10.6 17 10.6 16.9 10.7C16.4 11.2 15.8 11.8 15.3 12.3C15 12.6 14.8 12.6 14.5 12.3C14.2 12 14 11.8 13.7 11.5C13.5 11.3 13.5 11 13.7 10.8C13.9 10.6 14.1 10.6 14.4 10.9C14.5 11.2 14.6 11.3 14.8 11.5Z"
        fill="#FF5964"
      />
    </svg>
  );
};

export default SvgComponent;
