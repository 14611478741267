import {
  addDays as FNSAddDays,
  addMinutes as FNSAddMinutes,
  addMonths as FNSAddMonths,
  addYears as FNSAddYears,
  differenceInBusinessDays as FNSDifferenceInBusinessDays,
  differenceInDays as FNSDifferenceInDays,
  differenceInMonths as FNSDifferenceInMonths,
  differenceInSeconds as FNSDifferenceInSeconds,
  Duration as FNSDuration,
  endOfDay as FNSEndOfDay,
  endOfMonth as FNSEndOfMonth,
  format as FNSFormat,
  formatDistanceToNow as FNSFormatDistanceToNow,
  getDaysInYear as FNSGetDaysInYear,
  getUnixTime as FNSGetUnixTime,
  fromUnixTime as FNSFromUnixTime,
  isAfter as FNSIsAfter,
  isBefore as FNSIsBefore,
  isEqual as FNSIsEqual,
  isSameDay as FNSIsSameDay,
  isValid as FNSIsValid,
  isWithinInterval as FNSIsWithinInterval,
  parseISO as FNSParseISO,
  startOfDay as FNSStartOfDay,
  startOfMonth as FNSStartOfMonth,
  sub as FNSSub,
  subMonths as FNSSubMonths,
  isSameHour as FNSIsSameHour,
  isSameMinute as FNSIsSameMinute,
  isSameSecond as FNSIsSameSecond,
} from 'date-fns';
import { DateRangesData } from 'design-system';

import { SUBMIT_INVOICE_PERIODS, STRING_DATE_REGEX } from 'consts/constants';
import { DateRangeAlt } from 'types/types';

/**
 * Due to `moment` deprecation, we are replacing calls to use Native Date objects with FNS.
 * Please regard below functions when creating new features to InvoiceDatePicker instead of any `moment` implementation.
 */
export type DateRange = ReturnType<typeof createRange>;

export type DateRangeSelectOption = ReturnType<typeof mapDateRangeToSelectOption>;

const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};
export const DATE_RANGE_TYPE = {
  FIRST_HALF: 'first_half',
  SECOND_HALF: 'second_half',
  FULL: 'full',
};

const isLeapYear = (currentDate: Date) => FNSGetDaysInYear(currentDate) > 365;

const getRange = (currentDate: Date) =>
  ({
    [MONTHS.JANUARY]: {
      [DATE_RANGE_TYPE.FULL]: { start: '01-01', end: '01-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '01-01', end: '01-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '01-16', end: '01-31' },
    },
    [MONTHS.FEBRUARY]: {
      [DATE_RANGE_TYPE.FULL]: { start: '02-01', end: isLeapYear(currentDate) ? '02-29' : '02-28' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '02-01', end: '02-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: {
        start: '02-16',
        end: isLeapYear(currentDate) ? '02-29' : '02-28',
      },
    },
    [MONTHS.MARCH]: {
      [DATE_RANGE_TYPE.FULL]: { start: '03-01', end: '03-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '03-01', end: '03-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '03-16', end: '03-31' },
    },
    [MONTHS.APRIL]: {
      [DATE_RANGE_TYPE.FULL]: { start: '04-01', end: '04-30' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '04-01', end: '04-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '04-16', end: '04-30' },
    },
    [MONTHS.MAY]: {
      [DATE_RANGE_TYPE.FULL]: { start: '05-01', end: '05-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '05-01', end: '05-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '05-16', end: '05-31' },
    },
    [MONTHS.JUNE]: {
      [DATE_RANGE_TYPE.FULL]: { start: '06-01', end: '06-30' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '06-01', end: '06-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '06-16', end: '06-30' },
    },
    [MONTHS.JULY]: {
      [DATE_RANGE_TYPE.FULL]: { start: '07-01', end: '07-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '07-01', end: '07-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '07-16', end: '07-31' },
    },
    [MONTHS.AUGUST]: {
      [DATE_RANGE_TYPE.FULL]: { start: '08-01', end: '08-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '08-01', end: '08-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '08-16', end: '08-31' },
    },
    [MONTHS.SEPTEMBER]: {
      [DATE_RANGE_TYPE.FULL]: { start: '09-01', end: '09-30' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '09-01', end: '09-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '09-16', end: '09-30' },
    },
    [MONTHS.OCTOBER]: {
      [DATE_RANGE_TYPE.FULL]: { start: '10-01', end: '10-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '10-01', end: '10-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '10-16', end: '10-31' },
    },
    [MONTHS.NOVEMBER]: {
      [DATE_RANGE_TYPE.FULL]: { start: '11-01', end: '11-30' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '11-01', end: '11-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '11-16', end: '11-30' },
    },
    [MONTHS.DECEMBER]: {
      [DATE_RANGE_TYPE.FULL]: { start: '12-01', end: '12-31' },
      [DATE_RANGE_TYPE.FIRST_HALF]: { start: '12-01', end: '12-15' },
      [DATE_RANGE_TYPE.SECOND_HALF]: { start: '12-16', end: '12-31' },
    },
  }[currentDate.getMonth() + 1]);

export function normalizeUTCDate(rawDate: Date | string) {
  const date = new Date(rawDate);
  return FNSAddMinutes(date, -1 * date.getTimezoneOffset());
}

/**
 * The difference in months between two dates.
 * @param dateLeft The later date
 * @param dateRight The earlier date
 * @returns the number of full months
 */
export function differenceInMonths(dateLeft: Date | string, dateRight: Date | string) {
  const leftFNSDate = normalizeUTCDate(dateLeft);
  const rightFNSDate = normalizeUTCDate(dateRight);

  return FNSDifferenceInMonths(leftFNSDate, rightFNSDate);
}

export function differenceInDays(dateLeft: Date | string, dateRight: Date | string) {
  const leftFNSDate = normalizeUTCDate(dateLeft);
  const rightFNSDate = normalizeUTCDate(dateRight);

  return FNSDifferenceInDays(leftFNSDate, rightFNSDate);
}

export function toUTCDate(givenDate: Date) {
  const date = FNSStartOfDay(givenDate);
  const offset = date.getTimezoneOffset();
  return FNSAddMinutes(date, offset);
}

export function normalizeISODate(rawDate: Date | string) {
  const date = new Date(rawDate);
  return FNSAddMinutes(date, date.getTimezoneOffset());
}

export function sub(date: Date, duration: FNSDuration) {
  return FNSSub(date, duration);
}

export function isAfter(date: Date, dateToCompare: Date) {
  return FNSIsAfter(date, dateToCompare);
}

export function isValid(date: Date) {
  return FNSIsValid(date);
}

export function addDays(date: Date, days: number) {
  return FNSAddDays(date, days);
}

export function addYears(date: Date, years: number) {
  return FNSAddYears(date, years);
}

export function isEqual(a: Date, b: Date) {
  return FNSIsEqual(a, b);
}

export function addMonths(date: Date, months: number) {
  return FNSAddMonths(date, months);
}

export function subMonths(date: Date, months: number) {
  return FNSSubMonths(date, months);
}

export function endOfMonth(date: Date) {
  return FNSEndOfMonth(date);
}

export function startOfMonth(date: Date) {
  return FNSStartOfMonth(date);
}

export function startOfDay(date: Date) {
  return FNSStartOfDay(date);
}

export function endOfDay(date: Date) {
  return FNSEndOfDay(date);
}

export function differenceInSeconds(dateLeft: Date, dateRight: Date) {
  return FNSDifferenceInSeconds(dateLeft, dateRight);
}

export function getUnixTime(date: Date) {
  return FNSGetUnixTime(date);
}

export function fromUnixTime(unixTime: number | string) {
  const timeStamp = typeof unixTime === 'string' ? parseInt(unixTime, 10) : unixTime;
  return FNSFromUnixTime(timeStamp);
}

/**
 * Checks whether a given date is within a given range, by only taking into account the date
 * @param date The date to check
 * @param from The earlier date
 * @param to The late date
 * @returns a boolean value, true if is within the range; false otherwise.
 */
export function isWithinRange(date: Date, from: Date, to: Date) {
  return FNSIsWithinInterval(new Date(date.getFullYear(), date.getMonth(), date.getDate()), {
    start: new Date(from.getFullYear(), from.getMonth(), from.getDate()),
    end: new Date(to.getFullYear(), to.getMonth(), to.getDate()),
  });
}

// for use with string date format '2023-12-16T00:00:00.000Z'
const validateDateTime = (dateTime: string) => {
  if (!STRING_DATE_REGEX.test(dateTime)) {
    throw new Error(`Invalid datetime format: ${dateTime}`);
  }
};

// for use with string date format '2023-12-16T00:00:00.000Z'
export function createRangeFromSimpleStrings(start: string, end: string) {
  validateDateTime(start);
  validateDateTime(end);
  return start.split('T')[0] + ':' + end.split('T')[0];
}

export function createRange(start: string | Date, end: string | Date) {
  const DATE_FMT = 'yyyy-MM-dd';
  const startDate = typeof start === 'string' ? FNSParseISO(start) : start;
  const endDate = typeof end === 'string' ? FNSParseISO(end) : end;
  const id = `${FNSFormat(startDate, DATE_FMT)}:${FNSFormat(endDate, DATE_FMT)}`;
  const range = {
    id,
    start: startDate,
    end: endDate,
  };
  return range;
}

export function fromNow(time: string, addSuffix = true) {
  const date = normalizeISODate(new Date(time));
  const suffix = addSuffix ? { addSuffix } : undefined;
  return FNSFormatDistanceToNow(date, suffix);
}

// for use with string date format '2023-12-16T00:00:00.000Z'
export function formatDateFromSimpleStrings(dateString: string) {
  dateString && validateDateTime(dateString);
  return dateString.split('T')[0];
}

export function formatDate(date: number | Date, formatString: string) {
  return FNSFormat(date, formatString);
}

export function getFnsCurrentBimonth() {
  const now = toUTCDate(new Date(Date.now()));

  return now.getDate() <= 15 ? getFirstBimonthRange(now) : getSecondBimonthRange(now);
}

export function getPreviousDatesCount(date: Date) {
  const currentDate = toUTCDate(date);

  return currentDate.getDate() < 23 ? 5 : 2;
}

export function getFirstBimonthRange(date: Date) {
  const start = FNSStartOfMonth(date);
  const end = FNSStartOfDay(FNSAddDays(start, 14));
  return createRange(start, end);
}

export function getSecondBimonthRange(date: Date) {
  const firstDateOfMonth = FNSStartOfMonth(date);
  const start = FNSAddDays(firstDateOfMonth, 15);
  const end = FNSStartOfDay(FNSEndOfMonth(date));
  return createRange(start, end);
}

export function getFullMonthRange(date: Date) {
  const start = FNSStartOfMonth(date);
  const end = FNSStartOfDay(FNSEndOfMonth(date));
  return createRange(start, end);
}

export function getPreviousBimonthRange(date: Date) {
  const currentDate = toUTCDate(date);
  const isFirstBimonth = currentDate.getDate() >= 1 && currentDate.getDate() < 16;

  if (isFirstBimonth) {
    //Copy to avoid side-effects
    const copy = new Date(currentDate);
    copy.setMonth(copy.getMonth() - 1);
    return getSecondBimonthRange(copy);
  }

  return getFirstBimonthRange(currentDate);
}

function parseToDateRange({ start, end }: Pick<DateRange, 'start' | 'end'>): DateRange {
  const stringFormat = 'yyyy-MM-dd';
  return {
    id: `${FNSFormat(start, stringFormat)}:${FNSFormat(end, stringFormat)}`,
    start,
    end,
  };
}

export function parseCurrentDate(dateFromRange: string, currentDate: Date) {
  const [month, day] = dateFromRange.split('-');
  const date = new Date(currentDate.getTime());
  date.setMonth(parseInt(month, 10) - 1);
  date.setDate(parseInt(day, 10));
  return date;
}

function parseRangeToUTCDate({ start, end }: { start: string; end: string }, currentDate: Date) {
  return {
    start: parseCurrentDate(start, currentDate),
    end: parseCurrentDate(end, currentDate),
  };
}

function getDateRangesForInvoiceSubmit(currentDate: Date) {
  const range = getRange(currentDate);
  const dateRangesForInvoiceSubmit = {
    [DATE_RANGE_TYPE.FULL]: parseRangeToUTCDate(range[DATE_RANGE_TYPE.FULL], currentDate),
    [DATE_RANGE_TYPE.FIRST_HALF]: parseRangeToUTCDate(
      range[DATE_RANGE_TYPE.FIRST_HALF],
      currentDate,
    ),
    [DATE_RANGE_TYPE.SECOND_HALF]: parseRangeToUTCDate(
      range[DATE_RANGE_TYPE.SECOND_HALF],
      currentDate,
    ),
  };
  return dateRangesForInvoiceSubmit;
}

export function getRangeOfDateRanges(currentDate: Date): DateRange[] {
  const previousRange = getDateRangesForInvoiceSubmit(FNSSubMonths(currentDate, 1));
  const currentRange = getDateRangesForInvoiceSubmit(currentDate);
  return [previousRange[DATE_RANGE_TYPE.FULL], currentRange[DATE_RANGE_TYPE.FULL]].map(
    parseToDateRange,
  );
}

export function getBusinessDaysOfDateRange(start: Date, end: Date) {
  return FNSDifferenceInBusinessDays(FNSAddDays(end, 1), start);
}

export function mapDateRangeToSelectOption(dateRange: DateRange) {
  const days = FNSDifferenceInDays(FNSAddDays(dateRange.end, 1), dateRange.start);
  const isFullMonth = days >= 28 && days <= 31;
  // FNSDifferenceInBusinessDays Does not include the last day so I'm adding one more day
  const businessDays = FNSDifferenceInBusinessDays(FNSAddDays(dateRange.end, 1), dateRange.start);
  const startDateText = FNSFormat(dateRange.start, 'MMMM yyyy: do');
  const endDateText = FNSFormat(dateRange.end, 'do');
  const monthPrefix = isFullMonth ? 'Full Month:' : 'Half Month:';

  return {
    valueLabel: (
      <span>
        <b>
          {startDateText} - {endDateText}
        </b>{' '}
        ({businessDays} working days)
      </span>
    ),
    label: (
      <span>
        <b>{monthPrefix}</b> {startDateText} - {endDateText}
      </span>
    ),
    value: dateRange.id,
    dateRange,
  };
}

export function mapDateRangesToSelectOptions(dateRanges: DateRange[]) {
  return dateRanges.map(mapDateRangeToSelectOption);
}

export interface MonthWithDateRangesData {
  label: string;
  dateRangesData: DateRangesData[];
}

export function getInvoiceStep1MonthDateRanges(
  submittedInvoicesDateRanges: DateRangeAlt[],
): MonthWithDateRangesData[] {
  const parseRange = (dateRange: DateRange) => ({
    ...dateRange,
    start: dateRange.start.toISOString(),
    end: dateRange.end.toISOString(),
  });

  const isDateRangeSubmitted = (dateRange: DateRangeAlt) =>
    submittedInvoicesDateRanges?.some((submittedInvoiceDateRange) => {
      const isStartDateInSubmittedRange =
        dateRange.start >= submittedInvoiceDateRange.start &&
        dateRange.start <= submittedInvoiceDateRange.end;
      const isEndDateInSubmittedRange =
        dateRange.end >= submittedInvoiceDateRange.start &&
        dateRange.end <= submittedInvoiceDateRange.end;

      return isStartDateInSubmittedRange || isEndDateInSubmittedRange;
    });

  const { FIRST_HALF, SECOND_HALF, FULL_MONTH } = SUBMIT_INVOICE_PERIODS;
  const dateFormat = 'MMMM yyyy';
  const now = new Date();
  const previousMonth = FNSSubMonths(now, 1);
  const nextMonth = FNSAddMonths(now, 1);
  const months = [previousMonth, now, nextMonth];

  const ranges = months.map((month) => {
    const firstHalf = parseRange(getFirstBimonthRange(month));
    const secondHalf = parseRange(getSecondBimonthRange(month));
    const fullMonth = parseRange(getFullMonthRange(month));

    return {
      label: formatDate(month, dateFormat),
      dateRangesData: [
        {
          type: FIRST_HALF,
          disabled: isDateRangeSubmitted(firstHalf),
        },
        {
          type: SECOND_HALF,
          disabled: isDateRangeSubmitted(secondHalf),
        },
        {
          type: FULL_MONTH,
          disabled: isDateRangeSubmitted(fullMonth),
        },
      ],
    };
  });
  return ranges;
}

/**
 * Checks if date1 is same or after date2
 * @param Date date1
 * @param Date date2
 */
export function isSameOrAfter(date1: Date, date2: Date) {
  const isSameDay = FNSIsSameDay(date1, date2);
  const isAfter = FNSIsAfter(date2, date1);

  return isSameDay || isAfter;
}

/**
 * date is same or before dateToCompare (day)
 */
export function isSameOrBefore(date: Date, dateToCompare: Date) {
  const isSameDay = FNSIsSameDay(date, dateToCompare);
  const isBefore = FNSIsBefore(date, dateToCompare);
  return isSameDay || isBefore;
}

/**
 * date is same or before dateToCompare (day / hour / minute / second)
 */
export function isSameTimeOrBefore(date: Date, dateToCompare: Date) {
  const isSameDayHourMinuteSecond =
    FNSIsSameDay(date, dateToCompare) &&
    FNSIsSameHour(date, dateToCompare) &&
    FNSIsSameMinute(date, dateToCompare) &&
    FNSIsSameSecond(date, dateToCompare);
  const isBefore = FNSIsBefore(date, dateToCompare);
  return isSameDayHourMinuteSecond || isBefore;
}

export function getFnsDateRanges(
  { start, end }: { id: string; start: Date; end: Date },
  next: number,
  previous: number,
) {
  start = toUTCDate(start);
  end = toUTCDate(end);
  const now = toUTCDate(new Date(Date.now()));

  const isFirstBimonth = start.getDate() === 1 && end.getDate() === 15;
  const isSecondBimonth = start.getDate() === 16 && end.getDate() >= 28;

  let dateRange;
  if (isFirstBimonth) {
    dateRange = getFirstBimonthRange(now);
  } else if (isSecondBimonth) {
    dateRange = getSecondBimonthRange(now);
  } else {
    dateRange = getFullMonthRange(now);
  }

  const dateRanges = [dateRange];

  // Adjust previous ranges to take in account entire month if the given date is a bimonth.
  // previous = previous > 0 && isFirstBimonth ? Math.max(previous, isSecondBimonth ? 1 : 0) : previous

  for (let i = 0; i < previous; i++) {
    dateRanges.unshift(getPreviousRange(dateRanges[0]));
  }

  for (let i = 0; i < next; i++) {
    dateRanges.push(getNextRange(dateRanges[dateRanges.length - 1]));
  }

  return dateRanges;
}

export function getPreviousRange({ start, end }: { start: Date; end: Date }) {
  start = toUTCDate(start);
  end = toUTCDate(end);

  const isFirstBimonth = start.getDate() === 1 && end.getDate() === 15;
  const isSecondBimonth = start.getDate() === 16 && end.getDate() >= 28;

  if (isFirstBimonth) {
    return getFullMonthRange(start);
  } else if (isSecondBimonth) {
    return getFirstBimonthRange(start);
  } else {
    return getSecondBimonthRange(addMonths(start, -1));
  }
}

export function getNextRange({ start, end }: { start: Date; end: Date }) {
  start = toUTCDate(start);
  end = toUTCDate(end);

  const isFirstBimonth = start.getDate() === 1 && end.getDate() === 15;
  const isSecondBimonth = start.getDate() === 16 && end.getDate() >= 28;

  if (isFirstBimonth) {
    return getSecondBimonthRange(start);
  } else if (isSecondBimonth) {
    return getFullMonthRange(addMonths(start, 1));
  } else {
    return getFirstBimonthRange(start);
  }
}

export function isSameDay(date: Date, dateToCompare: Date): boolean {
  return FNSIsSameDay(date, dateToCompare);
}

export function diffFromToday(date: Date | string): number {
  return FNSDifferenceInDays(new Date(), new Date(date));
}
