import { ChangeEventHandler, KeyboardEvent, memo, SyntheticEvent } from 'react';

import cx from 'classnames';

import { Icon } from 'design-system/Atoms/Icon';
import { Image } from 'design-system/Atoms/Image';
import { Paragraph } from 'design-system/Atoms/Paragraph';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './Badge.module.scss';

type BadgeHandler = (
  e: SyntheticEvent<HTMLSpanElement, Event> | undefined,
) => ChangeEventHandler<HTMLSpanElement | undefined> | void;

export interface BadgeProps {
  id?: string;
  type:
    | 'regular'
    | 'dark'
    | 'common'
    | 'rare'
    | 'legendary'
    | 'featured'
    | 'sold-out'
    | 'coming-soon'
    | 'unavailable'
    | 'historic'
    | 'season-9'
    | 'season-10'
    | 'weekly'
    | 'flash'
    | 'completed'
    | 'legacy'
    | 'paid';
  text: string;
  showBackground: boolean;
  showIcon: boolean;
  iconSrc?: string;
  interactive: boolean;
  spanClassName?: string;
  paragraphClassName?: string;
  onClick?: BadgeHandler;
  ['data-testid']?: string;
}

const isDark = (type: string) => type === 'dark';
const isRegular = (type: string) => type === 'regular';
const isCommon = (type: string) => type === 'common';
const isRare = (type: string) => type === 'rare';
const isLegendary = (type: string) => type === 'legendary';
const isFeatured = (type: string) => type === 'featured';
const isSoldOut = (type: string) => type === 'sold-out';
const isComingSoon = (type: string) => type === 'coming-soon';
const isUnavailable = (type: string) => type === 'unavailable';
const isHistoric = (type: string) => type === 'historic';
const isSeason9 = (type: string) => type === 'season-9';
const isSeason10 = (type: string) => type === 'season-10';
const isWeekly = (type: string) => type === 'weekly';
const isCompleted = (type: string) => type === 'completed';
const isSpecial = (type: string) =>
  isCommon(type) || isRare(type) || isLegendary(type) || isFeatured(type);
const isLegacy = (type: string) => type === 'legacy';
const isFlash = (type: string) => type === 'flash';
const isPaid = (type: string) => type === 'paid';

const getIconFromType = (type: string) => {
  const iconIDSpecial = 'TestId__BADGEICONTYPESPECIAL';

  if (isCommon(type))
    return <Icon data-testid={iconIDSpecial} name="common" className={styles.BadgeIcon} />;
  else if (isRare(type))
    return <Icon data-testid={iconIDSpecial} name="rare" className={styles.BadgeIcon} />;
  else if (isLegendary(type))
    return <Icon data-testid={iconIDSpecial} name="legendary" className={styles.BadgeIcon} />;
  else if (isFeatured(type))
    return <Icon data-testid={iconIDSpecial} name="featured" className={styles.BadgeIcon} />;
};

export const Badge = memo<BadgeProps>((props: BadgeProps) => {
  const paragraphClasses = cx(props.paragraphClassName, {
    [styles.BadgeText]: !isDark(props.type) || isHistoric(props.type),
    [styles.BadgeTextDark]:
      isDark(props.type) ||
      isSoldOut(props.type) ||
      isComingSoon(props.type) ||
      isUnavailable(props.type),
    [styles.BadgeFlashText]: isFlash(props.type),
    [styles.BadgeWeeklyText]: isWeekly(props.type),
    [styles.BadgeLegacyText]: isLegacy(props.type),
    [styles.BadgeCompletedText]: isCompleted(props.type),
    [styles.BadgePaidText]: isPaid(props.type),
    [styles.BadgeFeaturedText]: isFeatured(props.type),
  });

  const spanClasses = cx(props.spanClassName, {
    [styles.BadgeClickable]: props.interactive,
    [styles.BadgeNoBackground]: !props.showBackground,
    [styles.BadgeDark]: isDark(props.type),
    [styles.BadgeRegular]: isRegular(props.type),
    [styles.BadgeCommon]: isCommon(props.type),
    [styles.BadgeRare]: isRare(props.type),
    [styles.BadgeLegendary]: isLegendary(props.type),
    [styles.BadgeFeatured]: isFeatured(props.type),
    [styles.BadgeSoldOut]: isSoldOut(props.type),
    [styles.BadgeComingSoon]: isComingSoon(props.type),
    [styles.BadgeUnavailable]: isUnavailable(props.type),
    [styles.BadgeHistoric]: isHistoric(props.type),
    [styles.BadgeSeason9]: isSeason9(props.type),
    [styles.BadgeSeason10]: isSeason10(props.type),
    [styles.BadgeWeekly]: isWeekly(props.type),
    [styles.BadgeFlash]: isFlash(props.type),
    [styles.BadgeLegacy]: isLegacy(props.type),
    [styles.BadgeCompleted]: isCompleted(props.type),
    [styles.BadgePaid]: isPaid(props.type),
    [styles.BadgeFeatured]: isFeatured(props.type),
  });

  const extraProps = props.interactive && {
    role: 'button',
    tabIndex: 0,
    onClick: props.onClick,
    onKeyUp: (e: KeyboardEvent<HTMLSpanElement>) =>
      isKeyboardEvent(e) && props.onClick ? props?.onClick(e) : undefined,
  };

  return (
    <span
      data-testid={props['data-testid'] ?? 'TestId__BADGEWRAPPER'}
      id={props.id}
      className={spanClasses}
      {...extraProps}
    >
      {props.showIcon && isSpecial(props.type) && <>{getIconFromType(props.type)}</>}

      {props.showIcon && !isSpecial(props.type) && props.iconSrc && (
        <Image
          data-testid="TestId__BADGEICONTYPENOTSPECIAL"
          variant="squared"
          src={props.iconSrc}
          className={styles.BadgeIcon}
          alt="badge icon"
        />
      )}

      <Paragraph
        data-testid="TestId__BADGEPARAGRAPH"
        variant="ui-bold"
        className={paragraphClasses}
      >
        {props.text}
      </Paragraph>
    </span>
  );
});
