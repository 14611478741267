import { cloneElement } from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

import { isAdmin, isManager, isSuperAdmin } from 'components/Auth/authHelpers';
import { withApiData, waitForApi } from 'components/Database';
import { INVOICE_STATUS } from 'consts/constants';
import { getPreviousDatesCount } from 'helpers/dateHelpers';
import { withUser } from 'providers/UserProvider';
import { addToast, removeToast, updateUserDataActionCreator } from 'store/actionCreators';
import {
  deleteInvoiceItemApiActionCreator,
  getHarvestTimeEntriesActionCreator,
  getHoursInvoiceCategoryApiActionCreator,
  getInvoiceCategoriesApiActionCreator,
  getSimplifiedProjectsApiActionCreator,
  getReferralInvoiceCategoryApiActionCreator,
  getUnleashBudgetApiActionCreator,
  getUserAssignmentsApiActionCreator,
  getUserCurrentInvoiceApiActionCreator,
  getUsersApiActionCreator,
  getUnleashPlusInvoiceCategoryApiActionCreator,
  patchCurrentInvoiceApiActionCreator,
  patchUserCurrentInvoiceApiActionCreator,
  postInvoiceItemApiActionCreator,
  putInvoiceItemApiActionCreator,
  updateInvoiceApiActionCreator,
  getUserCompletedInvoicesApiActionCreator,
  uploadInvoiceItemFileApiActionCreator,
  getProductiveTimeEntriesActionCreator,
} from 'store/reduxRestApi';
import { AppState } from 'store/storeTypes';
import { ApiRequestObject, InvoiceApiModel, User } from 'types/types';
import {
  formatDateFromSimpleStrings,
  getFnsCurrentBimonth,
  getFnsDateRanges,
} from 'utils/datesHelper';
import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';

interface SubmitDataProviderType {
  user: {
    id: number;
    canUseHarvest: boolean;
    harvestId: string;
    productiveId: string;
    canUseProductive: boolean;
  };
  harvestSettingEnabled: boolean;
  harvestEnabled: boolean;
  productiveSettingEnabled: boolean;
  productiveEnabled: boolean;
  invoice: ApiRequestObject<{ data: InvoiceApiModel }>;
  getHoursInvoiceCategoryApiActionCreator: (options: { useCache: boolean }) => void;
  getInvoiceCategoriesApiActionCreator: (options: { useCache: boolean }) => void;
  getSimplifiedProjectsApiActionCreator: () => void;
  getReferralInvoiceCategoryApiActionCreator: (options: { useCache: boolean }) => void;
  getUserAssignmentsApiActionCreator: (options: { params: { userId: number } }) => void;
  getUserCurrentInvoiceApiActionCreator: (options: {
    params: { userId: number };
    body: { createDraft: boolean };
  }) => void;
  getHarvestTimeEntriesActionCreator: (options: {
    body: { harvestId: string; start: string; end: string };
  }) => void;
  getProductiveTimeEntriesActionCreator: (options: {
    body: { productiveId: string; start: string; end: string };
  }) => void;
  getUnleashBudgetForDate: (dateFrom: string) => void;
  getUsersApiActionCreator: (options: { query: { isActive: boolean } }) => void;
  getUserCompletedInvoicesForInvoicePeriod: (id: number) => void;
  getUnleashPlusInvoiceCategoryApiActionCreator: (options: { useCache: boolean }) => void;
}

const SubmitDataProvider = ({ children, ...props }: React.PropsWithChildren<any>) => {
  return cloneElement(children, props);
};

type OwnPropsType = {
  userId: number;
  user: User;
};

const mapStateToProps = (state: AppState, ownProps: OwnPropsType) => {
  const harvestSetting = get(state.getSettings, ['response', 'data'], []).find(
    (item: any) => item.id === 'harvest',
  );

  const productiveSetting = get(state.getSettings, ['response', 'data'], []).find(
    (item: any) => item.id === 'productive.io',
  );

  const shouldSkipSurvey =
    !!ownProps.userId ||
    (ownProps.user?.isXWP &&
      [false, undefined].includes(harvestSetting?.values?.enableInvoiceSurvey));

  return {
    harvestSettingEnabled: harvestSetting?.values?.enableHarvest,
    harvestEnabled: !!(
      !isFeatureEnabled(FEATURE_FLAGS.productive) &&
      harvestSetting?.values?.enableHarvest &&
      ownProps.user.canUseHarvest &&
      ownProps.user.harvestId &&
      ownProps.user.rate !== null &&
      state.getUserCurrentInvoice.response?.data?._status.name === INVOICE_STATUS.DRAFT
    ),
    shouldSkipSurvey,
    assignments: state.getUserAssignments,
    harvestData: state.getHarvestData,
    hoursInvoiceCategory: state.getHoursInvoiceCategory,
    invoice: state.getUserCurrentInvoice,
    invoiceCategories: state.getInvoiceCategories,
    projects: state.getSimplifiedProjects,
    referralInvoiceCategory: state.getReferralInvoiceCategory,
    unleashBudget: state.getUnleashBudget,
    users: state.getUsers,
    userInvoices: state.getUserCompletedInvoices,
    unleashPlusInvoiceCategory: state.getUnleashPlusInvoiceCategory,
    canSubmitDuplicateInvoicePeriod:
      isAdmin(state.session?.data) ||
      isSuperAdmin(state.session?.data) ||
      isManager(state.session?.data),
    productiveSettingEnabled: productiveSetting?.values?.enableProductive,
    productiveEnabled: !!(
      productiveSetting?.values?.enableProductive &&
      isFeatureEnabled(FEATURE_FLAGS.productive) &&
      ownProps.user.canUseProductive &&
      ownProps.user.productiveId &&
      ownProps.user.rate !== null &&
      state.getUserCurrentInvoice.response?.data?._status.name === INVOICE_STATUS.DRAFT
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: OwnPropsType) => {
  return bindActionCreators(
    {
      addToast,
      deleteInvoiceItemApiActionCreator,
      getHarvestTimeEntriesActionCreator,
      getHoursInvoiceCategoryApiActionCreator,
      getInvoiceCategoriesApiActionCreator,
      getSimplifiedProjectsApiActionCreator,
      getReferralInvoiceCategoryApiActionCreator,
      getUserAssignmentsApiActionCreator,
      getUserCurrentInvoiceApiActionCreator,
      getUsersApiActionCreator,
      getUnleashPlusInvoiceCategoryApiActionCreator,
      patchCurrentInvoiceApiActionCreator,
      patchUserCurrentInvoiceApiActionCreator,
      postInvoiceItemApiActionCreator,
      putInvoiceItemApiActionCreator,
      removeToast,
      updateInvoiceApiActionCreator,
      updateUserDataActionCreator,
      uploadInvoiceItemFileApiActionCreator,
      getUnleashBudgetForDate(date) {
        return (
          (date &&
            getUnleashBudgetApiActionCreator({
              params: { userId: props.user.id },
              query: { dateFrom: formatDateFromSimpleStrings(date) },
            })) ||
          0
        );
      },
      getUserCompletedInvoicesForInvoicePeriod(userId) {
        const previousDatesCount = getPreviousDatesCount(Date.now());
        const { length, 0: firstDateRange, [length - 1]: lastDateRange } = getFnsDateRanges(
          getFnsCurrentBimonth(),
          0,
          previousDatesCount,
        );
        const startDate = new Date(firstDateRange.start).toISOString();
        const endDate = new Date(lastDateRange.end).toISOString();

        return getUserCompletedInvoicesApiActionCreator({
          params: { userId },
          query: { dateFrom: startDate, dateTo: endDate },
        });
      },
      getProductiveTimeEntriesActionCreator,
    },
    dispatch,
  );
};

const ConnectedSubmitDataProvider = compose<typeof SubmitDataProvider>(
  withUser,
  connect(mapStateToProps, mapDispatchToProps),
  withApiData(
    [
      (props: SubmitDataProviderType) =>
        props.getHoursInvoiceCategoryApiActionCreator({ useCache: true }),
      (props: SubmitDataProviderType) =>
        props.getInvoiceCategoriesApiActionCreator({ useCache: true }),
      (props: SubmitDataProviderType) => props.getSimplifiedProjectsApiActionCreator(),
      (props: SubmitDataProviderType) =>
        props.getReferralInvoiceCategoryApiActionCreator({ useCache: true }),
      (props: SubmitDataProviderType) =>
        props.getUserAssignmentsApiActionCreator({ params: { userId: props.user.id } }),
      (props: SubmitDataProviderType) => {
        return props.getUserCurrentInvoiceApiActionCreator({
          params: { userId: props.user.id },
          body: {
            createDraft: !!(
              (props.harvestSettingEnabled && props.user.canUseHarvest && props.user.harvestId) ||
              (props.productiveSettingEnabled &&
                props.user.canUseProductive &&
                props.user.productiveId)
            ),
          },
        });
      },
      (props: SubmitDataProviderType) => {
        const start = props.invoice.response?.data?.dateFrom ?? '';
        const end = props.invoice.response?.data?.dateTo ?? '';

        if (isFeatureEnabled('newInvoice')) {
          if (props.harvestEnabled) {
            props.getHarvestTimeEntriesActionCreator({
              body: {
                harvestId: props.user.harvestId,
                start,
                end,
              },
            });
          }
          if (props.productiveEnabled && start && end) {
            props.getProductiveTimeEntriesActionCreator({
              body: {
                productiveId: props.user.productiveId,
                start,
                end,
              },
            });
          }
        }
      },
      (props: SubmitDataProviderType) =>
        props.getUnleashBudgetForDate(get(props, 'invoice.response.data.dateFrom')),
      (props: SubmitDataProviderType) =>
        props.getUsersApiActionCreator({ query: { isActive: true } }),
      (props: SubmitDataProviderType) =>
        props.getUserCompletedInvoicesForInvoicePeriod(props.user.id),
      (props: SubmitDataProviderType) =>
        props.getUnleashPlusInvoiceCategoryApiActionCreator({ useCache: true }),
    ],
    ['location', 'invoice.response.data.dateFrom'],
  ),
  waitForApi(
    [
      'hoursInvoiceCategory',
      'invoiceCategories',
      'projects',
      'referralInvoiceCategory',
      'unleashBudget',
      'assignments',
      'invoice',
      'users',
      'userInvoices',
      'unleashPlusInvoiceCategory',
    ].filter(Boolean),
  ),
)(SubmitDataProvider);

export const withSubmitData = (WrappedComponent: React.ElementType) => (injectedProps: any) => {
  return (
    <ConnectedSubmitDataProvider {...injectedProps}>
      <WrappedComponent {...injectedProps} />
    </ConnectedSubmitDataProvider>
  );
};
