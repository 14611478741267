import { ReactNode, memo } from 'react';

import styles from './SubmitStep.module.css';

type SubmitStepProps = {
  children: ReactNode;
  isActive?: boolean;
};

const SubmitStep = ({ children }: SubmitStepProps) => (
  <li data-testid="submitStep" className={styles.Step}>
    {children}
  </li>
);

export default memo(SubmitStep);
