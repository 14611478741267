import { map } from 'lodash';

import { paymentMethodsMap } from 'consts/constants';
import { ValidationMessages } from 'pages/Profile/ProfileMessages';
import { isLooselyValidEmail } from 'utils/helpers';

export const getFieldsForMethod = (method) => paymentMethodsMap[method]?.fields ?? {};

export const validatePaymentFields = (method, payment = {}, ignoredFields = []) => {
  const fields = getFieldsForMethod(method);

  return map(fields, (value, key) => ({
    value,
    key,
  })).reduce((acc, field) => {
    if (ignoredFields.includes(field.key)) {
      return acc;
    }

    if (!payment[field.key] && !field.value.optional) {
      return {
        ...acc,
        [field.key]: `${field.value.label} needs to be filled`,
      };
    }

    if (field.key === 'paymentEmail' && payment[field.key]) {
      return !isLooselyValidEmail(payment[field.key])
        ? { ...acc, [field.key]: ValidationMessages.invalidTransferwiseEmail }
        : acc;
    }

    return acc;
  }, {});
};

export const hasPaymentMethod = (accountingData) => {
  return !!(accountingData?._paymentType?.name && accountingData._paymentType.id);
};
