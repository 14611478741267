import { memo } from 'react';

import clamp from 'lodash/clamp';

import ProgressBarStep, { ProgressBarStepProps } from './ProgressBarStep';

import styles from './ProgressBarStepper.module.scss';

function ProgressBarStepper({ progress, steps }: ProgressBarStepperProps) {
  function getStyle() {
    const width = clamp(progress, 0, 100);
    return { width: `${width}%` };
  }

  return (
    <div className={styles.ProgressBar_Container}>
      <div className={styles.ProgressBar_Line_Container}>
        <span className={styles.ProgressBar_Line} />
        <span
          className={`${styles.ProgressBar_Line} ${styles.ProgressBarStep_ProgressLine}`}
          style={getStyle()}
        />
      </div>
      <div className={styles.ProgressBar_Steps_Container}>
        {steps.map((step, index) => (
          <ProgressBarStep
            key={`step-${index}`}
            active={step.active}
            icon={step.icon}
            href={step.href}
            title={step.title}
            isFirst={index === 0}
            isLast={index === steps.length - 1}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(ProgressBarStepper);

type ProgressBarStepperProps = {
  progress: number;
  steps: Array<ProgressBarStepProps>;
};
