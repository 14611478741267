import { formatCurrency } from 'utils/helpers';

import styles from './InvoiceTotal.module.scss';

type Props = {
  total: number;
  currency: string;
};

const InvoiceTotal: React.FC<Props> = ({ total, currency }) => (
  <div className={styles.InvoiceTotal}>Current Total: {formatCurrency(total, currency, true)}</div>
);

export default InvoiceTotal;
