import { ReactNode } from 'react';

export type PaginationItem = {
  text: string | ReactNode;
  page: 'prev' | 'next' | number | null;
};

export type Config = {
  backwardArrow?: string | ReactNode;
  forwardArrow?: string | ReactNode;
  ellipsis?: string | ReactNode;
  isMobile?: boolean;
};

export function getMobilePageItems({
  items,
  page,
  pageCount,
  forwardArrow,
  ellipsis,
}: {
  items: PaginationItem[];
  page: number;
  pageCount: number;
  forwardArrow: string | ReactNode;
  ellipsis: string | ReactNode;
}) {
  items.push({ text: '1', page: 1 });
  if (page >= 4) {
    items.push({ text: ellipsis, page: null });
  }
  if (page > 3 && page < pageCount - 2) {
    items.push({ text: `${page}`, page: page });
  } else {
    const start = page + 2 >= pageCount ? pageCount - 2 : 2;
    const end = start === 2 ? 3 : pageCount - 1;
    for (let i = start; i <= end; i++) {
      items.push({ text: `${i}`, page: i });
    }
  }
  if (page < pageCount - 2) {
    items.push({ text: ellipsis, page: null });
  }
  items.push({ text: `${pageCount}`, page: pageCount });
  items.push({ text: forwardArrow, page: 'next' });
  return items;
}

export function getPageItems(page: number, pageCount: number, config: Config = {}) {
  if (!pageCount || !page || pageCount <= 1) {
    return [];
  }
  const ellipsis = config.ellipsis || '..';
  const backwardArrow = config.backwardArrow || '«';
  const forwardArrow = config.forwardArrow || '»';
  const items: PaginationItem[] = [{ text: backwardArrow, page: 'prev' }];

  // display up to 7 items on mobile
  if (config.isMobile && pageCount > 5) {
    return getMobilePageItems({
      items: items.slice(0),
      page,
      pageCount,
      forwardArrow,
      ellipsis,
    });
  }

  if (pageCount <= 5) {
    for (let i = 1; i <= pageCount; i++) {
      items.push({ text: `${i}`, page: i });
    }
  } else {
    if (page > 3) {
      items.push({ text: '1', page: 1 });
    }
    if (page > 4) {
      items.push({ text: ellipsis, page: null });
    }

    for (let i = page - 2; i <= page + 2; i++) {
      if (i > 0 && i <= pageCount) {
        items.push({ text: `${i}`, page: i });
      }
    }

    if (page < pageCount - 3) {
      items.push({ text: ellipsis, page: null });
    }
    if (page < pageCount - 2) {
      items.push({ text: `${pageCount}`, page: pageCount });
    }
  }
  items.push({ text: forwardArrow, page: 'next' });

  return items;
}

export const forceNoPaginationOnQuery = (query: Record<string, any>) => ({
  ...query,
  page: -1,
});
