import { memo } from 'react';

import { paymentMethodsMap } from 'consts/constants';
import { PaymentType } from 'types/types';
import { useGetPaymentTypes } from 'utils/apiQueryHooks';

import { ApiQueryProps, ApiQuerySelect } from '../ApiQuerySelect';

const PaymentTypeSelect = (props: Props) => (
  <ApiQuerySelect
    useGetQuery={useGetPaymentTypes}
    mapLabel={(_, option) => paymentMethodsMap[option.name].label}
    {...props}
  />
);

export default memo(PaymentTypeSelect);

type Props = Omit<ApiQueryProps<PaymentType, false>, 'useGetQuery'> & {
  useGetQuery?: any;
  onBlur?: () => void;
};
