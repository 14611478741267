import { memo } from 'react';

import cx from 'classnames';

import { Heading } from 'design-system/Atoms/Heading';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './PageHeader.module.scss';

export type PageHeaderProps = {
  title: string;
  subtitle?: string;
  alignment?: 'start' | 'center' | 'end';
  layout?: 'contained' | 'fluid';
} & JSX.IntrinsicElements['div'];

export const PageHeader = memo<PageHeaderProps>(
  ({ title, subtitle, alignment = 'start', layout = 'fluid', ...props }) => {
    const wrapperClasses = cx(styles.Wrapper, props.className, {
      [styles.Contained]: layout === 'contained',
      [styles.Fluid]: layout === 'fluid',
      [styles.Start]: alignment === 'start',
      [styles.Center]: alignment === 'center',
      [styles.End]: alignment === 'end',
    });

    return (
      <div data-testid="TestId__PAGEHEADERWRAPPER" className={wrapperClasses}>
        <Heading data-testid="TestId__PAGEHEADER__TITLE" className={styles.Title} level="h3">
          {title}
        </Heading>
        {subtitle && (
          <Paragraph
            data-testid="TestId__PAGEHEADER__SUBTITLE"
            className={styles.SubTitle}
            variant="regular"
          >
            {subtitle}
          </Paragraph>
        )}
      </div>
    );
  },
);
