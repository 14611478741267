import { useEffect, useRef } from 'react';

import { DropResult } from '@hello-pangea/dnd';
import currencyFormatter from 'currency-formatter';
import { addYears, differenceInDays, format, parseISO, subMonths, startOfMonth } from 'date-fns';
import moment from 'moment';

import {
  DATE_FORMAT_MONTH_LABEL,
  DISPLAY_DATE_FORMAT,
  EMAIL_REGEX,
  CURRENCIES_SYMBOLS,
  PHONE_REGEX,
  INVOICE_CATEGORY,
  FNS_FORMAT_MONTH_YEAR,
  EXPENSE_CATEGORY,
} from 'consts/constants';
import { addToast } from 'store/actionCreators';
import currentStore from 'store/currentStore';
import { UnleashBudget } from 'store/storeTypes';
import {
  Currency,
  DragAndDrop,
  Invoice,
  InvoiceItem,
  InvoiceStatus,
  Settings,
  VaultItemModel,
} from 'types/types';

import * as datesHelper from './datesHelper';

interface InvoiceItemWithInvoice extends InvoiceItem {
  _invoice?: {
    id: number;
    number: number;
    isCreatedBySystem?: boolean;
    dateFrom?: string;
    _currency?: Currency;
  };
}

export const isWeekday = (date: moment.Moment) =>
  date.isoWeekday() !== 6 && date.isoWeekday() !== 7;

export const formatDate = (
  date?: moment.Moment | Date | string | number,
  format = DISPLAY_DATE_FORMAT,
) => moment.utc(date).format(format);

export const FSformatDate = (date?: Date | string | number, formatStr = 'dd MMM yyyy') => {
  if (!date) {
    return '';
  }

  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date);
  return format(parsedDate, formatStr);
};

export const formatCurrency = (
  number?: number | 'Hidden',
  currency: string = 'usd',
  returnString: boolean = false,
  showSymbol: boolean = false,
) => {
  if (number === 'Hidden') {
    return number;
  }
  if (!number || isNaN(number)) {
    number = 0;
  }
  const configuredParts = [
    {
      addSpace: false,
      value: showSymbol ? CURRENCIES_SYMBOLS[currency.toUpperCase()] : '',
    },
    {
      addSpace: true,
      value: Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number as number),
    },
    {
      addSpace: false,
      value: currency.toUpperCase(),
    },
  ];

  const spacedString = configuredParts.reduce((str, { addSpace, value }) => {
    str += value;
    if (addSpace) str += ' ';
    return str;
  }, '');

  if (returnString) return spacedString;
  return <span dangerouslySetInnerHTML={{ __html: spacedString }} />;
};

export const formatCurrencyWithPrefix = (
  number?: string | number,
  currency: string = 'usd',
  returnString: boolean = false,
) => {
  const string =
    currency.toUpperCase() +
    ' ' +
    currencyFormatter.format(number as number, {
      code: currency.toUpperCase(),
      symbol: '',
    });

  if (returnString) return string;
  return <span dangerouslySetInnerHTML={{ __html: string }} />;
};

export const mkInt = (value: string) => Math.abs(parseInt(value.replace(/\D/g, ''), 10));

export const getSettingByName = (settings?: Settings[] | null, name?: string): any => {
  if (!settings) {
    return null;
  }
  const setting = settings.find((item) => item.id === name);

  if (setting?.values) {
    return setting.values;
  }

  return null;
};

// Set whole function as any types since anything can come from a component's state
export const setStateAsync = (self: any, stateReducer: any, callback: (...args: any[]) => any) => {
  return new Promise((resolve, reject) => {
    let originalState: any;
    self.setState(
      (state: any) => {
        originalState = state;
        return stateReducer(state);
      },
      () => {
        callback()
          .then(resolve)
          .catch((err: any) => {
            self.setState(originalState);
            return reject(err);
          });
      },
    );
  });
};

export const countDays = (
  startDate?: moment.Moment | Date | string,
  endDate?: moment.Moment | Date | string,
) => {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  return endMoment.diff(startMoment, 'days') + 1;
};

export const countBusinessDays = (
  startDate?: moment.Moment | Date | string,
  endDate?: moment.Moment | Date | string,
) => {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  let businessDays = 0;

  while (startMoment.isSameOrBefore(endMoment)) {
    const day = startMoment.day();
    if (day >= 1 && day <= 5) {
      businessDays++;
    }
    startMoment.add(1, 'day');
  }

  return businessDays;
};

interface ItemWithOrder {
  order?: number;
}

export function reorderItems<T extends ItemWithOrder>(
  { sourceIndex, targetIndex }: { sourceIndex: number; targetIndex: number },
  allItems: T[],
): T[] {
  const rearrangedItems = allItems.map((a) => Object.assign({}, a));
  const [removed] = rearrangedItems.splice(sourceIndex, 1);
  rearrangedItems.splice(targetIndex, 0, removed);
  return rearrangedItems;
}

export function updateItemsOnReorder<T extends ItemWithOrder>(
  { sourceIndex, targetIndex }: { sourceIndex: number; targetIndex: number },
  allItems: T[],
  updateItem: (item: T, _: unknown) => void,
): T[] {
  const rearrangedItems = reorderItems({ sourceIndex, targetIndex }, allItems);

  for (let i = 0; i < rearrangedItems.length; ++i) {
    if (rearrangedItems[i]?.order !== allItems[i]?.order) {
      rearrangedItems[i].order = allItems[i]?.order;
      updateItem(rearrangedItems[i], false);
    }
  }

  return rearrangedItems;
}

export function useInterval(callback?: (...args: any[]) => any, delay?: number) {
  const savedCallback = useRef<(...args: any[]) => any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallback.current?.(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getErrorMessage = (err: any) =>
  String(err.payload?.message || err.payload || err.message || err || '');

export const isLooselyValidEmail = (email: string) => {
  // Only way to properly validate is by testing the email address which should be done server side
  // https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html#input.email.attrs.value.single
  return EMAIL_REGEX.test(email);
};

export const minBirthday = datesHelper.sub(new Date(), {
  years: 100,
});

export const maxBirthday = datesHelper.sub(new Date(), {
  years: 16,
});

export const isBirthdayValid = (dateString?: moment.Moment | Date | string) => {
  const date = moment.utc(dateString);
  return date.isValid() && date.isBetween(minBirthday, maxBirthday);
};

export const roundMoney = (money: number) => (money ? Math.round(money * 100) / 100 : money);

export const numberBoundaries = ({
  number,
  min,
  max,
}: {
  number: number;
  min?: number;
  max?: number;
}) => {
  if (min != null && number < min) return min;
  if (max != null && number > max) return max;
  return number;
};

export const handleRequestError = (error: any) => {
  const message = getErrorMessage(error);

  console.error({ type: error.type, message });
};

export const isValidUrl = (stringUrl: string) => {
  try {
    new URL(stringUrl);
    return true;
  } catch (_) {
    return false;
  }
};

export const isExternal = (path: string) =>
  path.startsWith('http://') || path.startsWith('https://');

export const normalizeUrl = (string: string) => {
  const hasProtocol = isExternal(string);

  return hasProtocol ? string : `https://${string}`;
};

export const getStatus = (statusName: string, invoiceStatuses: InvoiceStatus[]): InvoiceStatus =>
  invoiceStatuses.find((statusObj) => statusObj.name === statusName) as InvoiceStatus;

export const getPreviousMonthsString = (date: Date | number = new Date(), months: number = 12) => {
  const options = [];
  for (let i = 0; i < months; i++) {
    const currentMonth = startOfMonth(subMonths(date, i));
    options.push({
      label: format(currentMonth, FNS_FORMAT_MONTH_YEAR),
      value: format(currentMonth, 'yyyy-MM-dd'),
    });
  }
  return options;
};

export const getPreviousMonths = (date?: moment.Moment | Date | string | number, months = 12) => {
  const momentDate = moment.utc(date);
  const dates: { label: string; value: number }[] = [];

  if (!momentDate.isValid()) {
    return dates;
  }

  const initialMonth = momentDate.clone().startOf('month');
  const initialLabel = initialMonth.format(DATE_FORMAT_MONTH_LABEL);

  dates.push({ label: initialLabel, value: initialMonth.valueOf() });

  for (let i = 1; i < months; i++) {
    const value = momentDate.subtract(1, 'month').startOf('month').clone();
    const label = value.format(DATE_FORMAT_MONTH_LABEL);

    dates.push({ label, value: value.valueOf() });
  }

  return dates;
};

export const getDateFromTo = (date: Date) => {
  const parsedDate = moment.utc(date);
  const dateFrom = parsedDate.clone().startOf('month').unix();
  const dateTo = parsedDate.clone().endOf('month').unix();
  return { dateFrom, dateTo };
};

export const getDateUnix = (date: number) => {
  return moment.unix(date);
};

export const FSgetDateUnix = (date: number) => {
  return new Date(date * 1000);
};

type ValueDateType = {
  dateFrom: number | undefined;
  dateTo: number | undefined;
};

export const getPreviousMonthsInvoicePeriods = (
  date?: moment.Moment | Date | string | number,
  months = 12,
  isMultiSelect = false,
) => {
  const momentDate = moment.utc(date);
  const dates: {
    label: string;
    value: ValueDateType | string;
  }[] = [];

  if (!momentDate.isValid()) {
    return dates;
  }

  for (let i = 1; i <= months; i++) {
    let momentMonth;
    if (i === 1) {
      momentMonth = momentDate.clone().startOf('month');
    } else {
      momentMonth = momentDate.subtract(1, 'month').startOf('month');
    }

    const monthlyLabel = `01-${momentMonth.daysInMonth()} ${momentMonth.format(
      DATE_FORMAT_MONTH_LABEL,
    )}`;
    const firstHalfLabel = `01-15 ${momentMonth.format(DATE_FORMAT_MONTH_LABEL)}`;
    const secondHalfLabel = `16-${momentMonth.daysInMonth()} ${momentMonth.format(
      DATE_FORMAT_MONTH_LABEL,
    )}`;

    let finalDateValue: ValueDateType | string = {
      dateFrom: momentMonth.valueOf(),
      dateTo: momentMonth.clone().add(14, 'days').valueOf(),
    };

    if (isMultiSelect) {
      finalDateValue = `${momentMonth.valueOf()}-${momentMonth.clone().add(14, 'days').valueOf()}`;
    }

    dates.push({
      label: firstHalfLabel,
      value: finalDateValue,
    });

    // Only include these ranges if they exist in the current month
    if ((i === 1 && momentDate.date() > 15) || i >= 2) {
      finalDateValue = {
        dateFrom: momentMonth.clone().add(15, 'days').valueOf(),
        dateTo: momentMonth.clone().endOf('month').startOf('day').valueOf(),
      };

      if (isMultiSelect) {
        finalDateValue = `${momentMonth
          .clone()
          .add(15, 'days')
          .valueOf()}-${momentMonth.clone().endOf('month').startOf('day').valueOf()}`;
      }

      dates.push({
        label: secondHalfLabel,
        value: finalDateValue,
      });

      finalDateValue = {
        dateFrom: momentMonth.valueOf(),
        dateTo: momentMonth.clone().endOf('month').startOf('day').valueOf(),
      };

      if (isMultiSelect) {
        finalDateValue = `${momentMonth.valueOf()}-${momentMonth
          .clone()
          .endOf('month')
          .startOf('day')
          .valueOf()}`;
      }

      dates.push({
        label: monthlyLabel,
        value: finalDateValue,
      });
    }
  }

  return dates;
};

export const getTotalMonthsSinceXHQReleaseDate = (): number => {
  const now = moment.utc(Date.now());
  const releaseDate = moment.utc('2015-12-01'); // December 2015
  return now.diff(releaseDate, 'month') + 1;
};

export const generateImageFormDataFromStaticURI = async (uri: string) => {
  const response = await fetch(uri);
  const imgBlob = await response.blob();
  const fileName = uri.split('/').pop() ?? 'image.svg';
  const file = new File([imgBlob], fileName, { type: 'image/svg+xml' });
  const formData = new FormData();
  formData.append('files', file);

  return formData;
};

export const parseNumbers = (text: string) => text.replace(/\D/g, '');

export const validatePhoneNumber = (phoneNumber: string) =>
  PHONE_REGEX.test(parseNumbers(phoneNumber));

// Feed does not accept @, ' and . in user id.
// filter more based on the RFC 5321 https://tools.ietf.org/html/rfc5321
export const getFeedUserId = (email = '') => email.replace(/[@."'`+!?^=/#$%& ]/gi, '');

export const isAirwallexEnabled = () =>
  String(process.env.REACT_APP_ENABLE_AIRWALLEX).toLowerCase() === 'true';

export const getCurrentCursorPosition = (inputId: string) => {
  const input = document.getElementById(inputId);

  if (!input) {
    return undefined;
  }

  const inputAsInputElement = input as HTMLInputElement;

  return inputAsInputElement.selectionStart;
};

export const concatEmojiWithText = ({
  emoji,
  text,
  position,
}: {
  emoji: string;
  text: string;
  position: number;
}) => {
  if (!position) {
    return text + emoji;
  }

  const arrayOfLetters = text.split('');

  arrayOfLetters.splice(position, 0, emoji);

  return arrayOfLetters.join('');
};

export const dragAndDropHandler = (
  dropResult: DragAndDrop | DropResult,
  items: any[],
  setState: any,
  actionCreator: (...args: any[]) => any,
) => {
  const sourceIndex = dropResult?.source?.index;
  const targetIndex = dropResult?.destination?.index as number;

  const { dispatch } = currentStore();

  const itemsToReorder: { id: any; order: any }[] = [];

  const rearrangedItems = updateItemsOnReorder(
    { sourceIndex, targetIndex },
    items,
    (item: { id: any; order: any }) =>
      itemsToReorder.push({
        id: item.id,
        order: item.order,
      }),
  );

  setState(rearrangedItems);

  return actionCreator({ body: itemsToReorder })
    .then(() => {
      dispatch(addToast({ text: 'Successfully updated', type: 'success' }));
    })
    .catch((err: { payload: { message: string } }) => {
      const text = err?.payload?.message || 'Failed to update';
      dispatch(addToast({ text, type: 'error' }));
    });
};

/**
 * Pad any value on start
 *
 * @param {any} value
 * @param {string} padString
 * @return {string} value with padding on start
 */
export const padStartValue = (value: number, padLength = 5, padString = '0') => {
  return String(value).padStart(padLength, padString);
};

/**
 * Get Item id or return default value (-1)
 *
 * @param {object} item
 * @return {string} item.id or -1
 */
export function getItemId(item: { id?: number }): string {
  return (item.id || -1).toString();
}

/**
 * Returns true whether stock label for Vault Item should be displayed
 *
 * @param {VaultItemModel} item
 * @return {boolean}
 */
export function shouldDisplayStockLabel(item: VaultItemModel): boolean {
  return item.stock === 0 && item._whenStockIsZero.id > 1;
}

/**
 * Returns the date of the first day of the current UTC month.
 * This matches UTC date values that come from the API, in order to avoid
 * unexpected date mismatches.
 *
 * @return {Date}
 */
export function getFirstDayOfCurrentMonth(): Date {
  const today = new Date();
  return new Date(today.getUTCFullYear(), today.getUTCMonth(), 1);
}

export function getFirstDayOfMonth(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
}

export function getLastDayOfMonth(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
}

export const toUTCDate = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
};

export function getISOUnixStartDate(startDate: number): string {
  return toUTCDate(datesHelper.startOfDay(FSgetDateUnix(startDate))).toISOString();
}

export function getISOUnixEndDate(endDate: number): string {
  return toUTCDate(datesHelper.endOfDay(FSgetDateUnix(endDate))).toISOString();
}

export function verifyIfHasExceededUnleashCategory(
  currentMonthUnleashInvoiceItems: InvoiceItemWithInvoice[],
  draftUnleashInvoiceItem: Partial<InvoiceItem>,
  draftUnleashCategoryItems?: InvoiceItem[],
) {
  const { rate: value, quantity, _unleashCategory } = draftUnleashInvoiceItem;
  const categoryLimit = Number(_unleashCategory?.limit);
  let totalUnleashValue = Number(value) * Number(quantity);

  // Draft reimbursement item focus
  if (categoryLimit && totalUnleashValue > categoryLimit) return true;

  // All draft reimbursement items of the same category of the item focus
  if (draftUnleashCategoryItems) {
    const totalDraftUnleashCategoryItems = draftUnleashCategoryItems?.reduce(
      (acc, item: InvoiceItem) => acc + item?.rate * item?.quantity,
      0,
    );
    totalUnleashValue += totalDraftUnleashCategoryItems;
  }

  // All reimbursement items of the same category that were submitted on the first invoice cycle of the month
  const currentInvoicesUsedByCategory = currentMonthUnleashInvoiceItems
    ? currentMonthUnleashInvoiceItems.filter((item) => {
        return item._invoice ? !item._invoice.isCreatedBySystem : false;
      })
    : [];

  const totalUsedUnleashValue = currentInvoicesUsedByCategory.reduce(
    (acc, item: InvoiceItem) => acc + item.rate * item.quantity,
    0,
  );

  const hasExceeded = !!categoryLimit && totalUnleashValue + totalUsedUnleashValue > categoryLimit;
  return hasExceeded;
}

export function verifyIfInvoiceHasZeroHours(invoice: Invoice) {
  const { hours, _invoiceItems } = invoice;
  const invoiceHasZeroHours = hours === 0;
  const lineItemsHasZeroHours = !_invoiceItems?.find((item) => {
    return (
      item?._category?.name === INVOICE_CATEGORY.HOURS_WORKED ||
      item?._expenseCategory?.name === EXPENSE_CATEGORY.HOURS_WORKED_BILLABLE ||
      item?._expenseCategory?.name === EXPENSE_CATEGORY.HOURS_WORKED_NON_BILLABLE
    );
  })?.quantity;
  // Invoice hours is not always present, so we need to double check with line items
  return invoiceHasZeroHours || lineItemsHasZeroHours;
}

export function formatToUSDate(date: string | Date) {
  try {
    const [year, month, day] = new Date(date).toISOString().split('T')?.[0]?.split('-');
    return `${month}/${day}/${year}`;
  } catch {
    return '';
  }
}

export function getDay(date: Date | string) {
  try {
    const day = Number(new Date(date).toISOString().split('T')?.[0]?.split('-')?.[2]);
    return day;
  } catch {
    return 0;
  }
}

export function castBytesToKb(size: number) {
  if (!Number(size) || Number(size) < 0) return 0;
  return size / 1024;
}

export function getAvailableUnleashCategoryBudget(
  unleashBudget: UnleashBudget | null,
  draftReimbursement: Partial<InvoiceItem>,
): number {
  if (!unleashBudget) {
    return 0;
  }
  const categoryBonuses = unleashBudget.bonuses
    ?.filter(
      (bonus) =>
        bonus.total > bonus.used &&
        draftReimbursement._unleashCategory?.id === bonus.unleashCategory.id,
    )
    ?.map((bonus) => ({ id: bonus.unleashCategory.id, available: bonus.total - bonus.used }));

  const availableBonus = categoryBonuses?.reduce((acc, bonus) => acc + bonus.available, 0);
  return roundMoney((availableBonus ?? 0) + unleashBudget.total - unleashBudget.used);
}

export function diffFromToday(date: Date): number {
  return differenceInDays(new Date(), date);
}

export function getTimeZoneOffset(): number {
  return -new Date().getTimezoneOffset() * 60;
}

export function todayISODate(): string {
  return new Date().toISOString();
}

export function dateISODate(date: Date | null): string {
  if (date) {
    return date.toISOString();
  }
  return '';
}

export function oneYearFromTodayISO(): string {
  const currentDate = new Date(); // gets the current date
  const dateOneYearFromNow = addYears(currentDate, 1);
  return dateOneYearFromNow.toISOString();
}

export function utcFromString(date: string): Date {
  return parseISO(date);
}
