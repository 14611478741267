import { ReactChildren } from 'react';

import cs from 'classnames';
import { isNil, get, identity } from 'lodash';
import ReactSelect, {
  components,
  createFilter,
  StylesConfig,
  SingleValueProps,
  ValueContainerProps,
  GroupBase,
  Props as ReactSelectProps,
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { MapOptionProps, OptionType, SelectProps } from './types';

import selectStyles from './Select.module.css';

export const mapOption = (item: MapOptionProps['item'], mapLabel: MapOptionProps['mapLabel']) => {
  const label = mapLabel(item.name || item.id, item);
  return {
    label,
    value: item.id,
    option: item,
  };
};

export const NONE_OPTION = {
  label: 'None',
  value: 'none',
  id: 'none',
  option: { value: 'none', id: 'none' },
};

interface SelectComponentProps {
  dataTestId?: string;
}

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  items = [],
  width = null,
  mapLabel = null,
  sort = null,
  sortOptions = null,
  value = null,
  reimburseCategory = null,
  reimburseProject = null,
  styles = {},
  isFilter = false,
  isBig = false,
  isMedium = false,
  isSmall = false,
  isFullWidth = false,
  isSemiFullWidth = false,
  isPayment = false,
  isTrimmed = true,
  isCreatable = false,
  isDisabled = false,
  onChange,
  placeholder = 'Select...',
  hasCustomSeparator = false,
  customSeparator = '',
  className: classNameProp = '',
  allowNone = false,
  dataTestId,
  ...props
}: Omit<ReactSelectProps<Option, IsMulti, Group>, keyof SelectProps> &
  SelectProps &
  SelectComponentProps) => {
  let height = 51;
  if (isSmall) height = 41;
  if (isFilter) height = 41;
  if (isMedium) height = 51;
  if (isBig) height = 60;
  if (isPayment) height = 54;

  let borderColor = '#dfdfdf';
  if (isFilter) borderColor = '#E9EDEE';
  if (props.isBlack) borderColor = '#000000';

  let font = 'inherit';
  if (isFilter) font = "1.6rem 'Source Sans Pro', sans-serif";

  width = width || 170;
  if (isBig) width = 195;
  if (isFullWidth) width = '100%';
  if (isSemiFullWidth) width = 'calc(100% - 5px)';

  if (reimburseCategory) {
    height = 54;
    width = '100%';
  }
  if (reimburseProject) {
    height = 54;
    width = '100%';
  }

  let options = props.options || items?.map((i: OptionType) => mapOption(i, mapLabel || identity));
  if (sortOptions) {
    options.sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));
  }

  if (allowNone) {
    options = [NONE_OPTION, ...options];
  }

  const customStyles: StylesConfig = {
    container: (base) => ({
      ...base,
      width,
      height,
      font,
      color: '#5A5665',
      '@media (min-width: 768px)': {
        width: reimburseCategory ? '23rem' : reimburseProject ? '20rem' : width,
      },
      ...styles.container,
    }),
    control: (base) => {
      return {
        ...base,
        boxShadow: 'none',
        backgroundColor: isDisabled ? 'lightgray' : 'white',
        width,
        height,
        borderColor,
        font,
        borderRadius: '0',
        '&:hover': {
          borderColor,
        },
        '@media (min-width: 768px)': {
          width: reimburseCategory ? '22rem' : reimburseProject ? '20rem' : width,
        },
        ...styles.control,
      };
    },
    option: (base, state) => {
      const { isSelected, isFocused } = state;
      const multipleSelectBackgroundColor = isSelected ? '#ff5964 !important' : 'inherit';

      return {
        ...base,
        cursor: 'pointer',
        backgroundColor: props?.isMulti
          ? multipleSelectBackgroundColor
          : isFocused
          ? 'var(--color-text-tag)'
          : 'transparent',
        borderBottom: '1px solid #e3e5e5',
        padding: '1.5rem 0 1.5rem 1rem',
        color: props?.isMulti
          ? isSelected
            ? 'var(--color-text-tag) !important'
            : isFocused
            ? 'var(--color-text-gray) !important'
            : base.color
          : base.color,
        '&:active': {
          backgroundColor: 'white',
          color: '#ff5964',
        },
        '&:hover': {
          color: '#B2B6BC',
        },
        '&:last-child': {
          borderBottom: '0',
        },
        ...(isCreatable
          ? {
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.5rem 1rem',
            }
          : {}),
        ...styles.option,
      };
    },
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '1.7rem',
      flexWrap: 'nowrap',
      color: '#5A5665',
      ...styles.valueContainer,
    }),
    singleValue: (base) => ({
      ...base,
      color: '#5A5665',
    }),
    menu: (base) => ({
      ...base,
      border: '1px solid rgba(0, 0, 255, .1)',
      borderRadius: '0px 0px 0.4rem 0.4rem',
      margin: '0',
      boxShadow: '0px 0.2rem 0.2rem 0.1rem rgba(0, 0, 255, .05)',
      zIndex: 50,
    }),
  };

  const getSelectedValue = () => {
    if (props.isMulti && value) {
      return options.filter((o: { value: string }) => value.includes(o.value));
    }

    if (!isNil(value)) {
      return (
        options?.find((o: { value: string | number }) => {
          if (typeof o.value === 'object') {
            return JSON.stringify(o.value) === JSON.stringify(value);
          }
          return String(o.value) === String(value);
        }) || ''
      );
    }
  };

  // Container to handle multiple selected values
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps & { children: [string, ReactChildren] }) => {
    const [values, input] = children;
    if (!Array.isArray(values)) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    if (values.length === 1) {
      const { data } = values[0].props;
      return (
        <components.ValueContainer {...props}>
          <div className={selectStyles.Select_value}>{data.label}</div>
          {input}
        </components.ValueContainer>
      );
    }

    return (
      <components.ValueContainer {...props}>
        <div className={selectStyles.Select_value}>{values.length} selected</div>
        {input}
      </components.ValueContainer>
    );
  };

  // This is how it was typed before, but not explicitly. We should take a look at this component and refactor it.
  const SingleValue = (props: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      {props.data.valueLabel || props.data.label}
    </components.SingleValue>
  );

  const finalProps = {
    className: cs(classNameProp, 'react-select-container', {
      [`${selectStyles.SelectParent}`]: props.showScroller,
    }),
    options: sort
      ? options.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label))
      : options,
    styles: customStyles,
    value: getSelectedValue(),
    onChange: (selected: OptionType) => onChange?.(get(selected, 'value', ''), selected),
    isMulti: props.isMulti,
    components: {
      ValueContainer,
      SingleValue,
      ...(hasCustomSeparator && { IndicatorSeparator: () => customSeparator }),
    },
    isSearchable: isCreatable || options?.length > 5,
    isDisabled,
    ...props,
  };

  return isCreatable ? (
    <CreatableSelect {...((finalProps as unknown) as CreatableProps<Option, IsMulti, Group>)} />
  ) : (
    <ReactSelect
      classNamePrefix="react-select"
      {...((finalProps as unknown) as StateManagerProps<Option, IsMulti, Group>)}
      filterOption={createFilter({ trim: isTrimmed })}
      placeholder={placeholder}
      id={dataTestId}
    />
  );
};

Select.defaultProps = {
  showScroller: true,
};

export default Select;
