import { HTMLProps } from 'react';

import classNames from 'classnames';

import styles from './StepMessage.module.scss';

type Props = HTMLProps<HTMLParagraphElement> & {
  isHidden?: boolean;
  isTitle?: boolean;
  isInline?: boolean;
  subTitle?: boolean;
};

const StepMessage = (props: Props) => {
  const { isHidden, isTitle, isInline, subTitle, children, ...restProps } = props;
  const classes = classNames(
    styles.StepMessage,
    isHidden && styles.StepMessage_hidden,
    isTitle && styles.StepMessage_title,
    isInline && styles.StepMessage_inline,
    subTitle && styles.StepMessage_subTitle,
  );

  return (
    <p className={classes} {...restProps}>
      {children}
    </p>
  );
};

export default StepMessage;
