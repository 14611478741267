import { memo, PropsWithChildren, ReactNode } from 'react';

import cs from 'classnames';
import { Card, Paragraph, Heading } from 'design-system';

import { ActiveState } from 'types/types';

import styles from './StepCard.module.scss';

export type StepCardProps = PropsWithChildren<{
  step?: string;
  stepClassName?: string;
  title?: string;
  titleClassName?: string;
  subtitle?: string | ReactNode;
  subtitleClassName?: string;
  description?: string | ReactNode;
  descriptionClassName?: string;
  state: ActiveState;
  sideContent?: ReactNode;
  bottomContent?: ReactNode;
  singleColumn?: boolean;
}>;

export const StepCard = memo((props: StepCardProps) => (
  <Card
    className={cs(
      props.singleColumn ? styles.SingleColumnCard : styles.DoubleColumnCard,
      styles.resetMargin,
    )}
  >
    <div className={cs(styles.Main, styles.resetMargin)}>
      <div className={cs(styles.Container, styles.resetMargin)}>
        {props.step && (
          <Paragraph variant="label" className={cs(styles.Step, styles.Main, props.stepClassName)}>
            {props.step}
          </Paragraph>
        )}
        <Heading
          level="h6"
          className={cs(styles.Title, props.titleClassName, styles.Main, {
            [styles.TitleInactive]: ['inactive', 'pending'].includes(props.state),
          })}
        >
          {props.title || 'Please enter a title'}
        </Heading>
        {props.subtitle && (
          <Heading level="h6" className={cs(styles.Subtitle, styles.Main, props.subtitleClassName)}>
            {props.subtitle}
          </Heading>
        )}
        {props.description && props.state && (
          <Paragraph
            className={(styles.Description, props.descriptionClassName)}
            variant="ui-regular"
          >
            {props.description}
          </Paragraph>
        )}
      </div>
      {props.children}
    </div>
    {props.sideContent && <div className={styles.Sidebar}>{props.sideContent}</div>}
    {props.bottomContent && <div className={styles.Footer}>{props.bottomContent}</div>}
  </Card>
));
