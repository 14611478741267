import { useState } from 'react';

import { Modal, Input, Paragraph, Heading } from 'design-system';

import styles from './AirwallexTokenValidationModal.module.scss';

export function AirwallexTokenValidationModal({
  onCancel,
  onConfirm,
  isFocusOnEnabled,
}: AirwallexTokenValidationModalProps) {
  const [airWallexToken, setAirWallexToken] = useState<string>();
  const handleFormSubmit = () => airWallexToken && onConfirm(airWallexToken);

  return (
    <Modal
      onClose={onCancel}
      isFocusOnEnabled={isFocusOnEnabled}
      onSubmit={handleFormSubmit}
      isButtonDisabled={!airWallexToken}
    >
      <Heading level="h5" className={styles.Heading}>
        Airwallex token validation{' '}
      </Heading>
      <Paragraph className={styles.MainText}>
        {`To proceed with the payments please insert Airwallex API key`}
      </Paragraph>
      <Input
        placeholder="Airwallex API key"
        state="inactive"
        mode="writable"
        layout="fluid"
        name="airWallexToken"
        className={styles.TokenInput}
        value={airWallexToken}
        onChange={(e) => setAirWallexToken(e.target.value)}
        required
        data-testid="name-input"
        onEnterUpEvent={handleFormSubmit}
      />
    </Modal>
  );
}

export type AirwallexTokenValidationModalProps = {
  onCancel: () => void;
  onConfirm: (token: string) => void;
  isFocusOnEnabled?: boolean;
};
