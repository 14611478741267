import { memo } from 'react';

import cs from 'classnames';

import styles from './heading.module.scss';

export type HLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingProps = {
  level: HLevel;
} & JSX.IntrinsicElements[HLevel];

export const Heading = memo<HeadingProps>(({ level: Hx, children, className, ...props }) => {
  const classnames = cs(className, styles.h, {
    [styles.h1]: Hx === 'h1',
    [styles.h2]: Hx === 'h2',
    [styles.h3]: Hx === 'h3',
    [styles.h4]: Hx === 'h4',
    [styles.h5]: Hx === 'h5',
    [styles.h6]: Hx === 'h6',
  });

  return (
    <Hx data-testid="TestId__HEADING" className={classnames} {...props}>
      {children}
    </Hx>
  );
});
