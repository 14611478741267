import { useEffect, useState, cloneElement, ReactElement } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { withApiData } from 'components/Database';
import { Loading } from 'components/Loading';
import { getUserApiActionCreator } from 'store/reduxRestApi';
import { ApiRequestObject, Match, UserApiModel } from 'types/types';

interface UserProviderProps {
  match: Match;
  shouldShowLoadingSpinner: boolean;
  user: ApiRequestObject<{ data: UserApiModel }>;
  sessionUser: UserApiModel;
  children: ReactElement;
}

function UserProvider({
  match,
  user: userProp,
  sessionUser,
  children,
  ...props
}: UserProviderProps) {
  const [user, setUser] = useState<null | UserApiModel>(null);
  const userId = match?.params?.userId;

  useEffect(() => {
    if (!userId && sessionUser) {
      setUser(sessionUser);
    } else if (userProp?.response?.data) {
      setUser(userProp.response.data);
    }
  }, [userId, userProp, sessionUser]);

  if (!user) {
    return <Loading />;
  }

  return cloneElement(children, {
    ...props,
    userId,
    user,
  });
}

export const mapStateToProps = (state: any) => ({
  user: state.getUser,
  sessionUser: state.session.data._user,
});

export const mapDispatchToProps = {
  getUserApiActionCreator,
};

const ConnectedUserProvider = compose<typeof UserProvider>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withApiData([
    (props: any) => {
      const userId = props.match?.params?.userId;
      return userId && props.getUserApiActionCreator({ params: { userId } });
    },
  ]),
)(UserProvider);

export const withUser = (WrappedComponent: React.FC) => (injectedProps: any) => {
  return (
    <ConnectedUserProvider {...injectedProps}>
      <WrappedComponent {...injectedProps} />
    </ConnectedUserProvider>
  );
};
