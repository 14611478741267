import { MouseEvent, useEffect, useState } from 'react';

import { DataList, Divider, Input } from 'design-system';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router';

import { DEBOUNCE_WAIT_INTERVAL } from 'consts/constants';
import { ADMIN } from 'consts/routing';
import { useGetSearch } from 'utils/apiMutationHooks';
import { useDebounce } from 'utils/hooks';

import { SearchInputResults } from './SearchInputResults';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ProjectResult, UserResult } from './types';

import styles from './Search.module.scss';

const SearchIconContainer: React.FC = () => (
  <div className={styles.searchIconContainer}>
    <SearchIcon />
    <Divider className={styles.divider} orientation="vertical" />
  </div>
);

const CloseIconContainer: React.FC<{ onCloseClick: () => void }> = ({ onCloseClick }) => {
  return (
    <div className={styles.closeButtonContainer}>
      <Divider className={styles.divider} orientation="vertical" />
      <button onClick={onCloseClick}>
        <CloseIcon />
      </button>
    </div>
  );
};

export async function fetchSearchData(
  getSearch: ReturnType<typeof useGetSearch>[0],
  searchValue: string,
) {
  const data = await getSearch({ query: { search: searchValue } });

  const userResults =
    data?.users?.map((user) => {
      return {
        id: user.id,
        value: user.displayName,
        avatarUrl: user.avatarUrl,
        isActive: user.isActive,
      };
    }) || [];

  const projectResults =
    data?.projects?.map((project) => {
      return {
        id: project.id,
        value: project.name,
        isActive: project.isActive,
        managerName: project.managerName,
      };
    }) || [];

  return { userResults, projectResults };
}

export function instanceOfUser(user: any): user is UserResult {
  return 'avatarUrl' in user;
}

export const Search: React.FC = () => {
  const history = useHistory();
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, DEBOUNCE_WAIT_INTERVAL);

  const [userResults, setUserResults] = useState<UserResult[]>([]);
  const [projectResults, setProjectResults] = useState<ProjectResult[]>([]);

  const [getSearch, { isLoading: isResultsLoading }] = useGetSearch();

  useEffect(() => {
    const setSearchData = async () => {
      const data = await fetchSearchData(getSearch, debouncedSearchValue);

      setUserResults(data.userResults);
      setProjectResults(data.projectResults);
    };

    if (debouncedSearchValue) {
      setSearchData().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useHotkeys('/', expandSearch);
  useHotkeys('escape', collapseSearch, { enableOnTags: ['INPUT'] });

  return (
    <>
      {!isSearchExpanded && (
        <button onClick={() => expandSearch()} className={styles.searchExpandButton}>
          <SearchIcon />
        </button>
      )}
      {isSearchExpanded && (
        <div className={styles.inputWrapper} data-testid="searchInputContainer">
          <Input
            forceAutoFocus
            forceStopResultInfo
            mode="writable"
            layout="fluid"
            state="inactive"
            placeholder="Search"
            value={searchValue}
            onChange={(ev) => setSearchValue(ev.target.value)}
            leftComponent={<SearchIconContainer />}
            rightComponent={<CloseIconContainer onCloseClick={() => collapseSearch()} />}
            inputTestId="searchInput"
          />
          {searchValue && (
            <SearchInputResults
              userResults={userResults}
              projectResults={projectResults}
              isInputEmpty={debouncedSearchValue === ''}
              isResultsLoading={isResultsLoading}
              onItemSelected={onItemSelected}
            />
          )}
        </div>
      )}
    </>
  );

  function onItemSelected(_event: MouseEvent<HTMLElement> | KeyboardEvent, itemSelected: DataList) {
    const navigatePath = instanceOfUser(itemSelected)
      ? `${ADMIN.USER_PATH}/${itemSelected.id}`
      : `${ADMIN.PROJECT_LIST_PATH}/${itemSelected.id}`;

    history.push(navigatePath);

    setIsSearchExpanded(false);
    setSearchValue('');
  }

  function expandSearch(ev?: KeyboardEvent) {
    setIsSearchExpanded(true);
    ev?.preventDefault();
  }

  function collapseSearch(ev?: KeyboardEvent) {
    setIsSearchExpanded(false);
    setSearchValue('');
    ev?.preventDefault();
  }
};
