import { memo, useCallback, useMemo } from 'react';

import cs from 'classnames';
import { Badge, Card, Paragraph, Image, AvatarGroup, useIsMobile, Icon } from 'design-system';

import bountyFallback from 'design-system/assets/Bounties/bounty1.svg';
import coinGifSvg from 'design-system/assets/Gif/coin.gif';
import { Bounty } from 'store/storeTypes';

import styles from './BountyCard.module.scss';

export type BountyCardProps = {
  className?: string;
  layout?: 'contained' | 'autolayout';
  bounty: Bounty;
  testId?: string;
  showLockButton?: boolean;
  onLock?: () => void;
};

export const BountyCard: React.FunctionComponent<BountyCardProps> = memo(
  ({
    bounty,
    onLock,
    className,
    layout = 'autolayout',
    showLockButton = false,
  }: BountyCardProps) => {
    const isMobile = useIsMobile();
    const unlockedBy = bounty._unlockedBounties
      ? bounty?._unlockedBounties.map((data: any) => ({
          id: data._grantedTo.id.toString(),
          accessibleName: data._grantedTo.displayName,
          imgSrc: data._grantedTo.avatarUrl,
          name: data._grantedTo.displayName,
        }))
      : [];
    const slackChannel = `#${bounty?._slackChannels?.[0]?.name ?? 'misc-bounty'}`;
    const typeBadgeProps = useMemo(() => {
      if (!!bounty?._event) {
        return {
          text: 'Weekly',
          type: 'flash',
        };
      }
      if (!!bounty?._bountyWeeklyDifficulty) {
        return {
          text: 'Weekly',
          type: 'weekly',
        };
      }
      return {};
    }, [bounty?._bountyWeeklyDifficulty, bounty?._event]) as {
      text: string;
      type: 'season-9' | 'season-10' | 'weekly';
    };

    const cardClasses = cs(
      styles.BountyContainer,
      {
        [styles.FlashBountyBorder]: !bounty.isDone && !!bounty._event,
        [styles.WeeklyBountyBorder]: !bounty.isDone && !!bounty._bountyWeeklyDifficulty,
        [styles.Contained]: layout === 'contained',
        [styles.AutoLayout]: layout === 'autolayout',
      },
      className,
    );

    const renderBadges = useCallback(
      (bounty: Bounty) => {
        return (
          <>
            {!!typeBadgeProps.text && (
              <Badge
                id="type-badge"
                interactive={false}
                showIcon={false}
                text={typeBadgeProps.text}
                data-testid={`${typeBadgeProps.text}-badge`}
                type={typeBadgeProps.type}
                showBackground
              />
            )}
            {!bounty?.isDone && (
              <Badge
                iconSrc={coinGifSvg}
                id="coins-badge"
                interactive={false}
                showIcon
                text={`x${bounty?.coinValue}`}
                type="regular"
                showBackground
              />
            )}
            {bounty?.isDone && (
              <Badge
                id="completed-badge"
                interactive={false}
                showIcon={false}
                text={'Completed'}
                type="completed"
                data-testid="Complete-badge"
                showBackground
              />
            )}
          </>
        );
      },
      [typeBadgeProps],
    );

    return (
      <Card className={cardClasses} data-testid="bounty-card">
        {isMobile && (
          <div className={styles.BountyHeader} data-testid="bounty-header-mobile">
            <Image
              alt="icon"
              data-testid="bounty-image-mobile"
              src={bounty?.coverImage?.location ?? bountyFallback}
              variant="squared"
              className={cs(styles.Image, { [styles.Claimed]: bounty?.isDone })}
            />
            <div className={styles.BadgesContainer}>{renderBadges(bounty)}</div>
          </div>
        )}
        <div className={styles.BountyBody}>
          {!isMobile && (
            <Image
              alt="icon"
              data-testid="bounty-image-desktop"
              src={bounty?.coverImage?.location ?? bountyFallback}
              variant="squared"
              className={cs(styles.Image, { [styles.Claimed]: bounty?.isDone })}
            />
          )}
          <div className={styles.InfoContainer}>
            <div className={styles.Header}>
              <Paragraph variant="bold" className={styles.BountyTitle}>
                {bounty?.title}
              </Paragraph>
              {showLockButton && (
                <div
                  data-testid="lock-unlock-btn"
                  onKeyDown={onLock}
                  tabIndex={0}
                  onClick={onLock}
                  role="button"
                  className={styles.LockContainer}
                >
                  <Icon name={bounty.locked ? 'lock' : 'unlock'} />
                </div>
              )}
            </div>
            <Paragraph variant="ui-regular">{bounty?.description}</Paragraph>

            <Paragraph variant="ui-regular" className={styles.SlackChannel}>
              Post in:
              <span
                data-testid="slack-channel"
                className={cs(false ? styles.GreyTag : styles.RedTag)}
              >
                {slackChannel}
              </span>
            </Paragraph>
          </div>
        </div>
        <div className={styles.BountyFooterInfo}>
          {unlockedBy && unlockedBy.length > 0 ? (
            <AvatarGroup
              amountVisible={3}
              data={unlockedBy}
              type="group"
              avatarVariant="grey"
              interactive
            />
          ) : (
            <Paragraph className={styles.Unclaimed} variant="ui-bold">
              Unclaimed
            </Paragraph>
          )}

          <div className={styles.BadgesContainer}>
            {bounty._bountyCategory && (
              <Badge
                id="category-badge"
                interactive={false}
                showIcon={false}
                text={bounty._bountyCategory?.name}
                type="regular"
                showBackground
              />
            )}
            {!isMobile && <div className={styles.DesktopBadges}>{renderBadges(bounty)}</div>}
          </div>
        </div>
      </Card>
    );
  },
);
