import { ToastMessage } from 'types/types';

const ValidationMessages = {
  requiredField: 'This field is required',
  invalidTransferwiseEmail: 'Please enter a valid email',
  invalidField: (fieldName: string) => `Must be a valid ${fieldName}`,
  greaterThanField: (constraint: string) => `Must be greater than ${constraint}`,
  mustBeField: (constraint: string) => `Must be ${constraint}`,
  lessThanField: (constraint: string) => `Must be less than ${constraint}`,
};

const ToastMessages = {
  redirectedWithAlert: {
    text: 'You are missing required Profile fields',
    autoClose: 10000,
  },
  fillContacts: {
    text:
      'Fill out the mandatory fields: contact number, emergency contact name, valid emergency contact email, emergency contact number, date of birth, nationality, and shipping address, name, city and zip code.',
    autoClose: 10000,
  },
  updateUserSuccess: {
    text: 'Profile saved successfully',
    autoClose: 3000,
    type: 'success',
  } as ToastMessage,
  updateUserError: (reason: string) => ({
    text: reason,
  }),
};

export { ValidationMessages, ToastMessages };
