import { forwardRef, HTMLAttributes, memo, ReactNode, useCallback } from 'react';

import cx from 'classnames';
import { castArray, uniqueId } from 'lodash';

import { TableItem } from 'design-system/Atoms';
import { useForwardRef, useClickable } from 'design-system/utils/hooks';

import styles from './TableRow.module.scss';

export interface ChildWrapperProps extends Pick<TableRowProps, 'children' | 'className'> {}

export const childWrapper = ({ children, className = '' }: ChildWrapperProps) =>
  castArray(children).map((child: ReactNode) => {
    const key = uniqueId('table-row-content-');

    return (
      <th className={cx(styles.TableCell, className)} key={key}>
        {child}
      </th>
    );
  });

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  children: ReactNode;
  customCellClassName?: string;
  isSelected?: boolean;
  header?: boolean;
  control?: ReactNode;
}

export const TableRow = memo(
  forwardRef<HTMLTableRowElement, TableRowProps>((props, forwardedRef) => {
    const {
      className,
      customCellClassName,
      isSelected,
      children,
      onClick,
      onKeyDown,
      tabIndex = -1,
      header,
      control,
      ...rest
    } = props;

    const ref = useForwardRef(forwardedRef);

    const { onKeyDown: handleOnKeyDown } = useClickable<HTMLTableRowElement>({
      onKeyDown,
      tabIndex,
    });

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLTableRowElement>) => {
        ref?.current?.focus({ preventScroll: true });
        onClick?.(event);
      },
      [onClick, ref],
    );

    return (
      <tr
        {...rest}
        aria-selected={isSelected}
        data-testid="TestId__TABLEROW"
        ref={ref}
        className={cx(styles.TableRow, className, { [styles.TableRowSelected]: isSelected })}
        onClick={onClick && ((event) => handleClick(event))}
        onKeyDown={handleOnKeyDown}
        tabIndex={tabIndex}
      >
        {header ? childWrapper({ children, className: customCellClassName }) : children}

        {control && (
          <TableItem testId="TestId__CONTROL" className={styles.TableRowSticky}>
            {control}
          </TableItem>
        )}
      </tr>
    );
  }),
);
