import { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react';

// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';

import { ReactComponent as CrossRemove } from 'assets/cross-remove.svg';
import Button from 'components/buttons/Button';

import { ReactComponent as CloudUpIcon } from '../CloudUp.svg';
import { MediaProgress } from '../Feed.types';

import styles from './MediaSelector.module.scss';

const MediaSelector = (props: MediaProps) => {
  const {
    multiple = false,
    mediaFiles = [],
    setMediaFiles,
    progress,
    isDisabled,
    onFileInputChange,
  } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInputEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files !== null ? [...event.target.files] : [];
    return onFileInputChange(selectedFiles);
  };

  // We are not using in browser camera for now. Keeping code.
  // const onTakeCameraPhoto = (dataUri: string) => {
  //   fetch(dataUri)
  //     .then(res => res.blob())
  //     .then(blob => {
  //       if (fileInput.current?.files) {
  //         const id = Math.round(performance.now()).toString(36) + Math.random().toString(36).substr(2, 5);
  //         const file = new File([blob], `Camera-${id}.png`, blob);
  //         fileInput.current.files = FileListItems([...fileInput.current.files, file]);
  //         setMediaFiles(fileInput.current.files);
  //         if (USE_LOCAL_CAMERA) hideCamera();
  //       }
  //     });
  // }

  const onClickRemove = (thumbnail: File) => {
    const files = mediaFiles.filter((file) => file !== thumbnail);
    setMediaFiles(files);
  };

  const getMediaProgress = (thumbnail: File) => {
    const media = progress?.find(({ file }) => file.name === thumbnail.name);
    return media?.progress || 0;
  };

  const getMediaThumbnailByType = (thumbnail: File) => {
    const fileUri = URL.createObjectURL(thumbnail);
    const { type } = thumbnail;

    switch (true) {
      case /^image.*/.test(type):
        return (
          <img
            src={fileUri}
            alt={`Preview of uploaded file ${thumbnail.name}`}
            onLoad={() => URL.revokeObjectURL(fileUri)}
          />
        );
      case /^video.*/.test(type):
        return (
          <video
            src={fileUri}
            className={styles.ThumbnailVideoIcon}
            onLoad={() => URL.revokeObjectURL(fileUri)}
            preload="metadata"
            muted
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.MediaSelectorContainer}>
      <form>
        <input ref={fileInput} multiple={multiple} type="file" onChange={handleFileInputEvent} />
      </form>
      <div className={styles.MediaContainer}>
        <button
          className={styles.UploadButton}
          onClick={() => fileInput.current?.click()}
          disabled={isDisabled}
        >
          <CloudUpIcon />
        </button>
        {mediaFiles
          .filter((n) => n)
          .map((thumbnail: File, index) => (
            <div className={styles.ThumbnailContainer} key={`${thumbnail.name}-${index}`}>
              <div className={styles.Thumbnail}>
                <div
                  style={{ height: `${getMediaProgress(thumbnail)}%` }}
                  className={styles.ThumbnailProgress}
                />
                {getMediaThumbnailByType(thumbnail)}
              </div>
              {!isDisabled && (
                <Button onClick={() => onClickRemove(thumbnail)} className={styles.CloseButton}>
                  <CrossRemove />
                </Button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

interface MediaProps {
  mediaFiles: File[];
  setMediaFiles: Dispatch<SetStateAction<File[]>>;
  onFileInputChange: (files: File[]) => void;
  isDisabled?: boolean;
  multiple?: boolean;
  progress?: MediaProgress[];
}

export default MediaSelector;
