import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './StepFooter.module.scss';

type Props = {
  isHidden?: boolean;
  isHours?: boolean;
  step6?: boolean;
};

const StepFooter = (props: PropsWithChildren<Props>) => {
  const { children, isHidden, isHours, step6 } = props;
  const footerClasses = classNames(
    styles.StepFooter,
    isHidden && styles.StepFooter_hidden,
    isHours && styles.StepFooter_hours,
    step6 && styles.StepFooter_step6,
  );

  return (
    <footer data-testid="stepFooter" className={footerClasses}>
      {children}
    </footer>
  );
};

export default StepFooter;
