import { ChangeEventHandler, SyntheticEvent, KeyboardEvent, PropsWithChildren } from 'react';

import cx from 'classnames';

import { Paragraph } from 'design-system/Atoms/Paragraph';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './Label.module.scss';

type LabelHandler = (
  e: SyntheticEvent<HTMLSpanElement, Event> | undefined,
) => ChangeEventHandler<HTMLSpanElement | undefined> | void;

export type LabelProps = {
  capitalize?: boolean;
  fullWidth?: boolean;
  large?: boolean;
  type: 'common' | 'black' | 'primary' | 'dark' | 'success' | 'featured' | 'error' | 'errorMessage';
  interactive: boolean;
  text?: string;
  showBackground: boolean;
  wrapperClassName?: string;
  paragraphClassName?: string;
  onClick?: LabelHandler;
} & JSX.IntrinsicElements['div'];

const isCommon = (type: string) => type === 'common';
const isBlack = (type: string) => type === 'black';
const isPrimary = (type: string) => type === 'primary';
const isDark = (type: string) => type === 'dark';
const isError = (type: string) => type === 'error';
const isErrorMessage = (type: string) => type === 'errorMessage';
const isFeatured = (type: string) => type === 'featured';
const isSuccess = (type: string) => type === 'success';

export const Label = ({
  capitalize = false,
  fullWidth = false,
  large = false,
  type,
  interactive,
  text,
  showBackground,
  wrapperClassName,
  paragraphClassName,
  onClick,
  ...props
}: PropsWithChildren<LabelProps>) => {
  const paragraphClasses = cx(paragraphClassName, {
    [styles.LabelText]: !isDark(type),
    [styles.LabelDarkText]: isDark(type),
    [styles.LabelErrorText]: isError(type),
    [styles.LabelErrorMessage]: isErrorMessage(type),
    [styles.LabelFeaturedText]: isFeatured(type),
    [styles.LabelSuccessText]: isSuccess(type),
    [styles.LabelCapitalize]: capitalize,
  });

  const wrapperClasses = cx(wrapperClassName, {
    [styles.LabelClickable]: interactive,
    [styles.LabelNoBackground]: !showBackground,
    [styles.LabelCommon]: isCommon(type),
    [styles.LabelBlack]: isBlack(type),
    [styles.LabelError]: isError(type) || isErrorMessage(type),
    [styles.LabelFeatured]: isFeatured(type),
    [styles.LabelPrimary]: isPrimary(type),
    [styles.LabelSuccess]: isSuccess(type),
    [styles.LabelBig]: large,
    [styles.LabelFullWidth]: fullWidth,
  });

  const extraProps = interactive && {
    role: 'button',
    tabIndex: 0,
    onClick: onClick,
    onKeyUp: (e: KeyboardEvent<HTMLSpanElement>) =>
      isKeyboardEvent(e) && onClick ? onClick?.(e) : undefined,
  };

  return (
    <div data-testid="TestId__LABELWRAPPER" className={wrapperClasses} {...extraProps} {...props}>
      <Paragraph
        data-testid="TestId__LABELPARAGRAPH"
        variant="ui-bold"
        className={paragraphClasses}
      >
        {text || props.children}
      </Paragraph>
    </div>
  );
};
