import { useState, useEffect, ReactNode } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'components/Loading';
import {
  addToast,
  setTokenActionCreator,
  userLoggedInActionCreator,
  userLoggedOutActionCreator,
} from 'store/actionCreators';
import {
  getSettingsApiActionCreator,
  getUserDocumentsToSignActionCreator,
} from 'store/reduxRestApi';
import {
  useGetCurrentUserBySession,
  useGetUnleashCategories,
  useGetCurrencies,
  useGetSettings,
  useGetPrintfulCountries,
  useGetJobOpenings,
} from 'utils/apiQueryHooks';
import { initializeFeatureFlags } from 'utils/featureFlags';

const AuthComponent = ({ children }: { children: ReactNode }) => {
  const [isReady, setIsReady] = useState(false);

  const token = useSelector((state) => state.session?.data?.id);
  const dispatch = useDispatch();

  const { refetch } = useGetCurrentUserBySession(true, { enabled: false, suspense: false });

  useEffect(() => {
    const run = async () => {
      try {
        await initializeFeatureFlags();
      } catch (err) {
        dispatch(addToast({ text: 'Could not fetch feature flags, try again in a few minutes.' }));
        throw err;
      }

      try {
        await dispatch(getSettingsApiActionCreator());
      } catch (err) {
        dispatch(
          addToast({ text: 'Could not fetch Settings from the API, try again in a few minutes.' }),
        );
        throw err;
      }

      const token = window.localStorage.getItem('token');

      if (!token) {
        return setIsReady(true);
      }

      // set token from localstorage temporarily for the time of validating
      dispatch(setTokenActionCreator(token));

      const user = await refetch();
      dispatch(userLoggedInActionCreator(user));
    };

    run()
      .catch(() => {
        dispatch(userLoggedOutActionCreator());
      })
      .finally(() => {
        setIsReady(true);
      });
  }, [dispatch, refetch]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (!token) {
      window.localStorage.removeItem('token');
    } else {
      window.localStorage.setItem('token', token);
      dispatch(getUserDocumentsToSignActionCreator());
    }
  }, [token, dispatch, isReady]);

  useEffect(() => {
    if (!isReady || !token) {
      return;
    }

    useGetSettings.prefetch();
    useGetCurrencies.prefetch();
    useGetUnleashCategories.prefetch();
    useGetPrintfulCountries.prefetch();
    useGetJobOpenings.prefetch();
  }, [isReady, token]);

  if (!isReady) {
    return <Loading />;
  }
  return <>{children}</>;
};

export default AuthComponent;
