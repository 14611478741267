import { range } from 'lodash';
import moment, { MomentInput } from 'moment';

export function getYearsBetween(start?: MomentInput, end?: MomentInput) {
  const startDate = moment.utc(start);
  const endDate = moment.utc(end);

  return range(endDate.year(), startDate.year() - 1, -1);
}

export function getPreviousDatesCount(dateInput: MomentInput) {
  return moment.utc(dateInput).date() < 23 ? 5 : 2;
}

export function formatTimeFromDate(date?: Date | string) {
  if (!date) {
    return 'N/A';
  }
  return moment(date).fromNow();
}

export const getCurrentMonthStartEndDates = (): [number, number] => {
  const currDate = moment.utc();
  const startDate = currDate.clone().startOf('month').unix();
  const endDate = currDate.clone().endOf('month').unix();
  return [startDate, endDate];
};
