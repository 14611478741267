import { memo } from 'react';

import { useAdminSetting } from 'utils/hooks';

import styles from './MainImage.module.scss';

const MainImage = () => {
  const settings = useAdminSetting('dashboard-modules')?.values as any;
  const mainImage = settings?.main_image;

  if (!mainImage?.location) return null;

  return (
    <div className={styles.MainImage}>
      <img src={mainImage?.location} alt="" width="100%" />
    </div>
  );
};

export default memo(MainImage);
