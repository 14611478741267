import { isNil } from 'lodash';

import { InvoiceItem, UnleashBudget } from 'types/types';
import { roundMoney } from 'utils/helpers';

const validateBonusError = (item: InvoiceItem, unleashBudget: UnleashBudget) => {
  const { rate, quantity, _unleashCategory } = item;
  const bonuses = unleashBudget.bonuses;
  const categoryBonus = bonuses.find((bonus) => bonus.unleashCategory.id === _unleashCategory?.id);

  if (!categoryBonus) {
    return false;
  }

  const totalUnleashValue = rate * quantity;
  const selectedUnleashLimit = _unleashCategory?.limit;
  const bonusRemaining = categoryBonus.total - Number(categoryBonus?.used);
  const isBonusCategory = categoryBonus?.unleashCategory?.isBonusCategory;

  return !!(isBonusCategory && isNil(selectedUnleashLimit) && totalUnleashValue > bonusRemaining);
};

export const isBonusError = (items: InvoiceItem[], unleashBudget: UnleashBudget) =>
  items?.length
    ? items.some((item) => validateBonusError(item, unleashBudget))
    : validateBonusError((items as unknown) as InvoiceItem, unleashBudget);

export const isInputError = (items: InvoiceItem[]) =>
  items.some((item) => {
    const { _category, _unleashCategory, _project, quantity, usdPrice, name, notes } = item;
    const requiredFieldsMissing = ![_category?.id, _project?.id, quantity, usdPrice].every(Boolean);
    const unleashWithoutCategory = _category?.name === 'Unleash+' && !_unleashCategory?.id;
    const unleashWithoutNotes = _category?.name === 'Unleash+' && !notes;
    const referralWithoutName = _category?.name === 'Referral' && !name;
    const validationArray = [
      requiredFieldsMissing,
      unleashWithoutCategory,
      unleashWithoutNotes,
      referralWithoutName,
    ];
    return validationArray.some((item) => item);
  });

export const isBudgetError = (items: InvoiceItem[], unleashBudget: UnleashBudget) => {
  const unleashItems = items?.filter(
    (item) =>
      item._category?.name === 'Unleash+' &&
      item._unleashCategory &&
      'isBonusCategory' in item._unleashCategory &&
      !item._unleashCategory.isBonusCategory,
  );
  const categoryBonuses = unleashBudget?.bonuses
    ?.filter((bonus) => bonus.total > Number(bonus?.used))
    ?.map((bonus) => ({
      id: bonus.unleashCategory.id,
      available: bonus.total - Number(bonus.used),
    }));
  const availableBonus = categoryBonuses
    ?.filter(({ id }) => unleashItems?.some((item) => item._unleashCategory?.id === id))
    ?.reduce((acc, bonus) => acc + bonus.available, 0);
  const availableUnleashBudget = roundMoney(
    availableBonus + unleashBudget.total - Number(unleashBudget.used),
  );
  const unleashBudgetUsed = unleashItems?.reduce((acc, item) => {
    return acc + ((item?.usdPrice || 0) * 100 || item.rate * 100 || 0) * item.quantity;
  }, 0);
  const planningUnleashValue = !unleashBudgetUsed ? 0 : unleashBudgetUsed / 100;
  return planningUnleashValue > 0 && planningUnleashValue > availableUnleashBudget;
};

export const parseUnleashInvoiceItems = (invoiceItems: InvoiceItem[]) =>
  invoiceItems?.reduce(
    (acc: { [key: string]: { id: number; used: number; limit: number; name: string } }, item) => {
      const categoryId = item._unleashCategory?.id;
      const { _unleashCategory, quantity, rate } = item;
      const isValid = [quantity, rate, _unleashCategory?.limit, _unleashCategory?.name].every(
        Boolean,
      );
      if (categoryId && isValid) {
        acc[categoryId] = {
          id: categoryId,
          limit: item._unleashCategory?.limit || 0,
          name: item._unleashCategory?.name || '',
          used: (acc[categoryId]?.used || 0) + item?.rate * item?.quantity,
        };
      }
      return acc;
    },
    {},
  );
