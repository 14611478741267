import { find } from 'lodash';

export function matchProjectsWithProductiveData(projects, productiveData) {
  const matchingProjects = [];

  productiveData.forEach((project) => {
    const matchingProject = find(projects, {
      productiveId: String(project.projectId),
    });

    if (matchingProject) {
      matchingProject['totalHours'] = project.totalHours;
      matchingProjects.push(matchingProject);
    }
  });

  return matchingProjects;
}

export async function handleProductiveProjectsAssignments(
  assignments,
  projects,
  { onRemove, onUpdate, onCreate },
) {
  const hasNoMatchingProject = !projects.length;

  for (const item of assignments) {
    const { assignment, invoiceItem, taskBreakdownSummedByCategory } = item;
    const isNotValidAssignment =
      !assignment?._project?.canSubmitHoursInvoices ||
      !taskBreakdownSummedByCategory ||
      (assignment?._project.productiveId && hasNoMatchingProject);

    if (isNotValidAssignment) {
      if (invoiceItem && !invoiceItem.isDetachedFromProductive) {
        await onRemove({ invoiceItem });
      }
      continue;
    }

    for (const project of projects) {
      if (!project._assignments.some((x) => x.id === assignment.id)) continue;

      const quantity = parseFloat(project.totalHours).toFixed(2);
      const rate = assignment.rate;

      if (invoiceItem) {
        await onUpdate({ invoiceItem, quantity });
      } else {
        await onCreate({
          rate,
          quantity,
          _project: {
            id: assignment._project.id,
            name: assignment._project.name,
          },
        });
      }
    }
  }
}

export async function handleProductiveProjectsAssignmentsNewInvoice(
  assignments,
  projects,
  { onRemove, onUpdate, onCreate },
) {
  const hasNoMatchingProject = !projects.length;

  for (const item of assignments) {
    const { assignment, invoiceItem, taskBreakdownSummedByCategory } = item;
    let isNotValidAssignment = false;
    isNotValidAssignment =
      !item._project?.canSubmitHoursInvoices ||
      !taskBreakdownSummedByCategory ||
      (assignment?._project.productiveId && hasNoMatchingProject);
    if (isNotValidAssignment) {
      if (invoiceItem) {
        await onRemove({ invoiceItem });
      }
      continue;
    }

    for (const project of projects) {
      if (!assignments?.some((x) => x._projectId === project?.id)) continue;

      const quantity = parseFloat(project.totalHours).toFixed(2);
      const rate = item.rate;
      if (invoiceItem) {
        await onUpdate({ invoiceItem, quantity });
      } else {
        await onCreate({
          rate,
          quantity,
          _project: {
            id: item._project.id,
            name: item._project.name,
            productiveId: item.productiveId,
          },
        });
      }
    }
  }
}
