import { createContext, useContext, useEffect, useState } from 'react';

import './theme/index.module.scss';

export enum Theme {
  light = 'light',
  dark = 'dark',
}

type DesignSystemProviderData = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const DesignSystemContext = createContext({} as DesignSystemProviderData);

interface DesignSystemProviderProps {
  defaultTheme?: Theme;
}

export const DesignSystemProvider: React.FC<DesignSystemProviderProps> = ({
  children,
  defaultTheme,
}) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme || browserThemePreference());

  useEffect(() => {
    setTheme(defaultTheme || browserThemePreference());
  }, [defaultTheme]);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <DesignSystemContext.Provider value={{ theme, setTheme }}>
      {children}
    </DesignSystemContext.Provider>
  );
};

export const useTheme = () => useContext(DesignSystemContext);

export const browserThemePreference = () => (browserPrefersDarkMode() ? Theme.dark : Theme.light);

export const browserPrefersDarkMode = () =>
  window?.matchMedia?.('(prefers-color-scheme: dark)').matches;
