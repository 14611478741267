import { COUNTRIES_OPTIONS } from 'components/inputs/CountrySelect';
import { paymentData, PaymentMethodName } from 'design-system/utils';
import { PaymentType } from 'store/storeTypes';

import { DataList } from './InputResult';

type PaymentDataType = typeof paymentData;

export const getPaymentMethod = (
  paymentMethod?: PaymentMethodName,
): PaymentDataType[PaymentMethodName] | undefined => {
  return paymentData[paymentMethod as PaymentMethodName];
};

export const getPaymentNameById = (
  paymentTypes: PaymentType[] = [],
  id: number,
): string | undefined => paymentTypes.find((paymentType) => paymentType.id === id)?.name;

export const getPaymentMethodsSearchDataList = (paymentTypes: PaymentType[] = []): DataList[] =>
  paymentTypes.map(({ id, name }) => ({
    id: id.toString(),
    value: getPaymentMethod(name)?.label || 'not found',
  }));

export const getCountriesDataList = (): DataList[] =>
  COUNTRIES_OPTIONS.map((country) => ({
    id: country.value,
    value: country.label,
  }));

export const getCountryDataListEntry = (countryValue?: string) =>
  getCountriesDataList().find((country) => country.id === countryValue);
