import { ComponentType } from 'react';

import { brandConfig } from 'brands';
import { BrandConfig } from 'brands/brand-config';
import c from 'classnames';

import CommunityDashboard from 'components/Home/CommunityDashboard';
import LiteDashboard from 'components/Home/LiteDashboard';
import { isFeatureEnabled } from 'utils/featureFlags';

import Submit from './Submit';
import SubmitInvoice from './SubmitInvoice';

import styles from './styles/Home.module.scss';

const components: Record<BrandConfig['startPage'], ComponentType> = {
  communityDashboard: () => <CommunityDashboard />,
  lite: () => <LiteDashboard />,
  blank: () => <div></div>,
  invoice: () => (isFeatureEnabled('newInvoice') ? <SubmitInvoice /> : <Submit />),
};

const Home = () => {
  const Component = components[brandConfig.startPage];
  return (
    <div data-testid="homeContainer" className={c(styles.Root, styles.CommunityBackground)}>
      <Component />
    </div>
  );
};

export default Home;
