import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './Heading.module.scss';

const Heading = ({ Level, className, children }: PropsWithChildren<HeadingProps>) => {
  const selectedStyles = {
    h1: styles.H1,
    h2: styles.H2,
    h3: styles.H3,
    h4: styles.H4,
    h5: styles.H5,
    h6: styles.H6,
  };
  return <Level className={classNames(selectedStyles[Level], className)}>{children}</Level>;
};

export const HeadingPrompt = ({ Level, className, children }: PropsWithChildren<HeadingProps>) => {
  const selectedStyles = {
    h1: styles.H1Prompt,
    h2: styles.H2Prompt,
    h3: styles.H3Prompt,
    h4: styles.H4Prompt,
    h5: styles.H5Prompt,
    h6: styles.H6Prompt,
  };
  return <Level className={classNames(selectedStyles[Level], className)}>{children}</Level>;
};

export default Heading;

export interface HeadingProps {
  Level: keyof Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
  className?: string;
}
