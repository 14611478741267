import { useEffect } from 'react';

import { connect } from 'react-redux';

import { isAuthenticated } from 'components/Auth/authHelpers';
import { updateUserTimezone } from 'store/reduxRestApi';

interface UpdateRequestBody {
  body: { timezone: string };
}

interface TimezoneUpdaterProps {
  isUserAuthenticated: boolean;
  updateUserTimezone: (requestBody: UpdateRequestBody) => unknown;
}

const TimezoneUpdater = ({ isUserAuthenticated, updateUserTimezone }: TimezoneUpdaterProps) => {
  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (isUserAuthenticated && timezone) {
      updateUserTimezone({
        body: {
          timezone,
        },
      });
    }
  }, [isUserAuthenticated, updateUserTimezone]);

  return null;
};

interface State {
  session: any;
}

const mapStateToProps = (state: State) => {
  return {
    isUserAuthenticated: state.session && isAuthenticated(state.session.data),
  };
};

const mapDispatchToProps = {
  updateUserTimezone,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneUpdater);
