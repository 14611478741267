import { StylesConfig, SingleValue, MultiValue } from 'react-select';

import { SimplifiedUser, User } from 'types/types';

export interface SelectOption {
  value: string | number;
  label: string;
  color?: string;
  imageUrl?: string;
}

export type SetSingleOption = SingleValue<SelectOption>;

export type SetMultiOption = MultiValue<SelectOption>;

export type SetSelectOption = SetSingleOption | SetMultiOption;

export const userToOption = (user: User | SimplifiedUser) => ({
  value: user.id,
  label: user.displayName,
  imageUrl: user.avatarUrl,
});

export const getStyles = (isDisabled?: boolean): StylesConfig<SelectOption> => {
  return {
    menuList: (base) => ({
      ...base,
      background: 'var(--grey-100)',
    }),
    container: (base) => ({
      ...base,
      fontSize: '1.4rem',
      fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--grey-500)',
    }),
    control: (base) => ({
      ...base,
      height: '4.0rem',
      minHeight: '4.0rem',
      boxShadow: 'none',
      borderColor: 'var(--grey-300)',
      backgroundColor: isDisabled ? 'var(--grey-100)' : 'var(--white)',
      borderRadius: '0',
      '&:active': {
        borderColor: 'var(--grey-800)',
      },
      '&:hover': {
        borderColor: 'var(--grey-500)',
      },
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? 'var(--grey-200)' : 'var(--grey-100)',
      borderBottom: '0',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '.8rem',
      flexWrap: 'nowrap',
      gap: '0.2rem',
      maxWidth: '40rem',
    }),
    menu: (base) => ({
      ...base,
      border: '.1rem solid black',
      borderRadius: '0 0 .4rem',
      margin: '0',
      boxShadow: '0 .2rem .2rem 0.1rem rgba(0, 0, 255, .05)',
      zIndex: 500,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--grey-800)',
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'var(--grey-100)',
      color: 'var(--grey-800)',
      marginLeft: '0',
    }),
    input: (base) => ({
      ...base,
      color: 'var(--grey-400)',
    }),
  };
};
