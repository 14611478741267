import { HTMLAttributes } from 'react';

import cs from 'classnames';

import { Label, Paragraph } from 'design-system/Atoms';

import styles from './FormLabel.module.scss';

export interface FormLabelProps extends HTMLAttributes<HTMLDivElement> {
  testId?: string;
  title?: string;
  helpText?: string;
  required?: boolean;
  layout?: 'auto' | 'fill';
  className?: string;
  children?: React.ReactNode;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  testId = 'TestId__FORM_LABEL',
  title,
  helpText,
  required = false,
  layout = 'fill',
  className = '',
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      data-testid={testId}
      className={cs(
        styles.FormLabel,
        {
          [styles.FormLabelLayoutAuto]: layout === 'auto',
          [styles.FormLabelLayoutFill]: layout === 'fill',
        },
        className,
      )}
    >
      {title && (
        <div data-testid={`${testId}__TITLE`} className={styles.FormLabelHeader}>
          <Label
            className={styles.FormLabelTitle}
            paragraphClassName={styles.FormLabelTitle}
            type="dark"
            showBackground={false}
            interactive={false}
            text={title}
          />
          {required && <span className={styles.FormLabelRequired}>*</span>}
        </div>
      )}
      {children}
      {helpText && (
        <Paragraph data-testid={`${testId}__HELP_TEXT`} className={styles.FormLabelHelpText}>
          {helpText}
        </Paragraph>
      )}
    </div>
  );
};
