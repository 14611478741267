import classNames from 'classnames';

import ProgressBarStepTitle from '../ProgressBarStepTitle';

import styles from './ProgressBarStep.module.scss';

export type ProgressBarStepProps = {
  active: boolean;
  icon?: string;
  href?: string;
  title: string;
  isFirst?: boolean;
  isLast?: boolean;
};

export default function ProgressBarStep({
  active,
  isFirst,
  isLast,
  icon,
  href,
  title,
}: ProgressBarStepProps) {
  const iconClasses = classNames(styles.ProgressBarStep_Icon, {
    [styles.ProgressBarStep_Icon_Active]: active,
    [styles.ProgressBarStep_Icon_Inactive]: !active,
  });

  const titleClasses = classNames({
    [styles.ProgressBarStep_Title_First]: isFirst,
    [styles.ProgressBarStep_Title_Last]: isLast,
  });

  return (
    <div className={styles.ProgressBarStep}>
      <div className={iconClasses}>
        {icon ? (
          <img className={styles.ProgressBarStep_Icon_Image} src={icon} alt="Icon" />
        ) : undefined}
      </div>
      <ProgressBarStepTitle href={href} title={title} classes={titleClasses} />
    </div>
  );
}
