import { memo, useState, useLayoutEffect, useEffect, useRef } from 'react';

import cs from 'classnames';
import {
  Card,
  DataList,
  Divider,
  Heading,
  Icon,
  Input,
  Label,
  Modal,
  Paragraph,
  Textarea,
  useIsMobile,
} from 'design-system';

import { CurrencyName, InvoiceItem } from 'types/types';
import { formatDate } from 'utils/datesHelper';

import styles from './HourCard.module.scss';

export type ProjectDataList = DataList & { rate: number };

export type HourCardProps = {
  invoiceItem?: Partial<InvoiceItem>;
  onCancel?: () => void;
  onDelete?: (invoiceItem: Partial<InvoiceItem>) => void;
  onSubmit?: (invoiceItem: Partial<InvoiceItem>) => void;
  onChange?: (invoiceItem: Partial<InvoiceItem>) => void;
  canEdit?: boolean;
  currency: CurrencyName;
  isSubmitDisabled?: boolean;
  projectList?: ProjectDataList[];
};

export const HourCard = memo<HourCardProps>(
  ({ canEdit = true, isSubmitDisabled, onChange, ...props }) => {
    const { projectList = [], onCancel, onSubmit, invoiceItem } = props;
    const isNewInvoiceItem = !invoiceItem;
    const [isEditing, setEdit] = useState(isNewInvoiceItem);
    const [project, setProjectName] = useState<ProjectDataList>({
      id: invoiceItem?._project?.id?.toString() || ``,
      value: invoiceItem?._project?.name || ``,
      rate: invoiceItem?.rate || 0,
    });
    const [totalHours, setTotalHours] = useState(invoiceItem?.quantity);
    const [notes, setNotes] = useState(invoiceItem?.notes || ``);
    const isMobile = useIsMobile();
    const isEditable = canEdit && !invoiceItem?._approvedBy?.displayName;
    const isValid = project?.value && totalHours && project.rate;
    const componentDidMount = useRef<boolean>(true);

    useEffect(() => {
      componentDidMount.current = false;
    }, []);

    useLayoutEffect(() => {
      if (!onChange || componentDidMount.current) return;
      onChange({
        ...invoiceItem,
        _project: { name: project?.value, id: Number(project?.id) || 0 },
        name: project?.value,
        addedAt: formatDate(new Date(), 'MMM dd, yyyy hh:mm a'),
        quantity: totalHours || 0,
        usdPrice: project.rate,
        rate: project.rate,
        notes: notes,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, totalHours, notes]);

    const handleEditable = () => {
      setEdit(!isEditing);
    };

    const renderModal = () => {
      return (
        <Modal
          onClose={() => {
            handleEditable();
            onCancel?.();
          }}
          isFocusOnEnabled={true}
          onSubmit={() => {
            onSubmit?.({
              ...invoiceItem,
              _project: { name: project?.value, id: Number(project?.id) || 0 },
              name: project?.value,
              addedAt: new Date().toISOString(),
              quantity: totalHours || 0,
              usdPrice: project.rate,
              rate: project.rate,
              notes: notes,
            });
            handleEditable();
          }}
          isButtonDisabled={!!isSubmitDisabled || !isValid}
        >
          <Heading level="h5" className={styles.Heading} data-testid="TestId__HOURCARD__MODAL">
            {isNewInvoiceItem ? 'Add hours' : 'Edit hours'}
          </Heading>
          <Paragraph className={styles.MainText}></Paragraph>
          <Input
            label="Project name"
            placeholder="Enter project name"
            mode="selectable"
            state={isNewInvoiceItem ? 'focused' : 'disabled'}
            layout="fluid"
            name="projectName"
            showInputResults
            inputSearch={{
              dataList: projectList,
              highlightMatchResult: true,
              inMemoryFilter: true,
              onItemSelected: (_, item) => {
                setProjectName(item as ProjectDataList);
              },
            }}
            value={project?.value}
            inputTestId={`TestId__HOURCARD__PROJECT-${invoiceItem?.id || 0}`}
          />
          <Input
            label="Total hours"
            placeholder="Enter total hours number"
            state="focused"
            mode="writable"
            layout="fluid"
            name="totalHours"
            value={totalHours?.toString()}
            type="number"
            onBlur={(e) => setTotalHours(parseFloat(e.target.value))}
            inputTestId={`TestId__HOURCARD__TOTALHOURS-${invoiceItem?.id || 0 || 0}`}
          />
          <Input
            label="Rate"
            placeholder="Rate"
            mode="writable"
            state="disabled"
            layout="fluid"
            name="rate"
            value={String(project?.rate)}
            type="number"
            inputTestId="rate-input"
          />
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Label type="dark" interactive={false} showBackground={false} text={'Notes'} />

            <Textarea
              handleChange={setNotes}
              value={notes}
              textareaTestId={`TestId__HOURCARD__NOTES-${invoiceItem?.id || 0}`}
            />
          </div>
        </Modal>
      );
    };

    const renderProjectName = () => {
      return (
        <Paragraph
          className={styles.Name}
          variant="bold"
          data-testid="TestId__HOURCARD__PROJECTNAME"
        >
          {invoiceItem?._project?.name}
        </Paragraph>
      );
    };

    const renderAddedAt = () => {
      return (
        invoiceItem?.addedAt && (
          <Paragraph
            data-testid="TestId__HOURCARD__ADDEDAT"
            className={cs(styles.Status, styles.Submitted)}
          >{`Submitted (${invoiceItem?.addedAt})`}</Paragraph>
        )
      );
    };

    const renderApprovedBy = () => {
      return (
        invoiceItem?._approvedBy?.displayName && (
          <Paragraph
            data-testid="TestId__HOURCARD__APPROVEDBY"
            className={cs(styles.Status, styles.Approved)}
          >{`Approved by ${invoiceItem?._approvedBy?.displayName} (${invoiceItem?.approvedAt})`}</Paragraph>
        )
      );
    };

    const renderPaidBy = () => {
      return (
        invoiceItem?._paidBy?.displayName && (
          <Paragraph
            data-testid="TestId__HOURCARD__PAIDBY"
            className={cs(styles.Status, styles.Paid)}
          >{`Paid by ${invoiceItem?._paidBy.displayName} (${invoiceItem?.approvedAt})`}</Paragraph>
        )
      );
    };

    const renderNotes = () => {
      return (
        invoiceItem?.notes && (
          <Paragraph
            className={styles.Description}
            variant="ui-regular"
            data-testid="TestId__HOURCARD__NOTES"
          >
            {invoiceItem?.notes}
          </Paragraph>
        )
      );
    };

    const renderIsEditable = () => {
      return (
        isEditable &&
        !invoiceItem?._project?.productiveId && (
          <div className={styles.ActionsContainer} data-testid="TestId__HOURCARD__EDIT">
            <>
              {isEditing && renderModal()}
              <Icon
                aria-label="edit-hour"
                role="button"
                data-testid={`TestId__HOURCARD__EDITBUTTON-${invoiceItem?.id || 0}`}
                className={styles.Edit}
                name={'edit'}
                onClick={() => handleEditable()}
              />
              <Icon
                aria-label="delete-hour"
                role="button"
                data-testid={`TestId__HOURCARD__DELETEBUTTON-${invoiceItem?.id || 0}`}
                name={'trash-outline'}
                onClick={() => {
                  if (!invoiceItem) return;
                  props.onDelete?.(invoiceItem);
                }}
              />
            </>
          </div>
        )
      );
    };

    const renderRightContainer = () => {
      return (
        <div className={styles.RightContainer}>
          <div
            className={styles.AmountsContainer}
            data-testid={`TestId__HOURCARD__CURRENCY-${props.currency}`}
          >
            <Paragraph
              className={styles.AmountsTotal}
              variant="ui-bold"
              data-testid="TestId__HOURCARD__PRICETOTAL"
            >
              {`${(invoiceItem?.quantity ?? 0) * (invoiceItem?.rate ?? 0)} ${props.currency}`}
            </Paragraph>
            <Paragraph
              className={styles.AmountsDetail}
              variant="ui-regular"
              data-testid="TestId__HOURCARD__PRICEDETAILS"
            >
              {`${invoiceItem?.quantity} x ${invoiceItem?.rate ?? 0} ${props.currency}`}
            </Paragraph>
          </div>
          {renderIsEditable()}
        </div>
      );
    };

    return (
      <Card className={cs(styles.Card, { [styles.notEditable]: !isEditable })}>
        <div className={styles.Container}>
          {renderProjectName()}
          {renderAddedAt()}
          {renderApprovedBy()}
          {renderPaidBy()}
          {renderNotes()}
        </div>
        {isMobile && <Divider className={styles.MobileDivider} />}
        {renderRightContainer()}
      </Card>
    );
  },
);
