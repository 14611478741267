import { Comment } from 'design-system';

import { DATE_FORMAT_MONTH_DAY_LABEL } from 'consts/constants';
import { formatDate } from 'utils/helpers';

import { Comment as CommentType } from '../Feed.types';

const FeedComment: React.FC<CommentType> = ({ comment: { user, data, created_at } }) => {
  return (
    <>
      <Comment
        userName={user?.data?.name}
        userAvatarSrc={data.publisherAvatar}
        teamIconLocation={data.teamIconLocation}
        teamName={data.teamName}
        date={formatDate(created_at, DATE_FORMAT_MONTH_DAY_LABEL)}
        text={data.text}
      />
    </>
  );
};

export default FeedComment;
