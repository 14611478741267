import { Fragment, PropsWithChildren, MouseEvent } from 'react';

import ArrowDownIcon from 'assets/arrow-down.svg';
import ArrowUpIcon from 'assets/arrow-up.svg';
import Label from 'components/Label';
import Row from 'components/table/Row';

import styles from './ChangeHistory.module.scss';

export interface ChangeHistoryProps<ChangeType> {
  changes: ChangeType[];
  changeDescription: (change: ChangeType) => string;
  expanded: boolean;
  showExpandButton: boolean;
  onExpandClick?: (event: MouseEvent<HTMLElement>) => void;
}

const ChangeHistory = <ChangeType,>({
  changes,
  expanded,
  changeDescription,
  onExpandClick,
  showExpandButton,
}: PropsWithChildren<ChangeHistoryProps<ChangeType>>) => {
  const [lastChange, ...mostRecentUpdates] = changes;
  if (!lastChange) {
    return null;
  }

  return (
    <Fragment>
      <Row className={styles.LastEditRow} isHorizontal isPaymentDisplay dataTestId="">
        <Label className={styles.LabelDate} isPaymentDisplay data-testid="TestId__LABEL__LastEdit">
          {`Last edit: ${changeDescription(lastChange)}`}
          {showExpandButton && changes.length > 1 && (
            <button
              className={styles.ExpandButton}
              onClick={onExpandClick}
              data-testid="expand-change-history"
            >
              <img
                className={styles.CollapseImage}
                src={expanded ? ArrowUpIcon : ArrowDownIcon}
                alt="Expand Edit History"
              />
            </button>
          )}
        </Label>
      </Row>
      {expanded &&
        mostRecentUpdates.map((update, index) =>
          update ? (
            <Row
              key={index}
              className={styles.LastEditRow}
              isHorizontal
              isPaymentDisplay
              dataTestId="change-history-row"
            >
              <Label className={styles.LabelDate} isPaymentDisplay>
                {changeDescription(update)}
              </Label>
            </Row>
          ) : null,
        )}
    </Fragment>
  );
};

export default ChangeHistory;
