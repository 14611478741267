import { FormEvent, useCallback, useEffect, useState } from 'react';

import { Button, Heading, Input, InputProps, Modal } from 'design-system';
import { some } from 'lodash';

import styles from './AddCompanyModal.module.scss';

interface Company {
  name: string;
  address: string;
  suffix: string;
  logoUrl: string;
}

export interface AddCompanyModalProps {
  className?: string;
  isOpen?: boolean;
  onCreateCompanyHandle?: () => void;
  modalOpenHandle?: (value?: boolean) => void;
}

const commonInputProps: InputProps = {
  required: true,
  className: styles.FormInput,
  paragraphClassName: styles.InputLabel,
  requiredLabelClassName: styles.InputRequiredLabel,
  layout: 'fluid',
  mode: 'writable',
  state: 'inactive',
};

export const AddCompanyModal = ({
  className,
  isOpen,
  onCreateCompanyHandle,
  modalOpenHandle,
}: AddCompanyModalProps) => {
  const [isValidForm, setIsValidForm] = useState(false);

  const [company, setCompany] = useState<Company>({
    name: '',
    address: '',
    suffix: '',
    logoUrl: '',
  });

  const handleFormChange = useCallback((field: string, value?: string) => {
    setCompany((prevValue) => ({
      ...prevValue,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    const isInvalid = some([!company.name, !company.address, !company.suffix, !company.logoUrl]);
    setIsValidForm(!isInvalid);
  }, [company]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onCreateCompanyHandle?.();
  };

  return (
    <div data-testid="AddCompanyModal">
      {isOpen && (
        <Modal
          onClose={() => {
            modalOpenHandle?.(false);
          }}
          modalClassName={styles.NewCompanyModal}
          contentClassName={className}
        >
          <form onSubmit={handleSubmit}>
            <Heading level="h4" className={styles.NewCompanyModalHeader}>
              Add new company
            </Heading>
            <div className={styles.FormItem}>
              <Input
                {...commonInputProps}
                inputTestId="NewCompanyInputName"
                label="Company name"
                placeholder="Name of the company"
                value={company.name}
                onChange={(e) => handleFormChange('name', e.target.value)}
              />
            </div>
            <div className={styles.FormItem}>
              <Input
                {...commonInputProps}
                inputTestId="NewCompanyInputAddress"
                label="Address"
                placeholder="Company's address"
                value={company.address}
                onChange={(e) => handleFormChange('address', e.target.value)}
              />
            </div>
            <div className={styles.FormItem}>
              <Input
                {...commonInputProps}
                inputTestId="NewCompanyInputSuffix"
                label="Suffix"
                value={company.suffix}
                onChange={(e) => handleFormChange('suffix', e.target.value)}
                placeholder="Company's suffix"
              />
            </div>
            <div className={styles.FormItem}>
              <Input
                {...commonInputProps}
                inputTestId="NewCompanyInputLogoUrl"
                label="Logo URL"
                value={company.logoUrl}
                onChange={(e) => handleFormChange('logoUrl', e.target.value)}
                placeholder="http://example.com/logo.png"
              />
            </div>
            <div className={styles.NewCompanyModalButtonWrapper}>
              <Button
                as="button"
                type="submit"
                layout="fluid"
                variant="primary"
                data-testid="NewCompanyCreateButton"
                disabled={!isValidForm}
              >
                Create
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};
