import { memo, PropsWithChildren, SyntheticEvent } from 'react';

import { FocusOn } from 'react-focus-on';

import { Button } from 'design-system/Atoms';
import { Heading } from 'design-system/Atoms/Heading';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './PromptPopup.module.scss';

export interface PromptPopupProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function stopEventPropagation(e: SyntheticEvent) {
  e.stopPropagation();
}

export const PromptPopup = memo<PropsWithChildren<PromptPopupProps>>(({ onCancel, onConfirm }) => {
  return (
    <div
      className={styles.Overlay}
      onClick={stopEventPropagation}
      role="button"
      tabIndex={0}
      data-testid="TestId__PROMPTPOPUP__OVERLAY"
      onKeyUp={stopEventPropagation}
    >
      <FocusOn onEscapeKey={onCancel}>
        <Button
          as="button"
          iconName="close-big"
          className={styles.CloseButton}
          iconPosition="start"
          layout="autolayout"
          variant="icon-tertiary"
          onClick={onCancel}
          aria-label="Close popover"
        />
        <div className={styles.Wrapper}>
          <Heading data-testid="TestId__PROMPTPOPUP__TITLE" className={styles.Title} level="h5">
            Are you sure?
          </Heading>
          <Paragraph
            data-testid="TestId__PROMPTPOPUP__WARNING1"
            className={styles.SubTitle}
            variant="regular"
          >
            You will lose your progress.
          </Paragraph>
          <Paragraph
            data-testid="TestId__PROMPTPOPUP__WARNING2"
            className={styles.Warning}
            variant="bold"
          >
            Warning this cannot be undone.
          </Paragraph>

          <div className={styles.ButtonContainer}>
            <Button as="button" layout="fluid" variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              as="button"
              layout="fluid"
              variant="primary"
              onClick={onConfirm}
              data-testid="TestId__PROMPTPOPUP__CONFIRM_BTN"
            >
              Confirm
            </Button>
          </div>
        </div>
      </FocusOn>
    </div>
  );
});
