import { CSSProperties } from 'react';

import cx from 'classnames';

import styles from './Dot.module.scss';

export interface DotProps {
  className?: string;
  color?: string;
  style?: CSSProperties;
}

export const Dot = ({ className, color, style }: DotProps) => {
  const classes = cx(styles.DotBase, className);
  const dotStyle = !!color ? { ...style, backgroundColor: `var(--${color})` } : style;

  return <span data-testid="TestId__Dot" className={classes} style={dotStyle} />;
};
