import { memo, useState } from 'react';

import { brandConfig } from 'brands';
import { Link } from 'react-router-dom';

import styles from './Logo.module.scss';

type LogoProps = {
  closeMenu?: () => void;
  darkMode: boolean;
};

const Logo = ({ closeMenu, darkMode }: LogoProps) => {
  const [state, setState] = useState({
    s3Error: false,
  });
  const {
    logo: LogoIcon,
    logoDarkMode: LogoIconDark,
    useLogoFromFeatureFlag,
    getLogoFeatureFlag,
  } = brandConfig;
  const logoFeatureFlag = getLogoFeatureFlag?.();

  const getLocalLogo = () =>
    darkMode ? (
      <LogoIconDark
        data-testid="logo-icon-dark"
        title={brandConfig.brandName}
        className={styles.Logo}
      />
    ) : (
      <LogoIcon data-testid="logo-icon" title={brandConfig.brandName} className={styles.Logo} />
    );

  const getLogoFromS3 = () => (
    <img
      alt={brandConfig.brandName}
      data-testid="react-svg-icon"
      src={`${process.env.REACT_APP_S3_BUCKET}${logoFeatureFlag}${darkMode ? '-white' : ''}.svg`}
      className={styles.Logo}
      onError={(error: any) => {
        console.log('error:', error);
        setState({ s3Error: true });
      }}
    />
  );

  const icon =
    !state.s3Error && useLogoFromFeatureFlag && logoFeatureFlag ? getLogoFromS3() : getLocalLogo();

  return (
    <Link to="/" onClick={closeMenu} tabIndex={0} role="link">
      <div style={brandConfig.logoSize}>{icon}</div>
    </Link>
  );
};

export default memo(Logo);
