import { MouseEvent, useState } from 'react';

import cx from 'classnames';
import { DataList, Divider } from 'design-system';
import Avatar from 'react-avatar';
import { useHotkeys } from 'react-hotkeys-hook';

import { ProjectResult, UserResult } from '../types';

import styles from './SearchInputResults.module.scss';

export const SearchInputResults: React.FC<{
  userResults?: UserResult[];
  projectResults?: ProjectResult[];
  isInputEmpty?: boolean;
  isResultsLoading?: boolean;
  onItemSelected?: (event: MouseEvent<HTMLElement> | KeyboardEvent, itemSelected: DataList) => void;
}> = ({ userResults, projectResults, onItemSelected, isInputEmpty, isResultsLoading }) => {
  const hasUserResults = !!userResults?.length;
  const hasProjectResults = !!projectResults?.length;
  const hasNoResults = !(hasUserResults || hasProjectResults);
  const [currentHighligted, setCurrentHighligthed] = useState(-1);

  const emptyResultsMessage = isInputEmpty
    ? 'Search for users or projects.'
    : isResultsLoading
    ? 'Searching...'
    : 'No results found.';

  const handleKeyDown = (ev: KeyboardEvent) => {
    const key = ev.code;
    let nextHighlighted = currentHighligted;
    const total = (userResults?.length || 0) + (projectResults?.length || 0);
    switch (key) {
      case 'ArrowDown':
        nextHighlighted = Math.min(nextHighlighted + 1, total);
        break;
      case 'ArrowUp':
        nextHighlighted = Math.max(0, nextHighlighted - 1);
        break;
      case 'Enter':
        if (nextHighlighted > (userResults?.length || 0)) {
          if (projectResults?.length && onItemSelected) {
            onItemSelected(ev, projectResults[nextHighlighted - (userResults?.length || 0)]);
          }
        } else if (userResults?.length && onItemSelected) {
          onItemSelected(ev, userResults[nextHighlighted]);
        }
        break;
    }
    setCurrentHighligthed(nextHighlighted);
  };

  useHotkeys('*', handleKeyDown, {
    filter: () => true,
    enableOnTags: ['INPUT'],
    keyup: false,
    keydown: true,
  });

  return (
    <div className={styles.wrapper} data-testid="searchInputResults">
      {hasNoResults && (
        <div className={styles.listHeader}>
          <span>{emptyResultsMessage}</span>
        </div>
      )}
      {hasUserResults && (
        <>
          <div className={styles.listHeader}>
            <span>Users</span>
            <Divider />
          </div>

          {userResults?.map((userResult, i) => (
            <button
              className={cx(
                styles.listItem,
                i === currentHighligted ? styles.listItemSelected : null,
              )}
              key={`searchUserResult-${userResult.id}`}
              onClick={onItemSelected && ((ev) => onItemSelected(ev, userResult))}
              data-testid="userResult"
            >
              <Avatar
                round
                src={userResult.avatarUrl}
                name={userResult.value}
                maxInitials={2}
                size="32px"
              />
              <span data-testid={`TestId__SEARCHINPUTRESULTS_USERITEM_${userResult.id}`}>
                {userResult.value}
              </span>
              <div className={cx(userResult.isActive ? styles.active : styles.inactive)}></div>
            </button>
          ))}
        </>
      )}

      {hasProjectResults && (
        <>
          <div className={cx(styles.listHeader, styles.projectsListHeader)}>
            <span>Projects</span>
            <Divider />
          </div>

          {projectResults?.map((projectResult, i) => (
            <button
              className={cx(
                styles.listItem,
                styles.projectListItem,
                i + (userResults?.length || 0) === currentHighligted
                  ? styles.listItemSelected
                  : null,
              )}
              key={`searchProjectResult-${projectResult.id}`}
              onClick={onItemSelected && ((ev) => onItemSelected(ev, projectResult))}
              data-testid="projectResult"
            >
              <span data-testid={`TestId__SEARCHINPUTRESULTS_PROJECTITEM_${projectResult.id}`}>
                {projectResult.value}
              </span>
              <div className={cx(projectResult.isActive ? styles.active : styles.inactive)}></div>
              <span className={styles.projectManager}>{projectResult.managerName}</span>
            </button>
          ))}
        </>
      )}
    </div>
  );
};
