import { PropsWithChildren, SyntheticEvent } from 'react';

import classNames from 'classnames';

import styles from './FluidButton.module.scss';

const typeClassName = {
  primary: styles.FluidButtonPrimary,
  secondary: styles.FluidButtonSecondary,
  tertiary: styles.FluidButtonTertiary,
} as const;

const FluidButton = ({
  level = 'primary',
  type,
  disabled,
  active,
  className,
  onClick,
  children,
  variant,
  ...props
}: PropsWithChildren<FluidButtonProps>) => {
  const variantStyles: Record<Exclude<typeof variant, undefined>, string[]> = {
    icon: [styles.FluidButtonIconVariant],
  };

  const buttonStyles = classNames(
    styles.FluidButton,
    typeClassName[level],
    active && styles.FluidButtonActive,
    styles.ButtonDisabled,
    !!variant ? variantStyles[variant] : '',
    className,
  );

  return (
    <button onClick={onClick} className={buttonStyles} disabled={disabled} type={type} {...props}>
      {children}
    </button>
  );
};

export default FluidButton;

export interface FluidButtonProps {
  level?: keyof typeof typeClassName;
  type?: JSX.IntrinsicElements['button']['type'];
  disabled?: boolean;
  active?: boolean;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  variant?: 'icon';
}
