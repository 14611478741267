import cx from 'classnames';

import styles from './Panel.module.scss';

export type PanelProps = {
  variant?: 'spaced' | 'small';
} & JSX.IntrinsicElements['div'];

export const Panel: React.FC<PanelProps> = ({ className, ...props }) => (
  <div
    data-testid="TestId__PANEL"
    className={cx(styles.Panel, className, { [styles.Spaced]: props.variant === 'spaced' })}
    {...props}
  >
    {props.children}
  </div>
);
