import { Children, memo, ReactElement, ReactNode } from 'react';

import cs from 'classnames';
import invariant from 'invariant';
import { upperFirst } from 'lodash';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Grid.module.scss';

interface TagChildrenProps {
  tag?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  debug?: boolean;
}

export type ColumnSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface FlexProps {
  xs?: ColumnSize;
  sm?: ColumnSize;
  md?: ColumnSize;
  lg?: ColumnSize;
  xl?: ColumnSize;
  xxl?: ColumnSize;
  pushXs?: ColumnSize;
  pushSm?: ColumnSize;
  pushMd?: ColumnSize;
  pushLg?: ColumnSize;
  pushXl?: ColumnSize;
  pushXxl?: ColumnSize;
}

export interface GridProps extends TagChildrenProps {
  classNames?: string;
}
export const Grid = memo<GridProps>(({ tag: Tag = 'div', children, debug, classNames }) => {
  return <Tag className={cs(styles.grid, classNames, { [styles.debug]: debug })}>{children}</Tag>;
});

export interface ColumnProps extends TagChildrenProps, FlexProps {}
export const Column = memo<ColumnProps>(
  ({
    tag: Tag = 'div',
    children,
    debug,
    xs = 1,
    sm = xs,
    md = sm,
    lg = md,
    xl = lg,
    xxl = xl,
    pushXs = 0,
    pushSm = pushXs,
    pushMd = pushSm,
    pushLg = pushMd,
    pushXl = pushLg,
    pushXxl = pushXl,
  }) => {
    return (
      <Tag
        className={cs(
          styles.column,
          {
            [styles.debug]: debug,
          },
          [
            styles[`xs--${xs}`],
            styles[`sm--${sm}`],
            styles[`md--${md}`],
            styles[`lg--${lg}`],
            styles[`xl--${xl}`],
            styles[`xxl--${xxl}`],
          ],
          [
            styles[`push-xs--${pushXs}`],
            styles[`push-sm--${pushSm}`],
            styles[`push-md--${pushMd}`],
            styles[`push-lg--${pushLg}`],
            styles[`push-xl--${pushXl}`],
            styles[`push-xxl--${pushXxl}`],
          ],
        )}
      >
        {children}
      </Tag>
    );
  },
);

export interface RowProps extends TagChildrenProps {}
export const Row = memo<RowProps>(({ tag: Tag = 'div', children, debug }) => {
  if (debug) {
    const sums = Children.toArray(children).reduce<{
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
      xxl: number;
    }>(
      (acc, el) => {
        invariant(typeof el === 'object' && el, 'Row can only contain Column children');
        const p = (el as ReactElement<ColumnProps>).props;
        acc['xs'] += (p.xs || 0) + (p.pushXs || 0);
        acc['sm'] += (p.sm || 0) + (p.pushSm || 0);
        acc['md'] += (p.md || 0) + (p.pushMd || 0);
        acc['lg'] += (p.lg || 0) + (p.pushLg || 0);
        acc['xl'] += (p.xl || 0) + (p.pushXl || 0);
        acc['xxl'] += (p.xxl || 0) + (p.pushXxl || 0);
        return acc;
      },
      { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
    );
    Object.entries(sums).forEach(([key, val]) => {
      invariant(
        val <= 12,
        `Sum of Column ${key} and push${upperFirst(key)} in a single Row cannot be larger than 12`,
      );
    });
  }
  return <Tag className={cs(styles.row, { [styles.debug]: debug })}>{children}</Tag>;
});
