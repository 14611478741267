import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Row.module.scss';

type RowProps = {
  children: ReactNode;
  isSection?: boolean;
  isUnleash?: boolean;
  isSmallSection?: boolean;
  isPaymentDisplay?: boolean;
  withLeftPadding?: boolean;
  isFlexCentered?: boolean;
  isVertical?: boolean;
  isHorizontal?: boolean;
  hasNote?: boolean;
  fullWidth?: boolean;
  className?: string | Record<string, any>;
  dataTestId?: string;
};

function Row({
  children,
  isSection,
  isUnleash,
  isSmallSection,
  isPaymentDisplay,
  withLeftPadding,
  isFlexCentered,
  isVertical,
  isHorizontal,
  hasNote,
  fullWidth,
  className,
  dataTestId,
}: RowProps) {
  const RowClasses = classNames(
    styles.Row,
    isSection && styles.Row_section,
    withLeftPadding && styles.Row_withLeftPadding,
    isSmallSection && styles.Row_small_section,
    isFlexCentered && styles.Row_flexCentered,
    isPaymentDisplay && styles.Row_paymentDisplay,
    isUnleash && styles.Row_unleash,
    isVertical && styles.Row_vertical,
    isHorizontal && styles.Row_horizontal,
    hasNote && styles.Row_hasNote,
    fullWidth && styles.Row_fullwidth,
    className,
  );

  return (
    <div data-testid={dataTestId} className={RowClasses}>
      {children}
    </div>
  );
}

export default Row;
