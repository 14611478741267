import { useState } from 'react';

import FluidButton from 'components/buttons/FluidButton';
import Input from 'components/inputs/Input';
import Modal from 'components/modals/ModalFullScreen';

import styles from './OverTimeConfirmModal.module.scss';

const OverTimeConfirmModal = ({ name, onCancel, onConfirm }: Props) => {
  const [approverName, setApproverName] = useState<string>(name);

  return (
    <Modal onClose={onCancel} modalClassName={styles.Modal} show>
      <div>
        <h4 className={styles.H4}>The limit of 8h per day has been exceeded! </h4>

        <p className={styles.MainText}>
          I acknowledge that the total amount of hours exceeds the limit. This has been authorized
          by
        </p>
        <Input
          fullwidth
          placeholder="Enter approver name"
          className={styles.ApproverInput}
          value={approverName}
          onChange={(e) => setApproverName(e.target.value)}
        />
      </div>

      <div className={styles.ButtonContainer}>
        <FluidButton level="secondary" type="button" onClick={onCancel}>
          Cancel
        </FluidButton>

        <FluidButton
          disabled={!approverName}
          onClick={() => onConfirm(approverName)}
          data-testid="approver-name-confirm"
        >
          Confirm
        </FluidButton>
      </div>
    </Modal>
  );
};

type Props = {
  name: string;
  onCancel: () => void;
  onConfirm: (name: string) => void;
};

export default OverTimeConfirmModal;
