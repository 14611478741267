import { memo, ReactNode } from 'react';

import classNames from 'classnames';
import { Heading, Icon, Tooltip } from 'design-system';
import { Carousel } from 'react-responsive-carousel';

import { SlideArrowLeft, SlideArrowRight } from 'components/feed/SlideArrows';
import { ImageOptions, VaultItemModel } from 'types/types';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './ViewOnlyVaultItem.module.scss';

export type ViewOnlyVaultItemProps = {
  isHome?: boolean;
  className?: string;
  item: VaultItemModel;
  onClick?: () => void;
  bottomContent?: ReactNode;
  imageOptions?: ImageOptions;
};

const ViewOnlyVaultItem = memo((props: ViewOnlyVaultItemProps) => {
  const {
    isHome,
    className,
    bottomContent = null,
    item: {
      images = [],
      itemName,
      stock,
      _rarity: { name: rarityName = '' } = {},
      _whenStockIsZero,
    },
    onClick,
  } = props;
  const viewOnlyVaultItemClass = classNames(styles.ViewOnlyVaultItem, className, {
    [styles.ViewOnlyVaultItemHome]: isHome,
    [styles.ViewOnlyVaultItemRare]: rarityName === 'Rare',
    [styles.ViewOnlyVaultItemLegendary]: rarityName === 'Legendary',
    [styles.ViewOnlyVaultItemCommon]: rarityName === 'Common',
  });

  const viewOnlyItemImageClass = classNames(styles.ImageCarousel, {
    [styles.ViewOnlyVaultItemNoStock]: stock === 0 && _whenStockIsZero.id !== 3,
  });

  return (
    <div className={viewOnlyVaultItemClass}>
      {images.length > 0 && (
        <Carousel
          renderThumbs={() => []}
          renderArrowNext={SlideArrowRight}
          renderArrowPrev={SlideArrowLeft}
          showStatus={false}
          renderIndicator={() => null}
          swipeScrollTolerance={80}
          preventMovementUntilSwipeScrollTolerance
        >
          {images.map((file, index) => (
            <div
              key={index}
              className={viewOnlyItemImageClass}
              role="button"
              tabIndex={0}
              onClick={onClick}
              onKeyUp={(e) => onClick !== undefined && isKeyboardEvent(e) && onClick()}
              data-testid={`${itemName} ${index}`}
              style={{
                background: `url(${file?.location}) no-repeat center`,
              }}
            />
          ))}
        </Carousel>
      )}

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={props.onClick}
        onKeyUp={(e) => props.onClick !== undefined && isKeyboardEvent(e) && props.onClick()}
        data-testid="bottom-content"
      >
        <div className={styles.TitleBox}>
          <div className={styles.TitleContent}>
            {rarityName ? (
              <Tooltip text={rarityName} placement="top">
                <Icon
                  name={rarityName.toLowerCase()}
                  className={styles.RarityIcon}
                  data-testid="rarity-icon"
                />
              </Tooltip>
            ) : null}
            <Heading className={styles.Title} level="h5">
              {props.item.itemName}
            </Heading>
          </div>
          {bottomContent}
        </div>
      </div>
    </div>
  );
});

export default ViewOnlyVaultItem;
