import { CURRENCIES, DEFAULT_UNLEASH_BUDGET } from 'consts/constants';
import { formatCurrency } from 'utils/helpers';

import { Definitions, Get, GetArrayItem } from '../types/types';

export function getUnleashLeftValueFormatted(
  unleashBudget?: Partial<{ used: number; total: number; currency: string }>,
  returnString = false,
) {
  const unleashBudgetUsed = unleashBudget?.used || 0;
  const unleashBudgetTotal = unleashBudget?.total || DEFAULT_UNLEASH_BUDGET;
  const unleashBudgetCurrency = unleashBudget?.currency || CURRENCIES.USD;
  const remainingBudgetValue = unleashBudgetTotal - unleashBudgetUsed;

  return formatCurrency(remainingBudgetValue, unleashBudgetCurrency, returnString);
}

export function getInvoiceCurrency(
  invoiceItem: GetArrayItem<Definitions, ['getUsersUserIdInvoicesUnleashResponse', 'data']>,
  unleashBudgetCurrency: Get<
    Definitions,
    ['getUsersUserIdUnleashBudgetResponse', 'data', 'currency']
  >,
) {
  const invoiceValue = formatCurrency(
    invoiceItem.usdPrice ?? 0 * invoiceItem.quantity ?? 0,
    unleashBudgetCurrency,
    true,
  );
  if (invoiceItem._invoice?._currency?.name === CURRENCIES.USD || !invoiceItem.rate) {
    return invoiceValue;
  }
  const localValue = formatCurrency(
    invoiceItem.rate * invoiceItem.quantity,
    invoiceItem._invoice?._currency?.name,
    true,
  );
  return `${invoiceValue} (${localValue})`;
}
