import { DefaultRootState } from 'react-redux';
import { Store } from 'redux';

import createXhqStore from './createStore';

const { getCurrentStore, mockCurrentStore } = (() => {
  let store: Store<DefaultRootState>;

  const getCurrentStore = () => {
    if (!store) {
      store = createXhqStore({});
    }

    return store;
  };

  const mockCurrentStore = (value: any) => {
    if (value) {
      store = createXhqStore(value);
    }

    return store;
  };

  return { getCurrentStore, mockCurrentStore };
})();

export default getCurrentStore;
export { mockCurrentStore };
