function SvgLegacy(props) {
  return (
    <svg viewBox="0 0 32 32" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M30 27.9H2V28.9H30V27.9Z" fill="#5A5665" />
      <path d="M28.7001 24.4H3.30005V25.4H28.7001V24.4Z" fill="#5A5665" />
      <path
        d="M26.1 20.9V13.5H25.1V20.9H19.7V13.5H18.7V20.9H13.3V13.5H12.3V20.9H6.79998V13.5H5.79998V20.9H4.59998V21.9H27.4V20.9H26.1Z"
        fill="#5A5665"
      />
      <path
        d="M29.5 11.9H2.49995L2.19995 11L15.7 2.30005H16.2L29.7999 11L29.5 11.9ZM4.19995 10.9H27.7999L16 3.20005L4.19995 10.9Z"
        fill="#5A5665"
      />
      <path
        d="M16 10.2C14.6 10.2 13.5 9.09995 13.5 7.69995C13.5 6.29995 14.6 5.19995 16 5.19995C17.4 5.19995 18.5 6.29995 18.5 7.69995C18.5 9.09995 17.4 10.2 16 10.2ZM16 6.19995C15.2 6.19995 14.5 6.89995 14.5 7.69995C14.5 8.49995 15.2 9.19995 16 9.19995C16.8 9.19995 17.5 8.49995 17.5 7.69995C17.5 6.89995 16.8 6.19995 16 6.19995Z"
        fill="#FF5964"
      />
    </svg>
  );
}

export default SvgLegacy;
