import { useEffect, PropsWithChildren, useMemo, useState } from 'react';

import { addMinutes, format } from 'date-fns';

import { FNS_RFC_3339 } from 'consts/constants';
import {
  AuthTokenResponse,
  GoogleApiContext,
  GoogleApiContextData,
  GoogleTokenClient,
} from 'contexts/GoogleApiContext';
import useLoaded from 'hooks/useLoaded';

const GSI_SRC = 'https://accounts.google.com/gsi/client';
const GAPI_SRC = 'https://apis.google.com/js/api.js';

const GSI_SCOPES = 'https://www.googleapis.com/auth/calendar';
const GAPI_DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

interface GoogleApiProviderProps {
  clientId: string;
}

interface CreateGoogleCalendarEventParams {
  title: string;
  startTime: Date;
  durationInMin: number;
  membersEmails: string[];
  eventDescription: string;
}

function loadScript(src: string, onLoad?: () => void) {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = onLoad ?? null;
  document.body.appendChild(script);
}

function GoogleApiProvider({ clientId, children }: PropsWithChildren<GoogleApiProviderProps>) {
  const [gsiLoaded, assertLoadedGsi] = useLoaded();
  const [gapiLoaded, assertLoadedGapi] = useLoaded();
  const [tokenClient, setTokenClient] = useState<GoogleTokenClient | undefined>(undefined);
  const [tokenResponse, setTokenResponse] = useState<AuthTokenResponse | undefined>(undefined);

  useEffect(() => {
    function initTokenClient() {
      if (!gsiLoaded) {
        setTokenClient(
          window.google?.accounts.oauth2.initTokenClient({
            client_id: clientId,
            scope: GSI_SCOPES,
            callback: (response) => {
              setTokenResponse(response);
            },
          }),
        );
        assertLoadedGsi();
      }
    }

    if (typeof window.google === 'undefined') {
      loadScript(GSI_SRC, initTokenClient);
    } else {
      initTokenClient();
    }

    function loadGapi() {
      if (!gapiLoaded) {
        window.gapi.load('client', async () => {
          if (typeof window.gapi.client !== 'undefined') {
            await window.gapi.client.init({
              discoveryDocs: GAPI_DISCOVERY_DOCS,
            });
            assertLoadedGapi();
          }
        });
      }
    }

    if (typeof window.gapi === 'undefined') {
      loadScript(GAPI_SRC, loadGapi);
    } else {
      loadGapi();
    }
  }, [gsiLoaded, gapiLoaded, clientId, assertLoadedGsi, assertLoadedGapi, setTokenResponse]);

  const value: Partial<GoogleApiContextData> = useMemo(
    () => ({
      flags: {
        gapiLoaded,
        gsiLoaded,
      },
    }),
    [gapiLoaded, gsiLoaded],
  );

  function checkAuth() {
    tokenClient?.requestAccessToken();
  }

  async function createGoogleCalendarEvent(
    {
      title,
      startTime,
      durationInMin,
      membersEmails,
      eventDescription,
    }: CreateGoogleCalendarEventParams,
    token: AuthTokenResponse | undefined = tokenResponse,
  ): Promise<string | undefined> {
    if (token) {
      if (window.gapi?.client?.getToken() === null) {
        window.gapi?.client?.setToken(token);
      }

      return gapi.client.calendar.events
        .insert({
          calendarId: 'primary',
          conferenceDataVersion: 1,
          resource: {
            start: {
              dateTime: format(startTime, FNS_RFC_3339),
            },
            end: {
              dateTime: format(addMinutes(startTime, durationInMin), FNS_RFC_3339),
            },
            summary: title,
            description: eventDescription,
            attendees: membersEmails.map((memberEmail) => ({
              email: memberEmail,
            })),
            conferenceData: {
              createRequest: {
                conferenceSolutionKey: { type: 'hangoutsMeet' },
              },
            },
          },
        })
        .then((val) => {
          let eventUrl = val.result.htmlLink;
          if (token.authuser) {
            eventUrl = eventUrl?.replace(
              '/calendar/event',
              `/calendar/u/${(token as any).authuser}/r/eventedit`,
            );
          } else {
            eventUrl = eventUrl?.replace('/calendar/event', `/calendar/r/eventedit`);
          }
          return eventUrl?.replace('?eid=', '/');
        });
    }
  }

  function isAuthenticated(): boolean {
    return window.gapi?.client?.getToken() !== null;
  }

  return (
    <GoogleApiContext.Provider
      value={
        {
          ...value,
          checkAuth,
          createGoogleCalendarEvent,
          isAuthenticated,
          setTokenResponse,
        } as GoogleApiContextData
      }
    >
      {children}
    </GoogleApiContext.Provider>
  );
}

export type { GoogleApiProviderProps, AuthTokenResponse };

export default GoogleApiProvider;
