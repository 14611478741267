import cx from 'classnames';
import { Paragraph } from 'design-system';

import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './Message.module.scss';

export type MessageProps = {
  type: 'SUCCESS' | 'ERROR';
  glow?: boolean;
  onClose?: null | (() => void);
  className?: string;
};

export const messageDefaultProps = {
  type: 'SUCCESS' as MessageProps['type'],
  glow: false,
};

export const Message: React.FC<MessageProps> = ({
  children,
  onClose,
  className,
  type = messageDefaultProps.type,
  glow = messageDefaultProps.glow,
}) => {
  const classNames = cx(styles.Message, className, {
    [styles.Success]: type === 'SUCCESS',
    [styles.Error]: type === 'ERROR',
    [styles.Glow]: glow,
  });

  return (
    <Paragraph className={classNames} variant="ui-regular">
      {onClose && (
        <span
          role="button"
          aria-roledescription="Close"
          aria-label="Close"
          data-testid={'close-message-button'}
          className={styles.MessageCloseButton}
          onClick={onClose}
          tabIndex={0}
          onKeyUp={(e) => isKeyboardEvent(e) && onClose()}
        />
      )}
      {children}
    </Paragraph>
  );
};
