import { useState } from 'react';

import classnames from 'classnames';
import { Label } from 'design-system';
import { Moment } from 'moment';
import 'react-dates/initialize';
import { DateRangePicker as ReactDateRangePicker, FocusedInputShape } from 'react-dates';
import {
  HORIZONTAL_ORIENTATION,
  ICON_AFTER_POSITION,
  START_DATE,
  VERTICAL_ORIENTATION,
} from 'react-dates/constants';

import 'react-dates/lib/css/_datepicker.css';

import { DATE_RANGE_PICKER_NUMBER_OF_MONTHS, DISPLAY_DATE_FORMAT } from 'consts/constants';
import { Icon } from 'design-system/Atoms/Icon';
import { useIsMobile } from 'design-system/utils';

import MobileDatePickerActions from './MobileDatePickerActions';

import styles from './DateRangePicker.module.scss';

export type MomentDateRange = {
  startDate: Moment;
  endDate: Moment;
  error?: any;
};

export type NullableMomentDateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
  error?: any;
};

type FocusedInput = FocusedInputShape | null;

export type OnDatesChange = (dateRange?: Partial<NullableMomentDateRange>) => void;

export type DateRangePickerProps = {
  className?: string;
  labelClassName?: string;
  disabled: boolean;
  endDate: Moment | null;
  endDateId: string;
  fullWidth: boolean;
  isDayBlocked?: (day: Moment) => boolean;
  isDayHighlighted?: (day: Moment) => boolean;
  label?: string;
  onClose?: OnDatesChange;
  onDatesChange?: OnDatesChange;
  onError?: (error: string) => void;
  required: boolean;
  startDate: Moment | null;
  startDateId: string;
  isOutsideRange?: (day: Moment) => boolean;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  labelClassName,
  disabled = false,
  endDate,
  endDateId,
  fullWidth = false,
  isDayBlocked = undefined,
  isDayHighlighted = undefined,
  label = 'Select dates',
  onDatesChange = undefined,
  onClose = undefined,
  onError = undefined,
  required = false,
  isOutsideRange = undefined,
  startDate,
  startDateId,
}) => {
  const isMobile = useIsMobile();
  const calendarOrientation = isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const [hasError, setHasError] = useState(false);
  const [innerStartDate, setInnerStartDate] = useState(startDate);
  const [innerEndDate, setInnerEndDate] = useState(endDate);

  const classNames = classnames([
    className,
    styles.XhqDateRangePicker,
    { [styles.XhqDateRangePicker__disabled]: disabled },
    { [styles.XhqDateRangePicker__error]: hasError },
    { [styles.XhqDateRangePicker__fullWidth]: fullWidth },
    { [styles.XhqDateRangePicker__pristine]: !innerStartDate && !innerEndDate },
    { [styles.XhqDateRangePicker__selected]: focusedInput !== null },
  ]);
  const labelClassNames = classnames([
    styles.XhqDateRangePicker__label,
    { [styles.XhqDateRangePicker__label_error]: hasError },
    labelClassName,
  ]);
  const leftNavigationClassNames = classnames([
    styles.XhqDateRangePicker__calendar_left_navigation,
    { [styles.XhqDateRangePicker__calendar_left_navigation_mobile]: isMobile },
  ]);
  const rightNavigationClassNames = classnames([
    styles.XhqDateRangePicker__calendar_right_navigation,
    { [styles.XhqDateRangePicker__calendar_right_navigation_mobile]: isMobile },
  ]);

  const isModalVisible = isMobile && !!focusedInput;

  const handleDatesChange = (dateRange: Partial<NullableMomentDateRange>) => {
    setInnerStartDate(dateRange.startDate ?? null);
    setInnerEndDate(dateRange.endDate ?? null);

    if (onDatesChange) {
      onDatesChange(dateRange);
    }
  };

  const handleOnClose = (dateRange: Partial<NullableMomentDateRange>) => {
    if (required) {
      validate(dateRange);
    }

    onClose?.();

    if (isMobile) return;

    setFocusedInput(null);
  };

  const handleFocusChange = (focusedInput: FocusedInput) => {
    setFocusedInput(focusedInput ? focusedInput : START_DATE);
  };

  const validate: OnDatesChange = ({ startDate = null, endDate = null } = {}) => {
    let errorMessage: string | undefined;
    if (!startDate) {
      errorMessage = "Invalid 'startDate'. Please insert a valid date range";
    }
    if (!endDate) {
      errorMessage = "Invalid 'endDate'. Please insert a valid date range";
    }

    setHasError(!!errorMessage);

    if (!!errorMessage && onError) {
      if (onError) {
        onError(errorMessage);
      }
    }

    if (onClose) {
      return onClose({
        startDate: startDate,
        endDate: endDate,
      });
    }
  };

  return (
    <div data-testid="TestId__DATERANGEPICKER__WRAPPER" className={classNames}>
      {label && (
        <div>
          <Label
            type="dark"
            interactive={false}
            showBackground={false}
            paragraphClassName={labelClassNames}
          >
            {label}
            {required && <span className={styles.Required}>*</span>}
          </Label>
        </div>
      )}
      <ReactDateRangePicker
        customCloseIcon={<Icon name="close-big" />}
        customInputIcon={
          !(!!innerEndDate || !!innerStartDate) && <Icon name="calendar-grey-full" />
        }
        daySize={40}
        disableScroll={isMobile}
        disabled={disabled}
        displayFormat={DISPLAY_DATE_FORMAT}
        endDate={innerEndDate}
        endDateId={endDateId}
        focusedInput={focusedInput}
        inputIconPosition={!(innerEndDate || innerStartDate) ? ICON_AFTER_POSITION : null}
        isDayBlocked={isDayBlocked}
        isDayHighlighted={isDayHighlighted}
        keepOpenOnDateSelect={isMobile}
        minimumNights={0}
        navNext={
          <div className={rightNavigationClassNames}>
            <Icon name="thin-long-right" />
          </div>
        }
        navPrev={
          <div className={leftNavigationClassNames}>
            <Icon name="thin-long-left" />
          </div>
        }
        numberOfMonths={DATE_RANGE_PICKER_NUMBER_OF_MONTHS}
        onClose={handleOnClose}
        onDatesChange={handleDatesChange}
        onFocusChange={handleFocusChange}
        orientation={calendarOrientation}
        showClearDates={!!innerEndDate || !!innerStartDate}
        startDate={innerStartDate}
        startDateId={startDateId}
        withFullScreenPortal={isMobile}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
      />
      {isModalVisible && (
        <MobileDatePickerActions
          innerStartDate={innerStartDate}
          innerEndDate={innerEndDate}
          onDateRangeChange={handleDatesChange}
          onFocusedInputChange={setFocusedInput}
        />
      )}
    </div>
  );
};
