import { memo, MouseEventHandler, SyntheticEvent } from 'react';

import cx from 'classnames';

import { Avatar } from 'design-system/Atoms/Avatar';
import { Paragraph } from 'design-system/Atoms/Paragraph';
import { fromNow } from 'utils/datesHelper';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './NotificationItem.module.scss';

type ActionClickHandler = (
  e: SyntheticEvent<HTMLDivElement> | undefined,
) => MouseEventHandler<HTMLDivElement | undefined> | void;

export type NotificationItemProps = {
  imgSrc?: string;
  name: string;
  action: string;
  date: Date;
  unread?: boolean;
  onClick?: ActionClickHandler;
};

export const NotificationItem = memo<NotificationItemProps>(({ date, ...props }) => {
  const dateClasses = cx(styles.Date, {
    [styles.Unread]: props.unread,
  });

  return (
    <div
      data-testid="TestId__NOTIFICATIONITEM__WRAPPER"
      className={styles.Wrapper}
      onClick={props.onClick}
      onKeyUp={(e) => isKeyboardEvent(e) && props.onClick?.(e)}
      tabIndex={0}
      role="button"
    >
      <div>
        <div className={styles.AvatarWrapper}>
          <Avatar
            interactive={false}
            accessibleName={props.name}
            imgSrc={props.imgSrc}
            name={!props.imgSrc ? props.name : undefined}
          />
        </div>
      </div>

      <div className={styles.ContentWrapper}>
        <div className={styles.ActionDescription}>
          <Paragraph variant="ui-regular">
            <b className={styles.Name}>{props.name}</b>
            {props.action}
          </Paragraph>
        </div>

        <Paragraph
          data-testid="TestId__NOTIFICATIONITEM__DISTANCE"
          className={dateClasses}
          variant={props.unread ? 'ui-bold' : 'ui-regular'}
        >
          {fromNow(date.toString())}
        </Paragraph>
      </div>

      <div>
        <div className={styles.UnreadWrapper}>
          <span
            data-testid="TestId__NOTIFICATIONITEM__SPANINDICATOR"
            className={props.unread ? styles.UnreadIndicator : styles.ReadIndicator}
          />
        </div>
      </div>
    </div>
  );
});
