import {
  ChangeEvent,
  Children,
  cloneElement,
  DetailedHTMLProps,
  DragEvent,
  HTMLAttributes,
  isValidElement,
  memo,
  useRef,
  useState,
} from 'react';

import { Icon, Paragraph, Toast } from 'design-system';

import { castBytesToKb } from 'utils/helpers';

import { SUPPORTED_MEDIA_TYPES } from '../mediaTypes';

import styles from './Droppable.module.scss';

type DraggableCallbackEventHandler = (e: DragEvent<HTMLDivElement>) => void | undefined;
type FileInputCallbackEventHandler = (e: ChangeEvent<HTMLInputElement>) => void | undefined;

export type DroppableProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  prefixInstructions?: string;
  allowedFileTypes?: string[];
  maxAllowedSizeKb?: number;
  onFileInputChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  onDragEnter?: (e: DragEvent<HTMLDivElement>) => void | undefined;
  onDragEnterFail?: (e: DragEvent<HTMLDivElement>) => void | undefined;
  onDragLeave?: (e: DragEvent<HTMLDivElement>) => void | undefined;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void | undefined;
  onDrop?: (e: DragEvent<HTMLDivElement>) => void | undefined;
  onDropFail?: (e: DragEvent<HTMLDivElement>) => void | undefined;
};

const getInstructionsMessage = (
  prefixInstructions: string,
  allowedFileTypes: string[],
  maxAllowedSizeKb: number,
) => {
  const pronoumn = allowedFileTypes.length === 1 ? 'is' : 'are';
  const fileTypesWithFormat = allowedFileTypes.map((allowedFileType) => `*.${allowedFileType}`);
  const fileTypesReadable = fileTypesWithFormat.join(', ').replace(/, ([^,]*)$/, ' or $1');
  const fileTypesMessage = `${pronoumn} ${fileTypesReadable}`;
  const finalInstructions = `${prefixInstructions} ${maxAllowedSizeKb} kb and format allowed ${fileTypesMessage}.`;
  return finalInstructions;
};

export const Droppable = memo<DroppableProps>(
  ({
    maxAllowedSizeKb = 500,
    allowedFileTypes = ['jpg', 'pdf'],
    prefixInstructions = getInstructionsMessage(
      'Choose a file or drag and drop it here. Max file size is',
      allowedFileTypes,
      maxAllowedSizeKb,
    ),
    ...props
  }) => {
    const [isShown, setIsShown] = useState(false);
    const handleShowToast = () => setIsShown(true);
    const handleCloseToast = () => setIsShown(false);

    const wrapper = useRef<HTMLDivElement>(null);

    const addHoverLayer = () => undefined;

    const addHoverLayerError = () => wrapper.current?.classList.add(styles.WrapperInactive);

    const removeHoverLayers = () => wrapper.current?.classList.remove(styles.WrapperInactive);

    const validateSupportedFileType = (
      e: DragEvent<HTMLDivElement>,
      onSuccess?: DraggableCallbackEventHandler,
      onFail?: DraggableCallbackEventHandler,
    ) => {
      const filesTypes = Object.values(e.dataTransfer.items).map((file) => file.type);
      const supportedTypes = allowedFileTypes.map((allowed) => SUPPORTED_MEDIA_TYPES[allowed]);
      const isValid = filesTypes.every((type) => supportedTypes.includes(type));

      if (isValid) {
        onSuccess?.(e);
      }

      if (!isValid) {
        removeHoverLayers();
        addHoverLayerError();
        onFail?.(e);
      }
    };

    const validateSupportedFileSize = (
      e: ChangeEvent<HTMLInputElement>,
      onSuccess?: FileInputCallbackEventHandler,
      onFail?: FileInputCallbackEventHandler,
    ) => {
      const files = Array.from(e.target.files ?? []);
      const filesSizes = Object.values(files).map((file) => castBytesToKb(file.size));
      const isValid = filesSizes.every((size) => size <= maxAllowedSizeKb);

      if (isValid) {
        onSuccess?.(e);
      }

      if (!isValid) {
        handleShowToast();
        onFail?.(e);
      }
    };

    const validateDropSupportedFileSize = (
      e: DragEvent<HTMLDivElement>,
      onSuccess?: DraggableCallbackEventHandler,
      onFail?: DraggableCallbackEventHandler,
    ) => {
      const filesSizes = Object.values(e.dataTransfer.files).map((file) =>
        castBytesToKb(file.size),
      );
      const isValid = filesSizes.every((size) => size <= maxAllowedSizeKb);

      if (isValid) {
        onSuccess?.(e);
      }

      if (!isValid) {
        handleShowToast();
        onFail?.(e);
      }
    };

    const handleOnFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      validateSupportedFileSize(e, props.onFileInputChange);
    };

    const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      removeHoverLayers();
      validateDropSupportedFileSize(e, props.onDrop, props.onDropFail);
    };

    const handleOnDragOver = (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      props.onDragOver?.(e);
    };

    const handleOnDragEnter = (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      addHoverLayer();
      validateSupportedFileType(e, props.onDragEnter, props.onDragEnterFail);
    };

    const handleOnDragLeave = (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      removeHoverLayers();
      props.onDragLeave?.(e);
    };

    const DroppableArea = () => (
      <div data-testid="TestId__DROPPABLE__AREA" className={styles.Content}>
        <input
          className={styles.FileInput}
          multiple
          onChange={handleOnFileInputChange}
          type="file"
        />
        <Icon name="cloud-up" className={styles.Icon} />
        <Icon name="close-big" className={styles.IconError} />
        <Paragraph variant="ui-regular" className={styles.Instructions}>
          {prefixInstructions}
        </Paragraph>
        <Paragraph variant="ui-regular" className={styles.InstructionsError}>
          {getInstructionsMessage(
            'File(s) not supported. Maximum size is',
            allowedFileTypes,
            maxAllowedSizeKb,
          )}
        </Paragraph>
      </div>
    );

    const childrenWithDroppable = Children.map(props.children, (child) => {
      if (isValidElement(child)) {
        const noBorderWidth = `calc(${child.props?.style?.width} - -2px)`;
        return cloneElement(
          <div style={{ width: noBorderWidth }} />,
          {
            'data-testid': 'TestId__DROPPABLE',
            ref: wrapper,
            id: 'iuhiuhiuhiuhiuhiuhiuh',
            className: styles.Wrapper,
            onDragEnter: handleOnDragEnter,
            onDragLeave: handleOnDragLeave,
            onDragOver: handleOnDragOver,
            onDrop: handleOnDrop,
          },
          <>
            {child}
            <DroppableArea />
          </>,
        );
      }

      return child;
    });

    return (
      <>
        <Toast
          shouldShow={isShown}
          onClose={handleCloseToast}
          type={'ERROR'}
          message={getInstructionsMessage(
            'File(s) not supported. Maximum size is',
            allowedFileTypes,
            maxAllowedSizeKb,
          )}
        />
        {childrenWithDroppable}
      </>
    );
  },
);
