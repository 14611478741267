import { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';

import { get, isEmpty, isNil, set, groupBy, isEqual } from 'lodash';

import Label from 'components/Label';
import Row from 'components/Row';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import { UploadInvoiceItem } from 'components/inputs/InputFile';
import Select from 'components/inputs/Select';
import Textarea from 'components/inputs/Textarea';
import UnleashCategorySelect from 'components/inputs/UnleashCategorySelect';
import UserSelect from 'components/inputs/UserSelect';
import { DEFAULT_REFERRAL_REIMBURSEMENT_RATE, EXPENSE_CATEGORY } from 'consts/constants';
import { parseNumber } from 'helpers/parseHelpers';
import {
  Assignment,
  Invoice,
  InvoiceItem,
  UnleashCategory,
  ExpenseCategory,
  ExpenseCategorySelect,
  User,
} from 'types/types';
import { useGetUserUnleashInvoiceItemsOfCurrentMonthByCategoryId } from 'utils/apiQueryHooks';
import {
  numberBoundaries,
  roundMoney,
  verifyIfHasExceededUnleashCategory,
  verifyIfInvoiceHasZeroHours,
} from 'utils/helpers';

import { isNewProjectExpensesEnabled } from '../../../utils/netsuiteIntegration';
import OverUnleashConfirmModal from '../OverUnleashConfirmModal/OverUnleashConfirmModal';
import ZeroUnleashConfirmModal from '../ZeroUnleashConfirmModal/ZeroUnleashConfirmModal';

import styles from './Reimbursement.module.scss';

export type ReimbursementProps = {
  assignments: Assignment[];
  availableUnleashBudget: number;
  exceededCategory?: UnleashCategory | null;
  isReferralItem: (catergoryId?: number) => boolean;
  isUnleashPlusItem: (categoryId?: number) => boolean;
  onExceedConfirm: (confirmed: boolean) => void;
  onZeroHourUnleashConfirm: (confirmed: boolean) => void;
  reimbursement: InvoiceItem;
  removeInvoiceItem: (id: number) => void;
  updateInvoiceItem: (id: number, newInvoice: Partial<InvoiceItem>) => void;
  user: User;
  invoice: Invoice;
  isBonusError: (item: InvoiceItem) => boolean;
  isValidBonusCategory: (unleashCategory: UnleashCategory) => boolean;
  setUnleashBudgetError: (error: boolean) => void;
  exceededMonthlyLimit?: boolean;
  onLoadingChanges: (loading: boolean) => void;
  onNextButtonClick?: { count: number; name: string };
  setReimbursements: Dispatch<SetStateAction<InvoiceItem[]>>;
};

export enum DATA_TEST_ID {
  SECTION = 'invoiceReimbursementSection',
  PROJECT = 'reimburseProject',
  TYPE = 'reimburseType',
  SELECT = 'reimbursementSelect',
  UNIT_PRICE = 'unitPrice',
  QUANTITY = 'reimburseQuantity',
  NOTES = 'notes',
  CLOSE_BUTTON = 'closeReimburse',
  OVER_UNLEASH_MODAL = 'overUnleashModal',
  ZERO_UNLEASH_MODAL = 'zeroUnleashModal',
}

const Reimbursement = ({
  assignments,
  availableUnleashBudget,
  exceededCategory,
  isReferralItem,
  isUnleashPlusItem,
  onExceedConfirm,
  onZeroHourUnleashConfirm,
  reimbursement,
  removeInvoiceItem,
  updateInvoiceItem,
  isBonusError,
  isValidBonusCategory,
  user,
  invoice,
  setUnleashBudgetError,
  onLoadingChanges,
  onNextButtonClick,
  setReimbursements,
}: ReimbursementProps) => {
  const isNewExpensesEnabled = isNewProjectExpensesEnabled();
  const getSelectedAssignment = (assignments: Assignment[], reimbursement: InvoiceItem) => {
    const { _project: project } = reimbursement;
    const selected = project && assignments.find((a) => a._projectId === project.id);

    return selected || assignments[0];
  };

  const getDefaultCategory = useCallback(
    (assignment?: Assignment) => {
      const allowedCategories =
        (isNewExpensesEnabled
          ? assignment?._project?._allowedExpenses?.filter(
              (expense) => !expense.name.includes('Hours'),
            )
          : assignment?._project?._allowedReimbursements) || [];

      return allowedCategories[0]?.id;
    },
    [isNewExpensesEnabled],
  );

  const getSelectedCategory = useCallback(
    (assignment: Assignment | undefined, reimbursement: InvoiceItem) => {
      const { _category: category, _expenseCategory: expenseCategory } = reimbursement;
      const categories = isNewExpensesEnabled
        ? assignment?._project?._allowedExpenses
        : assignment?._project?._allowedReimbursements || [];
      const selected =
        (category || expenseCategory) &&
        categories?.find(
          (c) => c.id === (isNewExpensesEnabled ? expenseCategory?.id : category?.id),
        );

      return selected?.id || getDefaultCategory(assignment);
    },
    [getDefaultCategory, isNewExpensesEnabled],
  );

  const getSelectedUnleashCategory = (reimbursement: InvoiceItem): number | null => {
    return reimbursement._unleashCategory?.id || null;
  };

  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | undefined>(
    getSelectedAssignment(assignments, reimbursement),
  );
  const [selectedCategory, setSelectedCategory] = useState(
    getSelectedCategory(selectedAssignment, reimbursement),
  );
  const [isReferral, setIsReferral] = useState(isReferralItem(selectedCategory));
  const [isUnleashPlus, setIsUnleashPlus] = useState(isUnleashPlusItem(selectedCategory));
  const [selectedUnleashCategory, setSelectedUnleashCategory] = useState(
    getSelectedUnleashCategory(reimbursement),
  );
  const [overUnleashLimit, setOverUnleashLimit] = useState(false);
  const [zeroHoursUnleash, setZeroHoursUnleash] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const rate = isReferral ? DEFAULT_REFERRAL_REIMBURSEMENT_RATE : reimbursement.rate;
  const [tmpFields, setTmpFields] = useState<InvoiceItem>({ ...reimbursement, rate });
  const [notesStyle, setNotesStyle] = useState({
    border: selectedCategory === 8 && !tmpFields.notes ? '1px solid red' : '',
  });
  const [totalValueStyle, setTotalValueStyle] = useState({
    border: isBonusError(tmpFields) ? '1px solid red' : '',
  });

  const [isZeroUnleashModalDisplayed, setIsZeroUnleashModalDisplayed] = useState(false);

  const isUnleashReimbursementType = isUnleashPlusItem(
    isNewExpensesEnabled ? tmpFields._expenseCategory?.id : tmpFields._category?.id,
  );
  const currentMonthUnleashSelectedCategoryInvoiceItems = useGetUserUnleashInvoiceItemsOfCurrentMonthByCategoryId(
    tmpFields?._unleashCategory?.id,
  );

  const openUnleashLimitConfirmationModal = useMemo(
    () =>
      overUnleashLimit ||
      (!!exceededCategory && exceededCategory?.id === tmpFields._unleashCategory?.id),
    [overUnleashLimit, exceededCategory, tmpFields._unleashCategory?.id],
  );

  const verifyUnleashBudget = useCallback(() => {
    // Submitted invoice items on current draft invoice
    const categoryId = tmpFields?._unleashCategory?.id;

    const invoiceItems = invoice?._invoiceItems?.filter((item) => item.id !== tmpFields.id);
    const draftUnleashCategoryItems =
      categoryId && invoiceItems ? groupBy(invoiceItems, '_unleashCategory.id')[categoryId] : [];

    const hasExceededUnleashCategory = verifyIfHasExceededUnleashCategory(
      currentMonthUnleashSelectedCategoryInvoiceItems,
      tmpFields,
      draftUnleashCategoryItems,
    );

    const hasUnleashBudgetError =
      isUnleashReimbursementType &&
      hasExceededUnleashCategory &&
      !tmpFields?.overTimeApprover?.length;
    setUnleashBudgetError(hasUnleashBudgetError);
    if (!overUnleashLimit && hasUnleashBudgetError) {
      setOverUnleashLimit(true);
    } else {
      onExceedConfirm(false);
    }
  }, [
    currentMonthUnleashSelectedCategoryInvoiceItems,
    isUnleashReimbursementType,
    setUnleashBudgetError,
    invoice?._invoiceItems,
    tmpFields,
    overUnleashLimit,
    onExceedConfirm,
  ]);

  const verifyInvoice = useCallback(() => {
    const hasZeroInvoiceUnleash = verifyIfInvoiceHasZeroHours(invoice);

    if (
      !zeroHoursUnleash &&
      hasZeroInvoiceUnleash &&
      !user.allow0Rate &&
      !isZeroUnleashModalDisplayed
    ) {
      setZeroHoursUnleash(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUnleashReimbursementType,
    setUnleashBudgetError,
    invoice,
    zeroHoursUnleash,
    user.allow0Rate,
  ]);

  const saveChange = useCallback(
    async (fieldName: string, useCurrentValue = false, currentValue?: any) => {
      const value = useCurrentValue ? currentValue : get(tmpFields, fieldName);
      const invoiceHasZeroHours = verifyIfInvoiceHasZeroHours(invoice);
      if (!isZeroUnleashModalDisplayed && !user.allow0Rate) {
        setZeroHoursUnleash(invoiceHasZeroHours);
        onZeroHourUnleashConfirm(false);
      }

      if (isNil(value)) {
        return;
      }

      const finalFieldName = fieldName === '_expenseCategory.id' ? '_category.id' : fieldName;
      await updateInvoiceItem(reimbursement.id, set({}, finalFieldName, value));

      if (currentValue) {
        onLoadingChanges(false);
      }

      if (['rate', 'quantity'].includes(fieldName)) {
        verifyInvoice();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tmpFields,
      invoice,
      onExceedConfirm,
      onZeroHourUnleashConfirm,
      updateInvoiceItem,
      reimbursement.id,
      verifyUnleashBudget,
      verifyInvoice,
      onLoadingChanges,
    ],
  );

  useEffect(() => {
    verifyInvoice();
    setIsZeroUnleashModalDisplayed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unleashBudgetDeeps = isNewExpensesEnabled
    ? [tmpFields.rate, tmpFields.quantity, selectedUnleashCategory]
    : [tmpFields.rate, tmpFields.quantity];

  useEffect(() => {
    verifyUnleashBudget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, unleashBudgetDeeps);

  useEffect(() => {
    setIsZeroUnleashModalDisplayed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNextButtonClick]);

  const handleChange = useCallback(
    async (fieldName: string, value: any, save = true) => {
      if (!value) {
        onLoadingChanges(true);
      }

      setTmpFields((fields) => {
        const updates = { ...fields };
        if (fieldName === 'files') {
          if (isEmpty(value)) {
            return updates;
          }
          const [files, isRemoving] = value;

          const filesUpdated = isRemoving ? files : (updates.files || []).concat(files);

          set(updates, fieldName, filesUpdated);
          setReimbursements((prevValues) => {
            const updatedItems = prevValues?.map((item) => {
              if (item.id === updates.id && !isEqual(item, updates)) {
                return { ...item, ...updates };
              }
              return item;
            });

            return updatedItems;
          });
          save && saveChange(fieldName, true, filesUpdated);
          return { ...updates, files: filesUpdated };
        } else if (fieldName === 'notes') {
          setNotesStyle({ border: selectedCategory === 8 && !value ? '1px solid red' : '' });
          set(updates, fieldName, value);
          setReimbursements((prevValues) => {
            const updatedItems = prevValues?.map((item) => {
              if (item.id === updates.id && !isEqual(item, updates)) {
                return { ...item, ...updates };
              }
              return item;
            });

            return updatedItems;
          });

          return updates;
        } else {
          set(updates, fieldName, value);
          setReimbursements((prevValues) => {
            const updatedItems = prevValues?.map((item) => {
              if (item.id === updates.id && !isEqual(item, updates)) {
                return { ...item, ...updates };
              }
              return item;
            });

            return updatedItems;
          });

          save && saveChange(fieldName, true, value === '' && fieldName === 'quantity' ? 0 : value);
          if (fieldName === 'rate' || fieldName === 'quantity') {
            setTotalValueStyle({ border: isBonusError(updates) ? '1px solid red' : '' });
          }
          return updates;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBonusError, saveChange, selectedCategory, onLoadingChanges],
  );

  const handleChangeSelectedCategory = useCallback(
    async (categoryId: number) => {
      const isReferral = isReferralItem(categoryId);
      const isUnleashPlus = isUnleashPlusItem(categoryId);

      if (isReferral) {
        await handleChange(
          isNewExpensesEnabled ? '_expenseCategory.id' : '_category.id',
          categoryId,
        );
        await handleChange('rate', DEFAULT_REFERRAL_REIMBURSEMENT_RATE);
        await handleChange('files', null);
      } else {
        await handleChange('rate', 0);
        await handleChange(
          isNewExpensesEnabled ? '_expenseCategory.id' : '_category.id',
          categoryId,
        );
        await handleChange('name', '');
      }

      if (!isUnleashPlus) {
        await handleChange('_unleashCategory.id', null);
        await handleChange('_unleashCategory.limit', null);
        await handleChange('_unleashCategory.name', null);
      }

      setNotesStyle({ border: isUnleashPlus && !tmpFields.notes ? '1px solid red' : '' });
      setIsReferral(isReferral);
      setIsUnleashPlus(isUnleashPlus);
      setSelectedCategory(categoryId);
      setSelectedUnleashCategory((isUnleashPlus && tmpFields._unleashCategory?.id) || null);
    },
    [handleChange, isReferralItem, isUnleashPlusItem, tmpFields, isNewExpensesEnabled],
  );

  const handleChangeSelectedAssignment = useCallback(
    async (value: number) => {
      const selectedAssignment = assignments.find((a) => a._projectId === value);
      const selectedCategory = getDefaultCategory(selectedAssignment);
      const isReferral = isReferralItem(selectedCategory);
      const isUnleashPlus = isUnleashPlusItem(selectedCategory);

      await handleChange('_project.id', value);

      if (!isReferral) {
        await handleChange('rate', 0);
        await handleChange(
          isNewExpensesEnabled ? '_expenseCategory.id' : '_category.id',
          selectedCategory,
        );
        await handleChange('name', '');
      } else {
        await handleChange(
          isNewExpensesEnabled ? '_expenseCategory.id' : '_category.id',
          selectedCategory,
        );
        await handleChange('rate', DEFAULT_REFERRAL_REIMBURSEMENT_RATE);
      }

      if (!isUnleashPlus) {
        await handleChange('_unleashCategory.id', null);
      }

      setIsReferral(isReferral);
      setIsUnleashPlus(isUnleashPlus);
      setSelectedAssignment(selectedAssignment);
      setSelectedCategory(selectedCategory);
    },
    [
      assignments,
      getDefaultCategory,
      handleChange,
      isReferralItem,
      isUnleashPlusItem,
      isNewExpensesEnabled,
    ],
  );

  const handleChangeSelectedUser = useCallback(
    async (owner: { id: number }) => {
      await handleChange('name', String(owner.id));

      return owner;
    },
    [handleChange],
  );

  const handleChangeUnleashCategory = useCallback(
    async (category?: UnleashCategory) => {
      setOverUnleashLimit(false);
      await handleChange('_unleashCategory.limit', category?.limit);

      setSelectedUnleashCategory(category?.id || null);
      await handleChange('_unleashCategory.id', category?.id);
      await handleChange('_unleashCategory.name', category?.name);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange],
  );

  const onOverUnleashConfirm = useCallback(
    async (name: string) => {
      setOverUnleashLimit(false);
      onExceedConfirm(false);
      setUnleashBudgetError(false);
      setIsApproved(true);
      await updateInvoiceItem(reimbursement.id, { overTimeApprover: name });
    },
    [onExceedConfirm, setUnleashBudgetError, setIsApproved, updateInvoiceItem, reimbursement.id],
  );

  const onZeroUnleashConfirm = useCallback(
    async (name: string) => {
      setIsZeroUnleashModalDisplayed(true);
      setZeroHoursUnleash(false);
      onZeroHourUnleashConfirm(false);
      setUnleashBudgetError(false);
      await updateInvoiceItem(reimbursement.id, { overTimeApprover: name });
    },
    [onZeroHourUnleashConfirm, setUnleashBudgetError, updateInvoiceItem, reimbursement.id],
  );

  const cancelExceedConfirmHandler = useCallback(async () => {
    setOverUnleashLimit(false);
    onExceedConfirm(true);
    setIsApproved(true);
    setUnleashBudgetError(false);
  }, [onExceedConfirm, setUnleashBudgetError]);

  const cancelZeroUnleashConfirmHandler = useCallback(async () => {
    setIsZeroUnleashModalDisplayed(true);
    onZeroHourUnleashConfirm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = useCallback(() => {
    removeInvoiceItem(reimbursement.id);
    onExceedConfirm(false);
    onZeroHourUnleashConfirm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onExceedConfirm, onZeroHourUnleashConfirm, removeInvoiceItem, reimbursement.id]);

  const handleOnBlur = useCallback(
    (fieldName: string) => async (_: any) => {
      saveChange(fieldName);
    },
    [saveChange],
  );

  const targetStrVal = ({ target }: { target: any }) => (target ? target.value : '');

  const clearZeroValue = useCallback(
    async (fieldName: string, value: number) => {
      if (value === 0) {
        await handleChange(fieldName, '', false);
      }
    },
    [handleChange],
  );

  const sortAvailableReimbursementTypes = useMemo(() => {
    const UNLEASH_PLUS_ID = 8;

    const availableReimbursementTypes: ExpenseCategorySelect[] | undefined = isNewExpensesEnabled
      ? selectedAssignment?._project?._allowedExpenses
          ?.filter((category: ExpenseCategory) => !category.name?.includes('Hours Worked'))
          .map((category: ExpenseCategory) => ({
            value: category.id,
            label: category.name,
          }))
      : selectedAssignment?._project?._allowedReimbursements?.map((category: UnleashCategory) => ({
          value: category.id,
          label: category.name,
          isBonusCategory: category.isBonusCategory,
        }));

    if (!availableReimbursementTypes) return [];

    const unleashReimbursementType = isNewExpensesEnabled
      ? availableReimbursementTypes.find(
          (reimbursement: ExpenseCategorySelect) =>
            reimbursement.label === EXPENSE_CATEGORY.UNLEASH_NON_BILLABLE,
        )
      : availableReimbursementTypes.find(
          (reimbursement: ExpenseCategorySelect) => reimbursement.value === UNLEASH_PLUS_ID,
        );

    // Check if Unleash+ is available and make it the first option
    if (unleashReimbursementType) {
      const sortedReimbursementTypes = isNewExpensesEnabled
        ? availableReimbursementTypes.filter(
            (reimbursement) => reimbursement.label !== EXPENSE_CATEGORY.UNLEASH_NON_BILLABLE,
          )
        : availableReimbursementTypes.filter(
            (reimbursement) => reimbursement.value !== UNLEASH_PLUS_ID,
          );
      sortedReimbursementTypes.unshift(unleashReimbursementType);

      return sortedReimbursementTypes;
    }

    return availableReimbursementTypes;
  }, [selectedAssignment, isNewExpensesEnabled]);

  const showUnleashConfirmModal = openUnleashLimitConfirmationModal && !isApproved;

  return (
    <div data-testid={DATA_TEST_ID.SECTION} className={styles.OuterWrapper}>
      <section className={styles.Reimbursement}>
        <Row reimbursement horizontal>
          <div data-testid={DATA_TEST_ID.PROJECT} className={styles.Project}>
            <Label reimburseField>Project</Label>
            <Select
              reimburseProject
              value={selectedAssignment?._projectId}
              onChange={handleChangeSelectedAssignment}
              options={assignments.map((a) => ({
                value: a._project?.id,
                label: a._project?.name,
              }))}
              placeholder="Select project"
              isFullWidth
            />
          </div>
          <div data-testid={DATA_TEST_ID.TYPE} className={styles.ReimburseType}>
            <Label reimburseField>Reimbursement type</Label>
            <Select
              data-testid={DATA_TEST_ID.SELECT}
              onChange={handleChangeSelectedCategory}
              reimburseCategory
              value={selectedCategory}
              options={sortAvailableReimbursementTypes}
              placeholder="Select type"
            />
          </div>
          <Row noPadding reimburseHorizontal>
            <Row noPadding reimburseVertical withMargin>
              <Label reimburseField>Quantity</Label>
              <Input
                data-testid={DATA_TEST_ID.QUANTITY}
                reimburseNumber
                type="number"
                placeholder="0"
                style={totalValueStyle}
                min="0"
                max="10"
                onFocus={(target) =>
                  clearZeroValue(
                    'quantity',
                    numberBoundaries({
                      number: Number(target.currentTarget.value),
                      max: 10,
                      min: 0,
                    }),
                  )
                }
                onChange={async ({ target }) =>
                  await handleChange(
                    'quantity',
                    String(numberBoundaries({ number: Number(target.value), min: 0, max: 10 })),
                    true,
                  )
                }
                onBlur={({ target }) =>
                  saveChange(
                    'quantity',
                    true,
                    String(
                      numberBoundaries({
                        number: Number(parseNumber(target.value || 0)),
                        min: 0,
                        max: 10,
                      }),
                    ),
                  )
                }
                value={numberBoundaries({ number: tmpFields.quantity, min: 0, max: 10 })}
              />
            </Row>
            <Row noPadding reimburseVertical withMargin withLeftMargin>
              <Label reimburseField>Unit Price</Label>
              <Input
                data-testid={DATA_TEST_ID.UNIT_PRICE}
                reimburseNumber
                type="number"
                pattern={'^\\d+(\\.\\d{1,2})?$'}
                placeholder="0"
                prefix="$"
                min="0"
                style={totalValueStyle}
                onFocus={(target) => clearZeroValue('rate', Number(target.currentTarget.value))}
                onChange={async ({ target }) =>
                  await handleChange('rate', roundMoney(Number(target.value)), true)
                }
                onBlur={({ target }) => {
                  saveChange('rate', true, roundMoney(Number(target.value)));
                }}
                value={tmpFields.rate}
              />
            </Row>
          </Row>
        </Row>
        {isReferral && (
          <Row reimbursementReferral>
            <UserSelect
              withAvatar
              excludeUsers={[user.id]}
              isClearable={false}
              isFullWidth={true}
              placeholder="Enter X-Teamer name"
              value={Number(tmpFields.name || reimbursement.name) || null}
              onChange={handleChangeSelectedUser}
            />
          </Row>
        )}
        {isUnleashPlus && (
          <Row reimbursementUnleashCategory>
            <UnleashCategorySelect
              value={selectedUnleashCategory}
              onChange={handleChangeUnleashCategory}
              isFullWidth
              isReimbursement
              filterOptions={isValidBonusCategory}
            />
          </Row>
        )}
        <Row reimbursementNotes>
          <Textarea
            data-testid={DATA_TEST_ID.NOTES}
            noPadding
            reimburseNotes
            placeholder="Enter Notes..."
            onChange={async (event) => await handleChange('notes', targetStrVal(event), true)}
            onBlur={handleOnBlur('notes')}
            value={tmpFields.notes || ''}
            style={notesStyle}
          />
        </Row>
        {!isReferral && (
          <Row reimbursement className={styles.FileUploadWrapper}>
            <p className={styles.FileUpload}>Receipt: </p>
            <UploadInvoiceItem
              accept={'application/pdf, image/jpeg, image/png, image/jpg'}
              multiple
              files={reimbursement.files}
              onFileChanged={async (files, isRemoving) =>
                await handleChange('files', [files, isRemoving])
              }
              label={'Upload receipts'}
            />
          </Row>
        )}
        <Row noPadding>
          <Button isFormBtn onClick={handleRemove} className={styles.RemoveReimburseStep}>
            <span>Remove Reimbursement</span>
          </Button>
        </Row>
      </section>
      <Button
        isClose
        className={styles.CloseReimburse}
        onClick={handleRemove}
        data-testid={DATA_TEST_ID.CLOSE_BUTTON}
      />
      {showUnleashConfirmModal && (
        <OverUnleashConfirmModal
          data-testid={DATA_TEST_ID.OVER_UNLEASH_MODAL}
          categoryName={tmpFields._unleashCategory?.name}
          categoryLimit={tmpFields._unleashCategory?.limit}
          availableBudget={availableUnleashBudget}
          onCancel={cancelExceedConfirmHandler}
          onConfirm={(name) => onOverUnleashConfirm(name)}
          approver={reimbursement.overTimeApprover}
          exitClickingOutside={true}
        />
      )}

      {zeroHoursUnleash && !isZeroUnleashModalDisplayed && (
        <ZeroUnleashConfirmModal
          data-testid={DATA_TEST_ID.ZERO_UNLEASH_MODAL}
          onCancel={cancelZeroUnleashConfirmHandler}
          onConfirm={(name) => onZeroUnleashConfirm(name)}
          approver={reimbursement.overTimeApprover}
          exitClickingOutside={false}
        />
      )}
    </div>
  );
};

export default Reimbursement;
