import { ComponentType } from 'react';

import { brandConfig } from 'brands';

import { ACTIVITY_STATUS, DISABLED_PAGES_EXCLUSION } from 'consts/constants';
import { ADMIN, APP } from 'consts/routing';
import type { DisabledMenuItems, UserRole } from 'types/types';
import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';
import { isAirwallexEnabled } from 'utils/helpers';
import { shouldRenderRunPaymentsPage } from 'utils/netsuiteIntegration';

import type { MenuProps } from './Menu';
import images from './images';

type PickProps =
  | 'user'
  | 'approvedInvoiceStatus'
  | 'submittedInvoiceStatus'
  | 'queuedInvoiceStatus'
  | 'projectsOwners'
  | 'admin'
  | 'superAdmin'
  | 'communityTeam'
  | 'manager'
  | 'peopleOps'
  | 'recruiter'
  | 'superWizard';
type MenuLinkOptions = Pick<MenuProps, PickProps>;

const RUN_PAYMENT_INSERT_INDEX = 3 as const;

export type MainMenuItemsOptions = {
  seasonNumber?: number;
  disabledItems?: DisabledMenuItems;
  privacyPolicyUrl: string;
};
export const getMainMenuItemsData = ({
  disabledItems,
  seasonNumber,
  privacyPolicyUrl,
}: MainMenuItemsOptions): RenderLinkOptions[] => {
  return [
    {
      link: '/submit',
      text: 'Submit invoice',
      ImageEl: images.Add,
    },
    brandConfig.flags.unleashEnabled
      ? {
          link: '/unleash',
          text: 'Unleash+',
          ImageEl: images.Unleash,
        }
      : null,
    brandConfig.flags.referEnabled
      ? { link: '/refer', text: 'Refer', ImageEl: images.Refer }
      : null,
    brandConfig.flags.vacationRequestEnabled
      ? {
          link: '/vacation-request',
          text: 'Vacation Request',
          ImageEl: images.Vacation,
        }
      : null,
    brandConfig.flags.vaultEnabled
      ? { link: '/vault', text: 'The Vault', ImageEl: images.Vault }
      : null,
    brandConfig.flags.bountiesEnabled
      ? {
          link: isFeatureEnabled(FEATURE_FLAGS.newBountiesUI)
            ? `/bounties?completed=t&tab=1`
            : `/bounties?show=all&season=${seasonNumber}`,
          text: 'Bounties',
          ImageEl: images.Bounties,
        }
      : null,
    brandConfig.flags.xtuUniverseEnabled
      ? {
          link: brandConfig.xtuUniversalUrl,
          text: 'X-Team Universe',
          ImageEl: images.Bounties,
          external: true,
        }
      : null,
    brandConfig.flags.outpostEnabled
      ? {
          link: brandConfig.outpostUrl,
          text: 'X-Outpost',
          ImageEl: images.Outpost,
          external: true,
        }
      : null,
    brandConfig.flags.legendsEnabled
      ? {
          link: APP.LEGENDS_PATH,
          text: 'Legends',
          ImageEl: images.Legends,
          excludedFrom: disabledItems?.legends ? DISABLED_PAGES_EXCLUSION : undefined,
        }
      : null,
    brandConfig.flags.legacyEnabled
      ? {
          link: APP.LEGACY_PATH,
          text: 'Legacy',
          ImageEl: images.Legacy,
        }
      : null,
    brandConfig.flags.housesEnabled
      ? {
          link: APP.HOUSES_PATH,
          text: 'Houses',
          ImageEl: images.Teams,
          excludedFrom: disabledItems?.houses ? DISABLED_PAGES_EXCLUSION : undefined,
        }
      : null,
    {
      link: brandConfig.handbookUrl,
      text: 'Handbook',
      ImageEl: images.Handbook,
      external: true,
    },
    brandConfig.flags.privacyPolicyEnabled && privacyPolicyUrl
      ? {
          link: privacyPolicyUrl,
          text: 'Privacy Policy',
          ImageEl: images.PrivacyPolicy,
          external: true,
        }
      : null,
  ].filter(Boolean) as RenderLinkOptions[];
};

export const getAdminMenuItemsData = (type: MenuSection, options: MenuLinkOptions) => {
  const {
    user,
    approvedInvoiceStatus,
    submittedInvoiceStatus,
    queuedInvoiceStatus,
    projectsOwners,
    admin,
    superAdmin,
    communityTeam,
    manager,
    peopleOps,
    recruiter,
    superWizard,
  } = options;
  const userId = user?.id ?? '';
  const managesProjects = projectsOwners?.some((x) => x.id === userId);
  let menuLinks: RenderLinkOptions[] = [];

  switch (type) {
    case 'admin':
      menuLinks = [
        {
          link: {
            pathname: ADMIN.REPORTS_PATH,
          },
          text: 'Reports',
          ImageEl: images.Reports,
        },
        {
          link: {
            pathname: ADMIN.APPROVAL_LIST_PATH,
            search: `?_order=_invoice.submittedAt_desc&status=${
              submittedInvoiceStatus ? submittedInvoiceStatus.id : ''
            }${managesProjects ? `&manager=${userId}` : ''}`,
          },
          text: 'Approvals',
          ImageEl: images.Approvals,
        },
        {
          link: {
            pathname: ADMIN.ACCOUNTING_PATH,
            search: `?status=${approvedInvoiceStatus ? approvedInvoiceStatus.id : ''}`,
          },
          text: 'Accounting',
          ImageEl: images.Accounting,
        },
        {
          link: {
            pathname: ADMIN.USER_LIST_PATH,
            search: `?status=${ACTIVITY_STATUS.ACTIVE}&_order=displayName_asc`,
          },
          text: 'Users',
          ImageEl: images.Users,
        },
        {
          link: {
            pathname: ADMIN.PROJECT_LIST_PATH,
            search: managesProjects
              ? `?owners${isFeatureEnabled(FEATURE_FLAGS.newProjects) ? '%5B0%5D' : ''}=${userId}`
              : '',
          },
          text: 'Projects',
          ImageEl: images.Projects,
        },
        brandConfig.flags.rateUpdateRequests
          ? {
              link: {
                pathname: ADMIN.RATE_UPDATE_REQUESTS_PATH,
              },
              text: 'Rate Update Requests',
              ImageEl: images.RateUpdateRequests,
            }
          : null,
        brandConfig.flags.companiesEnabled
          ? { link: ADMIN.COMPANIES_LIST_PATH, text: 'Companies', ImageEl: images.Companies }
          : null,
        brandConfig.flags.vacationRequestEnabled
          ? {
              link: {
                pathname: ADMIN.VACATION_REQUESTS_PATH,
                search: managesProjects ? `?manager=${userId}` : '',
              },
              text: 'Vacation Requests',
              ImageEl: images.Vacation,
            }
          : null,
        {
          link: ADMIN.USERS_GROUPS_PATH,
          text: 'Groups',
          ImageEl: images.Groups,
        },
        {
          link: ADMIN.REFERRALS_MANAGEMENT_PATH,
          text: 'Referrals',
          ImageEl: images.Refer,
        },
      ].filter(Boolean) as RenderLinkOptions[];

      if (superAdmin && isAirwallexEnabled() && shouldRenderRunPaymentsPage()) {
        menuLinks = [
          ...menuLinks.slice(0, RUN_PAYMENT_INSERT_INDEX),
          {
            link: {
              pathname: ADMIN.RUN_PAYMENTS_PATH,
              search: `?status=${queuedInvoiceStatus ? queuedInvoiceStatus.id : ''}`,
            },
            text: 'Run Payments',
            ImageEl: images.RunPayments,
          },
          ...menuLinks.slice(RUN_PAYMENT_INSERT_INDEX),
        ];
      }
      break;
    case 'community':
      menuLinks = [
        brandConfig.flags.invoiceSurveyEnabled
          ? { link: ADMIN.QUESTIONS_PATH, text: 'Questions', ImageEl: images.Questions }
          : null,
        brandConfig.flags.invoiceSurveyEnabled
          ? { link: ADMIN.ANSWERS_PATH, text: 'Answers', ImageEl: images.Answers }
          : null,
        brandConfig.flags.unleashEnabled
          ? {
              link: ADMIN.UNLEASH_CATEGORIES_PATH,
              text: 'Unleash+ Categories',
              ImageEl: images.Unleash,
            }
          : null,
        brandConfig.flags.vaultEnabled
          ? { link: ADMIN.VAULT_PATH, text: 'Vault Items', ImageEl: images.Vault }
          : null,
        brandConfig.flags.vaultEnabled
          ? {
              link: {
                pathname: ADMIN.VAULT_COLLECTIONS_PATH,
                search: 'status=true',
              },
              text: 'Vault Collections',
              ImageEl: images.Vault,
            }
          : null,
        brandConfig.flags.bountiesEnabled
          ? {
              link: {
                pathname: ADMIN.BOUNTIES_EDIT_PATH,
                search: 'status=true',
              },
              text: 'Edit Bounties',
              ImageEl: images.Achievements2,
            }
          : null,
        brandConfig.flags.bountiesEnabled
          ? {
              link: {
                pathname: ADMIN.BOUNTIES_COLLECTIONS_PATH,
                search: 'status=true',
              },
              text: 'Bounties Collections',
              ImageEl: images.Achievements2,
            }
          : null,
        brandConfig.flags.bountiesEnabled
          ? {
              link: {
                pathname: ADMIN.BOUNTIES_TAGS_PATH,
              },
              text: 'Bounties Tags',
              ImageEl: images.Achievements2,
            }
          : null,
        brandConfig.flags.housesEnabled
          ? { link: ADMIN.HOUSES_LIST_PATH, text: 'Houses', ImageEl: images.Teams }
          : null,
        brandConfig.flags.legendsEnabled
          ? { link: ADMIN.LEGENDS_PATH, text: 'Legends', ImageEl: images.Legends }
          : null,
        brandConfig.flags.legacyEnabled
          ? { link: ADMIN.LEGACY_PATH, text: 'Legacy', ImageEl: images.Legacy }
          : null,
        brandConfig.flags.eventsEnabled
          ? { link: ADMIN.EVENTS_PATH, text: 'Events', ImageEl: images.Vacation }
          : null,
        brandConfig.flags.betsEnabled
          ? { link: ADMIN.BETS_PATH, text: 'Bets', ImageEl: images.Bets }
          : null,
      ].filter(Boolean) as RenderLinkOptions[];
      break;

    case 'settings':
      menuLinks = [{ link: ADMIN.SETTINGS_PATH, text: 'Settings', ImageEl: images.Settings }];
  }

  let allowedLinks: string[] = [];
  if (admin || superAdmin) {
    allowedLinks = [];
  } else if (communityTeam) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.VAULT_COLLECTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.BOUNTIES_COLLECTIONS_PATH,
      ADMIN.BOUNTIES_TAGS_PATH,
      ADMIN.LEGENDS_PATH,
      ADMIN.LEGACY_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.BETS_PATH,
    ];
  } else if (manager) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.APPROVAL_LIST_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.BOUNTIES_TAGS_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.RATE_UPDATE_REQUESTS_PATH,
    ];
  } else if (peopleOps) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.ACCOUNTING_PATH,
      ADMIN.APPROVAL_LIST_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_COLLECTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.BOUNTIES_COLLECTIONS_PATH,
      ADMIN.BOUNTIES_TAGS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.LEGENDS_PATH,
      ADMIN.LEGACY_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.REFERRALS_MANAGEMENT_PATH,
      ADMIN.BETS_PATH,
    ];
  } else if (recruiter) {
    allowedLinks = [ADMIN.REFERRALS_MANAGEMENT_PATH];
  } else if (superWizard) {
    allowedLinks = [
      ADMIN.REPORTS_PATH,
      ADMIN.USER_LIST_PATH,
      ADMIN.PROJECT_LIST_PATH,
      ADMIN.ANSWERS_PATH,
      ADMIN.QUESTIONS_PATH,
      ADMIN.VAULT_COLLECTIONS_PATH,
      ADMIN.VAULT_PATH,
      ADMIN.EVENTS_PATH,
      ADMIN.SETTINGS_PATH,
      ADMIN.UNLEASH_CATEGORIES_PATH,
      ADMIN.BOUNTIES_EDIT_PATH,
      ADMIN.BOUNTIES_COLLECTIONS_PATH,
      ADMIN.BOUNTIES_TAGS_PATH,
      ADMIN.VACATION_REQUESTS_PATH,
      ADMIN.USERS_GROUPS_PATH,
      ADMIN.LEGENDS_PATH,
      ADMIN.LEGACY_PATH,
      ADMIN.HOUSES_LIST_PATH,
      ADMIN.BETS_PATH,
      ADMIN.RATE_UPDATE_REQUESTS_PATH,
    ];
  }

  if (allowedLinks.length !== 0) {
    return menuLinks.filter((item) =>
      allowedLinks.includes(typeof item.link === 'string' ? item.link : item.link.pathname),
    );
  } else {
    return menuLinks;
  }
};

type MenuSection = 'admin' | 'community' | 'settings';

export interface RenderLinkOptions {
  link: { pathname: string; search?: string } | string;
  text: string;
  ImageEl: ComponentType;
  sublinks?: {
    link: { pathname: string; search?: string } | string;
    text: string;
    ImageEl: ComponentType;
  }[];
  external?: boolean;
  sublink?: boolean;
  onClickHandler?: (() => void) | null;
  key?: string;
  excludedFrom?: UserRole[];
}
