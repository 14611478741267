import { memo, MouseEventHandler, SyntheticEvent, PropsWithChildren } from 'react';

import cx from 'classnames';
import { Button, Badge, Card, Paragraph } from 'design-system';

import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './ReferralCard.module.scss';

type ActionClickHandler = (
  e: SyntheticEvent<HTMLDivElement> | undefined,
) => MouseEventHandler<HTMLDivElement | undefined> | void;

export type ReferralCardProps = {
  title: string;
  description: string;
  skills: string[];
  onCardClick: ActionClickHandler;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
};

export const ReferralCard = memo<PropsWithChildren<ReferralCardProps>>((props) => (
  <Card
    data-testid="TestId__REFERRALCARDWRAPPER"
    className={cx(styles.Wrapper, props.className)}
    onKeyUp={(e) => isKeyboardEvent(e) && props.onCardClick(e)}
    onClick={props.onCardClick}
    tabIndex={0}
    role="button"
  >
    <div className={styles.TitleContainer}>
      <Paragraph data-testid="TestId__REFERRALCARD__TITLE" className={styles.Title} variant="bold">
        {props.title}
      </Paragraph>
      {props.closable && (
        <Button
          data-testid="TestId__REFERRALCARD__CLOSEBUTTON"
          onClick={() => props.onClose?.()}
          iconName="close-big"
          variant="icon"
          layout="autolayout"
          as="button"
        />
      )}
    </div>
    <ul className={styles.Skills}>
      {props.skills.map((skill) => (
        <li key={skill}>
          <Badge
            id={skill}
            type="common"
            text={skill}
            showBackground
            showIcon={false}
            interactive={false}
            spanClassName={styles.Skill}
            paragraphClassName={styles.SkillText}
          />
        </li>
      ))}
    </ul>
    <Paragraph
      data-testid="TestId__REFERRALCARD__DESCRIPTION"
      className={styles.Description}
      variant="ui-regular"
    >
      {props.description}
    </Paragraph>
    {props.children}
  </Card>
));
