import { Component, Fragment } from 'react';

import { brandConfig } from 'brands';
import { Modal } from 'design-system';
import { isEqual, get, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Label from 'components/Label';
import { InlineLoading } from 'components/Loading';
import Row from 'components/Row';
import Button from 'components/buttons/Button';
import EditIcon from 'components/buttons/EditIconButton';
import Input from 'components/inputs/Input';
import Legend from 'components/legends/Legend';
import EditStep from 'components/steps/EditStep';
import Footer from 'components/steps/StepFooter';
import Msg from 'components/steps/StepMessage';
import Title from 'components/steps/StepTitle';
import Wrap from 'components/steps/StepWrap';
import Step from 'components/steps/SubmitStep';
import { Messages } from 'consts/Messages';
import { parseNumber } from 'helpers/parseHelpers';
import { addModalToast, addToast } from 'store/actionCreators';
import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';
import { formatCurrency, countBusinessDays, getErrorMessage } from 'utils/helpers';
import { isNewProjectExpensesEnabled } from 'utils/netsuiteIntegration';

import OverTimeConfirmModal from './OverTimeConfirmModal/OverTimeConfirmModal';

import styles from './Step2.module.scss';

const STEP = 2;

class Step2 extends Component {
  state = {
    projectHours: {},
    overTimeApprover: '',
    showOverTimeConfirm: false,
    showHoursNotDaysConfirm: false,
    isLoadingInvoiceItemsSubmit: {},
  };

  isNewExpensesEnabled = isNewProjectExpensesEnabled();

  async componentDidMount() {
    const { user, invoice, harvestEnabled, productiveEnabled } = this.props;
    this.setState(() => ({
      projectHours: {
        ...this.getHoursBySortedProjects(),
      },
      overTimeApprover: invoice?._invoiceItems[0]?.overTimeApprover || '',
    }));

    // if the user doesn't change the default dates in the DatePicker, it will be rendered already
    if (harvestEnabled) {
      const value = { start: invoice.dateFrom, end: invoice.dateTo };
      await this.props.getHarvestAPITimeEntries({ harvestId: user.harvestId, value });
      await this.props.removeHarvestInvoiceItems();
    }

    if (productiveEnabled) {
      const value = { start: invoice.dateFrom, end: invoice.dateTo };
      await this.props.getProductiveAPITimeEntries({ productiveId: user.productiveId, value });
      await this.props.removeProductiveInvoiceItems();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.invoice, this.props.invoice)) {
      this.setState(() => ({
        projectHours: {
          ...this.getHoursBySortedProjects(),
        },
      }));
    }
  }

  onChange = (item) => (event) => {
    const { invoice } = this.props;
    const {
      assignment: {
        _project: { id, name },
        hoursLimit,
      },
      tempId,
    } = item;

    const { value } = event.target;
    const amoutOfDays = countBusinessDays(invoice.dateFrom, invoice.dateTo);
    const hoursLimitPerDateRange = amoutOfDays * hoursLimit;

    if (!isNil(hoursLimit) && value > hoursLimitPerDateRange) {
      this.props.addToast({
        id: 'hoursError',
        autoClose: 0,
        type: 'error',
        text: `The value of hours submitted in ${name} exceeds the limit agreed of ${hoursLimitPerDateRange} hours. Make sure it’s the correct value and that it was confirmed with your manager`,
      });
    }

    this.setState((oldState) => ({
      projectHours: {
        ...oldState.projectHours,
        [this.isNewExpensesEnabled ? tempId : id]: value,
      },
    }));
  };

  onBlur = (item, isProductive = false) => async () => {
    const {
      invoiceItem,
      assignment: { rate, _project },
      hoursWorkedCategories,
      expenseCategory,
      tempId,
      totalHours,
    } = item;
    const hoursWorkedDefaultCategory = hoursWorkedCategories?.length && hoursWorkedCategories[0];

    const {
      createInvoiceItem,
      updateInvoiceItem,
      removeInvoiceItem,
      hoursInvoiceCategory,
    } = this.props;
    const { projectHours } = this.state;
    const displayHoursNotDaysConfirm = this.onOverTimeValidate();
    const quantity = projectHours[this.isNewExpensesEnabled ? tempId : _project.id];
    if (quantity < 31 && displayHoursNotDaysConfirm && !brandConfig.isXWP)
      this.setState({ showHoursNotDaysConfirm: true });
    if (isNil(quantity)) return;

    this.setState((oldState) => {
      oldState.isLoadingInvoiceItemsSubmit[_project.id] = true;
      return oldState;
    });
    try {
      if (
        invoiceItem?.isDetachedFromProductive &&
        (parseNumber(quantity) === '0' || parseNumber(quantity) === '0.00')
      ) {
        removeInvoiceItem(invoiceItem.id);
      } else if (invoiceItem) {
        updateInvoiceItem(invoiceItem.id, {
          quantity: parseNumber(quantity),
          isDetachedFromProductive:
            isProductive && parseFloat(totalHours) !== quantity ? true : undefined,
        });
      } else {
        await createInvoiceItem({
          quantity: parseNumber(quantity),
          rate,
          isDetachedFromProductive:
            isProductive && parseFloat(totalHours) !== quantity ? true : undefined,
          _project,
          _category: this.isNewExpensesEnabled
            ? expenseCategory || hoursWorkedDefaultCategory
            : hoursInvoiceCategory[0],
        });
      }
    } catch (err) {
      const message = getErrorMessage(err);
      this.props.addToast(Messages.ToastMessages.Invoice.failedSubmitProjectHour(message));
    }
    this.setState((oldState) => {
      oldState.isLoadingInvoiceItemsSubmit[_project.id] = false;
      return oldState;
    });
  };

  isHoursItem = (item) =>
    this.isNewExpensesEnabled
      ? item._expenseCategory?.name.includes('Hours Worked')
      : get(item, '_category.id') === get(this.props, 'hoursInvoiceCategory')[0]?.id;

  getHoursBySortedProjects = () => {
    return this.props
      .getSortedProjects()
      .filter((item) => !!item.invoiceItem)
      .reduce(
        (acc, item) => ({
          ...acc,
          [get(item, this.isNewExpensesEnabled ? 'tempId' : 'invoiceItem._project.id')]: get(
            item,
            'invoiceItem.quantity',
            '',
          ),
        }),
        {},
      );
  };

  getSortedProjectsWithHours = () => {
    return this.props
      .getSortedProjects()
      .filter(
        (item) =>
          this.state.projectHours[
            get(item, this.isNewExpensesEnabled ? 'tempId' : 'assignment._project.id')
          ],
      );
  };

  toggleOverTimeConfirmModal = () => {
    const { showOverTimeConfirm } = this.state;

    this.setState({ showOverTimeConfirm: !showOverTimeConfirm });
  };

  validate = () => {
    const { invoice } = this.props;
    return !!invoice;
  };

  onOverTimeValidate = () => {
    const { overTimeApprover, projectHours } = this.state;
    const { invoice, updateInvoiceItem } = this.props;

    const totalWorkHours =
      Object.values(projectHours).length > 0
        ? Object.values(projectHours).reduce(
            (prev = 0, current) => parseFloat(prev) + parseFloat(current),
          )
        : [];
    const amountOfDays = countBusinessDays(invoice.dateFrom, invoice.dateTo);

    const workedOverTime = totalWorkHours > amountOfDays * 8;

    if (workedOverTime && !overTimeApprover) {
      this.setState({
        showOverTimeConfirm: true,
      });
      return false;
    }
    if (!workedOverTime) {
      this.setState({ overTimeApprover: '' });
      invoice._invoiceItems.forEach((item) => {
        try {
          updateInvoiceItem(item.id, { overTimeApprover: '' });
        } catch (err) {
          console.error(`Error updating invoice item ${item.id}`, err);
        }
      });
    }

    return true;
  };

  onOverTimeConfirm = async (name) => {
    const { invoice, updateInvoiceItem } = this.props;

    this.setState({
      showOverTimeConfirm: false,
      overTimeApprover: name,
    });

    invoice._invoiceItems.forEach(async (item) => {
      try {
        await updateInvoiceItem(item.id, { overTimeApprover: name });
      } catch (err) {
        console.error(`Error updating invoice item ${item.id}`, err);
      }
    });
  };

  onClickNext = () => {
    const { selectStep, harvestEnabled } = this.props;

    const canGoToNext = this.onOverTimeValidate();

    if (!canGoToNext && !harvestEnabled) return;

    selectStep(STEP + 1)();
  };

  renderEditInvoiceItems = () => {
    const { invoice, harvestEnabled, productiveEnabled } = this.props;
    const { projectHours, isLoadingInvoiceItemsSubmit } = this.state;
    const items = this.props.getSortedProjects();

    return items
      .filter((item) =>
        !harvestEnabled ? item : !get(item, ['assignment', '_project', 'harvestId']),
      )
      .filter((item) =>
        !productiveEnabled ? item : !get(item, ['assignment', '_project', 'productiveId']),
      )
      .map((item) => {
        const {
          assignment: { _project, rate },
          expenseCategory,
          tempId,
        } = item;
        const { id, name } = _project;

        return (
          <>
            {this.isNewExpensesEnabled && expenseCategory && (
              <Label data-testid="expenseCategory-label" className={styles.workHoursAlert}>
                {expenseCategory.name}
              </Label>
            )}
            <Row
              key={this.isNewExpensesEnabled ? tempId : id}
              noPadding
              nomargin="true"
              className={styles.inputRow}
              data-testid="edit-invoiceItems"
            >
              <Label className={styles.labelProject}>{name}</Label>
              <Input
                data-testid="input-hours"
                type="number"
                min="0"
                placeholder="0"
                isNarrow
                onChange={this.onChange(item)}
                onBlur={this.onBlur(item)}
                disabled={!!isLoadingInvoiceItemsSubmit[id]}
                value={projectHours[this.isNewExpensesEnabled ? tempId : id] || ''}
                className={styles.inputHours}
              />
              <Label className={styles.labelRate}>{`(${rate} ${get(
                invoice,
                '_currency.name',
              )}/hr)`}</Label>
            </Row>
          </>
        );
      });
  };

  renderIntegrationEditInvoiceItems = (integrationName) => {
    const { invoice, isLoadingProductiveData } = this.props;
    const { isLoadingInvoiceItemsSubmit } = this.state;
    let items = [];
    if (integrationName === 'harvest') {
      items = this.props.getSortedHarvestProjects();
    } else if (integrationName === 'productive') {
      items = this.props.getSortedProductiveProjects();
    }

    if (!!isLoadingProductiveData) {
      return <InlineLoading />;
    }
    return items.map((item) => {
      const {
        assignment: {
          _project: { name, id },
          rate,
        },
        totalHours,
        tempId,
        invoiceItem,
      } = item;

      const hoursToDisplay = invoiceItem?.isDetachedFromProductive
        ? invoiceItem?.quantity
        : totalHours;

      return (
        <Fragment key={tempId}>
          <Row
            noPadding
            className={styles.inputRow}
            data-testid={`${integrationName}-invoiceItems`}
          >
            <Label className={styles.labelProject}>{name}</Label>
            <Input
              data-testid="productive-input-hours"
              type="float"
              min="0"
              placeholder={hoursToDisplay || ''}
              isNarrow
              onChange={this.onChange(item)}
              onBlur={this.onBlur(item, true)}
              disabled={!!isLoadingInvoiceItemsSubmit[id]}
              className={styles.labelProjectHours}
            />
            <Label className={styles.labelRate} data-testid="invoiceItemRate">{`(${rate} ${get(
              invoice,
              '_currency.name',
            )}/hr)`}</Label>
          </Row>
          {invoiceItem?.isDetachedFromProductive && (
            <Row className={styles.detachedRow}>
              {`Project hours manually updated by user, original value from Productive was ${
                totalHours || 0
              }`}
            </Row>
          )}
          <Row withPadding withMargin className={styles.taskRowContainer} />
        </Fragment>
      );
    });
  };

  renderHoursSummary = (tempId, name, hours, rate, invoice) => {
    return (
      <Row key={tempId} noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>{name}</span>
        <span className={styles.SummaryCalculation}>
          {hours}x({formatCurrency(rate, get(invoice, '_currency.name'))}/hr)
          <div className={styles.SummaryCalculationTotal} data-testid="hours-sumary-total">
            Total:
            {parseNumber(hours * rate)}
            {get(invoice, '_currency.name')}
          </div>
        </span>
      </Row>
    );
  };

  renderInvoiceItemsSummary = () => {
    const { invoice } = this.props;
    const { projectHours } = this.state;
    const projects = this.getSortedProjectsWithHours();
    const hasHoursInvoiceItems = !isEmpty(invoice._invoiceItems.filter(this.isHoursItem));

    if (!hasHoursInvoiceItems) {
      return (
        <Row data-testid="hours-not-set" noPadding>
          Hours not set for any project.
        </Row>
      );
    }

    return (
      <Fragment>
        {projects?.map((item) => {
          const projectName = get(item, 'invoiceItem._project.name');
          const {
            assignment: {
              _project: { id, allowsNonBillable, _allowedExpenses },
              rate,
            },
            invoiceItem,
            tempId,
          } = item;
          const hoursWorkedCategories = _allowedExpenses?.filter(
            (category) => category.name.includes('Hours Worked') && category.isActive,
          );
          const name =
            this.isNewExpensesEnabled && allowsNonBillable && hoursWorkedCategories.length === 2
              ? `${projectName} - ${invoiceItem?._expenseCategory?.name}`
              : projectName;

          return this.renderHoursSummary(
            tempId || id,
            name,
            projectHours[this.isNewExpensesEnabled ? tempId : id],
            rate,
            invoice,
          );
        })}
      </Fragment>
    );
  };

  render() {
    const { overTimeApprover: stateOverTimeApprover, showOverTimeConfirm } = this.state;
    const { currentStep, selectStep, invoice, isHoursItem, harvestEnabled } = this.props;

    const isActive = currentStep === STEP;
    const hoursInvoiceItems = invoice._invoiceItems.filter(isHoursItem);

    return (
      <Step>
        <Title step={STEP} isActive={isActive}>
          Hours
          <EditStep
            isSubmit
            onClick={selectStep(STEP)}
            showSortUpIcon={currentStep === STEP}
            showEditIcon={currentStep > STEP}
          />
        </Title>

        <Wrap isHidden={!isActive}>
          <Row noPadding className={styles.titleRow}>
            <Msg isTitle>How many hours did you work this period?</Msg>
            <Msg subTitle>
              {brandConfig.contactContentForInvoices}
              {brandConfig.contactsListForInvoices.map(({ name, url, additionalText }, index) => {
                return (
                  <Fragment key={index}>
                    {url ? (
                      <a data-testid="invoicesContact" href={url}>
                        {name}
                      </a>
                    ) : (
                      <span data-testid="invoicesContact">{name}</span>
                    )}
                    {index !== brandConfig.contactsListForInvoices.length - 1
                      ? additionalText || ' or '
                      : ''}
                  </Fragment>
                );
              })}
            </Msg>
          </Row>
          {this.props.harvestFetchError && (
            <div className={styles.nonExistingProjectsContainer}>
              <span className={styles.nonExistingProjectsText} data-testid="harvestFetchError">
                {this.props.harvestFetchError}
              </span>
            </div>
          )}
          <Row noPadding className={styles.legendRow}>
            <Legend noPadding className={styles.legendLarge} message="Project:" />
            {hoursInvoiceItems && (
              <Legend noPadding className={styles.legendSmall} message=" Total Hours:" />
            )}
          </Row>
          {this.renderEditInvoiceItems()}

          {isFeatureEnabled(FEATURE_FLAGS.productive)
            ? this.renderIntegrationEditInvoiceItems('productive')
            : this.renderIntegrationEditInvoiceItems('harvest')}

          {stateOverTimeApprover && (
            <div className={styles.overTimeApprover}>
              Overtime authorized by: <b data-testid="overtime-approver">{stateOverTimeApprover}</b>{' '}
              <EditIcon onClick={this.toggleOverTimeConfirmModal} />
            </div>
          )}
          <Footer isHours>
            <Msg className={styles.bottomMsg}>
              If you have expenses, don't include them as hours. Insert expenses on the next step.
            </Msg>
            <Button isFormBtn submitNext onClick={this.onClickNext} className={styles.nextButton}>
              Next
            </Button>
          </Footer>
        </Wrap>

        <Wrap isHidden={isActive}>{this.renderInvoiceItemsSummary()}</Wrap>

        {showOverTimeConfirm && !harvestEnabled && (
          <OverTimeConfirmModal
            name={stateOverTimeApprover}
            onCancel={() => this.setState({ showOverTimeConfirm: false })}
            onConfirm={(name) => this.onOverTimeConfirm(name)}
          />
        )}
        {this.state.showHoursNotDaysConfirm && (
          <Modal
            onClose={() => this.setState({ showHoursNotDaysConfirm: false })}
            primaryButtonContent="Close"
            secondaryButtonClassName={styles.modalSecondaryButton}
            onSubmit={() => this.setState({ showHoursNotDaysConfirm: false })}
          >
            <h3 className={styles.modalTitle}>Are you sure you input hours and not days worked?</h3>
          </Modal>
        )}
      </Step>
    );
  }
}

Step2.propTypes = {
  currentStep: PropTypes.number.isRequired,
  selectStep: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  assignments: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  updateInvoiceItem: PropTypes.func.isRequired,
  createInvoiceItem: PropTypes.func.isRequired,
  removeHarvestInvoiceItems: PropTypes.func.isRequired,
  getHarvestAPITimeEntries: PropTypes.func.isRequired,
  getProductiveAPITimeEntries: PropTypes.func.isRequired,
  isLoadingHarvestData: PropTypes.bool,
  harvestFetchError: PropTypes.string,
  isHoursItem: PropTypes.func.isRequired,
  getSortedProjects: PropTypes.func.isRequired,
  getSortedHarvestProjects: PropTypes.func.isRequired,
  getSortedProductiveProjects: PropTypes.func.isRequired,
  hoursInvoiceCategory: PropTypes.object.isRequired,
  harvestEnabled: PropTypes.bool.isRequired,
  productiveEnabled: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  addModalToast,
  addToast,
};

export default connect(null, mapDispatchToProps)(Step2);
