import { useState } from 'react';

function useLoaded(): [boolean, () => void] {
  const [loaded, setLoaded] = useState(false);
  return [
    loaded,
    () => {
      if (!loaded) {
        setLoaded(true);
      }
    },
  ];
}

export default useLoaded;
