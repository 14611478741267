import { FunctionComponent } from 'react';

import { Paragraph } from 'design-system';

import styles from './PaymentView.module.scss';

export const ViewEntry: FunctionComponent<{ title: string }> = (props) => (
  <div className={styles.Entry}>
    <Paragraph variant="ui-bold" className={styles.Title}>
      {props.title}
    </Paragraph>
    <Paragraph variant="regular">{props.children || '-'}</Paragraph>
  </div>
);
