import { memo } from 'react';

import { Avatar, Badge, Card, Divider, Paragraph, useIsMobile } from 'design-system';

import { CurrencyName, InvoiceItem } from 'types/types';

import styles from './ReimbursementBrief.module.scss';

export type ReimbursementBriefProps = {
  reimbursement: InvoiceItem & { referralName?: string };
  currency: CurrencyName;
  referralName?: string;
};

export const ReimbursementBrief = memo<ReimbursementBriefProps>(
  ({ reimbursement, currency, referralName }) => {
    const approver = reimbursement._approvedBy?.displayName;
    const unitPrice = reimbursement?.usdPrice || 0;
    const reimbursementType = reimbursement?._category?.name;
    const unleashCategoryId = String(reimbursement?._unleashCategory?.id);
    const unleashCategoryName = reimbursement?._unleashCategory?.name;

    return (
      <Card data-testid="TestId__REIMBURSEMENT__CARD__WRAPPER" className={styles.Card}>
        <div className={styles.Container}>
          <ul className={styles.Types}>
            {!!reimbursementType && (
              <li>
                <Badge
                  key={reimbursementType}
                  id={reimbursementType}
                  type="common"
                  text={reimbursementType}
                  showBackground
                  showIcon={false}
                  interactive={false}
                  spanClassName={styles.Type}
                  paragraphClassName={styles.TypeText}
                />
              </li>
            )}
            {!!unleashCategoryName && (
              <li>
                <Badge
                  key={unleashCategoryId}
                  id={unleashCategoryId}
                  type="common"
                  text={unleashCategoryName}
                  showBackground
                  showIcon={false}
                  interactive={false}
                  spanClassName={styles.Type}
                  paragraphClassName={styles.TypeText}
                />
              </li>
            )}
          </ul>
          {approver && (
            <Badge
              key={approver}
              id={approver}
              type="sold-out"
              text={`Authorized by ${approver}`}
              showBackground
              showIcon={false}
              interactive={false}
            />
          )}
          {referralName && (
            <div className={styles.ReferralContainer}>
              <Avatar name={referralName} />
              <Paragraph className={styles.ReferralName} variant="ui-regular">
                {referralName}
              </Paragraph>
            </div>
          )}
          <div>
            {reimbursement?.files?.map((file) => (
              <Paragraph className={styles.File} variant="ui-bold" key={file?.key}>
                {file?.originalName}
              </Paragraph>
            ))}
          </div>
          <Paragraph className={styles.Description} variant="ui-regular">
            {reimbursement.notes}
          </Paragraph>
        </div>
        {useIsMobile() && <Divider className={styles.MobileDivider} />}
        <div className={styles.Container}>
          <Paragraph className={styles.Ammounts} variant="ui-bold">
            {`${reimbursement.quantity * unitPrice} ${currency}`}
          </Paragraph>
          <Paragraph className={styles.Ammounts} variant="ui-regular">
            {`${reimbursement.quantity} x ${unitPrice} ${currency}`}
          </Paragraph>
        </div>
      </Card>
    );
  },
);
