import { memo, useState } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon, Paragraph, Spacing } from 'design-system/';

import styles from './MenuItem.module.scss';

export interface MenuItemProps {
  link: string;
  icon: string;
  text: string;
  subItems?: MenuItemProps[];
  isSubItem?: boolean;
  isUserMenuItem?: boolean;
  onClickHandler?: () => void;
}

export const MenuItem = memo<MenuItemProps>(
  ({ link, icon, text, isSubItem, subItems, isUserMenuItem, onClickHandler }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <li
          className={classNames(styles.MenuItem, {
            [styles.SubItem]: isSubItem,
            [styles.UserMenu]: isUserMenuItem,
          })}
        >
          <NavLink
            data-testid="nav-link"
            to={link}
            className={styles.Link}
            onClick={() => {
              onClickHandler?.();
              setIsOpen(!isOpen);
            }}
          >
            <Spacing size="small" align="center" direction="row">
              <Icon name={icon} className={styles.Icon} />
              <Paragraph variant="ui-regular">{text}</Paragraph>
            </Spacing>
            {subItems && subItems.length > 0 && <Icon name={isOpen ? 'caret-up' : 'caret-down'} />}
          </NavLink>
        </li>
        {subItems && subItems.length > 0 && isOpen && (
          <ul className={styles.SubItems}>
            {subItems.map((subItem) => (
              <MenuItem key={subItem.text} {...subItem} />
            ))}
          </ul>
        )}
      </>
    );
  },
);
