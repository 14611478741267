import classnames from 'classnames';
import { isArray } from 'lodash';

import Button from 'components/buttons/Button';
import { useAdminSetting } from 'utils/hooks';

import styles from './Ads.module.scss';

export const Ads = () => {
  const settings = useAdminSetting('dashboard-modules')?.values as any;

  const firstAdEnabled = settings?.first_ad_enabled;
  const secondAdEnabled = settings?.second_ad_enabled;

  const desktopSize =
    firstAdEnabled && secondAdEnabled ? styles.SmallDesktopBanner : styles.BigDesktopBanner;

  return (
    <>
      {firstAdEnabled && renderBanner(settings, 'first', desktopSize)}
      {secondAdEnabled && renderBanner(settings, 'second', desktopSize)}
    </>
  );
};

const renderBanner = (settings: any, banner: 'first' | 'second', size: string) => {
  const {
    [`${banner}_ad_desktop_image`]: desktopImage,
    [`${banner}_ad_button_text`]: buttonText,
    [`${banner}_ad_button_url`]: buttonURL,
  } = settings;

  if (!desktopImage) return null;

  const images = isArray(desktopImage) ? desktopImage : [desktopImage];
  return (
    <div className={classnames(styles.Banner, size)}>
      {images.map((image, index) => (
        <img key={index} src={image.location} alt="" />
      ))}
      {buttonText && (
        <>
          <div className={styles.Gradient}></div>
          <Button onClick={() => window.open(buttonURL, '_blank')}>{buttonText}</Button>
        </>
      )}
    </div>
  );
};
