const SvgComponent = (props) => {
  return (
    <svg width="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.2999 22.7C27.1999 22.6 27.2 22.6 27.2 22.5C27.2 22.4 27.2 22.4 27.2 22.3C27.3 21.2 27 20.2 26.5 19.3C26.1 18.6 25.7 17.8 25.4 17.1C25.2999 16.9 25.2999 16.7 25.2999 16.5C25.2999 16 25.2999 15.6 25.2999 15.1C25.2999 14.8 25.2 14.6 25 14.5C24.6 14.2 24.1999 14 23.7999 13.7C23.5999 13.6 23.4999 13.5 23.2999 13.5C23.1999 13.5 23.1 13.5 23.1 13.5C22.6 13.6 22.2 13.7 21.7 13.9C21.7 13.9 21.6 13.9 21.5 13.9C21.4 13.9 21.2999 13.9 21.2999 13.9C20.3999 13.5 19.5 13.1 18.6 12.8C18.5 12.8 18.4 12.6 18.4 12.5C18.3 11.7 18.1 10.8 18 9.99998C18 9.89998 18 9.79998 18.1 9.69998C18.4 9.39998 18.7 9.09998 19 8.79998C19.1 8.69998 19.1999 8.69998 19.2999 8.69998C19.6999 8.69998 19.9999 8.69998 20.4 8.69998C20.6999 8.69998 21.0999 8.69998 21.4 8.69998C21.4999 8.69998 21.6 8.79998 21.7 8.89998C21.9 9.19998 22.0999 9.49998 22.2999 9.79998C22.3999 9.99998 22.5999 10.1 22.9 10.2C23.8 10.3 24.6 10.5 25.5 10.6C25.6 10.6 25.6 10.6 25.7 10.6C25.8 10.6 26 10.6 26.1 10.5C26.9 10.2 27.7 9.99998 28.6 9.69998C28.4 9.29998 28.2 8.99998 28 8.69998L27.5 8.89998C26.9 9.09998 26.3 9.29998 25.7 9.49998H25.6H25.5C25 9.39998 24.6 9.39998 24.1 9.29998C23.7 9.19998 23.4 9.19998 23 9.09998C22.8 8.79998 22.5999 8.49998 22.4 8.19998C22.0999 7.89998 21.6999 7.59998 21.2999 7.59998C20.8999 7.59998 20.6 7.59998 20.2 7.59998C19.8 7.59998 19.5 7.59998 19.1 7.59998C18.7 7.59998 18.4 7.79998 18.1 7.99998C17.8 8.29998 17.4 8.59998 17.1 8.99998C16.8 9.29998 16.7 9.69998 16.7 10.1C16.8 11 16.9 11.9 17.1 12.7C17.2 13.1 17.5 13.6 17.9 13.7C18.6999 14.1 19.6 14.5 20.6 14.9C20.8 15 21 15 21.2 15C21.3 15 21.5 15 21.6 15C22.1 14.9 22.5 14.8 23 14.7C23.3 14.9 23.7 15.2 24.1 15.4C24.1 15.8 24.1 16.3 24.1 16.7C24.1 16.9 24.1999 17.3 24.2999 17.7L24.5 18.1C24.8 18.7 25.1 19.4 25.5 20C26 20.8 26.2 21.6 26.1 22.4C26.1 22.5 26.1 22.7 26.1 23C26.1 23.1 26.2 23.4 26.4 23.6C26.5 23.7 26.5 23.8 26.6 23.9C26.7 24.1 26.9 24.3 27 24.4L27.1 24.5C27.3 24.2 27.5 23.9 27.7 23.7C27.7 23.2 27.4999 23 27.2999 22.7Z"
        fill="#FF5964"
      />
      <path
        d="M20.7 3.49995C20.6 3.49995 20.5 3.59995 20.5 3.59995C20.4 3.59995 20.3 3.59995 20.2 3.59995H20H19.7999C19.6999 3.59995 19.4999 3.59995 19.4 3.59995C19 3.59995 18.4999 3.89995 18.2999 4.29995C17.9999 4.49995 17.9 4.69995 17.7 4.99995L17.6 5.29995L17.2 6.19995L18.1 6.59995C18.4 6.79995 18.9 6.99995 19.4 6.99995C19.6999 6.99995 19.9 6.99995 20.2 6.89995C20.5 6.79995 20.8 6.69995 21.2 6.59995C22.3 6.99995 23.3 7.19995 24.4 7.39995L24.7 7.49995C24.8 7.49995 24.9 7.49995 25 7.49995C25.2 7.49995 25.4 7.39995 25.6 7.29995C25.8 7.19995 26 6.99995 26.2 6.89995L26.4 6.79995L26.6 6.69995C26.2 6.29995 25.7999 5.89995 25.4 5.49995C25.5999 5.69995 25.7 5.79995 25.9 5.99995C25.5999 6.19995 25.5 6.29995 25.2 6.39995H25.1H25C23.9 6.19995 22.7 5.99995 21.6 5.59995C21.5 5.59995 21.4 5.59995 21.4 5.59995C21.3 5.59995 21.2 5.59995 21.2 5.59995C20.8 5.69995 20.4 5.79995 20 5.89995C19.8 5.99995 19.7 5.99995 19.5 5.99995C19.2 5.99995 18.9 5.89995 18.6 5.79995C18.8 5.49995 18.9 5.09995 19.1 4.79995C19.2 4.69995 19.3 4.59995 19.4 4.59995C19.4999 4.59995 19.6999 4.59995 19.7999 4.59995C19.8999 4.59995 20.1 4.59995 20.2 4.59995C20.3 4.59995 20.5 4.59995 20.6 4.59995C20.8 4.59995 21 4.49995 21.2 4.49995C21.7 4.29995 22.1 3.99995 22.6 3.79995C22.6 3.79995 22.6999 3.79995 22.7999 3.79995H22.9C22.9999 3.89995 23.1 3.89995 23.2 3.99995C22.7 3.69995 22.2 3.39995 21.6 3.19995C21.2 3.19995 21 3.29995 20.7 3.49995Z"
        fill="#FF5964"
      />
      <path
        d="M10.1 3.19998C10.2 3.19998 10.2 3.09998 10.3 3.09998C10.4 3.09998 10.4 3.09998 10.5 3.19998C11.2 3.79998 12.2 4.19998 13.1 4.29998C13.4 4.29998 13.8 4.39998 14.2 4.49998C14.1 4.69998 14 4.89998 13.9 4.99998C13.9 5.09998 13.8 5.09998 13.7 5.19998H13.6C12.7 5.29998 12.1 5.89998 11.7 6.69998C11.3 7.69998 10.5 8.39998 9.59995 8.89998C8.89995 9.39998 8.09995 9.59998 7.29995 9.69998C6.69995 9.79998 6.09995 9.89998 5.49995 9.89998C5.09995 9.99998 4.99995 10.2 4.99995 10.6C4.99995 11 4.99995 11.4 4.99995 11.7C4.99995 11.9 5.09995 12.1 5.19995 12.3C5.29995 12.4 5.39995 12.6 5.59995 12.7C5.99995 13 5.99995 13.5 5.89995 13.9C5.19995 13.4 4.59995 13 3.99995 12.6C3.89995 12.6 3.89995 12.5 3.89995 12.4C3.69995 11.8 3.49995 11.2 3.29995 10.5C3.29995 10.4 3.29995 10.3 3.29995 10.2C3.09995 10.7 2.89995 11.3 2.69995 11.8C2.79995 12.1 2.89995 12.4 2.99995 12.7C2.99995 12.8 3.19995 13.2 3.49995 13.4C3.99995 13.7 4.49995 14.1 4.99995 14.4L5.49995 14.7L6.89995 15.6V14C6.99995 13.5 6.99995 12.5 6.09995 11.9L5.99995 11.8C5.99995 11.7 5.99995 11.6 5.99995 11.6C5.99995 11.4 5.99995 11.2 5.99995 10.9C6.49995 10.8 6.99995 10.8 7.49995 10.7C8.59995 10.6 9.39995 10.3 10.2 9.79998C11.1 9.29998 12.2 8.49998 12.8 7.09998C13.1 6.49998 13.4 6.19998 13.9 6.09998C14 6.09998 14.2 6.09998 14.5 5.89998C14.6 5.79998 14.8 5.69998 14.9 5.39998C14.9 5.29998 15 5.09998 15 4.89998L15.1 4.69998L15.5 3.59998L14.4 3.39998C14.3 3.39998 14.2 3.39998 14.1 3.29998C13.8 3.29998 13.6 3.19998 13.3 3.19998C12.5 3.09998 11.9 2.79998 11.5 2.59998L10.1 3.19998Z"
        fill="#FF5964"
      />
      <path
        d="M8.19999 14.8C8.39999 15.2 8.6 15.6 8.8 15.9C8.9 16.2 9.2 16.4 9.5 16.4C9.8 16.4 10.2 16.4 10.5 16.4C10.9 16.4 11.2 16.4 11.6 16.4C11.8 16.4 12 16.4 12.2 16.4C12.4 16.4 12.5 16.5 12.6 16.6C13 17.2 13.3 17.8 13.7 18.5C13.9 18.8 14.1 18.9 14.4 18.9C14.6 18.9 14.7 18.9 14.9 18.9C15.1 18.9 15.4 18.9 15.6 18.9C15.5 19.5 15.4 20 15.3 20.6C15.3 20.7 15.2 20.7 15.1 20.8C14.6 21.3 14.1 21.8 13.5 22.4C13.3 22.6 13.2 22.8 13.2 23C13.4 23.8 13.1 24.3 12.4 24.7C12.1 24.9 11.8 25.1 11.6 25.3C11.4 25.4 11.3 25.6 11.3 25.9C11.3 26.4 11.3 26.9 11.3 27.5C11.3 27.5 11.3 27.5 11.3 27.6C11 27.6 10.7 27.4 10.6 27.1C10 25.9 9.79999 24.7 9.89999 23.4C9.99999 22.5 9.89999 21.6 9.89999 20.6C9.89999 20.2 9.79999 20.1 9.39999 20C8.79999 19.9 8.2 19.8 7.49999 19.6C7.4 19.6 7.19999 19.5 7.19999 19.3C6.99999 19 6.89999 18.8 6.69999 18.5C6.39999 18 6.2 17.4 6.3 16.8C6.3 16.7 6.3 16.6 6.4 16.5C7 16 7.59999 15.4 8.19999 14.8ZM8.49999 13.1L7.49999 14.1L7.09999 14.5C6.59999 15 6.2 15.4 5.8 15.8C5.5 16.1 5.4 16.5 5.4 16.7C5.3 17.6 5.5 18.4 5.9 19.1C6 19.2 6 19.2 6 19.3C6.1 19.4 6.09999 19.6 6.19999 19.8C6.39999 20.1 6.79999 20.4 7.09999 20.5C7.49999 20.6 7.9 20.7 8.3 20.7C8.4 20.7 8.59999 20.8 8.69999 20.8C8.69999 21 8.69999 21.2 8.69999 21.4C8.69999 22 8.69999 22.7 8.69999 23.3C8.69999 24.6 9 26 9.6 27.4C10 28.3 10.8 28.5 11.1 28.5L11.9 28.6L12.2 27.8C12.2 27.8 12.3 27.6 12.3 27.4V27C12.3 26.7 12.3 26.3 12.3 26C12.4 25.9 12.4 25.9 12.5 25.8C12.6 25.7 12.8 25.6 12.9 25.5C13.9 24.9 14.4 24 14.3 22.9C14.7 22.5 15.1 22.1 15.5 21.7L15.8 21.4C15.9 21.3 16.2 21.1 16.2 20.7C16.3 20.3 16.4 19.9 16.5 19.4L16.6 19L16.8 17.8H15.6H15.2H14.8C14.7 17.8 14.6 17.8 14.5 17.8C14.3 17.5 14.1 17.2 14 16.9C13.8 16.6 13.7 16.3 13.5 16.1C13.3 15.8 12.9 15.4 12.3 15.4C12.1 15.4 11.9 15.4 11.7 15.4H11.2H10.7C10.4 15.4 10.1 15.4 9.7 15.4C9.6 15.1 9.5 14.9 9.3 14.7L9.1 14.3L8.49999 13.1Z"
        fill="#FF5964"
      />
      <path
        d="M16 2C23.7 2 30 8.3 30 16C30 23.7 23.7 30 16 30C8.3 30 2 23.7 2 16C2 8.3 8.3 2 16 2ZM16 1C7.7 1 1 7.7 1 16C1 24.3 7.7 31 16 31C24.3 31 31 24.3 31 16C31 7.7 24.3 1 16 1Z"
        fill="#5A5665"
      />
    </svg>
  );
};

export default SvgComponent;
