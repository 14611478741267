import { memo, MouseEventHandler } from 'react';

import { Button, useIsMobile } from 'design-system';

import { ButtonActiveState } from 'types/types';

import styles from './StepCardButtons.module.scss';

export interface StepCardButtonsProps {
  text?: string;
  state?: ButtonActiveState;
  onClick?: MouseEventHandler;
  buttonTestId?: string;
  useFluidMobileButton?: boolean;
}

export const StepCardButtons = memo<StepCardButtonsProps>(
  ({ text = 'Next', state = 'inactive', onClick, useFluidMobileButton = false, buttonTestId }) => {
    const isMobile = useIsMobile();
    const useFluid = isMobile && useFluidMobileButton;

    if (state === 'pending') {
      return null;
    }

    return (
      <div className={styles.Container}>
        {['active', 'disabled', 'loading'].includes(state) ? (
          <div
            data-testid="TestId__STEPCARDBUTTONS__BUTTONPRIMARY__WRAPPER"
            className={useFluid ? '' : styles.Right}
          >
            <Button
              onClick={onClick}
              variant={'primary'}
              data-testid={buttonTestId}
              layout={useFluid ? 'fluid' : 'autolayout'}
              as={'button'}
              disabled={state === 'disabled' || state === 'loading'}
              isLoading={state === 'loading'}
            >
              {text}
            </Button>
          </div>
        ) : (
          <div data-testid="TestId__STEPCARDBUTTONS__BUTTONSECONDARY__WRAPPER">
            <Button
              variant={'secondary'}
              layout={'autolayout'}
              data-testid={buttonTestId}
              as={'button'}
              onClick={onClick}
              className={styles.FocusReset}
            >
              {text}
            </Button>
          </div>
        )}
      </div>
    );
  },
);
