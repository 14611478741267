import { MouseEvent } from 'react';

import { get, map } from 'lodash';

import ChangeHistory from 'components/ChangeHistory';
import { FNS_LONG_DATE_TIME_FORMAT, paymentMethodsMap } from 'consts/constants';
import { AccountingData } from 'types/types';
import { formatDate } from 'utils/datesHelper';
import { hasPaymentMethod } from 'utils/paymentHelper';

import styles from './DisplayPayment.module.css';

const DisplayPayment = ({
  accountingData,
  onExpandClick,
  showEditButton,
  expanded,
  showExpandButton,
  toggleEditMode,
}: Props) => {
  if (!hasPaymentMethod(accountingData)) {
    return <div className={styles.Message}>No payment selected</div>;
  }

  // I left PayPal as the default option to avoid TS-warning. It is important to mention that this default value
  // should not be happening, because we check that the value exists before line 21
  const paymentTypeName = accountingData._paymentType?.name || 'paypal';
  const editHistory = accountingData.info?.latestUpdates ?? [];
  const { label = '-', fields: paymentFields } = paymentMethodsMap[paymentTypeName] ?? {};

  return (
    <>
      <div className={styles.Table}>
        <div className={styles.Column}>
          <div className={styles.LabelText}>Payment Via</div>
          <div className={styles.LabelValue}>{label}</div>
        </div>

        {map(paymentFields, (input, fieldName) => (
          <div className={styles.Column} key={fieldName}>
            <div className={styles.LabelText}>{input?.label}</div>
            <div className={styles.LabelValue}>{get(accountingData, ['info', fieldName], '-')}</div>
          </div>
        ))}

        <div className={styles.Column}>
          <div className={styles.LabelText}>Additional Banking Notes</div>
          <div className={styles.LabelValue}>{accountingData.notes || '-'}</div>
        </div>
      </div>
      {showEditButton && (
        <button className={styles.EditButton} onClick={toggleEditMode}>
          Edit
        </button>
      )}

      <div className={styles.LastChange}>
        {editHistory.length > 0 && (
          <ChangeHistory
            changes={editHistory}
            changeDescription={(change: number) => formatDate(change, FNS_LONG_DATE_TIME_FORMAT)}
            expanded={expanded}
            onExpandClick={onExpandClick}
            showExpandButton={showExpandButton}
          />
        )}
      </div>
    </>
  );
};

export default DisplayPayment;

export type Props = {
  accountingData: AccountingData;
  expanded: boolean;
  showEditButton?: boolean;
  showExpandButton: boolean;
  onExpandClick?: (event: MouseEvent<HTMLElement>) => void;
  toggleEditMode?: () => void;
};
