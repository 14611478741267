import { memo, useState } from 'react';

import { brandConfig } from 'brands';
import { QuestionCard } from 'design-system';

import { SUBMIT_INVOICE } from 'consts/constants';
import { Invoice, StepActions } from 'types/types';

export interface Step5Props extends StepActions {
  notes: string;
  updateInvoice: (invoiceData: Partial<Invoice>) => void;
}

export const Step5 = memo<Step5Props>((props) => {
  const step = SUBMIT_INVOICE['CONTENT'][5];
  const [notes, setNotes] = useState<string>(props.notes);
  const { updateInvoice } = props;

  return (
    <QuestionCard
      {...props}
      isRequired={false}
      singleColumn
      type="text"
      state={props.state}
      step={step.step}
      title={step.title}
      answer={notes}
      nextButtonText={brandConfig.isXWP ? 'Go to Referral' : 'Go to survey'}
      description={(props.state === 'active' && step.description) || ''}
      onQuestionAnswer={setNotes}
      onBlur={() => updateInvoice({ notes })}
      placeholder="Enter notes..."
    />
  );
});
