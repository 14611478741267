/* eslint-disable jsx-a11y/no-autofocus */
import { memo } from 'react';

import c from 'classnames';
import { Image, Placeholder, Video } from 'cloudinary-react';
import { Carousel, CarouselProps } from 'react-responsive-carousel';

import { ReactComponent as Arrow } from 'assets/arrow-right.svg';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './MultimediaViewer.module.scss';

type Media = {
  publicId: string;
  type: 'video' | 'image';
};

export type MultimediaViewerProps = {
  media: Media[];
  carouselProps?: CarouselProps;
};

export const MultimediaViewer = memo<MultimediaViewerProps>((props) => {
  const SlideArrowLeft = (clickHandler: () => void, hasPrev: boolean) => {
    return hasPrev ? (
      <div
        className={c(styles.ArrowContainer, styles.LeftArrowContainer)}
        onClick={clickHandler}
        onKeyUp={(e) => isKeyboardEvent(e) && clickHandler()}
        role="button"
        tabIndex={0}
      >
        <Arrow className={c(styles.ArrowIcon, styles.ArrowIconLeft)} />
      </div>
    ) : null;
  };

  const SlideArrowRight = (clickHandler: () => void, hasPrev: boolean) => {
    return hasPrev ? (
      <div
        className={c(styles.ArrowContainer, styles.RightArrowContainer)}
        onClick={clickHandler}
        onKeyUp={(e) => isKeyboardEvent(e) && clickHandler()}
        role="button"
        tabIndex={0}
      >
        <Arrow className={c(styles.ArrowIcon)} />
      </div>
    ) : null;
  };

  const createCarouselItem = (media: Media) => {
    const isVideo = media.type === 'video';
    const isImage = media.type === 'image';

    return (
      <div
        className={c(styles.MediaCarouselContainer, { [styles.VideoContainer]: isVideo })}
        key={media.publicId}
      >
        {isImage && (
          <Image
            className={styles.Media}
            loading="lazy"
            publicId={media.publicId}
            crop="scale"
            alt="User uploaded image"
          >
            <Placeholder type="pixelate" />
          </Image>
        )}
        {isVideo && (
          <Video
            className={c(styles.Media, styles.Video)}
            publicId={media.publicId}
            controls={{ playerControls: true }}
          />
        )}
      </div>
    );
  };
  return (
    <div data-testid="TestId__MULTIMEDIAVIEWERWRAPPER" className={styles.MultimediaViewerWrapper}>
      {props?.media?.length > 0 ? (
        <div className={styles.HasMediaContainer}>
          <Carousel
            className={styles.Carousel}
            autoFocus
            useKeyboardArrows
            {...props?.carouselProps}
            renderThumbs={
              props?.carouselProps?.renderThumbs ? props?.carouselProps.renderThumbs : () => []
            }
            renderArrowPrev={
              props?.carouselProps?.renderArrowPrev
                ? props?.carouselProps.renderArrowPrev
                : SlideArrowLeft
            }
            renderArrowNext={
              props?.carouselProps?.renderArrowNext
                ? props?.carouselProps.renderArrowNext
                : SlideArrowRight
            }
            showStatus={props?.carouselProps?.showStatus ? props?.carouselProps.showStatus : false}
          >
            {props?.media.map(createCarouselItem)}
          </Carousel>
        </div>
      ) : null}
    </div>
  );
});
