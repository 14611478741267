import { memo } from 'react';

import { Badge } from 'design-system/Atoms';
import coinsGif from 'design-system/assets/Gif/coin.gif';
import vaultIcon from 'design-system/assets/Icon/Vault/vault-item.svg';
import starsPng from 'design-system/assets/Png/stars.png';

export type RewardBadgeProps = {
  type?: string;
  vaultName?: string;
  coins?: number;
  stars?: number;
  ['data-testid']?: string;
};

export const RewardBadge: React.FunctionComponent<RewardBadgeProps> = memo(
  (props: RewardBadgeProps) => {
    let rewardOptions:
      | {
          icon: string;
          text: string;
        }
      | undefined;

    switch (props.type) {
      case 'coin':
        rewardOptions = {
          icon: coinsGif,
          text: `X${props.coins || 0}`,
        };
        break;
      case 'star':
        rewardOptions = {
          icon: starsPng,
          text: `X${props.stars || 0}`,
        };
        break;
      case 'vault item':
        rewardOptions = {
          icon: vaultIcon,
          text: props.vaultName || '',
        };
        break;
    }

    return (
      <>
        {rewardOptions && (
          <Badge
            type="regular"
            text={rewardOptions.text}
            showBackground
            showIcon
            interactive={false}
            iconSrc={rewardOptions.icon}
            data-testid={props['data-testid'] ?? 'TestId_REWARDBADGE'}
          />
        )}
      </>
    );
  },
);
