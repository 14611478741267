import { USER_ROLE } from 'consts/constants';
import { SessionModel } from 'types/types';

const {
  ADMIN,
  COMMUNITY_TEAM,
  MANAGER,
  PEOPLE_OPS,
  SUPER_ADMIN,
  RECRUITER,
  SUPER_WIZARD,
} = USER_ROLE;

export const isAuthenticated = (
  session: SessionModel | undefined,
): session is Required<SessionModel> => Boolean(session?._user);

export const hasRole = (
  session: SessionModel | undefined,
): session is Required<SessionModel> & { _user: Required<SessionModel['_user']> } =>
  isAuthenticated(session) && Boolean(session._user._role);

export const hasGivenRole = (session: SessionModel | undefined, role: string) =>
  hasRole(session) && session._user._role.name === role;

export const isAdmin = (session: SessionModel | undefined) => hasGivenRole(session, ADMIN);

export const isSuperAdmin = (session: SessionModel | undefined) =>
  hasGivenRole(session, SUPER_ADMIN);

export const isCommunityTeam = (session: SessionModel | undefined) =>
  hasGivenRole(session, COMMUNITY_TEAM);

export const isManager = (session: SessionModel | undefined) => hasGivenRole(session, MANAGER);

export const isPeopleOps = (session: SessionModel | undefined) => hasGivenRole(session, PEOPLE_OPS);

export const isRecruiter = (session: SessionModel | undefined) => hasGivenRole(session, RECRUITER);

export const isSuperWizard = (session: SessionModel | undefined) =>
  hasGivenRole(session, SUPER_WIZARD);

export const isActivated = (session: SessionModel | undefined) =>
  isAuthenticated(session) && Boolean(session._user.isActive);

export const getUserRole = (session: SessionModel | undefined): string =>
  (isAuthenticated(session) && session?._user?._role?.name) || '';
