import { memo } from 'react';

import cs from 'classnames';

import styles from './Face.module.scss';

export const moodMap = {
  verySad: {
    face: '😭',
    label: 'Very sad face',
  },
  sad: {
    face: '🙁',
    label: 'Sad face',
  },
  neutral: {
    face: '😐',
    label: 'Neutral face',
  },
  happy: {
    face: '🙂',
    label: 'Happy face',
  },
  veryHappy: {
    face: '😀',
    label: 'Very happy Face',
  },
};

export type Mood = keyof typeof moodMap;

export const Face = memo<{
  mood?: Mood;
  classname?: string;
}>((props) => {
  const pickedFace = moodMap[props.mood || 'neutral'];

  const faceClasses = cs(styles.Face, props.classname);

  return (
    <span
      data-testid="TestId__FACE"
      className={faceClasses}
      role="img"
      aria-label={pickedFace.label}
      children={pickedFace.face}
    />
  );
});
