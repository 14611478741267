import { memo } from 'react';

import classNames from 'classnames';

import { Badge, Card, Icon, Paragraph } from 'design-system/Atoms';
import { RewardBadge } from 'design-system/Molecules';

import styles from './BountiesCollectionTab.module.scss';

export const BountiesCollectionTab: React.FunctionComponent<BountiesCollectionTabProps> = memo(
  ({ collection, checked, isCompleted, isLocked, className, onClick }) => {
    const classes = classNames(styles.BountiesCollectionTab, className, {
      [styles.Checked]: checked,
      [styles.Locked]: isLocked,
    });

    const { icon, name, bountiesCount, unlockedBountiesCount, reward } = collection;

    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };

    return (
      <Card
        className={classes}
        role="button"
        data-testid={`BountiesCollectionTab-${collection.id}`}
        onClick={handleClick}
      >
        <div className={styles.Header}>
          <Paragraph className={styles.Title} data-testid="BountiesCollectionTitle">
            {name}
          </Paragraph>
          <Paragraph className={styles.ClaimNumbers} data-testid="BountiesCollectionClaimsNumbers">
            {unlockedBountiesCount}/{bountiesCount}
          </Paragraph>
        </div>
        <div className={styles.Body}>
          <div className={styles.Badge}>
            {icon?.location && (
              <img
                src={icon?.location}
                className={styles.CollectionIcon}
                alt="Bounties Collection Icon"
                data-testid="BountiesCollectionIcon"
              />
            )}
            {isCompleted ? (
              <Badge
                id="completed-badge"
                interactive={false}
                showIcon={false}
                text={'Completed'}
                type="completed"
                data-testid="CompleteBadge"
                showBackground
              />
            ) : (
              reward && <RewardBadge {...reward} data-testid="RewardBadge" />
            )}
          </div>
          {isLocked && (
            <div className={styles.Lock}>
              <Icon name="lock" data-testid="LockIcon" />
            </div>
          )}
        </div>
      </Card>
    );
  },
);

export type BountiesCollectionTabProps = {
  onClick: () => void;
  collection: {
    id?: number;
    name: string;
    bountiesCount: number;
    unlockedBountiesCount: number;
    icon?: icon;
    reward?: {
      type?: string;
      vaultName?: string;
      coins?: number;
      stars?: number;
    };
  };
  checked?: boolean;
  isCompleted: boolean;
  isLocked: boolean;
  className?: string;
};

type icon = {
  location: string;
};
