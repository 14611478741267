import { memo } from 'react';

import cx from 'classnames';

import { Image } from 'design-system/Atoms/Image';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './Avatar.module.scss';

export type AvatarVariant = 'red' | 'grey';

export type AvatarProps = {
  id?: string;
  accessibleName?: string;
  imgSrc?: string;
  name?: string;
  interactive?: boolean;
  className?: string;
  displayNameAfter?: boolean;
  variant?: AvatarVariant;
} & JSX.IntrinsicElements['div'];

const getNameInitials = (name: string) =>
  name
    .match(/(^\S\S?|\b\S)?/g)
    ?.join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toUpperCase();

export const Avatar = memo<AvatarProps>(({ displayNameAfter, ...props }) => {
  const showName = !!displayNameAfter && !!props.name;

  if (!showName) {
    return <AvatarImage {...props} />;
  } else {
    return (
      <div className={styles.AvatarWithName}>
        <AvatarImage className={styles.UserAvatar} {...props} />
        <Paragraph
          data-testid="TestId__AVATARNAMEAFTER"
          aria-label={props.accessibleName ?? props.name ?? 'Avatar'}
          variant="ui-regular"
        >
          {props.name}
        </Paragraph>
      </div>
    );
  }
});

function AvatarImage(props: AvatarProps) {
  const extraProps = props.interactive && {
    role: 'button',
    onClick: props.onClick,
  };

  const variant = props.variant ?? 'red';

  const avatarClasses = cx(
    styles.Avatar,
    {
      [styles.AvatarClickable]: props.interactive,
      [styles.RedVariant]: variant === 'red',
      [styles.GreyVariant]: variant === 'grey',
    },
    props.className,
  );

  return (
    <div
      data-testid="TestId__AVATARWRAPPER"
      className={avatarClasses}
      id={props.id}
      {...extraProps}
    >
      {props.name && !props.imgSrc && (
        <Paragraph
          data-testid="TestId__AVATARNAME"
          aria-label={props.accessibleName ?? 'Avatar'}
          className={styles.AvatarInitials}
          variant="bold"
        >
          {getNameInitials(props.name)}
        </Paragraph>
      )}

      {props.imgSrc && (
        <Image
          data-testid="TestId__AVATARIMAGE"
          overlayer
          variant="circle"
          className={styles.AvatarImageSource}
          src={props.imgSrc}
          alt={props.accessibleName ?? props.name ?? 'Avatar'}
        />
      )}
    </div>
  );
}
