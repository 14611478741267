import { memo } from 'react';

import HomeVideoModal from 'components/modals/HomeVideoModal';

import { Ads } from '../Ads';
import HomeCards from '../HomeCards';
import MainImage from '../MainImage';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './LiteDashboard.module.scss';

const LiteDashboard = () => {
  return (
    <div className={styles.Layout}>
      <div className={styles.MainContent}>
        <main className={styles.Main}>
          <div className={styles.News}>
            <MainImage />
            <HomeCards />
            <HomeVideoModal />
            <Ads />
          </div>
        </main>
      </div>
    </div>
  );
};

export default memo(LiteDashboard);
