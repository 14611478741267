import { FunctionComponent } from 'react';

import styles from './Columns.module.scss';

export const Columns: FunctionComponent = (props) => (
  <div data-testid="TestId__COLUMNS" className={styles.Columns}>
    {props.children}
  </div>
);

export const ColumnsFullRow: FunctionComponent = (props) => (
  <div data-testid="TestId__COLUMNS__FULL" className={styles.FullRow}>
    {props.children}
  </div>
);
