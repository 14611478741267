import { Fragment, memo, useEffect, useState } from 'react';

import { HourCard, HourForm, Paragraph, StepCard, StepCardButtons } from 'design-system';

import { SUBMIT_INVOICE } from 'consts/constants';
import { ContainerInfo } from 'design-system/Atoms/ContainerInfo';
import {
  HarvestAPITimeEntriesParams,
  ProductiveAPITimeEntriesParams,
} from 'pages/SubmitInvoice/SubmitInvoice';
import {
  Assignment,
  CurrencyName,
  InvoiceCategory,
  Invoice,
  InvoiceItem,
  StepActions,
} from 'types/types';
import { countBusinessDays } from 'utils/helpers';

import { brandConfig } from '../../../brands';
import useValiteInvoiceItems from '../../../utils/useValiteInvoiceItems';

import styles from './Step2.module.scss';

export interface Step2Props extends StepActions {
  assignments: Assignment[];
  harvestAssignments: Assignment[];
  productiveAssignments: Assignment[];
  currency: CurrencyName;
  invoiceItems: InvoiceItem[];
  hoursInvoiceCategory: InvoiceCategory;
  harvestEnabled: boolean;
  harvestFetchError?: string;
  invoice: Invoice;
  amount?: number;
  setAmount: (amount: number) => void;
  createInvoiceItem: (invoiceItemData: Partial<InvoiceItem & { _invoice: { id: number } }>) => void;
  updateInvoiceItem: (invoiceItemData: Partial<InvoiceItem>) => void;
  removeHarvestInvoiceItems: () => void;
  getHarvestAPITimeEntries: (data?: HarvestAPITimeEntriesParams) => Promise<void>;
  getProductiveAPITimeEntries: (
    data?: ProductiveAPITimeEntriesParams,
    hasChangedDatesStepOne?: boolean,
  ) => Promise<void>;
  removeProductiveInvoiceItems: () => void;
  productiveEnabled: boolean;
  productiveFetchError?: string;
}

export const Step2 = memo<Step2Props>(
  ({
    state,
    activeHandler,
    inactiveHandler,
    setAmount,
    currency,
    invoiceItems: invoiceItemsProps,
    invoice,
    amount = 0,
    assignments,
    harvestAssignments,
    harvestEnabled,
    harvestFetchError,
    getHarvestAPITimeEntries,
    removeHarvestInvoiceItems,
    hoursInvoiceCategory,
    createInvoiceItem,
    updateInvoiceItem,
    removeProductiveInvoiceItems,
    getProductiveAPITimeEntries,
    productiveEnabled,
    productiveFetchError,
    productiveAssignments,
  }) => {
    const { CONTENT } = SUBMIT_INVOICE;
    const step = CONTENT[2];
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>(invoiceItemsProps);
    const [stepCompleted, setStepCompleted] = useState(false);
    const [productiveFirstLoad, setProductiveFirstLoad] = useState(false);
    const businessDays = countBusinessDays(invoice.dateFrom, invoice.dateTo);
    const {
      validationState: { isValid },
      renderWarningModals,
      toggleOverLimitModal,
      overTimeApprover,
    } = useValiteInvoiceItems({
      invoiceItems,
      amount,
      businessDays,
      assignments,
      setAmount,
      state,
      harvestEnabled,
      hoursInvoiceCategoryId: hoursInvoiceCategory.id,
      productiveEnabled,
    });

    const onNextButtonClick = () => {
      if (!isValid && !harvestEnabled) return;
      setStepCompleted(true);
      activeHandler?.();
      invoiceItems.forEach((item) => {
        if (item.id) {
          updateInvoiceItem({ ...item, overTimeApprover });
          return;
        }
        createInvoiceItem({
          ...item,
          _invoice: {
            id: invoice.id,
          },
          overTimeApprover,
          _category: hoursInvoiceCategory,
        });
      });
    };

    const onEditButtonClick = () => {
      setStepCompleted(false);
      inactiveHandler?.();
    };

    const renderHourStepSubmit = () => {
      // Support channels configured at brand level - xhq/src/brands/brand-config.ts
      const supportChannels = () => {
        return (
          <div>
            <Paragraph variant="ui-regular" className={styles.question}>
              Couldn't find the answer? DM&nbsp;
              {brandConfig.contactsListForInvoices.map(({ name, url }, index) => {
                return (
                  <Fragment key={name}>
                    {url ? (
                      <a data-testid="invoicesContact" href={url}>
                        {name}
                      </a>
                    ) : (
                      <span>{name}</span>
                    )}
                    {index !== brandConfig.contactsListForInvoices.length - 1 ? ' or ' : ''}
                  </Fragment>
                );
              })}
            </Paragraph>
          </div>
        );
      };
      const renderFAQ = () => {
        return (
          <div>
            {step.questions?.map((item, index) => (
              <Fragment key={index}>
                <Paragraph variant="ui-regular" className={styles.question}>
                  {item.question}
                </Paragraph>
                <Paragraph variant="ui-regular" className={styles.questionLink}>
                  <a href={item?.url ? item.url : '/'}>{item.link}</a>
                </Paragraph>
                <br />
              </Fragment>
            ))}
            {supportChannels()}
          </div>
        );
      };

      const renderWarningAboutExpenses = () => {
        return (
          <ContainerInfo
            text={step?.warning || ''}
            textAlignment={'center'}
            classNames={styles.warning}
          />
        );
      };

      const renderHarvestErrorWarning = () => {
        return (
          <div className={styles.nonExistingProjectsContainer}>
            <span
              className={styles.nonExistingProjectsText}
              data-testid="TestId__STEP2_HARVEST_FETCH_ERROR"
            >
              {harvestFetchError}
            </span>
          </div>
        );
      };

      const renderProductiveErrorWarning = () => {
        return (
          <div className={styles.nonExistingProjectsContainer}>
            <span
              className={styles.nonExistingProjectsText}
              data-testid="TestId__STEP2_PRODUCTIVE_FETCH_ERROR"
            >
              {productiveFetchError}
            </span>
          </div>
        );
      };

      return (
        <>
          {!stepCompleted && (
            <>
              {renderFAQ()}
              {renderWarningAboutExpenses()}
              {!!harvestFetchError && renderHarvestErrorWarning()}
              {!!productiveFetchError && renderProductiveErrorWarning()}
              <HourForm
                assignments={assignments}
                harvestAssignments={harvestEnabled ? harvestAssignments : []}
                productiveAssignments={productiveEnabled ? productiveAssignments : []}
                overTimeApprover={overTimeApprover}
                invoiceItems={invoiceItems}
                currency={currency}
                updateInvoices={setInvoiceItems}
                businessDays={businessDays}
                toggleOverLimitModal={toggleOverLimitModal}
              />
            </>
          )}
        </>
      );
    };

    const handleHourStepReview = (invoiceItemParam: Partial<InvoiceItem>) => {
      const updatedInvoiceItems = [...invoiceItems].map((invoiceItem) => {
        if (invoiceItem.id === invoiceItemParam.id) {
          return invoiceItemParam;
        }

        return invoiceItem;
      });
      setInvoiceItems(updatedInvoiceItems as InvoiceItem[]);
    };

    const renderHourStepSubmitReview = () =>
      invoiceItems.map((invoiceItem, index) => (
        <HourCard
          key={index}
          canEdit={false}
          invoiceItem={invoiceItem}
          currency={currency}
          onSubmit={handleHourStepReview}
        />
      ));

    useEffect(() => {
      if (invoiceItemsProps) {
        setInvoiceItems(invoiceItemsProps);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceItemsProps?.length]);

    useEffect(() => {
      (async () => {
        if (harvestEnabled) {
          await getHarvestAPITimeEntries();
          removeHarvestInvoiceItems();
        }
        if (productiveEnabled && !productiveFirstLoad) {
          removeProductiveInvoiceItems();
          await getProductiveAPITimeEntries();
          setProductiveFirstLoad(true);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      (async () => {
        if (productiveEnabled && productiveFirstLoad) {
          removeProductiveInvoiceItems();
          await getProductiveAPITimeEntries(undefined, true);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice.dateFrom]);

    return (
      <StepCard
        step={step.step}
        title={step.title}
        state={state}
        bottomContent={
          state !== 'pending' ? (
            <StepCardButtons
              state={isValid ? state : 'disabled'}
              text={state === 'active' ? 'Next' : 'Edit'}
              onClick={state === 'active' ? onNextButtonClick : onEditButtonClick}
            />
          ) : null
        }
        singleColumn
      >
        {state === 'active' && (
          <Paragraph variant="ui-bold" className={styles.question}>
            {step.description}
          </Paragraph>
        )}
        {!stepCompleted && state === 'active' && renderHourStepSubmit()}
        {state !== 'active' && invoiceItems && renderHourStepSubmitReview()}
        {renderWarningModals()}
      </StepCard>
    );
  },
);
