import { memo, useMemo } from 'react';

import { Link } from 'react-router-dom';

import ViewOnlyVaultItem from 'components/vault/ViewOnlyVaultItem';
import { VaultItemModel, VaultItemTypes, RarityTypes, File } from 'types/types';
import { useGetMyHomeVaultItems } from 'utils/apiQueryHooks';

import styles from './HomeVaultItems.module.scss';

export const HomeVaultItems = memo(() => {
  const { resolvedData: recentVaultItems } = useGetMyHomeVaultItems();

  const vaultItems = useMemo(
    () =>
      recentVaultItems?.data.map((item) => {
        const vaultItem: VaultItemModel = {
          id: item.id,
          coinPrice: item.coinPrice,
          images: item.images as File[],
          description: item.description,
          itemName: item.itemName,
          _vaultItemType: {
            id: item._vaultItemType?.id,
            name: item._vaultItemType?.name as VaultItemTypes,
          },
          starPrice: 0,
          stock: item.stock,
          _whenStockIsZero: { id: 1, name: '' },
          _whenStockIsZeroId: 1,
          _team: item._team,
          _rarity: {
            id: item._rarity.id,
            name: item._rarity.name as RarityTypes,
          },
          _printfulVariants: item._printfulVariants,
        };

        return vaultItem;
      }),
    [recentVaultItems],
  );

  return (
    <section className={styles.VaultItems}>
      <h3 className={styles.sectionTitle}>Collect all the things</h3>
      <Link to="/vault" className={styles.titleLink}>
        The Vault
      </Link>
      <div className={styles.vaultItemsGrid}>
        {vaultItems?.map((item) => (
          <Link
            className={styles.VaultItemLink}
            key={item.id}
            to={{ pathname: '/vault', state: { activeVaultItem: item } }}
          >
            <ViewOnlyVaultItem isHome item={item} />
          </Link>
        ))}
      </div>
    </section>
  );
});
