import { useState, useRef, useCallback, useEffect, memo, KeyboardEvent } from 'react';

import c from 'classnames';
import Avatar from 'react-avatar';
import { useDispatch } from 'react-redux';

import { logOutActionCreator, removeAllToasts } from 'store/actionCreators';
import { useCurrentUser, useOnClickOutside } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import { Logout } from '../images';
import { UserProfileMenuOptions } from './UserProfileMenuOptions';

import styles from './ProfileMenu.module.scss';

export const ProfileMenu = memo(() => {
  const [opened, setOpened] = useState(false);
  const [hidden, setHidden] = useState(true);
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const menuRef = useRef(null);

  const handleLogOut = useCallback(() => {
    dispatch(removeAllToasts());
    dispatch(logOutActionCreator());
  }, [dispatch]);

  const open = useCallback(() => {
    setOpened(true);
    setHidden(false);
  }, [setOpened, setHidden]);

  const close = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    if (!opened) {
      setHidden(true);
    }
  }, [opened]);

  const toggleOpened = useCallback(() => {
    if (opened) {
      close();
    } else {
      open();
    }
  }, [close, open, opened]);

  useOnClickOutside(menuRef, close);

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Enter':
        toggleOpened();
        break;
      default:
        return;
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div
      data-testid="menuProfile"
      role="button"
      tabIndex={0}
      ref={menuRef}
      className={c(styles.ProfileMenu, { [styles.opened]: opened })}
      onClick={toggleOpened}
      onKeyUp={(e) => isKeyboardEvent(e) && handleKeyPress(e)}
    >
      <div className={styles.Profile} role="menu">
        <Avatar round src={user.avatarUrl} name={user.displayName} maxInitials={2} size="40px" />
        <div className={styles.ProfileChevron} />
      </div>

      <div
        data-testid="menuProfileButton"
        className={c(styles.Overlay, { [styles.hidden]: hidden })}
        onClick={close}
        onKeyUp={(e) => isKeyboardEvent(e) && close()}
        role="button"
        tabIndex={0}
      />

      <div className={styles.Menu}>
        <div className={c(styles.MenuSection, styles.Info)}>
          <Avatar round src={user.avatarUrl} name={user.displayName} maxInitials={2} size="32px" />
          <div className={styles.Text}>
            <div className={styles.Name}>{user.displayName}</div>
            <div className={styles.Email}>{user.email}</div>
          </div>
        </div>

        <UserProfileMenuOptions toggleOpened={toggleOpened} />

        <div className={c(styles.MenuSection, styles.Links)}>
          <button
            data-testid="menuProfileLogout"
            className={styles.Link}
            tabIndex={0}
            onClick={handleLogOut}
          >
            <Logout className={styles.LogoutIcon} />
            <div className={styles.LinkText}>Logout</div>
          </button>
        </div>
      </div>
    </div>
  );
});
