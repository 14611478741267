import { brandConfig, brandDictionary } from 'brands';

import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';

export const shouldRenderRunPaymentsPage = (): boolean =>
  // both flags need to be ON to hide page
  !isFeatureEnabled(FEATURE_FLAGS.xhqNetsuiteIntegration) ||
  !isFeatureEnabled(FEATURE_FLAGS.xhqAirwallexToNetsuite) ||
  brandConfig.brandName === brandDictionary.xwp;

export const isNetsuiteEnabled = () =>
  isFeatureEnabled(FEATURE_FLAGS.xhqNetsuiteIntegration) &&
  brandConfig.brandName === brandDictionary.xteam;

export const isNewProjectExpensesEnabled = () =>
  isFeatureEnabled(FEATURE_FLAGS.xhqProjectExpenses) &&
  brandConfig.brandName === brandDictionary.xteam;

export const shouldSkipXHQtoAirwallexFlow = () =>
  brandConfig.brandName === brandDictionary.xteam &&
  isFeatureEnabled(FEATURE_FLAGS.xhqNetsuiteIntegration) &&
  isFeatureEnabled(FEATURE_FLAGS.xhqAirwallexToNetsuite);
