import { Button } from 'design-system';
import { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import ReactDOM from 'react-dom';

import { isKeyboardEvent } from 'design-system/utils';

import styles from './MobileDatePickerActions.module.scss';

type DateRange = {
  startDate?: Moment | null;
  endDate?: Moment | null;
};

interface MobileDatePickerActionsProps {
  innerStartDate: Moment | null;
  innerEndDate: Moment | null;
  onDateRangeChange: (dateRange: DateRange) => void;
  onFocusedInputChange: (focusedInput: FocusedInputShape | null) => void;
}

const MobileDatePickerActions: React.FC<MobileDatePickerActionsProps> = ({
  innerStartDate,
  innerEndDate,
  onDateRangeChange,
  onFocusedInputChange,
}) => {
  const onClear = () => {
    onDateRangeChange({ startDate: null, endDate: null });
    onFocusedInputChange(START_DATE);
  };

  const onClose = () => {
    onFocusedInputChange(null);
  };

  return ReactDOM.createPortal(
    <div>
      <Button
        as="button"
        layout="autolayout"
        variant="secondary"
        className={styles.XhqDateRangePicker__mobile_controls__clearButton}
        onClick={onClear}
      >
        Clear
      </Button>

      <Button
        as="button"
        layout="autolayout"
        variant="icon"
        iconName="close-big"
        className={styles.XhqDateRangePicker__mobile_controls__closeButton}
        onClick={onClose}
      />

      <div className={styles.XhqDateRangePicker__mobile_controls__footer}>
        <Button
          as="button"
          layout="fluid"
          variant="primary"
          disabled={!innerStartDate || !innerEndDate}
          onClick={onClose}
          onKeyUp={(e) => isKeyboardEvent(e) && onClose()}
        >
          Apply
        </Button>
      </div>
    </div>,
    document.body,
  );
};

export default MobileDatePickerActions;
