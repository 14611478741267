import { memo, useState } from 'react';

import cs from 'classnames';
import {
  Avatar,
  Badge,
  Card,
  Divider,
  FilesUploader,
  Heading,
  Icon,
  Modal,
  Paragraph,
  useIsMobile,
} from 'design-system';

import { INVOICE_STATUS } from 'consts/constants';
import { CurrencyName, File, InvoiceItem } from 'types/types';
import { formatDate } from 'utils/datesHelper';

import styles from './EditableReimbursementBrief.module.scss';

export type EditableReimbursementBriefProps = {
  reimbursement: Partial<InvoiceItem>;
  referralName?: string;
  onDelete?: (id: number) => void;
  onRemoveFile?: (invoiceItemId: number, updatedFiles: File[]) => Promise<void>;
  onEdit?: () => void;
  updateReimbursement?: (values: any) => void;
  currency: CurrencyName;
  isDarkModeSelected?: boolean;
};

export const EditableReimbursementBrief = memo<EditableReimbursementBriefProps>(
  ({ reimbursement, onDelete, onRemoveFile, ...props }) => {
    const uploadedFiles = reimbursement.files?.map((file) => file.originalName) || [];
    const [deleteFileStatus, setDeleteFileStatus] = useState({
      displayPrompt: false,
      fileIndex: -1,
    });
    const [shouldShowDeleteItemConfirmationModal, setShowDeleteItemConfirmationModal] = useState(
      false,
    );

    const unitPrice = reimbursement?.usdPrice || reimbursement?.rate || 0;
    const quantity = reimbursement?.quantity || 0;
    const reimbursementType = reimbursement?._category?.name;
    const isEditable =
      reimbursement?._status?.name === INVOICE_STATUS.SUBMITTED ||
      reimbursement?._status?.name === INVOICE_STATUS.DRAFT;
    const submittedTime =
      reimbursement.addedAt && formatDate(new Date(reimbursement.addedAt), 'PPp');
    const approver = reimbursement?._approvedBy?.displayName;
    const approvedTime =
      reimbursement.approvedAt && formatDate(new Date(reimbursement.approvedAt), 'PPp');
    const payer = reimbursement?._paidBy?.displayName;
    const paidTime = reimbursement.paidAt && formatDate(new Date(reimbursement.paidAt), 'PPp');
    const unleashCategory = reimbursement?._unleashCategory?.name;

    const isMobile = useIsMobile();

    const removeInvoiceItem = () => {
      onDelete?.(reimbursement.id || 0);
    };

    const deleteInvoiceItemFile = (_index: number) => {
      const updatedFiles = reimbursement.files?.filter((_, index) => index !== _index) || [];
      onRemoveFile?.(reimbursement.id || 0, updatedFiles);
      setDeleteFileStatus({ displayPrompt: false, fileIndex: -1 });
    };

    return (
      <Card className={cs(styles.Card, { [styles.notEditable]: !isEditable })}>
        <div className={styles.Container} data-testid="TestId__EDITABLEREIMBURSEMENTBRIEF_ITEM">
          {submittedTime && (
            <Paragraph
              className={cs(styles.Status, styles.Submitted)}
            >{`Submitted (${submittedTime})`}</Paragraph>
          )}
          {!!approver && (
            <Paragraph
              className={cs(styles.Status, styles.Approved)}
            >{`Approved by ${approver} (${approvedTime})`}</Paragraph>
          )}
          {!!payer && (
            <Paragraph
              className={cs(styles.Status, styles.Paid)}
            >{`Paid by ${payer} (${paidTime})`}</Paragraph>
          )}
          <ul className={styles.Types}>
            {!!reimbursementType && (
              <li>
                <Badge
                  key={reimbursementType}
                  id={reimbursementType}
                  type="common"
                  text={reimbursementType}
                  showBackground
                  showIcon={false}
                  interactive={false}
                  spanClassName={styles.Type}
                  paragraphClassName={styles.TypeText}
                />
              </li>
            )}
            {!!unleashCategory && (
              <li>
                <Badge
                  key={unleashCategory}
                  id={unleashCategory}
                  type="common"
                  text={unleashCategory}
                  showBackground
                  showIcon={false}
                  interactive={false}
                  spanClassName={styles.Type}
                  paragraphClassName={styles.TypeText}
                />
              </li>
            )}
          </ul>
          {props.referralName && (
            <div className={styles.ReferralContainer}>
              <Avatar imgSrc={'https://thispersondoesnotexist.com/image'} />
              <Paragraph className={styles.ReferralName} variant="ui-regular">
                {props.referralName}
              </Paragraph>
            </div>
          )}
          <div>
            <FilesUploader
              isEditable={isEditable}
              dropSectionClassName={styles.DropSection}
              uploadedFiles={uploadedFiles}
              fileContainerClassName={props.isDarkModeSelected ? '' : styles.FileContainer}
              removeFile={(index: number) => {
                setDeleteFileStatus({ displayPrompt: true, fileIndex: index });
              }}
            />
          </div>
          <Paragraph className={styles.Description} variant="ui-regular">
            {reimbursement.notes}
          </Paragraph>
        </div>
        {isMobile && <Divider className={styles.MobileDivider} />}
        <div className={styles.RightContainer}>
          <div className={styles.AmountsContainer}>
            <Paragraph className={styles.AmountsTotal} variant="ui-bold">
              {`${quantity * unitPrice} ${props.currency}`}
            </Paragraph>
            <Paragraph className={styles.AmountsDetail} variant="ui-regular">
              {`${quantity} x ${unitPrice} ${props.currency}`}
            </Paragraph>
          </div>
          {isEditable && (
            <div
              data-testid="TestId__EDITABLEREIMBURSEMENTBRIEF__ACTIONSCONTAINER"
              className={cs(styles.ActionsContainer, {
                [styles.ActionsContainerDarkTheme]: props.isDarkModeSelected,
              })}
            >
              <>
                <Icon
                  data-testid="TestId__EDITABLEREIMBURSEMENTBRIEF__EDITBUTTON"
                  className={styles.Edit}
                  name={'edit'}
                  onClick={props.onEdit}
                />
                <Icon
                  data-testid="TestId__EDITABLEREIMBURSEMENTBRIEF__DELETEBUTTON"
                  name={'trash-outline'}
                  onClick={() => {
                    setShowDeleteItemConfirmationModal(true);
                  }}
                />
              </>
            </div>
          )}
        </div>
        {deleteFileStatus.displayPrompt && (
          <Modal
            primaryButtonContent="Delete"
            onClose={() => setDeleteFileStatus({ displayPrompt: false, fileIndex: -1 })}
            onSubmit={() => deleteInvoiceItemFile(deleteFileStatus.fileIndex)}
            footerClassName={styles.ModalFooter}
          >
            <Heading level="h5" className={styles.ModalTitle}>
              Delete file
            </Heading>
            <Paragraph variant="regular" className={styles.ModalDescription}>
              Are you sure you want to delete this file?
            </Paragraph>
          </Modal>
        )}
        {shouldShowDeleteItemConfirmationModal && (
          <Modal
            primaryButtonContent="Delete"
            onClose={() => setShowDeleteItemConfirmationModal(false)}
            onSubmit={() => removeInvoiceItem()}
            footerClassName={styles.ModalFooter}
          >
            <Heading level="h5" className={styles.ModalTitle}>
              Delete invoice item
            </Heading>
            <Paragraph variant="regular" className={styles.ModalDescription}>
              Are you sure you want to delete this invoice item?
            </Paragraph>
          </Modal>
        )}
      </Card>
    );
  },
);
