import { SelectOption } from 'design-system/Atoms/Select/Select.helper';
import { File, InvoiceItem, UnleashBudget, UnleashCategory } from 'types/types';

export interface ReimbursementCardForm {
  project?: SelectOption;
  reimbursementType?: SelectOption;
  unleashCategory?: SelectOption;
  overTimeApprover?: string;
  notes?: string;
  quantity?: number;
  unitPrice?: number;
  referral?: SelectOption;
  files?: File[];
}

const optionalFieldsUnleash = ['notes', 'referral', 'overTimeApprover', 'files'];
const optionalFieldsReferral = ['notes', 'overTimeApprover', 'files', 'unleashCategory'];

export const areRequiredFieldsFilled = (formValues: ReimbursementCardForm): boolean => {
  const optionalFields =
    formValues.reimbursementType?.label?.toLowerCase() === 'unleash+'
      ? optionalFieldsUnleash
      : optionalFieldsReferral;
  return Object.keys(formValues).every((fieldName) => {
    const value = formValues[fieldName as keyof ReimbursementCardForm];
    if (optionalFields.includes(fieldName)) return true;
    return !!value;
  });
};

const formatSelectOption = (id?: number, name?: string): SelectOption | undefined =>
  !id || !name
    ? undefined
    : {
        value: id,
        label: name,
      };

export const formatInitialValues = (
  reimbursement: Partial<InvoiceItem>,
  referrals?: SelectOption[],
): ReimbursementCardForm => {
  const {
    _project,
    _category,
    _unleashCategory,
    name: referralId,
    notes,
    usdPrice: unitPrice,
    quantity,
    overTimeApprover,
    files,
  } = reimbursement;
  const project = formatSelectOption(_project?.id, _project?.name);
  const reimbursementType = formatSelectOption(_category?.id, _category?.name);
  const unleashCategory = formatSelectOption(_unleashCategory?.id, _unleashCategory?.name);
  const referral = referrals?.find((referral) => referral.value === Number(referralId));

  return {
    notes,
    unitPrice,
    overTimeApprover,
    quantity,
    files,
    project,
    reimbursementType,
    referral,
    unleashCategory,
  };
};

export const formatSelectPayload = (
  fieldName: keyof ReimbursementCardForm,
  option: SelectOption,
): Partial<InvoiceItem> => {
  const id = Number(option?.value);
  if (!id) return {};
  switch (fieldName) {
    case 'project':
      return {
        _project: { id },
      };
    case 'reimbursementType':
      return {
        _category: { id },
      };
    case 'unleashCategory':
      return {
        _unleashCategory: { id },
      };
    case 'referral':
      return {
        name: String(option?.value),
      };
    default:
      return {};
  }
};

export const formatInputPayload = (
  fieldName: keyof ReimbursementCardForm,
  formValues: ReimbursementCardForm,
): Partial<InvoiceItem> => {
  switch (fieldName) {
    case 'notes':
      return {
        notes: formValues?.notes,
      };
    case 'quantity':
      return {
        quantity: Number(formValues?.quantity),
      };
    case 'overTimeApprover':
      return {
        overTimeApprover: formValues?.overTimeApprover,
      };
    case 'files':
      return {
        files: formValues?.files,
      };
    case 'unitPrice':
      return {
        usdPrice: Number(formValues?.unitPrice),
        rate: Number(formValues?.unitPrice),
      };
    default:
      return {};
  }
};

export const getFullPayload = (formValues: ReimbursementCardForm) => {
  const selectPayload = Object.keys(formValues).reduce((acc, fieldName) => {
    const key = fieldName as keyof ReimbursementCardForm;
    const selectPayload = formatSelectPayload(key, formValues[key] as SelectOption);
    return {
      ...acc,
      ...selectPayload,
    };
  }, {});
  const inputPayload = Object.keys(formValues).reduce((acc, fieldName) => {
    const inputPayload = formatInputPayload(fieldName as keyof ReimbursementCardForm, formValues);
    return {
      ...acc,
      ...inputPayload,
    };
  }, {});

  return {
    ...selectPayload,
    ...inputPayload,
  };
};

export const isValidBonusCategory = (
  unleashBudget: UnleashBudget,
  unleashCategory: UnleashCategory,
) => {
  if (!unleashCategory.isBonusCategory) return true;

  const bonuses = unleashBudget.bonuses;
  const categoryBonus:
    | (Omit<UnleashBudget, 'bonuses' | 'category'> & {
        unleashCategory: UnleashCategory;
        used?: number;
      })
    | undefined = bonuses.find((bonus) => bonus.unleashCategory.id === unleashCategory.id);

  if (!categoryBonus) return false;

  const bonusRemaining = categoryBonus.total - Number(categoryBonus.used);
  if (bonusRemaining > 0) return true;

  return false;
};
