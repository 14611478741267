import { memo, MouseEventHandler, PropsWithChildren } from 'react';

import cx from 'classnames';

import { mimicKeyboardClick } from 'utils/keyboardEvent';

import styles from './Tile.module.scss';

export interface TileProps {
  active: boolean;
  onClick?: MouseEventHandler;
  tabIndex?: HTMLElement['tabIndex'];
}

export const Tile = memo<PropsWithChildren<TileProps>>((props: PropsWithChildren<TileProps>) => {
  const TileStyle = cx(styles.Tile, {
    [styles.Active]: props.active,
  });

  return (
    <div
      role="button"
      tabIndex={props.tabIndex}
      className={TileStyle}
      onKeyUp={mimicKeyboardClick(props.onClick)}
      onClick={props.onClick}
      data-testid="TestId__TILE"
    >
      {props.children}
    </div>
  );
});
