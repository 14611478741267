import { PropsWithChildren, memo, useRef, useState, useEffect, useCallback } from 'react';

import cx from 'classnames';
import { Button } from 'design-system';
import YouTubePlayer from 'youtube-player';

import { YOUTUBE_VIDEO_STATE } from 'consts/constants';

import styles from './Youtube.module.scss';

export type YoutubeProps = PropsWithChildren<{
  videoId: string;
  title: string;
  show?: boolean;
  continueWhilePlaying?: boolean;
}>;

export const Youtube = memo(
  ({ videoId, title, show = true, continueWhilePlaying = false }: YoutubeProps) => {
    const [playerState, setPlayerState] = useState(YOUTUBE_VIDEO_STATE.ENDED);
    const [player, setPlayer] = useState<ReturnType<typeof YouTubePlayer> | null>(null);
    const playerRef = useRef<HTMLIFrameElement>(null);

    const onPlayerStateChange = useCallback((event: { data: number }) => {
      setPlayerState(event.data);
    }, []);

    useEffect(() => {
      if (!player && playerRef.current) {
        const ytPlayer = YouTubePlayer(playerRef.current);
        ytPlayer.on('stateChange', onPlayerStateChange);
        setPlayer(ytPlayer);
      }
    }, [player, playerRef, onPlayerStateChange]);

    useEffect(() => {
      if (!show && !continueWhilePlaying) {
        player?.stopVideo();
      }
    }, [show, continueWhilePlaying, player]);

    const forceShowWhilePlaying = !show && continueWhilePlaying;
    const showWhilePlaying = continueWhilePlaying && playerState === YOUTUBE_VIDEO_STATE.PLAYING;
    const hideWhenNotPlaying = !(show || showWhilePlaying);

    const containerClasses = cx({ [styles.Hide]: hideWhenNotPlaying });
    const videoEmbedClasses = cx(styles.VideoEmbed, {
      [styles.PopupPlayer]: forceShowWhilePlaying,
    });
    const closeButtonClasses = cx({ [styles.ClosePopupButton]: forceShowWhilePlaying });

    const handleCloseButtonClick = () => {
      player?.stopVideo();
    };

    return (
      <div data-testid="TestId__YOUTUBE__WRAPPER" className={containerClasses}>
        <div data-testid="TestId__YOUTUBE__VIDEO__EMBED" className={videoEmbedClasses}>
          <iframe
            role="presentation"
            ref={playerRef}
            id="youtube-iframe"
            seamless
            src={`//www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={title}
          />
          {forceShowWhilePlaying && (
            <Button
              data-testid="TestId__YOUTUBE__VIDEO__CLOSEBUTTON"
              className={closeButtonClasses}
              as="button"
              iconName="close"
              iconPosition="start"
              layout="autolayout"
              variant="icon"
              onClick={handleCloseButtonClick}
            />
          )}
        </div>
      </div>
    );
  },
);
