import * as Rox from 'rox-browser';

const FEATURE_FLAGS = {
  newInvoice: 'newInvoice',
  xTeamLogo: 'xTeamLogo',
  productive: 'productive.io',
  newBountiesUI: 'newBounties-UI-Update',
  fastUsersEndpoint: 'fastUsersEndpoint',
  newRunPayments: 'newRunPayments',
  newUsers: 'newUsers',
  newProjects: 'newProjects',
  newCompanies: 'newCompanies',
  newAccounting: 'newAccounting',
  newVacationRequests: 'newVacationRequests',
  newGroups: 'newGroups',
  xhqProjectDepartments: 'xhqProjectDepartments',
  xhqProjectExpenses: 'xhqProjectExpenses',
  xhqNetsuiteIntegration: 'xhqNetsuiteIntegration',
  xhqAirwallexToNetsuite: 'xhqAirwallexToNetsuite',
};
const USER_TARGET = 'userId';

export const handleRoxDynamicApiIsEnable = (flag: string, value: boolean): boolean => {
  try {
    return Rox.dynamicApi?.isEnabled(flag, value);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const handleRoxDynamicApiValue = (flag: string, value: string): string => {
  try {
    return Rox.dynamicApi?.value(flag, value);
  } catch (error) {
    console.error(error);
    return '';
  }
};

export async function initializeFeatureFlags() {
  if (process.env.REACT_APP_FEATURE_FLAG_API_KEY)
    await Rox.setup(process.env.REACT_APP_FEATURE_FLAG_API_KEY, {});
}

export function isFeatureEnabled(flag: string): boolean {
  return process.env.REACT_APP_FEATURE_FLAG_API_KEY
    ? handleRoxDynamicApiIsEnable(flag, false)
    : false;
}

export function getStringFeatureFlagValue(flag: string): string {
  return process.env.REACT_APP_FEATURE_FLAG_API_KEY ? handleRoxDynamicApiValue(flag, '') : '';
}

export function targetUser(userId: string) {
  if (process.env.REACT_APP_FEATURE_FLAG_API_KEY) Rox.setCustomStringProperty(USER_TARGET, userId);
}

export default FEATURE_FLAGS;
