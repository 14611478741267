import { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { userLoggingInActionCreator, userLoggedInActionCreator } from 'store/actionCreators';
import { useValidateEmailTokenLink } from 'utils/apiMutationHooks';
import { getErrorMessage } from 'utils/helpers';
import { useToast } from 'utils/hooks';

export const ValidateEmailTokenLink = memo(() => {
  const {
    params: { token },
  } = useRouteMatch<{ token: string }>();
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const toast = useToast();

  const [validateEmailTokenLink] = useValidateEmailTokenLink({ useErrorBoundary: false });

  useEffect(() => {
    const run = async () => {
      dispatch(userLoggingInActionCreator());
      try {
        const session = await validateEmailTokenLink({ body: { token } });
        if (!session) {
          throw new Error();
        }
        dispatch(userLoggedInActionCreator(session));
      } catch (error) {
        toast({
          text: getErrorMessage(error) || 'Error logging in',
          type: 'error',
        });
        replace('/login');
      }
    };
    run();
  }, [dispatch, replace, toast, token, validateEmailTokenLink]);

  return null;
});
ValidateEmailTokenLink.displayName = 'ValidateEmailTokenLink';

export default ValidateEmailTokenLink;
