import { HTMLAttributes, memo, ReactNode } from 'react';

import cx from 'classnames';

import styles from './TableItem.module.scss';
export interface TableItemProps extends HTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode;
  className?: string;
  testId?: string;
  colSpan?: number;
}

export const TableItem = memo<TableItemProps>(({ children, className, testId, colSpan }) => {
  return (
    <td
      data-testid={testId || 'table-item'}
      className={cx(styles.TableCell, className)}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
});
