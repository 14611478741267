import { FormikProps } from 'formik';
import { get, isEmpty, map } from 'lodash';

import { DisplayError } from 'components/Error';
import CountrySelect from 'components/inputs/CountrySelect';
import Input from 'components/inputs/Input';
import PaymentTypesSelect from 'components/inputs/PaymentTypeSelect';
import Textarea from 'components/inputs/Textarea';
import Row from 'components/table/Row';
import { paymentMethodsMap } from 'consts/constants';

import { IPaymentFormik, IPaymentInput, IPaymentValues } from '../Payment/Payment.types';

import styles from './EditPayment.module.scss';

type PaymentTypes = 'paymentBankAddress' | 'paymentAccountOwnerAddress';

const EditPayment = ({
  isValid,
  isSubmitting,
  values,
  handleSubmit,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  cancelButton,
  errors,
  dirty,
  touched,
}: IPaymentFormik & FormikProps<IPaymentValues>) => {
  const renderField = (field: string, input: IPaymentInput, fieldName: PaymentTypes) => {
    const paymentTypeName = get(values._paymentType, 'name');

    if (!paymentTypeName) {
      return null;
    }

    return (
      <div className={styles.Column} key={`${paymentTypeName}_${field}`}>
        <div className={styles.LabelText}>{input.label}</div>
        <div>
          {input.type === 'dropdown' &&
          ((fieldName as string) === 'paymentAccountOwnerCountry' ||
            (fieldName as string) === 'paymentBankCountryCode') ? (
            <CountrySelect
              id={field}
              name={field}
              className={styles.Input}
              value={get(values, field, '')}
              onChange={(value) => handleChange({ target: { id: field, name: field, value } })}
              onBlur={handleBlur}
              isSemiFullWidth
            />
          ) : (
            <Input
              isPayment
              className={styles.Input}
              type={input.type}
              value={get(values, field, '')}
              id={field}
              name={field}
              data-testid={field}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </div>

        {errors[fieldName] && touched.info && touched.info[fieldName] && (
          <Row>
            <DisplayError error={errors[fieldName]} />
          </Row>
        )}
      </div>
    );
  };

  const renderFieldsForPaymentType = () => {
    const name = get(values._paymentType, 'name');
    if (!name) {
      return null;
    }

    return map(paymentMethodsMap[name].fields, (input, field) =>
      renderField('info.' + field, input, field as PaymentTypes),
    );
  };

  const { _paymentType } = values;
  const shouldDisableSubmit = !isValid || !dirty || !isEmpty(errors) || isSubmitting;

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.Table}>
        <div className={styles.Column}>
          <div className={styles.LabelText}>Payment Via</div>
          <div>
            <PaymentTypesSelect
              isPayment
              isSemiFullWidth
              value={_paymentType?.id}
              onChange={(value) => {
                setFieldValue('_paymentType', value);
                setFieldValue('method', value.name);
              }}
              onBlur={() => setFieldTouched('_paymentType')}
              placeholder="Payment Method"
            />
          </div>
        </div>

        {renderFieldsForPaymentType()}

        <div className={styles.FullColumn}>
          <div className={styles.LabelText}>Additional Banking Notes</div>
          <div>
            <Textarea
              className={styles.Textarea}
              noPadding
              isPayment
              name="notes"
              value={values.notes || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter notes..."
              data-testid="info.paymentNotes"
            />
          </div>
        </div>
      </div>

      <div className={styles.ButtonWrapper} id="button">
        {cancelButton}
        <button className={styles.SaveButton} disabled={shouldDisableSubmit} type="submit">
          Confirm Changes
        </button>
      </div>
    </form>
  );
};

export default EditPayment;
