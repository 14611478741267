/* eslint-disable css-modules/no-unused-class */

import { memo } from 'react';

import cs from 'classnames';

import styles from './Spacing.module.scss';

export interface SpacingProps {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  align: 'start' | 'center' | 'end' | 'baseline';
  direction: 'row' | 'column';
}

export const Spacing = memo<SpacingProps>(({ children, size, align, direction }) => {
  return (
    <div
      className={cs(
        styles.spacing,
        styles[`spacing--${size}`],
        styles[`spacing--${align}`],
        styles[`spacing--${direction}`],
      )}
    >
      {children}
    </div>
  );
});
