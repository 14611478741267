import { createContext, ReactNode, useContext, useState } from 'react';

import { UsersPaginationContext } from '../types/users';

interface UsersPaginationProviderProps {
  children: ReactNode;
}

export const UserPaginationContext = createContext({} as UsersPaginationContext);

export function UserPaginationProvider({ children }: UsersPaginationProviderProps) {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const updatePage = (page: number) => {
    setPage(page);
  };
  const updatePageCount = (pageCount: number) => {
    setPageCount(pageCount);
  };

  return (
    <UserPaginationContext.Provider
      value={{
        page,
        pageCount,
        updatePage,
        updatePageCount,
      }}
    >
      {children}
    </UserPaginationContext.Provider>
  );
}

export const useUserPagination = () => {
  const context = useContext(UserPaginationContext);

  if (!context) {
    throw new Error('useUserPagination must be called inside UseUserPaginationProvider');
  }
  return context;
};
