import { KeyboardEvent } from 'react';

export const isKeyboardEvent = (event: KeyboardEvent) =>
  ['keydown', 'keypress', 'keyup'].includes(event.type) && ['Enter', ' '].includes(event.key);

export const handleEvent = (key: string, event: KeyboardEvent, callback?: () => void) =>
  event.key === key && callback && callback();

export const handleEnterUpEvent = (event: KeyboardEvent, callback?: () => void) =>
  handleEvent('Enter', event, callback);
