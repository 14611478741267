import { Fragment, memo } from 'react';

import classNames from 'classnames';

import EditIcon from 'assets/edit-icon.svg';
import SortDownIcon from 'assets/sort-down-icon.svg';
import SortUpIcon from 'assets/sort-up-icon.svg';

import styles from './EditStep.module.scss';

export type EditListProps = {
  showEditIcon?: boolean;
  showSortUpIcon?: boolean;
  showSortDownIcon?: boolean;
  isProfile?: boolean;
  isSubmit?: boolean;
  onClick: any;
};

const EditStep = memo(
  ({
    showEditIcon,
    showSortUpIcon,
    showSortDownIcon,
    isProfile,
    onClick,
    isSubmit,
  }: EditListProps) => {
    const EditStep = classNames(
      styles.EditStep,
      isProfile && styles.EditStep_profile,
      isSubmit && styles.EditStep_submit,
    );

    return (
      <button data-testid="editButton" className={EditStep} onClick={onClick}>
        <EditStepItem
          showEditIcon={showEditIcon}
          showSortUpIcon={showSortUpIcon}
          showSortDownIcon={showSortDownIcon}
        />
      </button>
    );
  },
);

const EditStepItem = memo(
  ({
    showEditIcon,
    showSortUpIcon,
    showSortDownIcon,
  }: {
    showEditIcon?: boolean;
    showSortUpIcon?: boolean;
    showSortDownIcon?: boolean;
  }) => {
    if (showEditIcon) {
      return (
        <Fragment>
          <span className={styles.EditStep_title}>Edit</span>
          <img
            data-testid="edit"
            alt="Done - Edit"
            className={styles.EditStep_img}
            src={EditIcon}
          />
        </Fragment>
      );
    } else if (showSortUpIcon) {
      return (
        <img alt="Active" data-testid="active" className={styles.EditStep_img} src={SortUpIcon} />
      );
    } else if (showSortDownIcon) {
      return (
        <img
          alt="Inactive"
          className={`${styles.EditStep_img} ${styles.EditStep_img_inactive}`}
          src={SortDownIcon}
          data-testid="inactive"
        />
      );
    }
    return null;
  },
);

export default EditStep;
