import { memo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import Checkbox from 'components/inputs/Checkbox';
import { apiUrl } from 'config';

import Button from '../../buttons/Button';

import styles from './GoogleLogin.module.css';

export const GoogleLogin = memo(() => {
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const googleReady = useSelector((state) => state.session?.googleReady ?? false);
  const location = useLocation<{ redirect?: string }>();
  const redirect = location?.state?.redirect ?? '';
  const login = () => {
    if (redirect.length > 0) {
      window.location.href = `${apiUrl}/sessions/google/auth?keepMeSignedIn=${keepMeSignedIn}&requestedUserPage=${redirect.slice(
        1,
      )}`;
      return;
    }

    window.location.href = `${apiUrl}/sessions/google/auth?keepMeSignedIn=${keepMeSignedIn}`;
  };

  return (
    <div className={styles.LoginWrapper}>
      {googleReady && (
        <Button data-testid="google-login-btn" isBig={true} onClick={login}>
          Log in with Google
        </Button>
      )}
      <label className={styles.KeepMeSignedIn} htmlFor="KeepMeSignedIn">
        Keep me signed in
        <Checkbox
          id="KeepMeSignedIn"
          data-testid="keep-me-signed-in-btn"
          className={styles.KeepMeSignedInCheckbox}
          checked={keepMeSignedIn}
          onChange={(val) => setKeepMeSignedIn(val)}
        />
      </label>
    </div>
  );
});
GoogleLogin.displayName = 'GoogleLogin';
