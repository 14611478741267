import { memo, useState } from 'react';

import { BankingInfoCard, DataFields } from 'design-system';

import { Props, StepActions } from 'types/types';

export interface Step4Props extends StepActions {
  bankingInfo: DataFields;
  updateBankInfo: (bankInfo: Partial<DataFields>) => void;
  shouldSkipSurvey?: boolean;
}
export const Step4 = memo<Step4Props>((props) => {
  const [data, setData] = useState<Props<typeof BankingInfoCard>['data']>({
    ...props.bankingInfo,
  });

  const handleSubmit = async (data: DataFields) => {
    const newData = {
      ...data,
      lastEdited: new Date().getTime(),
    };
    setData(newData);
    await props.updateBankInfo(newData);
  };

  return (
    <BankingInfoCard
      step="Step 4"
      flow="onetime"
      data={data}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
});
