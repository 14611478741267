import { memo, useState } from 'react';

import {
  StepCard,
  StepCardActions,
  EditPayment,
  Message,
  PaymentView,
  Paragraph,
} from 'design-system';

import { FNS_LONG_DATE_TIME_FORMAT, INVOICE_STATUS } from 'consts/constants';
import { InvoiceStatusName, Props, StepActions } from 'types/types';
import { useGetPaymentTypes } from 'utils/apiQueryHooks';
import { formatDate } from 'utils/datesHelper';

import styles from './BankingInfoCard.module.scss';

type PropsFromStepCard = Pick<Props<typeof StepCard>, 'step'>;

export const BankingInfoCard = memo(
  (
    props: StepActions &
      PropsFromStepCard &
      Omit<Props<typeof EditPayment>, 'paymentTypes'> & {
        flow?: 'onetime' | 'permanent';
      } & { confirmationPage?: boolean; status?: InvoiceStatusName; shouldSkipSurvey?: boolean },
  ) => {
    const [isEditing, setIsEditing] = useState(false);

    const { data: paymentTypesData } = useGetPaymentTypes();
    const paymentTypes = paymentTypesData?.data?.map(({ _currency, ...data }) => data) || [];
    const isInvoiceApprovedOrPaid =
      props.status === INVOICE_STATUS.APPROVED || props.status === INVOICE_STATUS.PAID;

    return (
      <StepCard
        singleColumn
        step={props.step}
        state={props.state}
        title="Banking info"
        description={props.state === 'active' ? 'Confirm your baking information' : ''}
        bottomContent={
          isEditing || props.status === INVOICE_STATUS.PAID ? null : (
            <>
              <StepCardActions
                left={{
                  label: props.confirmationPage ? 'Update your payment information' : 'Edit',
                  onClick: () => {
                    props.inactiveHandler?.();
                    setIsEditing(true);
                  },
                  state: 'inactive',
                }}
                right={
                  props.state === 'active' && !props.confirmationPage
                    ? {
                        label: 'Next',
                        onClick: () => props.activeHandler?.(),
                        state: 'active',
                      }
                    : undefined
                }
              />
              {props.data?.lastEdited && (
                <Paragraph
                  variant="ui-regular"
                  className={styles.Color}
                  data-testid="TestId__PARAGRAPH_LASTEDIT"
                >
                  Last edit: {formatDate(props.data.lastEdited, FNS_LONG_DATE_TIME_FORMAT)}
                </Paragraph>
              )}
            </>
          )
        }
      >
        {props.flow === 'onetime' && !isInvoiceApprovedOrPaid && (
          <Message className={styles.Message} type="ERROR">
            Editing the banking information will result in updating the details for this invoice
            only
          </Message>
        )}
        <div>
          {isEditing ? (
            <EditPayment
              data={props.data}
              handleSubmit={(data) => {
                props.handleSubmit?.(data);
                setIsEditing(false);
              }}
              paymentTypes={paymentTypes}
            />
          ) : (
            <PaymentView data={props.data} />
          )}
        </div>
      </StepCard>
    );
  },
);
