import { PropsWithChildren } from 'react';

import styles from './SubmitSteps.module.scss';

const SubmitSteps = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  return (
    <main className={styles.Container}>
      <ol className={styles.SubmitSteps}>{children}</ol>
    </main>
  );
};

export default SubmitSteps;
