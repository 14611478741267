import { Accordion, ContainerInfo, Label } from 'design-system/Atoms';
import { Column, Grid, Row } from 'design-system/Templates';
import { CurrencyName, HarvestAPIDataType } from 'types/types';

import styles from './ProjectAccordion.module.scss';

export type ProjectAccordionProps = {
  rate?: number;
  details: HarvestAPIDataType;
  currency: CurrencyName;
};

export const ProjectAccordion = (props: ProjectAccordionProps) => {
  const {
    rate,
    details: { projectName, totalHours, taskBreakdownSummedByCategory },
    currency,
  } = props;

  const hoursAndRate = `${parseInt(totalHours) || 0} x ${rate} ${currency}`;

  return (
    <Accordion leftText={projectName} rightText={hoursAndRate}>
      <Grid classNames={styles.grid}>
        <Row>
          <Column xs={6}>
            <Label
              text={'Task name'}
              showBackground={false}
              type={'common'}
              interactive={false}
              wrapperClassName={styles.header}
            />
          </Column>
          <Column xs={6}>
            <Label
              text={'Total hours'}
              showBackground={false}
              type={'common'}
              interactive={false}
              wrapperClassName={styles.header}
            />
          </Column>
        </Row>
        {taskBreakdownSummedByCategory?.map((item, index) => (
          <Row key={index}>
            <Column xs={6}>
              <ContainerInfo
                data-testid="TestId__CONTAINERINFOTASKNAME"
                text={item.taskName}
                textAlignment="left"
                classNames={styles.removeBackground}
              />
            </Column>
            <Column xs={6}>
              <ContainerInfo
                data-testid="TestId__CONTAINERINFOTOTALHOURS"
                text={item.totalTaskHours}
                textAlignment="left"
                classNames={styles.removeBackground}
              />
            </Column>
          </Row>
        ))}
      </Grid>
    </Accordion>
  );
};
