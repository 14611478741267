import classNames from 'classnames';

import styles from './Textarea.module.scss';

type Props = {
  isPayment?: boolean;
  isFullWidth?: boolean;
  className?: string;
  noPadding?: boolean;
  submitStep?: boolean;
  reimburseNotes?: boolean;
  value: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  name?: string;
  isModal?: boolean;
  rows?: number;
  required?: boolean;
  disabled?: boolean;
  style?: Record<string, any>;
  type?: string;
  isBig?: boolean;
  cols?: number;
  error?: string;
};

const Textarea = (props: Props) => {
  const {
    isPayment,
    isFullWidth,
    isBig,
    isModal,
    noPadding,
    submitStep,
    className,
    reimburseNotes,
    ...rest
  } = props;

  const textProps = { className: styles.Textarea, ...rest };

  const textareaClasses = classNames(
    styles.Textarea_wrap,
    isBig && styles.Textarea_big,
    isModal && styles.Textarea_modal,
    isFullWidth && styles.Textarea_fullwidth,
    submitStep && styles.Textarea_submitStep,
    reimburseNotes && styles.Textarea_reimburseNotes,
    className,
    {
      [styles.noPadding]: noPadding,
      [styles.isPayment]: isPayment,
    },
  );

  return (
    <div className={textareaClasses}>
      <textarea {...textProps} />
    </div>
  );
};

export default Textarea;
