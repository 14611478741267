import { forwardRef, ImgHTMLAttributes } from 'react';

import cx from 'classnames';

import styles from './Image.module.scss';

export type ImageProps = {
  variant?: 'squared' | 'rounded' | 'circle';
  fluid?: boolean;
  overlayer?: boolean;
  interactive?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ className, fluid, overlayer, interactive, variant = 'squared', ...props }, ref) => {
    const imageClasses = cx(
      className,
      { [styles.TypeFluid]: fluid },
      { [styles.Overlayer]: overlayer },
      { [styles.Interactive]: interactive },
      { [styles.Squared]: variant === 'squared' },
      { [styles.Rounded]: variant === 'rounded' },
      { [styles.Circle]: variant === 'circle' },
    );

    return (
      <img // eslint-disable-line jsx-a11y/alt-text
        data-testid="TestId__IMAGE"
        ref={ref}
        className={imageClasses}
        {...props}
      />
    );
  },
);
