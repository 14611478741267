import { memo, lazy, Suspense, useEffect } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { brandConfig } from 'brands';
import { useClearCache } from 'react-clear-cache';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';

import AppPage from 'components/AppPage';
import { PrivateRoute } from 'components/Auth/authRedirect';
import { GoogleAnalyticsRouteTracker } from 'components/GoogleAnalytics';
import { Loading } from 'components/Loading';
import NotActivePage from 'components/NotActivePage';
import { AcceptInvitationLogin } from 'components/login/AcceptInvitationLogin';
import LoginPage from 'components/login/LoginPage';
import ValidateEmailTokenLink from 'components/login/ValidateEmailTokenLink';
import Menu from 'components/menu/Menu';
import PageToasts from 'components/toasts/PageToasts';
import { googleClientId } from 'config';
import { USER_ROLE } from 'consts/constants';
import DocumentSigning from 'pages/DocumentSigning/DocumentSigning';
import GoogleApiProvider from 'providers/GoogleApiProvider';
import { googleReadyActionCreator, logInWithSessionIdActionCreator } from 'store/actionCreators';
import TimezoneUpdater from 'utils/TimezoneUpdater';

const AdminPage = lazy(() => import(/* webpackChunkName: "AdminPage" */ './components/AdminPage'));
const {
  ADMIN,
  COMMUNITY_TEAM,
  MANAGER,
  PEOPLE_OPS,
  SUPER_ADMIN,
  RECRUITER,
  SUPER_WIZARD,
} = USER_ROLE;
const allowedToSeeAdmin = [
  ADMIN,
  COMMUNITY_TEAM,
  MANAGER,
  PEOPLE_OPS,
  SUPER_ADMIN,
  RECRUITER,
  SUPER_WIZARD,
];

const Main = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }

    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');
    const keepMeSignedIn = params.get('keepMeSignedIn') === 'true';

    if (sessionId) {
      dispatch(logInWithSessionIdActionCreator(sessionId, keepMeSignedIn));
    }
  }, [emptyCacheStorage, isLatestVersion, dispatch]);

  return (
    <Menu>
      <Helmet>
        <title>
          {brandConfig.appName} | {brandConfig.brandName}
        </title>
      </Helmet>
      <GoogleOAuthProvider
        onScriptLoadSuccess={() => dispatch(googleReadyActionCreator())}
        clientId={googleClientId}
      >
        <GoogleApiProvider clientId={googleClientId}>
          <GoogleAnalyticsRouteTracker />
          <TimezoneUpdater />
          <PageToasts />
          <Suspense fallback={<Loading />}>
            <Switch>
              <PrivateRoute
                path="/inactive"
                component={NotActivePage}
                userIsAuthenticated
                userIsDeactivated
              />
              <PrivateRoute
                path="/admin"
                component={AdminPage}
                userIsAuthenticated
                userIsActive
                allowedRoles={allowedToSeeAdmin}
              />
              <PrivateRoute
                path="/login/email-token/:token"
                component={ValidateEmailTokenLink}
                userIsNotAuthenticated
              />
              <PrivateRoute
                path="/login/accept/:token"
                component={AcceptInvitationLogin}
                userIsNotAuthenticated
              />
              <PrivateRoute path="/login" component={LoginPage} userIsNotAuthenticated />
              <PrivateRoute
                path="/document-signing"
                component={DocumentSigning}
                userHasDocumentsToSign
              />
              <PrivateRoute path="/" component={AppPage} userIsAuthenticated userIsActive />
            </Switch>
          </Suspense>
        </GoogleApiProvider>
      </GoogleOAuthProvider>
    </Menu>
  );
};

export default memo(Main);
