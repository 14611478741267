import { memo } from 'react';

import { isNil } from 'lodash';
import { useSelector, DefaultRootState } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { hasUserFilledRequiredFields } from 'pages/Profile/hasUserFilledRequiredFields';
import { useCurrentSession } from 'utils/hooks';
import { hasUserFilledBanking } from 'utils/profileBankingHelper';

import { isActivated, isAuthenticated, hasGivenRole } from './authHelpers';

const DEFAULT_INACTIVE_URL = '/inactive';
const DEFAULT_LOGGED_OUT_URL = '/login';
const PROFILE_URL = '/profile';
const PROFILE_BANKING_URL = '/profile-banking';
const DOCUMENT_SIGNING_URL = '/document-signing';
export const PROFILE_ALERT_PARAM = '?alert=true';

const userHasRoleInSession = (session: DefaultRootState['session'], roles: string[]) => {
  return roles.some((role) => hasGivenRole(session?.data, role));
};
const isInPage = (location: ReturnType<typeof useLocation>, pageUrl: string) => {
  return location.pathname === pageUrl;
};

type OtherProps = {
  allowedRoles?: Array<string>;
  userIsAuthenticated?: boolean;
  userIsNotAuthenticated?: boolean;
  userIsActive?: boolean;
  userIsDeactivated?: boolean;
  userHasDocumentsToSign?: boolean;
};

export const PrivateRoute = memo<RouteProps & OtherProps>(
  ({
    allowedRoles,
    userIsAuthenticated,
    userIsNotAuthenticated,
    userIsActive,
    userIsDeactivated,
    userHasDocumentsToSign,
    ...routeProps
  }) => {
    const session = useCurrentSession();
    const documentsToSign = useSelector(
      (state) => state.getUserDocumentsToSign?.response?.data || [],
    );

    const location = useLocation<{ redirect?: string }>();
    const DEFAULT_LOGGED_URL = location?.state?.redirect ?? '/';

    if (userIsAuthenticated && !isAuthenticated(session?.data)) {
      return (
        <Redirect
          to={{
            pathname: DEFAULT_LOGGED_OUT_URL,
            state: { redirect: location.pathname },
          }}
        />
      );
    } else if (userIsNotAuthenticated && isAuthenticated(session?.data)) {
      return <Redirect to={{ pathname: DEFAULT_LOGGED_URL }} />;
    } else if (userIsActive && !isActivated(session?.data)) {
      return <Redirect to={{ pathname: DEFAULT_INACTIVE_URL }} />;
    } else if (userIsDeactivated && isActivated(session?.data)) {
      return <Redirect to={{ pathname: DEFAULT_LOGGED_URL }} />;
    } else if (allowedRoles && !userHasRoleInSession(session, allowedRoles)) {
      return <Redirect to={{ pathname: DEFAULT_LOGGED_URL }} />;
    } else if (userHasDocumentsToSign && !documentsToSign.length) {
      return <Redirect to={{ pathname: DEFAULT_LOGGED_URL }} />;
    }

    const hasActiveSession = !isNil(session?.data) && session?.data._user.isActive;
    const hasFilledContactFields =
      hasActiveSession && hasUserFilledRequiredFields(session?.data?._user ?? {});
    const shouldRedirectToProfile =
      hasActiveSession && !hasFilledContactFields && !isInPage(location, PROFILE_URL);
    const shouldRedirectToProfileBanking =
      hasActiveSession &&
      hasFilledContactFields &&
      !hasUserFilledBanking(session?.data?._user) &&
      !isInPage(location, PROFILE_BANKING_URL);
    const shouldRedirectToDocumentSigning =
      hasActiveSession &&
      hasFilledContactFields &&
      hasUserFilledBanking(session?.data?._user) &&
      documentsToSign.length &&
      !isInPage(location, DOCUMENT_SIGNING_URL);

    if (shouldRedirectToProfileBanking) {
      return <Redirect to={PROFILE_BANKING_URL} />;
    } else if (shouldRedirectToProfile) {
      return (
        <Redirect
          to={{
            pathname: PROFILE_URL,
            search: PROFILE_ALERT_PARAM,
          }}
        />
      );
    } else if (shouldRedirectToDocumentSigning) {
      return <Redirect to={DOCUMENT_SIGNING_URL} />;
    }

    return <Route {...routeProps} />;
  },
);
