import cx from 'classnames';

import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './ContainerInfo.module.scss';

export type ContainerInfoProps = {
  text: string | number;
  textAlignment: 'left' | 'center' | 'right';
  classNames?: string;
  'data-testid'?: string;
};

export const ContainerInfo = (props: ContainerInfoProps) => {
  const classes = cx(styles.ContainerInfo, props.classNames);

  return (
    <Paragraph
      data-testid={props['data-testid'] || 'TestId__CONTAINERINFOPARAGRAPH'}
      className={classes}
      variant="ui-regular"
      style={{ textAlign: props.textAlignment }}
    >
      {props.text}
    </Paragraph>
  );
};
