import 'react-activity-feed/dist/index.css';
import { useState, useCallback, useRef, useLayoutEffect } from 'react';

import { CloudinaryContext } from 'cloudinary-react';
import { StreamApp, FlatFeed } from 'react-activity-feed';
import { useLocation } from 'react-router-dom';

import {
  isAdmin,
  isPeopleOps,
  isCommunityTeam,
  isSuperAdmin,
  isManager,
  isSuperWizard,
} from 'components/Auth/authHelpers';
import { SnackbarAlert, useSnackbarAlert } from 'components/SnackbarAlert';
import {
  useFeedPinnedPostDeleteMutation,
  useFeedUserPostDeleteMutation,
  useGetFeedPost,
} from 'utils/apiMutationHooks';
import { useGetUserFeedToken } from 'utils/apiQueryHooks';
import { getErrorMessage } from 'utils/helpers';
import { useCurrentUser, useIsMobile, useToast, useAllowedRoles } from 'utils/hooks';

import { AutoNotifier } from '../AutoNotifier';
import {
  Activity,
  POST_TYPES,
  POST_OPERATION_TYPES,
  RefreshFeedCallbackFunction,
  UploaderOptions,
  SelectedActivity,
  FeedActivityProps,
  NotifierProps,
} from '../Feed.types';
import { FeedConfirmationModal } from '../FeedConfirmationModal';
import { DeletePostModalContent } from '../FeedDeletePostModalContent';
import { FeedSkeleton } from '../FeedSkeleton';
import FeedWrapper from '../FeedWrapper';
import FullPost from '../FullPost';
import NewPost from '../NewPost';
import PinnedPost from '../PinnedPost';

import styles from './Feed.module.scss';

const GETSTREAM_KEY = process.env.REACT_APP_GETSTREAM_KEY || '';
const GETSTREAM_APPID = process.env.REACT_APP_GETSTREAM_APPID || '';
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

type FeedProps = { children: React.ReactNode };

const Feed = ({ children }: FeedProps) => {
  const [fullPostActivity, setFullPostActivity] = useState<Activity | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const user = useCurrentUser();
  const [
    deletePinnedFeedPost,
    { status: deletePinnedFeedStatus },
  ] = useFeedPinnedPostDeleteMutation();
  const [deleteUserFeedPost, { status: deleteUserFeedStatus }] = useFeedUserPostDeleteMutation();
  const [snackbarAlertMessage] = useState<string>('');
  const [isSnackbarAlertOpened, , snackbarType] = useSnackbarAlert();
  const [selectedActivity, setSelectedActivity] = useState<SelectedActivity>();
  const isMobile = useIsMobile();
  const toast = useToast();

  const loadingPinned = deletePinnedFeedStatus === 'loading';
  const loadingPosts = deleteUserFeedStatus === 'loading';

  const isUpdating = selectedActivity?.operation === POST_OPERATION_TYPES.EDIT_CONFIRM;
  const isDeleting = selectedActivity?.operation === POST_OPERATION_TYPES.DELETE;

  const location = useLocation();
  const [getPost] = useGetFeedPost();

  const canCreatePost = useAllowedRoles([
    isAdmin,
    isSuperAdmin,
    isCommunityTeam,
    isPeopleOps,
    isManager,
    isSuperWizard,
  ]);

  useLayoutEffect(() => {
    const postId = new URLSearchParams(location.search).get('post');
    if (postId) {
      getPost({ params: { postId } }).then((response) => {
        if (!response) {
          return;
        }

        const { actor, ...rest } = response.activity;

        // activity returned from back-end has different properties
        setFullPostActivity({
          actor: {
            created_at: '',
            updated_at: '',
            id: response.activity.actor,
            data: {
              name: response.activity.publisherName,
              slackId: user?.slackId,
              avatarUrl: response.activity.publisherAvatar,
            },
          },
          ...rest,
        } as Activity);
      });
    }
  }, [location, getPost, user]);

  const { refetch } = useGetUserFeedToken();

  const [newFeedToken, setNewFeedToken] = useState<string>();

  useLayoutEffect(() => {
    const refetchFunc = async () => {
      try {
        const newToken = await refetch();
        setNewFeedToken(newToken?.data);
      } catch (error) {
        console.error(error);
      }
    };
    refetchFunc();
  }, [user?.id, refetch]);

  const onFeedError = (error: unknown) => {
    console.log(error);
  };

  const removeUserPost = async () => {
    const activityId = selectedActivity?.activity?.id;

    if (selectedActivity?.operation === POST_OPERATION_TYPES.DELETE && activityId) {
      try {
        await deleteUserFeedPost({ params: { postId: activityId } });
        onPostRemoveSuccess();
      } catch (error) {
        toast({
          text: getErrorMessage(error) || 'Something went wrong...',
          type: 'error',
        });
      }
    }
  };

  const removePinnedPost = async () => {
    const activityId = selectedActivity?.activity?.id;

    if (selectedActivity?.operation === POST_OPERATION_TYPES.DELETE && activityId) {
      try {
        await deletePinnedFeedPost({ params: { postId: activityId } });

        onPostRemoveSuccess();
      } catch (error) {
        toast({
          text: getErrorMessage(error) || 'Something went wrong...',
          type: 'error',
        });
      }
    }
  };

  const onPostRemoveSuccess = () => {
    toast({
      text: 'Successfully removed the post!',
      type: 'success',
    });
    setSelectedActivity(undefined);
  };

  const handlePostRemove = (activity: Activity) => {
    setSelectedActivity({ activity, operation: POST_OPERATION_TYPES.DELETE });
    setIsModalOpen(true);
  };

  const editPost = async (activity: Activity) => {
    setSelectedActivity({ activity, operation: POST_OPERATION_TYPES.EDIT });
  };

  const handlePostUpdate = (editedData: UploaderOptions) => {
    setSelectedActivity({
      activity: editedData.activity,
      editedData,
      operation: POST_OPERATION_TYPES.UPDATE,
    });
  };

  const resetActivity = () => {
    setSelectedActivity(undefined);
  };

  const toggleFeedRefresh = (shouldRefresh: boolean) => {
    setShouldRefresh(shouldRefresh);
  };

  const refreshFeedRef = useRef<RefreshFeedCallbackFunction>();

  const onClickPost = useCallback(
    (activity: Activity, refreshFeed?: RefreshFeedCallbackFunction) => {
      setFullPostActivity(activity);

      if (!refreshFeedRef.current) {
        refreshFeedRef.current = refreshFeed;
      }
    },
    [],
  );

  const onCloseFullPost = () => {
    setFullPostActivity(undefined);
  };

  if (!user || !newFeedToken) {
    return null;
  }

  const determineSubmitMethod = () => {
    if (!selectedActivity) {
      return;
    }

    if (isDeleting) {
      selectedActivity.activity?.type === POST_TYPES.USER_POST
        ? removeUserPost()
        : removePinnedPost();
    }
  };

  const getConfirmationText = () => {
    return isUpdating ? 'Edit' : 'Delete';
  };

  return (
    <div data-type="feed-main">
      <SnackbarAlert isOpened={isSnackbarAlertOpened} type={snackbarType}>
        {snackbarAlertMessage}
      </SnackbarAlert>
      {selectedActivity?.activity && isModalOpen && (
        <FeedConfirmationModal
          onConfirm={() => determineSubmitMethod()}
          onCancel={() => setIsModalOpen(false)}
          title={`Are you sure to ${getConfirmationText()} this post?`}
          cancelButtonText={isMobile ? 'Cancel' : 'No, keep the post'}
          confirmButtonText={
            isMobile ? getConfirmationText() : `Yes, ${getConfirmationText()} the post`
          }
          confirmDisabled={loadingPosts}
        >
          {isDeleting && <DeletePostModalContent activity={selectedActivity?.activity} />}
        </FeedConfirmationModal>
      )}
      <div className={styles.PinnedFeedContainer}>
        <CloudinaryContext cloudName={CLOUDINARY_CLOUD_NAME}>
          <StreamApp
            apiKey={GETSTREAM_KEY}
            appId={GETSTREAM_APPID}
            token={newFeedToken}
            errorHandler={onFeedError}
          >
            {loadingPinned ? (
              <FeedSkeleton />
            ) : (
              <FlatFeed
                feedGroup="pinned"
                options={{ limit: 3, withOwnReactions: false, withReactionCounts: false }}
                notify
                Notifier={(props: NotifierProps) => (
                  <AutoNotifier
                    {...props}
                    shouldRefresh={shouldRefresh}
                    triggerFeedRefresh={toggleFeedRefresh}
                  />
                )}
                LoadingIndicator={FeedSkeleton}
                Activity={(props: FeedActivityProps) => {
                  return (
                    <PinnedPost
                      {...props}
                      user={user}
                      onPostRemove={handlePostRemove}
                      onPostEdit={editPost}
                      onClickPost={onClickPost}
                    />
                  );
                }}
              />
            )}
          </StreamApp>
        </CloudinaryContext>
      </div>
      {selectedActivity?.activity?.type === POST_TYPES.PINNED_POST && (
        <NewPost
          selectedActivity={selectedActivity}
          onResetForm={resetActivity}
          onConfirmUpdate={handlePostUpdate}
          triggerFeedRefresh={toggleFeedRefresh}
          isInlineForm
        />
      )}
      {children}

      {canCreatePost && (
        <NewPost
          onResetForm={resetActivity}
          onConfirmUpdate={handlePostUpdate}
          triggerFeedRefresh={toggleFeedRefresh}
        />
      )}

      <CloudinaryContext cloudName={CLOUDINARY_CLOUD_NAME}>
        <StreamApp
          apiKey={GETSTREAM_KEY}
          appId={GETSTREAM_APPID}
          token={newFeedToken}
          errorHandler={onFeedError}
        >
          {loadingPosts ? (
            <FeedSkeleton />
          ) : (
            <FeedWrapper
              shouldRefresh={shouldRefresh}
              toggleFeedRefresh={toggleFeedRefresh}
              selectedActivity={selectedActivity}
              user={user}
              resetActivity={resetActivity}
              handlePostUpdate={handlePostUpdate}
              editPost={editPost}
              handlePostRemove={handlePostRemove}
              onClickPost={onClickPost}
            />
          )}
          {fullPostActivity && (
            <FullPost
              activity={fullPostActivity}
              onClose={onCloseFullPost}
              refreshFeed={refreshFeedRef.current}
            />
          )}
        </StreamApp>
      </CloudinaryContext>
    </div>
  );
};

export default Feed;
