import { useState, useLayoutEffect, useRef } from 'react';

import { StreamApp, NotificationFeed, LoadMorePaginator } from 'react-activity-feed';
import { useDispatch } from 'react-redux';

import arrowLeftIcon from 'assets/arrow-left.png';
import { AutoNotifier } from 'components/feed/AutoNotifier';
import { LoadMorePaginatorProps, NotifierProps } from 'components/feed/Feed.types';
import { FeedPaginatorButton } from 'components/feed/FeedPaginatorButton';
import { setAllNotificationsAsReadCreator } from 'store/actionCreators';
import { useGetUserFeedToken } from 'utils/apiQueryHooks';
import { useIsMobile, useCurrentUser, useOnClickOutside } from 'utils/hooks';

import { Empty } from '../Empty';
import { Notification } from '../Notification';
import { ReactComponent as NotificationBell } from '../notificationBell.svg';
import { NotificationProps } from './Notifications.types';

import styles from './Notifications.module.scss';

const {
  REACT_APP_GETSTREAM_KEY: GETSTREAM_KEY = '',
  REACT_APP_GETSTREAM_APPID: GETSTREAM_APPID = '',
} = process.env;

export function Notifications() {
  const [isOpen, setIsOpen] = useState(false);
  const [thereAreNotifications, setThereAreNotifications] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const isMobile = useIsMobile();
  const user = useCurrentUser();
  const { refetch } = useGetUserFeedToken();
  const dispatch = useDispatch();
  const [newFeedToken, setNewFeedToken] = useState<string>();
  const notificationsRef = useRef(null);
  const close = () => {
    setIsOpen(false);
  };

  useOnClickOutside(notificationsRef, close);

  useLayoutEffect(() => {
    const refetchFunc = async () => {
      try {
        const newToken = await refetch();
        setNewFeedToken(newToken?.data);
      } catch (error) {
        console.error(error);
      }
    };
    refetchFunc();
  }, [user?.id, refetch]);

  if (!newFeedToken) {
    return null;
  }

  function handleToggleDropdown() {
    setIsOpen(!isOpen);
  }

  const toggleFeedRefresh = (shouldRefresh: boolean) => {
    setShouldRefresh(shouldRefresh);
  };

  async function handleMarkAllAsRead() {
    await dispatch(setAllNotificationsAsReadCreator());
    setShouldRefresh(true);
  }

  return (
    <StreamApp
      apiKey={GETSTREAM_KEY}
      appId={GETSTREAM_APPID}
      token={newFeedToken}
      errorHandler={console.error}
    >
      <div className={styles.container} ref={notificationsRef}>
        <button className={styles.icon} onClick={handleToggleDropdown}>
          <NotificationBell />
        </button>

        {isOpen && (
          <div className={styles.contentWrapper}>
            {isMobile && (
              <button className={styles.backButton} onClick={handleToggleDropdown}>
                <img src={arrowLeftIcon} alt="Close notifications dropdown" />
                Go back
              </button>
            )}

            <div className={styles.content}>
              <div className={styles.header}>
                <h1 className={styles.headerTitle}>Notifications</h1>
                {thereAreNotifications && (
                  <button className={styles.headerAction} onClick={handleMarkAllAsRead}>
                    Mark all as read
                  </button>
                )}
              </div>
              <div className={styles.divider} />
              <div className={styles.list}>
                <NotificationFeed
                  feedGroup="notification"
                  Placeholder={() => <Empty setThereAreNotifications={setThereAreNotifications} />}
                  options={{ limit: 9 }}
                  notify
                  Notifier={(props: NotifierProps) => {
                    return (
                      <AutoNotifier
                        {...props}
                        showToast={false}
                        shouldRefresh={shouldRefresh}
                        triggerFeedRefresh={toggleFeedRefresh}
                      />
                    );
                  }}
                  Paginator={(props: LoadMorePaginatorProps) => (
                    <LoadMorePaginator {...props} LoadMoreButton={FeedPaginatorButton} />
                  )}
                  Group={(props: NotificationProps) => <Notification {...props} />}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </StreamApp>
  );
}
