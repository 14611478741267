import { memo, PropsWithChildren, TextareaHTMLAttributes } from 'react';

import { Label, Panel, Paragraph } from 'design-system';

import styles from './Textarea.module.scss';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  handleChange?: (value: string) => void;
  label?: string;
  textareaClassName?: string;
  textareaTestId?: string;
  disabled?: boolean;
  required?: boolean;
}

export const Textarea = memo((props: PropsWithChildren<TextareaProps>) => {
  if (props.disabled && !props.value) return null;
  if (props.disabled && props.value) {
    return (
      <>
        <TextareaLabel label={props.label} required={props.required} />
        <Panel data-testid="TestId__DISABLEDTEXTAREA">
          <Paragraph>{props.value}</Paragraph>
        </Panel>
      </>
    );
  }

  return (
    <>
      <TextareaLabel label={props.label} required={props.required} />
      <textarea
        className={[styles.Textarea, props.textareaClassName].join(' ')}
        onChange={(e) => props.handleChange?.(e.currentTarget.value)}
        data-testid={props.textareaTestId || 'TestId__TEXTAREA'}
        value={props.value}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        rows={props.rows}
      />
    </>
  );
});

const TextareaLabel = ({ label, required }: { label?: string; required?: boolean }) => {
  if (!label) return null;

  return (
    <div>
      <Label
        type={'dark'}
        interactive={false}
        showBackground={false}
        paragraphClassName={styles.Label}
      >
        {label}
        {required && <span className={styles.Required}>*</span>}
      </Label>
    </div>
  );
};
