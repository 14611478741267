import { memo, ChangeEvent } from 'react';

import cx from 'classnames';
import { Input } from 'design-system';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './ReimbursementCard.module.scss';

export type QuantityAndPriceRowProps = {
  onQuantityChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPriceChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onQuantityBlur: () => void;
  onPriceBlur: () => void;
  quantity: number;
  unitPrice: number;
  error?: boolean;
};

export const QuantityAndPriceRow = memo<QuantityAndPriceRowProps>((props) => {
  const state = props.error ? 'error' : 'inactive';
  return (
    <div className={cx(styles.DoubleRow, styles.MobileDoubleRow)}>
      <Input
        state={state}
        label={'Quantity'}
        placeholder={'0'}
        paragraphClassName={styles.LabelHelper}
        value={(!!props.quantity && props.quantity.toString()) || undefined}
        layout={'fluid'}
        mode={'writable'}
        inputTestId="quantity"
        onChange={props.onQuantityChange}
        onBlur={props.onQuantityBlur}
        type="number"
      />
      <Input
        state={state}
        label={'Unit price'}
        paragraphClassName={styles.LabelHelper}
        placeholder={'0'}
        value={(!!props.unitPrice && props.unitPrice.toString()) || undefined}
        layout={'fluid'}
        inputTestId="unitPrice"
        mode={'writable'}
        onChange={props.onPriceChange}
        onBlur={props.onPriceBlur}
        type="number"
      />
    </div>
  );
});
