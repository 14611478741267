import { Component } from 'react';

import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

const init = (options = {}) => {
  const env = process.env || {};
  const isGAEnabled = Boolean(env.REACT_APP_GA_TRACKING_ID);

  if (isGAEnabled && env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(env.REACT_APP_GA_TRACKING_ID, {
      debug: env.REACT_APP_GA_DEBUG === 'true',
      ...options,
    });
  }

  return isGAEnabled;
};

type Props = {
  location: {
    pathname: string;
    search: string;
  };
  options: Record<string, any>;
};

class GoogleAnalytics extends Component<Props> {
  componentDidMount() {
    init();
    this.logPageChange(this.props.location.pathname, this.props.location.search);
  }

  componentDidUpdate({ location: prevLocation }: Props) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname: string, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

export const GoogleAnalyticsRouteTracker = () => <Route component={GoogleAnalytics} />;
