/**
 * As per requirements, starting from Season X (10) all seasons must use numerals
 * @see https://x-team-internal.atlassian.net/browse/XHQ-4095
 * @param number
 */
export const formatSeasonNumber = (number: number): string => {
  if (number < 10) {
    return String(number);
  }

  return formatAsRomanNumeral(number);
};

/**
 * @see https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
 * @param number
 */
export const formatAsRomanNumeral = (number: number): string => {
  const roman: Record<string, number> = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  let numeral = '';

  for (const i of Object.keys(roman)) {
    const q = Math.floor(number / roman[i]);
    number -= q * roman[i];
    numeral += i.repeat(q);
  }

  return numeral;
};

export const formatSnakeCaseToPascalCase = (value: string) => {
  const words = value.split(/_+/);
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return formattedWords.join(' ');
};
