import PageContents from 'components/page/PageContents';
import PageTitle from 'components/page/PageTitle';

const NotActivePage = () => (
  <PageContents>
    <PageTitle>User not active</PageTitle>
    <p style={{ textAlign: 'center' }} data-testid="not-active-message">
      Your account was deactivated.
      <br />
      Please contact someone from the <strong>core team</strong> to receive the access to the app.
    </p>
  </PageContents>
);

export default NotActivePage;
