import { FormikComputedProps, FormikState } from 'formik/dist/types';

const isEmpty = (value: any): boolean => {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const shouldDisableSubmit = (
  form: FormikState<Record<string, any>> & FormikComputedProps<Record<string, any>>,
) => {
  return !form.isValid || !form.dirty || !isEmpty(form.errors) || form.isSubmitting;
};
