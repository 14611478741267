import { memo } from 'react';

import cs from 'classnames';

import styles from './paragraph.module.scss';

type VariantLevel = 'bold' | 'regular' | 'ui-bold' | 'ui-regular' | 'label' | 'small';
export type ParagraphProps = {
  variant?: VariantLevel;
  noVerticalMargin?: boolean;
} & JSX.IntrinsicElements['p'];

export const Paragraph = memo<ParagraphProps>(
  ({ variant = 'regular', children, className, ...props }) => {
    const classnames = cs(className, styles.p, {
      [styles['p--variant--bold']]: variant === 'bold',
      [styles['p--variant--regular']]: variant === 'regular',
      [styles['p--variant--ui-bold']]: variant === 'ui-bold',
      [styles['p--variant--ui-regular']]: variant === 'ui-regular',
      [styles['p--variant--label']]: variant === 'label',
      [styles['p--variant--small']]: variant === 'small',
      [styles['p--no-vertical-margin']]: props.noVerticalMargin,
    });

    return (
      <p data-testid="TestId__PARAGRAPH" className={classnames} {...props}>
        {children}
      </p>
    );
  },
);
