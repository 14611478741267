import { memo, useState } from 'react';

import cx from 'classnames';
import { Card, StepCard, StepCardButtons, Paragraph } from 'design-system';
import { uniqBy } from 'lodash';

import { SUBMIT_INVOICE } from 'consts/constants';
import { Assignment, Invoice, StepActions } from 'types/types';

import styles from './Step0.module.scss';

export interface Step0Props extends StepActions {
  companyId?: number;
  assignments: Assignment[];
  updateInvoice: (invoiceData: Partial<Invoice>) => void;
}

export const Step0 = memo<Step0Props>((props) => {
  const projects = props.assignments.map(({ _project }) => _project).filter(Boolean) ?? [];
  const companies =
    uniqBy(projects.map((project) => project?._company).filter(Boolean), 'id') ?? [];

  const [stepCompleted, setStepCompleted] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(
    () => companies?.findIndex((company) => company?.id === props.companyId) ?? -1,
  );

  const { CONTENT } = SUBMIT_INVOICE;
  const step = CONTENT[0];
  const completedCardStyles = cx(styles.ShortHeight, styles.RedBackground);

  const getCardStyles = (index: number) => {
    return selectedCompany === index
      ? cx(styles.ShortHeight, styles.RedBackground)
      : styles.ShortHeight;
  };

  const onNextButtonClick = async () => {
    setStepCompleted(true);
    const _companyId = companies[selectedCompany]?.id;
    await props.updateInvoice({ _companyId });
    props.activeHandler?.();
  };

  const onEditButtonClick = () => {
    setStepCompleted(false);
    props.inactiveHandler?.();
  };

  return (
    <StepCard
      step={step.step}
      title={step.title}
      description={step.description}
      state={props.state}
      bottomContent={
        <StepCardButtons
          state={selectedCompany === -1 ? 'disabled' : props.state}
          text={props.state === 'active' ? 'Next' : 'Edit'}
          onClick={props.state === 'active' ? onNextButtonClick : onEditButtonClick}
        />
      }
      singleColumn
    >
      <div className={styles.Grid}>
        {stepCompleted ? (
          <Card
            key={companies[selectedCompany]?.id}
            className={completedCardStyles}
            type={'highlighted'}
          >
            <Paragraph variant={'ui-bold'}>{companies[selectedCompany]?.name}</Paragraph>
          </Card>
        ) : (
          companies.map((company, index) => (
            <Card
              key={company?.id}
              className={getCardStyles(index)}
              onClick={() => setSelectedCompany(index)}
              type={selectedCompany === index ? 'highlighted' : 'default'}
            >
              <Paragraph variant={'ui-bold'}>{company?.name}</Paragraph>
            </Card>
          ))
        )}
      </div>
    </StepCard>
  );
});
