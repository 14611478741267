import { memo, Children, PropsWithChildren, HTMLAttributes, useState, useCallback } from 'react';

import cx from 'classnames';

import { Button } from 'design-system/Atoms';
import { MobileModalBox } from 'design-system/Molecules';

import styles from './Filter.module.scss';

export interface FilterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  contentItemsClassName?: string;
  filterButtonClassName?: string;
  displayedItems?: number;

  /**
   * If true, The items will be displayed only in the modal
   * @default false
   */
  onlyModalMobile?: boolean;
}

export const Filter = memo<PropsWithChildren<FilterProps>>(
  ({
    children,
    className,
    contentItemsClassName,
    filterButtonClassName,
    onlyModalMobile = false,
    displayedItems = 4,
    ...props
  }: PropsWithChildren<FilterProps>) => {
    const [showMore, setShowMore] = useState(false);

    const childrenArray = Children.toArray(children);
    const childrenLength = childrenArray.length;
    const hidedItems = childrenLength > displayedItems ? childrenLength - displayedItems : 0;

    const handleShowResultsClick = useCallback(() => {
      setShowMore(!showMore);
    }, [setShowMore, showMore]);

    return (
      <div
        {...props}
        data-testid="TestId__FILTER"
        className={cx(styles.Filter, className, {
          [styles.MoreOpen]: showMore,
          [styles.MoreClose]: !showMore,
        })}
      >
        <MobileModalBox modalOpen={showMore} openClose={handleShowResultsClick}>
          <div className={cx(styles.Content, { [styles.OnlyModal]: onlyModalMobile })}>
            <div
              className={cx(styles.ContentItems, contentItemsClassName)}
              style={{
                gridTemplateColumns: `repeat(${displayedItems}, 1fr)`,
              }}
            >
              {childrenArray.map((item, index) => {
                return (
                  <div
                    className={cx(styles.Children, {
                      [styles.Hidden]: index >= displayedItems && !showMore,
                    })}
                    key={index}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            <div>
              <Button
                variant="secondary"
                layout="autolayout"
                as="button"
                iconName="slider"
                iconPosition="end"
                className={cx(styles.ButtonFilter, filterButtonClassName, {
                  [styles.Empty]: hidedItems === 0,
                })}
                onClick={handleShowResultsClick}
              >
                Filters {showMore || onlyModalMobile ? '' : `(${hidedItems})`}
              </Button>
            </div>
            {showMore && (
              <Button
                variant="primary"
                layout="autolayout"
                as="button"
                className={styles.ButtonShowResults}
                onClick={handleShowResultsClick}
              >
                Show results
              </Button>
            )}
          </div>
        </MobileModalBox>
      </div>
    );
  },
);
