import { memo, useState, useCallback, MouseEventHandler } from 'react';

import { useDispatch } from 'react-redux';

import { env } from 'config';
import { googleReadyActionCreator } from 'store/actionCreators';
import { useGetHealthCheck } from 'utils/apiQueryHooks';

import { EmailPasswordLogin } from '../EmailPasswordLogin';
import { EmailTokenLogin } from '../EmailTokenLogin';
import { GoogleLogin } from '../GoogleLogin';

import styles from './Login.module.css';

export const Login = memo(() => {
  const { error } = useGetHealthCheck(true, { suspense: false });
  const dispatch = useDispatch();
  const [logInWithEmail, setLogInWithEmail] = useState(false);
  const [logInWithEmailPassword, setLogInWithEmailPassword] = useState(false);

  const isGoogleEnabled = true;
  let isEmailPasswordEnabled = false;
  if (env === 'staging' || env === 'stage' || env === 'develop' || env === 'local') {
    isEmailPasswordEnabled = true;
  }

  const loginWithGoggleCallback = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    dispatch(googleReadyActionCreator());
    setLogInWithEmail(false);
    setLogInWithEmailPassword(false);
  }, [dispatch]);

  const loginWithEmailTokenCallback = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setLogInWithEmail(true);
    setLogInWithEmailPassword(false);
  }, []);

  const loginWithEmailPasswordCallback = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setLogInWithEmail(false);
    setLogInWithEmailPassword(true);
  }, []);

  if (error) {
    return (
      <p className={styles.ServerDownInfo}>
        It seems that our server is currently down.
        <br />
        Please try again soon.
      </p>
    );
  }

  return (
    <div className={styles.LoginModuleContainer}>
      {(logInWithEmail && <EmailTokenLogin />) ||
        (logInWithEmailPassword && <EmailPasswordLogin />) ||
        (isGoogleEnabled && <GoogleLogin />)}

      {isGoogleEnabled && (logInWithEmail || logInWithEmailPassword) && (
        <button className={styles.LoginWithoutGoogle} onClick={loginWithGoggleCallback}>
          Log in via Google Auth
        </button>
      )}

      {!logInWithEmail && (
        <button className={styles.LoginWithoutGoogle} onClick={loginWithEmailTokenCallback}>
          Log in via email link
        </button>
      )}

      {isEmailPasswordEnabled && !logInWithEmailPassword && (
        <button className={styles.LoginWithoutGoogle} onClick={loginWithEmailPasswordCallback}>
          Log in via email and password
        </button>
      )}
    </div>
  );
});
Login.displayName = 'Login';
