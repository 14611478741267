import { useCallback, KeyboardEvent } from 'react';

export interface UseClickableProps<T> {
  /**
   * Whether the div is disabled
   */
  disabled?: boolean;

  /**
   * The onKeyDown handler
   */
  onKeyDown?: (event: KeyboardEvent<T>) => void;

  /**
   * The `tabIndex` of the div
   */
  tabIndex?: number;
}

export interface UseClickableReturnProps<T> {
  /**
   * The `tabIndex` of the div
   */
  tabIndex: number;

  /**
   * The `onKeyDown` handler of the div
   */
  onKeyDown?: (event: KeyboardEvent<T & HTMLElement>) => void;
}

/**
 * A hook that provides the `tabIndex` and `onKeyDown` props for a clickable HTML element
 *
 * @param props - The `UseClickableProps` object
 *
 * @returns The `UseClickableReturnProps` object
 */
export const useClickable = <T,>({
  onKeyDown: onKeyDownHandler,
  disabled,
  tabIndex = -1,
}: UseClickableProps<T>): UseClickableReturnProps<T> => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<T & HTMLElement>) => {
      if (event.defaultPrevented) return;
      if (disabled) return;
      if (event.metaKey) return;
      if (event.target !== event.currentTarget) return;

      onKeyDownHandler?.(event);

      const target = event.currentTarget;

      target?.focus({ preventScroll: true });

      if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault();
        target?.click();
      }
    },
    [disabled, onKeyDownHandler],
  );

  return {
    tabIndex: disabled ? -1 : tabIndex,
    onKeyDown,
  };
};
