import { memo } from 'react';

import cs from 'classnames';
import { Avatar, Icon, Label, Paragraph } from 'design-system';
import ReactSelect, { components, Props as ReactSelectProps } from 'react-select';

import { getStyles, SelectOption } from './Select.helper';

import styles from './Select.module.scss';

export interface SelectProps extends ReactSelectProps<SelectOption> {
  testId?: string;
  options: SelectOption[];
  label?: string;
  required?: boolean;
  labelClassName?: string;
  hideSeparator?: boolean;
  paragraphClassName?: string;
  requiredLabelClassName?: string;
  noMargins?: boolean;
  resetStyle?: boolean;
  setStatusSelectOpenedIndex?: (other?: number) => void;
}

export const Select = memo(
  ({ resetStyle = false, setStatusSelectOpenedIndex, ...props }: SelectProps) => {
    const paragraphClasses = cs(styles.Label, props.paragraphClassName);
    const requiredLabelClasses = cs(styles['Label-required'], props.requiredLabelClassName);
    const inputAndStateBaseClass = props.noMargins
      ? styles.MainContainerNoMargin
      : styles.MainContainer;

    return (
      <div
        data-testid={props.testId}
        className={cs(styles.MainWrapper, { [styles.SelectResetStyle]: resetStyle })}
      >
        {props.label && (
          <div>
            <Label
              type="dark"
              wrapperClassName={styles.LabelWrapper}
              paragraphClassName={paragraphClasses}
              interactive={false}
              showBackground={false}
              text={props.label}
            />
            {props.required && <p className={requiredLabelClasses}>*</p>}
          </div>
        )}
        <div className={inputAndStateBaseClass}>
          <ReactSelect
            {...props}
            onMenuOpen={() => setStatusSelectOpenedIndex?.()}
            onMenuClose={() => setStatusSelectOpenedIndex?.(0)}
            styles={getStyles(props.isDisabled)}
            components={{
              Option: (props) => (
                <components.Option {...props}>
                  <div
                    data-testid={`TestId__SELECT__OPTION__${props.label.replace(/\s/g, '')}`}
                    className={styles.SelectItem}
                  >
                    {props.data?.imageUrl && (
                      <Avatar className={styles.Avatar} imgSrc={props.data.imageUrl} />
                    )}
                    <Paragraph variant="ui-regular">{props.children}</Paragraph>
                  </div>
                </components.Option>
              ),
              MultiValue: (props) => (
                <components.MultiValue {...props}>
                  <div
                    data-testid={`TestId__SELECT__OPTION__MV__${props.children
                      ?.toString()
                      .replace(/\s/g, '')}`}
                    className={styles.SelectItem}
                  >
                    <Paragraph variant="ui-regular" className={styles.SelectedItem}>
                      {props.children}
                    </Paragraph>
                  </div>
                </components.MultiValue>
              ),
              MultiValueRemove: (props) => (
                <components.MultiValueRemove {...props}>
                  <div className={styles.MultiValueRemove}>
                    <Icon name="trash-outline-dynamic" />
                  </div>
                </components.MultiValueRemove>
              ),
              ...(props.hideSeparator ? { IndicatorSeparator: () => null } : {}),
              ...props.components,
            }}
          />
        </div>
      </div>
    );
  },
);

export default Select;
