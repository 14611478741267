import { useRef, useEffect } from 'react';

import cx from 'classnames';
import { Fade, Message, MessageProps } from 'design-system';

import styles from './Toast.module.scss';

export type ToastProps = {
  shouldShow: boolean;
  message: string | null; // Passing null will result in the last message being displayed. This is helpful if the error message is derived from dynamic props.
  onClose: () => void;
  type: MessageProps['type'];
  placement?: 'TOP_CENTER' | 'BOTTOM_CENTER';
  autoHide?: boolean;
  className?: string;
  durationMS?: number;
};

export const toastDefaultProps = {
  placement: 'TOP_CENTER' as ToastProps['placement'],
  autoHide: true,
};

export const Toast = ({
  shouldShow,
  message: messageProp,
  onClose,
  type,
  durationMS,
  className,
  placement = toastDefaultProps.placement,
  autoHide = toastDefaultProps.autoHide,
}: ToastProps) => {
  const classNames = cx(
    styles.Toast,
    {
      [styles.TopCenter]: placement === 'TOP_CENTER',
      [styles.BottomCenter]: placement === 'BOTTOM_CENTER',
    },
    className,
  );
  const message = useRef<null | string>(null);

  useEffect(() => {
    if (messageProp) {
      message.current = messageProp;
    }
  }, [messageProp]);

  return (
    <Fade shouldShow={shouldShow} onClose={autoHide ? onClose : null} autoCloseDelayMS={durationMS}>
      <div className={classNames}>
        <Message type={type} glow onClose={autoHide ? null : onClose}>
          {messageProp || message.current}
        </Message>
      </div>
    </Fade>
  );
};
