import { memo } from 'react';

import {
  Button,
  Input,
  Columns,
  OnItemSelected,
  ColumnsFullRow,
  PaymentMethodFieldNames,
} from 'design-system';
import { useFormik } from 'formik';

import {
  getCountriesDataList,
  getCountryDataListEntry,
  getPaymentMethod,
  getPaymentMethodsSearchDataList,
  getPaymentNameById,
} from 'design-system/Atoms/Input/Input.mappers';
import {
  shouldDisableSubmit,
  getFieldValue,
  getPaymentMethodFields,
  DataFields,
} from 'design-system/utils';
import { PaymentType } from 'store/storeTypes';
import { validatePaymentFields } from 'utils/paymentHelper';

import styles from './EditPayment.module.scss';

export type EditPaymentProps = {
  data: DataFields;
  handleSubmit?: (data: DataFields) => void;
  paymentTypes: PaymentType[];
};

export const EditPayment = memo((props: EditPaymentProps) => {
  const { paymentTypes } = props;
  const form = useFormik<DataFields>({
    initialValues: props.data,
    validate: (values) => validatePaymentFields(values.paymentType, values),
    onSubmit: (values) => {
      props.handleSubmit?.(values);
    },
  });

  const handleItemSelect = (fieldName: string): OnItemSelected => (_, itemSelected) => {
    form.setFieldValue(fieldName, itemSelected.id);
  };

  const handlePaymentTypeChange: OnItemSelected = (_, itemSelected) => {
    form.setFieldValue('paymentType', {
      id: Number(itemSelected.id),
      name: getPaymentNameById(paymentTypes, Number(itemSelected.id)),
    });
  };

  const pickedPayment = getPaymentMethod(form.values.paymentType?.name);
  const fields = getPaymentMethodFields(form.values.paymentType?.name);

  const getError = (fieldName: PaymentMethodFieldNames) => {
    return form.errors[fieldName] && form.touched[fieldName];
  };

  return (
    <form data-testid="TestId__EDITPAYMENT__FORM" onSubmit={form.handleSubmit}>
      <Columns>
        <div>
          <Input
            data-testid="TestId__EDITPAYMENT__FORM__FIELD__DROPDOWN__paymentType"
            label="Payment Via"
            name="paymentType"
            layout="fluid"
            mode="selectable"
            state="inactive"
            value={pickedPayment?.label}
            showInputResults
            inputSearch={{
              dataList: getPaymentMethodsSearchDataList(paymentTypes),
              onItemSelected: handlePaymentTypeChange,
            }}
            onBlur={form.handleBlur}
          />
        </div>

        {pickedPayment &&
          fields.map((field) => (
            <div
              data-testid={`TestId__EDITPAYMENT__FORM__FIELD__WRAPPER__${field.name.replace(
                /\s/g,
                '',
              )}`}
              key={field.name}
            >
              {field.type === 'dropdown' ? (
                <Input
                  data-testid={`TestId__EDITPAYMENT__FORM__FIELD__DROPDOWN__${field.name.replace(
                    /\s/g,
                    '',
                  )}`}
                  label={field.label}
                  name={field.name}
                  layout="fluid"
                  mode="selectable"
                  state={getError(field.name) ? 'error' : 'inactive'}
                  showInputResults
                  onBlur={form.handleBlur}
                  value={getCountryDataListEntry(getFieldValue(field.name, form.values))?.value}
                  inputSearch={{
                    dataList: getCountriesDataList(),
                    onItemSelected: handleItemSelect(field.name),
                  }}
                />
              ) : (
                <Input
                  data-testid={`TestId__EDITPAYMENT__FORM__FIELD__INPUT__${field.name.replace(
                    /\s/g,
                    '',
                  )}`}
                  label={field.label}
                  layout="fluid"
                  mode="writable"
                  state={getError(field.name) ? 'error' : 'inactive'}
                  value={getFieldValue(field.name, form.values)}
                  name={field.name}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
              )}
            </div>
          ))}

        <ColumnsFullRow>
          <Input
            label="Additional Banking Notes"
            name="notes"
            layout="fluid"
            mode="writable"
            state="inactive"
            value={form.values.notes || ''}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Enter notes..."
            data-testid="info.paymentNotes"
          />
        </ColumnsFullRow>
      </Columns>

      <div className={styles.ButtonWrapper}>
        <Button
          as="button"
          type="submit"
          layout="autolayout"
          variant="primary"
          disabled={!form.dirty ? false : shouldDisableSubmit(form)}
        >
          Confirm Changes
        </Button>
      </div>
    </form>
  );
});
