import { PropsWithChildren, memo } from 'react';

import { Checkbox, Panel, Paragraph, RadioButton } from 'design-system/Atoms';
import { QuestionItem } from 'types/types';
import { getAnswerString } from 'utils/answersHelper';

import styles from './OptionSelector.module.scss';

export interface OptionSelectorProps {
  handleChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  questionItems?: QuestionItem[];
  allowMultipleItemsSelection?: boolean;
}

export const OptionSelector = memo((props: PropsWithChildren<OptionSelectorProps>) => {
  if (props.disabled) {
    if (props.value) {
      return (
        <>
          <Panel className={styles.AnswersContainer}>
            {(props.value?.split('; \n') || []).map((answer, index) => (
              <Paragraph
                key={`answer-${index}`}
                data-testid={`TestId__Options__Answer${index}`}
                className={styles.AnswerItem}
              >
                {answer}
              </Paragraph>
            ))}
          </Panel>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <div data-testid="TestId__OPTIONSELECTORWRAPPER">
      {props.questionItems?.map((questionItem, index) =>
        !props.allowMultipleItemsSelection ? (
          <div
            className={styles.ListFormItem}
            key={`QuestionlistRadio-${index}`}
            data-testid="TestId__OPTIONSELECTORRADIO"
          >
            <RadioButton
              checked={props.value === questionItem.text}
              onChange={() => {
                props.handleChange?.(questionItem.text);
              }}
              id={`${questionItem.id}-checkboxOptionItem`}
              data-testid={`${questionItem.id}-radioOptionItem`}
              label={questionItem.text}
            />
          </div>
        ) : (
          <div
            className={styles.ListFormItem}
            key={`QuestionlistCheckbox-${index}`}
            data-testid="TestId__OPTIONSELECTORCHECKBOX"
          >
            <Checkbox
              checked={(props.value?.split('; \n') || []).some(
                (answer) => answer === questionItem.text,
              )}
              onChange={() => {
                const answerArray = props.value?.split('; \n') || [];
                const newAnswerArray = answerArray.includes(questionItem.text)
                  ? answerArray.filter((item) => item !== questionItem.text)
                  : [...answerArray, questionItem.text];

                const newAnswer = getAnswerString(newAnswerArray);
                props.handleChange?.(newAnswer);
              }}
              id={`${questionItem.id}-checkboxOptionItem`}
              data-testid={`${questionItem.id}-checkboxOptionItem`}
              label={questionItem.text}
              noVerticalMargin
            />
          </div>
        ),
      )}
    </div>
  );
});
