import { createContext, useContext } from 'react';

import { TokenResponse, OverridableTokenClientConfig } from '@react-oauth/google';

interface AuthTokenResponse extends TokenResponse {
  authuser?: string;
}

interface GoogleTokenClient {
  requestAccessToken: (overridableClientConfig?: OverridableTokenClientConfig) => void;
}

interface GoogleApiContextData {
  flags: {
    gsiLoaded: boolean;
    gapiLoaded: boolean;
  };

  checkAuth(): void;
  isAuthenticated(): boolean;
  createGoogleCalendarEvent(
    event: {
      title: string;
      startTime: Date;
      durationInMin: number;
      membersEmails: string[];
      eventDescription: string;
    },
    tokenResponse?: AuthTokenResponse,
  ): Promise<string | undefined>;
}

const GoogleApiContext = createContext<GoogleApiContextData>({
  flags: {
    gapiLoaded: false,
    gsiLoaded: false,
  },
} as GoogleApiContextData);

const useGoogleApi = () => useContext(GoogleApiContext);

export type { GoogleTokenClient, GoogleApiContextData, AuthTokenResponse };

export { GoogleApiContext, useGoogleApi };
