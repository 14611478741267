import { MouseEvent, KeyboardEvent, KeyboardEventHandler, MouseEventHandler } from 'react';

const isKeyboardEvent = <T>(event: KeyboardEvent<T>) =>
  ['keydown', 'keypress', 'keyup'].includes(event.type) && ['Enter', ' '].includes(event.key);

export const mimicKeyboardClick: <T>(
  clickHandler?: MouseEventHandler,
) => KeyboardEventHandler<T> | undefined = (clickHandler) => (e) =>
  isKeyboardEvent(e) && clickHandler?.((e as unknown) as MouseEvent);

export default isKeyboardEvent;
