import { memo } from 'react';

import { components, OptionProps, SingleValueProps } from 'react-select';

import UserAvatar from 'components/user/UserAvatar';
import { UserApiModel } from 'types/types';
import {
  useGetUsers,
  useGetUsersBasic,
  useGetUsersForAppreciation,
  useGetUsersForCommunity,
} from 'utils/apiQueryHooks';

import { ApiQuerySelect, ApiQueryProps } from '../ApiQuerySelect';

const SingleValue = memo(({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue {...props}>
    <UserAvatar user={props.data.option} size={32} />{' '}
    <span style={{ marginLeft: '2rem', verticalAlign: 'middle' }}>{children}</span>
  </components.SingleValue>
));

const Option = memo(({ children, ...props }: OptionProps<any>) => (
  <components.Option {...props}>
    <UserAvatar user={props.data.option} size={32} />{' '}
    <span style={{ marginLeft: '2rem' }}>{children}</span>
  </components.Option>
));

const getFilterOptions = (userIds?: number[]) => {
  if (userIds?.length) {
    return (o: UserApiModel) => Boolean(o.isActive && !userIds.includes(o.id));
  }

  return (o: UserApiModel) => Boolean(o.isActive);
};

export type UserSelectProps<IsMulti> = {
  withAvatar?: boolean;
  excludeUsers?: number[];
  isBasic?: boolean;
  isForAppreciation?: boolean;
  isCommunityPage?: boolean;
  isMulti?: IsMulti;
  isMedium?: boolean;
  hideSelectedOptions?: boolean;
  className?: string;
  useGetQuery?: any;
} & Omit<ApiQueryProps<UserApiModel, IsMulti>, 'useGetQuery'>;

function getHook(isForAppreciation?: boolean, isCommunityPage?: boolean, isBasic?: boolean) {
  if (isForAppreciation) {
    return useGetUsersForAppreciation;
  }
  if (isCommunityPage) {
    return useGetUsersForCommunity;
  }
  if (isBasic) {
    return useGetUsersBasic;
  }
  return useGetUsers;
}

function UserSelect<IsMulti = false>(props: UserSelectProps<IsMulti>) {
  const { excludeUsers, isBasic, isForAppreciation, isCommunityPage, withAvatar } = props;
  const filterOptions = getFilterOptions(excludeUsers);
  const getQuery = getHook(isForAppreciation, isCommunityPage, isBasic);

  return (
    <ApiQuerySelect
      isClearable
      sortOptions
      filterOptions={filterOptions}
      mapLabel={(_name, option) => option.displayName}
      isFullWidth
      useGetQuery={getQuery}
      styles={{ ...props.styles }}
      {...(withAvatar && {
        components: {
          Option,
          SingleValue,
        },
      })}
      {...props}
    />
  );
}

export default memo(UserSelect) as typeof UserSelect;
