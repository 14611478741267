import {
  paymentData,
  DataFields,
  PaymentMethod,
  PaymentMethodName,
  PaymentMethodField,
  PaymentMethodFields,
  PaymentMethodFieldWithName,
} from './types';

export const getPaymentMethodFields = (
  method?: PaymentMethodName,
): PaymentMethodFieldWithName[] => {
  if (!method) {
    return [];
  }

  const fields = paymentData[method].fields;
  const fieldNames = (Object.keys(fields) as unknown) as Array<keyof PaymentMethodFields>;

  return fieldNames.map((fieldName) => ({
    name: fieldName,
    ...getPaymentMethodField(fieldName, fields),
  }));
};

const getPaymentMethodField = (
  name: keyof PaymentMethodFields,
  fields: PaymentMethod['fields'],
): PaymentMethodField => fields[name];

export const getFieldValue = (name: keyof PaymentMethodFields, data: DataFields) => {
  return data[name];
};
