import classNames from 'classnames';
import { Avatar, Paragraph, Button, Spacing, Divider } from 'design-system';

import styles from './Comment.module.scss';

export interface CommentProps {
  userName: string | undefined;
  userAvatarSrc: string | undefined;
  teamName?: string | null | undefined;
  teamIconLocation?: string | null | undefined;
  date: string;
  text: string | undefined;
  likes: number;
  onClickLike?: () => void;
  className?: string;
  contentClassName?: string;
  hideDivider?: boolean;
}

export const Comment = (props: CommentProps) => {
  const renderLikes = () => {
    if (props.likes > 1) {
      return `${props.likes} likes`;
    }
    if (props.likes === 1) {
      return `${props.likes} like`;
    }
    return null;
  };
  return (
    <div className={classNames(styles.Comment, props.className)}>
      <div className={classNames(styles.CommentContent, props.contentClassName)}>
        <Spacing size="small" align="center" direction="row">
          <Avatar name={props.userName} imgSrc={props.userAvatarSrc} />
          <div className={styles.User}>
            <Paragraph variant="ui-bold" className={styles.CommentUserName}>
              {props.userName}{' '}
              {props?.teamName && props?.teamIconLocation && (
                <img
                  src={props.teamIconLocation}
                  className={styles.TeamIconStyle}
                  alt={props.teamName}
                />
              )}
            </Paragraph>
            <Paragraph variant="ui-regular" className={styles.CommentDate}>
              {props.date}
            </Paragraph>
          </div>
        </Spacing>
        <div>
          <Paragraph variant="ui-regular">{props.text}</Paragraph>
        </div>
        <div
          className={classNames(
            (props?.likes > 0 || props?.onClickLike) && styles.Footer,
            props?.likes === 0 && !props?.onClickLike && styles.HideElement,
          )}
        >
          <Button
            as="button"
            iconPosition="start"
            iconName="like"
            layout="autolayout"
            variant="icon"
            data-testid="TEST_ID_LIKE_BUTTON"
            onClick={props.onClickLike}
            disabled={props?.onClickLike ? false : true}
          >
            {renderLikes()}
          </Button>
        </div>
      </div>
      {!props.hideDivider && <Divider />}
    </div>
  );
};

Comment.defaultProps = {
  userName: '',
  userAvatarSrc: '',
  teamName: '',
  teamIconLocation: '',
  text: '',
  likes: 0,
  onClickLike: undefined,
};
