import {
  ChangeEvent,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useEffect,
  useState,
  MouseEvent,
  useRef,
  useCallback,
  KeyboardEvent,
} from 'react';

import cs from 'classnames';
import { Icon, Label, Paragraph, handleEnterUpEvent, DataList } from 'design-system';

import { InputResults } from './InputResult';

import styles from './input.module.scss';

export type OnItemSelected = (event: MouseEvent<HTMLDivElement>, itemSelected: DataList) => void;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
  placeholder?: string;
  labelHelper?: string;
  rightComponent?: ReactNode;
  leftComponent?: ReactNode;
  label?: string;
  layout: 'autolayout' | 'fluid';
  state: 'inactive' | 'focused' | 'filled' | 'disabled' | 'error' | 'success';
  mode: 'writable' | 'selectable' | 'writable-and-selectable';
  value?: string;
  noMargins?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  showInputResults?: boolean;
  inputSearch?: {
    dataList?: DataList[];
    inMemoryFilter?: boolean;
    highlightMatchResult?: boolean;
    onItemSelected?: OnItemSelected;
  };
  className?: string;
  inputClassName?: string;
  inputResultsClassName?: string;
  paragraphClassName?: string;
  requiredLabelClassName?: string;
  onEnterUpEvent?: () => any;
  forceAutoFocus?: boolean;
  inputTestId?: string;
  forceStopResultInfo?: boolean;
  resetStyle?: boolean;
}

export const Input = memo<InputProps>((props) => {
  const {
    testId,
    inputClassName = '',
    inputResultsClassName = '',
    placeholder = 'Type in here',
    layout,
    state,
    labelHelper,
    label,
    required,
    mode,
    rightComponent,
    leftComponent,
    value,
    onChange,
    showInputResults,
    inputSearch,
    className,
    paragraphClassName,
    requiredLabelClassName,
    onEnterUpEvent,
    forceAutoFocus,
    inputTestId,
    forceStopResultInfo,
    resetStyle = false,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(value ?? '');
  const [inputResultInfo, setInputResultInfo] = useState<{
    showResult: boolean;
    source?: 'USER_TYPE' | 'USER_CLICK';
    onBlurSource?: 'INPUT' | 'RIGHT_COMPONENT';
    currentBottomPosition?: number;
  }>({ showResult: false, currentBottomPosition: 0 });

  const inputRef = useRef<HTMLInputElement>(null);
  const rightComponentRef = useRef<HTMLDivElement>(null);
  const inputResultRef = useRef<HTMLDivElement>(null);

  const closeInputResult = useCallback(() => {
    !forceStopResultInfo &&
      setInputResultInfo((prevState) => ({
        ...prevState,
        showResult: false,
        source: 'USER_CLICK',
        currentBottomPosition: 0,
      }));
  }, [forceStopResultInfo]);

  useEffect(() => {
    setCurrentValue(value ?? '');
  }, [value]);

  useEffect(() => {
    if (!currentValue && inputResultInfo.showResult && inputResultInfo.source === 'USER_TYPE') {
      closeInputResult();
    }
  }, [closeInputResult, currentValue, inputResultInfo]);

  useEffect(() => {
    if (
      inputResultInfo.showResult &&
      document.activeElement !== inputRef.current &&
      document.activeElement !== rightComponentRef.current
    ) {
      closeInputResult();
    }
  }, [closeInputResult, inputResultInfo.showResult]);

  const onInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
    !forceStopResultInfo &&
      setInputResultInfo((prevState) => ({
        ...prevState,
        showResult: true,
        source: 'USER_TYPE',
      }));
    onChange?.(event);
  };

  const onItemSelectedHandler = (event: MouseEvent<HTMLDivElement>, itemSelected: DataList) => {
    setCurrentValue(itemSelected.value);
    !forceStopResultInfo &&
      setInputResultInfo((prevState) => ({
        ...prevState,
        showResult: !prevState.showResult,
        source: 'USER_CLICK',
      }));
    inputSearch?.onItemSelected?.(event, itemSelected);
  };

  const onClickRightComponentHandler = () => {
    if (state === 'disabled') {
      return;
    }
    toggleInputResult();
  };

  const toggleInputResult = () => {
    !forceStopResultInfo &&
      setInputResultInfo((prevState) => ({
        ...prevState,
        showResult: !prevState.showResult,
        source: 'USER_CLICK',
      }));
  };

  const layoutClasses = cs({
    [styles['layout--autolayout']]: layout === 'autolayout',
    [styles['layout--fluid']]: layout === 'fluid',
  });

  const inputAndStateBaseClass = props.noMargins
    ? styles.mainContainerNoMargin
    : styles.mainContainer;

  const inputClasses = cs(inputAndStateBaseClass, layoutClasses, className);
  const paragraphClasses = cs(styles.label, paragraphClassName);
  const requiredLabelClasses = cs(styles['label-required'], requiredLabelClassName);

  const stateClasses = cs(inputAndStateBaseClass, props.className, {
    [styles['state--inactive']]: state === 'inactive',
    [styles['state--focused']]: state === 'focused',
    [styles['state--disabled']]: state === 'disabled',
    [styles['state--filled']]: state === 'filled',
    [styles['state--error']]: state === 'error',
    [styles['state--success']]: state === 'success',
    [styles.CommonInputResetStyle]: resetStyle,
  });

  const isSelectable = mode === 'selectable' || mode === 'writable-and-selectable';

  const inputLeftComponentClasses = cs(styles.input, inputClassName, {
    [styles['withLeftComponent']]: leftComponent,
    [styles['withRightComponent']]: !isSelectable && rightComponent,
  });

  const inputResultClasses = cs(layoutClasses, styles.inputResult, inputResultsClassName);

  const displayDefaultSelectableIcon = isSelectable && !rightComponent;
  const displayCustomSelectableIcon = isSelectable && rightComponent;
  return (
    <div data-testid={testId} className={stateClasses}>
      {label && (
        <div>
          <Label
            type="dark"
            wrapperClassName={styles.labelWrapper}
            paragraphClassName={paragraphClasses}
            interactive={false}
            showBackground={false}
            text={label}
          />
          {required && <p className={requiredLabelClasses}>*</p>}
        </div>
      )}
      <div className={inputClasses}>
        {leftComponent && (
          <div id={'leftComponent'} className={styles.leftComponent}>
            {leftComponent}
          </div>
        )}

        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={forceAutoFocus}
          data-testid={inputTestId || 'atomInput'}
          name={props.name}
          type={props.type || 'text'}
          ref={inputRef}
          placeholder={placeholder}
          value={currentValue}
          onChange={onInputChangeHandler}
          className={inputLeftComponentClasses}
          disabled={state === 'disabled'}
          onBlur={(e) => {
            !forceStopResultInfo &&
              setInputResultInfo((prevState) => ({
                ...prevState,
                onBlurSource: 'INPUT',
              }));
            props.onBlur?.(e);
          }}
          onKeyUp={(event: KeyboardEvent) => handleEnterUpEvent(event, onEnterUpEvent)}
          {...rest}
        />

        {displayDefaultSelectableIcon && (
          <div
            data-testid="INPUT_RIGHT_COMPONENT"
            onClick={onClickRightComponentHandler}
            onBlur={() => {
              !forceStopResultInfo &&
                setInputResultInfo((prevState) => ({
                  ...prevState,
                  onBlurSource: 'RIGHT_COMPONENT',
                  showResult: false,
                }));
            }}
            onKeyPress={onClickRightComponentHandler}
            className={styles.selectableChevron}
            tabIndex={0}
            ref={rightComponentRef}
            role={'button'}
          >
            <Icon name="chevron-down"></Icon>
          </div>
        )}

        {displayCustomSelectableIcon && (
          <div
            data-testid="INPUT_RIGHT_COMPONENT"
            ref={rightComponentRef}
            onClick={onClickRightComponentHandler}
            onKeyPress={onClickRightComponentHandler}
            className={styles.rightComponent}
            tabIndex={0}
            role={'button'}
            onBlur={() => {
              !forceStopResultInfo &&
                setInputResultInfo((prevState) => ({
                  ...prevState,
                  onBlurSource: 'RIGHT_COMPONENT',
                }));
            }}
          >
            {rightComponent}
          </div>
        )}

        {!isSelectable && rightComponent && (
          <div id={'rightComponent'} className={styles.rightComponent}>
            {rightComponent}
          </div>
        )}
      </div>
      <div ref={inputResultRef} className={inputResultClasses}>
        {inputResultInfo.showResult && showInputResults && inputSearch && (
          <InputResults
            items={inputSearch.dataList}
            onItemSelected={onItemSelectedHandler}
            value={currentValue}
            inMemoryFilter={inputSearch.inMemoryFilter}
            highlightMatchResult={inputSearch.highlightMatchResult}
          />
        )}
      </div>
      {labelHelper && <Paragraph className={styles.labelHelper}>{labelHelper}</Paragraph>}
    </div>
  );
});
