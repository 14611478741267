import { useCallback, useState } from 'react';

import { Toast } from 'design-system';

import { InvoiceItem, UnleashBudget } from 'types/types';

export function calculatedData(
  reimbursements: InvoiceItem[],
  draftReimbursements: Partial<InvoiceItem>,
  availableUnleashBudget: number,
) {
  const updatedReimbursements = reimbursements.slice(0);
  const index = updatedReimbursements.findIndex((item) => item.id === draftReimbursements.id);
  updatedReimbursements.splice(index, index === -1 ? 0 : 1, {
    ...updatedReimbursements[index],
    ...draftReimbursements,
  });
  const totalSum = updatedReimbursements.reduce(
    (acc, item) => acc + (item.usdPrice || item.rate) * item.quantity,
    0,
  );
  return {
    errors: {
      isOverUnleashLimit: totalSum > availableUnleashBudget,
    },
    totalSum,
  };
}

export function useValidateReimbursements(unleashBudget: UnleashBudget) {
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const handleCloseToast = useCallback(() => setShouldShowToast(false), []);
  const totalUnleashBudget = unleashBudget.total || 0;
  const availableUnleashBudget = totalUnleashBudget - (unleashBudget?.used || 0);
  const toastMessage = `You can reimburse up to ${totalUnleashBudget.toLocaleString()} USD per year in Unleash+ expenses. Please reduce the amount you’re requesting to continue.`;

  const validateReimbursements = useCallback(
    (reimbursements: InvoiceItem[], draftReimbursements: Partial<InvoiceItem>) => {
      const { errors } = calculatedData(
        reimbursements,
        draftReimbursements,
        availableUnleashBudget,
      );
      setShouldShowToast(errors.isOverUnleashLimit);
      return Object.values(errors).every((error) => !error);
    },
    [availableUnleashBudget],
  );

  const renderWarnings = useCallback(() => {
    return (
      <Toast
        shouldShow={shouldShowToast}
        message={toastMessage}
        onClose={handleCloseToast}
        type={'ERROR'}
      />
    );
  }, [shouldShowToast, toastMessage, handleCloseToast]);

  return {
    renderWarnings,
    validateReimbursements,
  };
}
