import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './StepWrap.module.css';

type Props = {
  isHidden?: boolean;
};

const StepWrap = (props: PropsWithChildren<Props>) => {
  const { children, isHidden } = props;
  const wrapClasses = classNames(isHidden && styles.Wrap_hidden);

  return <span className={wrapClasses}>{children}</span>;
};

export default StepWrap;
