import { useState, useMemo, useCallback } from 'react';

import { brandConfig } from 'brands';

import Row from 'components/Row';
import Button from 'components/buttons/Button';
import Legend from 'components/legends/Legend';
import DisplayPayment from 'components/payments/DisplayPayment';
import { Payment as PaymentEdit } from 'components/payments/Payment';
import EditStep from 'components/steps/EditStep';
import Footer from 'components/steps/StepFooter';
import Title from 'components/steps/StepTitle';
import Wrap from 'components/steps/StepWrap';
import Step from 'components/steps/SubmitStep';
import { PROFILE } from 'consts/routing';
import { User, AccountingData, Invoice } from 'types/types';

import styles from './Step4.module.scss';

export type Step4Props = {
  currentStep: number;
  user: User;
  invoice: Invoice;
  shouldSkipSurvey?: boolean;
  updateInvoice: (accountingData: Partial<Invoice>) => Promise<void>;
  selectStep: (step: number) => void;
  history?: any;
};

const STEP = 4;

const Step4: React.FC<Step4Props> = ({
  currentStep,
  selectStep,
  invoice,
  user,
  updateInvoice,
  history,
}) => {
  const [accountingData, setAccountingData] = useState<AccountingData | null | undefined>(
    invoice._accountingData || user._accountingData,
  );
  const [showEditPayment, setShowEditPayment] = useState(false);
  const isActive = useMemo(() => currentStep === STEP, [currentStep]);
  const { isXWP } = brandConfig;

  const handleUpdateInvoice = useCallback(
    (_accountingData: AccountingData) => {
      const updateObject = {
        _accountingData,
      };

      setAccountingData(_accountingData);
      updateInvoice(updateObject);
    },
    [updateInvoice],
  );

  return (
    <Step>
      <Title step={STEP} isActive={isActive}>
        Banking Info
        <EditStep
          isSubmit
          onClick={selectStep(STEP)}
          showSortUpIcon={currentStep === STEP}
          showEditIcon={currentStep > STEP}
        />
      </Title>

      <Wrap isHidden={!isActive}>
        <Legend noPadding submitStep message="Confirm your banking information" />
        {!isXWP && (
          <>
            {accountingData && (
              <DisplayPayment
                accountingData={accountingData}
                showExpandButton={false}
                showEditButton={false}
                expanded={false}
              />
            )}
            <Row noPadding>
              <Button
                className={styles.editBanking}
                data-testid="updateBankingInfoButton"
                isBankingConfirm
                onClick={() =>
                  history.push({
                    pathname: PROFILE.BANKING_PATH,
                  })
                }
                isGray
              >
                Click here to change your bank details
              </Button>
            </Row>
          </>
        )}
        {isXWP && (
          <>
            {showEditPayment && accountingData ? (
              <PaymentEdit
                payment={accountingData}
                onSave={(_accountingData) => {
                  handleUpdateInvoice(_accountingData);
                  setShowEditPayment(false);
                }}
              />
            ) : (
              accountingData && (
                <DisplayPayment
                  accountingData={accountingData}
                  showExpandButton={false}
                  showEditButton={false}
                  expanded={false}
                />
              )
            )}
            {!showEditPayment && (
              <Row noPadding>
                <Button
                  data-testid="updateBankingInfoButton"
                  isBankingConfirm
                  onClick={() => setShowEditPayment(true)}
                  isGray
                >
                  Edit
                </Button>
              </Row>
            )}
          </>
        )}
        <Footer>
          <Row noPadding>
            <Button submitNext onClick={selectStep(STEP + 1)}>
              Next
            </Button>
          </Row>
        </Footer>
      </Wrap>
      <Wrap isHidden={isActive}>
        {accountingData && (
          <DisplayPayment
            accountingData={accountingData}
            showExpandButton={false}
            expanded={false}
          />
        )}
      </Wrap>
    </Step>
  );
};

export default Step4;
