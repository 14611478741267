import { useCallback } from 'react';

import 'react-activity-feed/dist/index.css';
import { FlatFeed, InfiniteScrollPaginator } from 'react-activity-feed';

import { AutoNotifier } from '../AutoNotifier';
import {
  Activity,
  FeedActivityProps,
  FeedProps,
  FeedWrapperProps,
  POST_OPERATION_TYPES,
  NotifierProps,
} from '../Feed.types';
import FeedPost from '../FeedPost';
import NewPost from '../NewPost';

const GlobalFeed = (props: FeedProps) => {
  const {
    user,
    shouldRefresh,
    toggleFeedRefresh,
    editPost,
    handlePostRemove,
    onClickPost,
    limit,
    selectedActivity,
    resetActivity,
    handlePostUpdate,
  } = props;

  const isEditingActivity = useCallback(
    (activityItem: Activity) => {
      const { activity, operation } = selectedActivity || {};

      return (
        activity?.foreign_id === activityItem?.foreign_id &&
        operation !== POST_OPERATION_TYPES.DELETE
      );
    },
    [selectedActivity],
  );

  return (
    <FlatFeed
      feedGroup="global"
      notify
      Notifier={(props: NotifierProps) => (
        <AutoNotifier
          {...props}
          shouldRefresh={shouldRefresh}
          triggerFeedRefresh={toggleFeedRefresh}
        />
      )}
      options={{
        limit: limit || 40,
      }}
      Activity={(props: FeedActivityProps) => {
        if (isEditingActivity(props.activity)) {
          return (
            <NewPost
              selectedActivity={selectedActivity}
              onResetForm={resetActivity}
              onConfirmUpdate={handlePostUpdate}
              triggerFeedRefresh={toggleFeedRefresh}
              isInlineForm
            />
          );
        }

        return (
          <FeedPost
            {...props}
            user={user}
            onPostEdit={editPost}
            onPostRemove={handlePostRemove}
            onClickPost={onClickPost}
            isAllFilter
          />
        );
      }}
      Paginator={(props: any) => <InfiniteScrollPaginator {...props} />}
    />
  );
};

const FeedWrapper = (props: FeedWrapperProps) => {
  return (
    <>
      <div>
        <GlobalFeed
          {...props}
          limit={100}
          shouldRefresh={props.shouldRefresh}
          toggleFeedRefresh={props.toggleFeedRefresh}
        />
      </div>
    </>
  );
};

export default FeedWrapper;
