import { PropsWithChildren, memo } from 'react';

import {
  StepCard,
  StepCardButtons,
  MoodPicker,
  Textarea,
  Mood,
  OptionSelector,
} from 'design-system';

import { INVOICE_STATUS } from 'consts/constants';
import {
  InvoiceStatusName,
  Props,
  QuestionItem,
  QuestionTypeDefinition,
  StepActions,
} from 'types/types';
import { isActive } from 'utils/activeStateHelper';

import { getButtonState } from './QuestionCard.helper';

type StepCardProps = Omit<Props<typeof StepCard>, 'bottomContent' | 'sideContent'>;

export type QuestionCardProps = PropsWithChildren<
  StepCardProps &
    StepActions & {
      type: QuestionTypeDefinition;
      answer?: Mood | string;
      onQuestionAnswer?: (answer: Mood | string) => void;
      isRequired: boolean;
      nextButtonText?: string;
      status?: InvoiceStatusName;
      onBlur?: () => void;
      placeholder?: string;
      questionItems?: QuestionItem[];
      allowMultipleItemsSelection?: boolean;
    }
>;

export const QuestionCard = memo((props: QuestionCardProps) => {
  const buttonState = getButtonState(props.state, props.isRequired, props.answer);
  const active = isActive(props.state);
  const questionState = active ? true : undefined;

  const extraProps = active
    ? {
        onClick: props.activeHandler,
      }
    : {
        onClick: props.inactiveHandler,
      };

  return (
    <StepCard
      {...props}
      description={props.description}
      sideContent={
        props.type === 'mood' ? (
          <MoodPicker
            selectedMood={props.answer as Mood}
            handleMoodPick={questionState && props.onQuestionAnswer}
          />
        ) : props.type === 'list' ? (
          <OptionSelector
            {...props}
            handleChange={props.onQuestionAnswer}
            allowMultipleItemsSelection={props.allowMultipleItemsSelection}
            value={props.answer}
            disabled={!active}
          />
        ) : (
          <Textarea
            disabled={Boolean(!questionState)}
            value={props.answer as string}
            handleChange={props.onQuestionAnswer}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
          />
        )
      }
      bottomContent={
        (props.status === INVOICE_STATUS.SUBMITTED || !props.status) && (
          <StepCardButtons
            state={buttonState}
            text={active ? props.nextButtonText || 'Next' : 'Edit'}
            {...extraProps}
          />
        )
      }
    />
  );
});
