import { FunctionComponent, useState, useCallback, useMemo, ChangeEvent } from 'react';

import { isNil, get, debounce } from 'lodash';

import Label from 'components/Label';
import Button from 'components/buttons/Button';
import Textarea from 'components/inputs/Textarea';
import Legend from 'components/legends/Legend';
import Footer from 'components/steps/StepFooter';
import Title from 'components/steps/StepTitle';
import Wrap from 'components/steps/StepWrap';
import Step from 'components/steps/SubmitStep';
import { Invoice, InvoiceCategory, User } from 'types/types';
import { hasUserFilledBanking } from 'utils/profileBankingHelper';

type Error = {
  error?: string;
};

export type Step5Props = {
  currentStep: number;
  invoice: Invoice;
  user: User;
  referralInvoiceCategory: InvoiceCategory;
  shouldSkipSurvey?: boolean;
  updateInvoice: (updateData: Partial<Invoice>) => Promise<Error>;
  handleSubmit: () => Promise<void>;
};

const STEP = 5;
const WAIT_INTERVAL = 4000;

const Step5: FunctionComponent<Step5Props> = ({
  currentStep,
  invoice,
  user,
  referralInvoiceCategory,
  shouldSkipSurvey,
  updateInvoice,
  handleSubmit,
}) => {
  const [notes, setNotes] = useState<string>(invoice?.notes || '');

  const isActive = useMemo(() => currentStep === STEP, [currentStep]);

  const isValid = useMemo(() => {
    // we need to have some not empty invoice items
    const hasEmptyItems = !invoice?._invoiceItems?.some((item) => {
      const { quantity, rate, _category, _expenseCategory, _project, name } = item;

      const isValidRate = (user?.allow0Rate && !rate) || rate > 0;

      if (quantity <= 0 || !isValidRate || (!_category && !_expenseCategory) || !_project) {
        return false;
      }

      const itemIsReferral =
        _category?.id === referralInvoiceCategory.id ||
        _expenseCategory?.id === referralInvoiceCategory.id;
      const itemHasName = !isNil(name);

      return itemIsReferral ? itemHasName : true;
    });

    if (hasEmptyItems) {
      const allow0Rate = user?.allow0Rate;
      if (!allow0Rate) {
        return false;
      }
    }

    // also we need valid accounting data in user
    return hasUserFilledBanking({ _accountingData: user._accountingData });
  }, [invoice, user, referralInvoiceCategory]);

  const submit = useCallback(async () => {
    const { error } = await updateInvoice({ notes });
    if (!error) await handleSubmit();
  }, [notes, handleSubmit, updateInvoice]);

  const updateNotes = debounce(() => {
    updateInvoice({ notes });
  }, WAIT_INTERVAL);

  const handleNotesChange = (event: ChangeEvent) => setNotes(get(event, 'target.value'));

  return (
    <Step>
      <Title step={STEP} isActive={isActive}>
        Notes
      </Title>

      <Wrap isHidden={!isActive}>
        <Legend noPadding submitStep message="Do you have anything to note?" />

        <Label submitStep>Additional Notes</Label>
        <Textarea
          data-testid="invoiceNotes"
          noPadding
          isFullWidth
          submitStep
          placeholder="Add notes"
          onChange={handleNotesChange}
          onBlur={updateNotes}
          value={notes}
        />
        <Footer>
          <Button data-testid="buttonGoToSurvey" submitNext disabled={!isValid} onClick={submit}>
            {shouldSkipSurvey ? 'Submit Invoice' : 'Go To Survey'}
          </Button>
        </Footer>
      </Wrap>
    </Step>
  );
};

export default Step5;
