import { memo, MouseEventHandler, SyntheticEvent } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'design-system/Atoms/Button';
import { Card } from 'design-system/Atoms/Card';
import { Image } from 'design-system/Atoms/Image';
import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './InfoBox.module.scss';

export enum OrientationOption {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type ActionClickHandler = (
  e: SyntheticEvent<HTMLButtonElement> | undefined,
) => MouseEventHandler<HTMLButtonElement | undefined> | void;

export type InfoBoxProps = {
  title: string;
  description: string;
  imgSrc: string;
  orientation?: OrientationOption;
  actionText?: string;
  onActionClick: ActionClickHandler;
};

export const InfoBox = memo<InfoBoxProps>(
  ({ orientation = OrientationOption.HORIZONTAL, ...props }) => {
    const cardClasses = classNames(styles.InfoBox, {
      [styles.Horizontal]: isOrientationHorizontal(orientation),
      [styles.Vertical]: !isOrientationHorizontal(orientation),
    });

    return (
      <Card data-testid="TestId__INFOBOXWRAPPER" className={cardClasses}>
        <Image variant="squared" src={props.imgSrc} alt="infobox" className={styles.InfoBoxImage} />

        <div className={styles.InfoBoxContent}>
          <Paragraph variant="label" className={styles.InfoBoxTitle}>
            {props.title}
          </Paragraph>

          <Paragraph variant="ui-regular" className={styles.InfoBoxDescription}>
            {props.description}
          </Paragraph>

          <div>
            <Button
              data-testid="TestId__INFOBOX__CTA"
              layout={isOrientationHorizontal(orientation) ? 'autolayout' : 'fluid'}
              as="button"
              variant="secondary"
              onClick={props.onActionClick}
            >
              {isEmpty(props.actionText) ? 'Learn more here' : props.actionText}
            </Button>
          </div>
        </div>
      </Card>
    );
  },
);

const isOrientationHorizontal = (orientation: OrientationOption) =>
  orientation === OrientationOption.HORIZONTAL;
