/* eslint-disable @typescript-eslint/ban-ts-comment */
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { brandConfig } from 'brands';
import { AmountTotal, DataFields, InnerPage, SubmitInvoiceHeader } from 'design-system';
import { get, groupBy, omit } from 'lodash';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router';

import { PrivacyPolicyModal } from 'components/PrivacyPolicyModal';
import { Messages } from 'consts/Messages';
import {
  INVOICE_CATEGORY,
  INVOICE_ITEM_TYPES,
  INVOICE_STATUS,
  SUBMIT_INVOICE,
} from 'consts/constants';
import { INVOICE } from 'consts/routing';
import { handleProjectsAssignments, matchProjectsWithHarvestData } from 'helpers/harvest';
import {
  handleProductiveProjectsAssignmentsNewInvoice,
  matchProjectsWithProductiveData,
} from 'helpers/productive';
import {
  StepActions,
  Invoice,
  Assignment,
  HarvestAPIDataType,
  User,
  AccountingData,
  InvoiceCategory,
  InvoiceItem,
  File,
  UnleashBudget,
  ProductiveAPIDataType,
  JobOpeningApiModel,
} from 'types/types';
import { createRange, formatDate, normalizeISODate } from 'utils/datesHelper';
import { useStepHook } from 'utils/useStepHook';
import { useTotalAmount } from 'utils/useTotalAmount';

import { withSubmitData } from '../Submit/SubmitDataProvider';
import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';

export interface SubmitInvoiceProps {
  hoursInvoiceCategory: InvoiceCategory;
  user: User;
  invoice: Invoice;
  assignments: Assignment[];
  harvestData: HarvestAPIDataType[];
  harvestEnabled: boolean;
  productiveData: ProductiveAPIDataType[];
  productiveEnabled: boolean;
  unleashBudget: UnleashBudget;
  unleashPlusInvoiceCategory: InvoiceCategory;
  userInvoices: Invoice[];
  canSubmitDuplicateInvoicePeriod: boolean;
  shouldSkipSurvey: boolean;
  getHarvestTimeEntriesActionCreator: (options: {
    body: { harvestId?: string; start?: string; end?: string };
  }) => Promise<{ payload: { data: HarvestAPIDataType[] } }>;
  getProductiveTimeEntriesActionCreator: (options: {
    body: { productiveId?: string; start?: string; end?: string };
  }) => Promise<{ payload: { data: ProductiveAPIDataType[] } }>;
  getUserCurrentInvoiceApiActionCreator: (options: {
    params: { userId: number };
    body: Partial<Invoice & { createDraft: boolean }>;
  }) => Promise<{ payload: { data: Invoice } }>;
  updateInvoiceApiActionCreator: ({
    params,
    body,
  }: {
    params: { invoiceId: number };
    body: Invoice;
  }) => void;
  updateUserDataActionCreator: (user: User, source: string) => void;
  postInvoiceItemApiActionCreator: (payload: {
    body: Partial<InvoiceItem & { _invoice: { id: number } }>;
  }) => Promise<void>;
  putInvoiceItemApiActionCreator: ({
    params,
    body,
  }: {
    params: { invoiceItemId: number };
    body: Partial<InvoiceItem>;
  }) => void;
  deleteInvoiceItemApiActionCreator: (payload: {
    params: { invoiceItemId: number };
  }) => Promise<void>;
  uploadInvoiceItemFileApiActionCreator: (payload: {
    params: { invoiceItemId: number };
    body: FormData;
  }) => Promise<{ payload: { data: File[] } }>;
}

export interface HarvestAPITimeEntriesParams {
  harvestId: string;
  value: { start: string; end: string };
}

export interface ProductiveAPITimeEntriesParams {
  productiveId: string;
  value: { start: string; end: string };
}

export interface SubmitState {
  user: User;
  invoice: Invoice;
  showEditPayment: boolean;
  harvestAPIData?: HarvestAPIDataType[];
  harvestFetchError?: string;
  isLoadingHarvestData: boolean;
  productiveAPIData?: ProductiveAPIDataType[];
  productiveFetchError?: string;
  isLoadingProductiveData: boolean;
  showPrivacyPolicyModal: boolean;
}

export const SubmitInvoice = memo<SubmitInvoiceProps>((props) => {
  const {
    assignments,
    harvestEnabled: harvestEnabled_,
    harvestData,
    productiveEnabled: productiveEnabled_,
    productiveData,
    unleashBudget,
    unleashPlusInvoiceCategory,
    user,
    userInvoices,
    hoursInvoiceCategory,
    canSubmitDuplicateInvoicePeriod,
    shouldSkipSurvey,
    getUserCurrentInvoiceApiActionCreator,
    getHarvestTimeEntriesActionCreator,
    getProductiveTimeEntriesActionCreator,
    updateInvoiceApiActionCreator,
    updateUserDataActionCreator,
    postInvoiceItemApiActionCreator,
    putInvoiceItemApiActionCreator,
    deleteInvoiceItemApiActionCreator,
    uploadInvoiceItemFileApiActionCreator,
  } = props;

  const history = useHistory();

  const stepHandler = useStepHook(
    ['optional', 'optional', 'optional', 'optional', 'optional', 'optional'],
    () => {
      if (brandConfig.isXWP) {
        const jobs =
          queryCache.getQueryData<{ data: JobOpeningApiModel[] }>(['/job-openings'])?.data ?? [];
        history.replace({
          pathname: jobs?.length
            ? `${INVOICE.REFERRALS_PATH}/${invoice?.id}`
            : `${INVOICE.CONFIRMATION_PATH}/${invoice?.id}`,
          state: {
            fromSurvey: true,
          },
        });
      } else {
        history.push('/survey');
      }
    },
  );

  const [state, setState] = useState<SubmitState>({
    user,
    invoice: props.invoice,
    showEditPayment: false,
    isLoadingHarvestData: false,
    isLoadingProductiveData: false,
    showPrivacyPolicyModal: true,
  });
  const { invoice } = state;
  const [totalAmount, setAmount] = useTotalAmount();
  const isMounted = useRef(false);
  const [hasCompanyAssignments, setHasCompanyAssignments] = useState(false);
  const [hasProductiveAssignments, setHasProductiveAssignments] = useState(false);

  const activeAssignments = useMemo(
    () =>
      assignments.filter(
        (assignment) => assignment?._project?.canSubmitHoursInvoices && assignment?.isActive,
      ),
    [assignments],
  );

  const companyActiveAssignments = useMemo(
    () =>
      activeAssignments
        .filter((assignment) => assignment?._project?._companyId === invoice?._companyId)
        .sort((a, b) => String(a?._project?.name).localeCompare(String(b?._project?.name))),
    [activeAssignments, invoice?._companyId],
  );

  const reimbursementAssignments = companyActiveAssignments?.filter(
    (assignment) => !!assignment?._project?._allowedReimbursements?.length,
  );

  const harvestAssignments = useMemo(() => {
    if (!state.harvestAPIData || state.isLoadingHarvestData) return [];
    return companyActiveAssignments
      .filter(({ _project }) => _project?.harvestId)
      .map((assignment) => {
        let result = assignment;
        state.harvestAPIData?.forEach((project) => {
          if (String(project.projectId) === assignment?._project?.harvestId) {
            result = {
              ...result,
              taskBreakdownItemized: project.taskBreakdownItemized,
              taskBreakdownSummedByCategory: project.taskBreakdownSummedByCategory,
              totalHours: project.totalHours,
            };
          }
        });
        return result;
      });
  }, [companyActiveAssignments, state.harvestAPIData, state.isLoadingHarvestData]);

  const productiveAssignments = useMemo(() => {
    if (!state.productiveAPIData || state.isLoadingProductiveData) return [];
    return companyActiveAssignments
      .filter(({ _project }) => _project?.productiveId)
      .map((assignment) => {
        let result = assignment;
        state.productiveAPIData?.forEach((project) => {
          if (String(project.projectId) === assignment?._project?.productiveId) {
            result = {
              ...result,
              taskBreakdownItemized: project.taskBreakdownItemized,
              taskBreakdownSummedByCategory: project.taskBreakdownSummedByCategory,
              totalHours: project.totalHours,
            };
            if (!hasProductiveAssignments) {
              setHasProductiveAssignments(true);
            }
          }
        });
        return result;
      });
  }, [
    companyActiveAssignments,
    state.productiveAPIData,
    state.isLoadingProductiveData,
    hasProductiveAssignments,
  ]);

  const harvestEnabled = useMemo(
    () =>
      Boolean(
        harvestEnabled_ &&
          user.canUseHarvest &&
          user.harvestId &&
          invoice._status &&
          invoice._status.name === INVOICE_STATUS.DRAFT,
      ),
    [harvestEnabled_, invoice._status, user.canUseHarvest, user.harvestId],
  );

  const productiveEnabled = useMemo(
    () =>
      Boolean(
        productiveEnabled_ &&
          user.canUseProductive &&
          user.productiveId &&
          invoice._status &&
          invoice._status.name === INVOICE_STATUS.DRAFT,
      ),
    [productiveEnabled_, invoice._status, user.canUseProductive, user.productiveId],
  );

  const { TITLE, CURRENT_PROGRESS, PROGRESS_INDICATOR_DATA } = SUBMIT_INVOICE;
  const { text: subtitle } = Messages.ToastMessages.Invoice.onTimePayment();
  const currency = get(invoice, '_currency.name');

  const submittedInvoicesDateRanges = canSubmitDuplicateInvoicePeriod
    ? []
    : userInvoices
        .filter((userInvoice) =>
          userInvoice?._invoiceItems?.some(
            (item) => item?._typeId === INVOICE_ITEM_TYPES.DEDUCTION,
          ),
        )
        .filter(({ dateFrom, dateTo }) => dateFrom && dateTo && dateFrom !== dateTo)
        .map(({ dateFrom, dateTo }) =>
          createRange(new Date(String(dateFrom)), new Date(String(dateTo))),
        )
        // @ts-ignore
        .sort((a, b) => a.start - b.start);

  const hoursWorkedItemsWithIntegrations =
    groupBy(invoice?._invoiceItems, '_category.name')
      [INVOICE_CATEGORY.HOURS_WORKED]?.map((item) => {
        const harvestId = get(item, '_project.harvestId');

        if (harvestId) {
          const details = harvestData?.find?.(
            ({ projectId }) => projectId.toString() === harvestId,
          );
          if (details) {
            return { ...item, details };
          }
        }

        const productiveId = get(item, '_project.productiveId');

        if (productiveId) {
          const details = productiveData?.find?.(
            ({ projectId }) => projectId.toString() === productiveId,
          );
          if (details) {
            return { ...item, details };
          }
        }
        return item;
      })
      .filter(Boolean) || [];

  const reimbursementInvoiceItems =
    invoice?._invoiceItems
      ?.filter((item) => item?._category?.name !== INVOICE_CATEGORY.HOURS_WORKED)
      ?.slice()
      ?.sort((a, b) => a.id - b.id) || [];

  const bankingInfo = (): DataFields => {
    const { _accountingData: data } = user;
    const paymentType = get(data, '_paymentType.name');
    const notes = get(data, 'notes');
    const fields = get(data, 'info');
    return {
      ...fields,
      paymentType: {
        name: paymentType,
        id: get(data, '_paymentType.id'),
      },
      notes,
    };
  };

  const stepHandlers = (step: number): StepActions => ({
    state: stepHandler.getState(step),
    activeHandler: stepHandler.next,
    inactiveHandler: () => stepHandler.goTo(step),
  });

  const updateInvoiceReducer = (invoiceData: Partial<Invoice>) => ({
    invoice: { ...state.invoice, ...invoiceData },
  });

  const updateInvoice = async (invoiceData: Partial<Invoice>) => {
    try {
      if (invoice.id) {
        setState({ ...state, ...updateInvoiceReducer(invoiceData) });
        return { invoiceData };
      } else {
        const { payload } = await getUserCurrentInvoiceApiActionCreator({
          params: { userId: user.id },
          body: { createDraft: true },
        });
        setState((state) => ({
          ...state,
          invoice: { ...payload?.data, _companyId: invoiceData._companyId },
        }));
      }
    } catch (err) {
      const errorMessage = get(err, 'payload.message') || 'Failed to update the invoice';
      return { error: errorMessage };
    }
  };

  const refetchCurrentInvoice = async (createDraft = true) => {
    const response = await getUserCurrentInvoiceApiActionCreator({
      body: { createDraft },
      params: { userId: user.id },
    });
    updateInvoice(response?.payload?.data);
  };

  const createInvoiceItem = async (invoiceItemData: Partial<InvoiceItem>) => {
    await postInvoiceItemApiActionCreator({
      body: {
        ...invoiceItemData,
        _invoice: {
          id: invoice.id,
        },
      },
    });
    await refetchCurrentInvoice();
  };

  const createReimbursementInvoiceItem = async () => {
    if (reimbursementAssignments?.length === 0) return;
    const project = reimbursementAssignments?.[0]?._project;
    await postInvoiceItemApiActionCreator({
      body: {
        quantity: 1,
        rate: 0,
        usdPrice: 0,
        _project: { id: Number(project?.id) },
        _category: {
          id: Number(
            project?._allowedReimbursements?.sort(({ name }) => (name === 'Unleash+' ? -1 : 1))?.[0]
              ?.id,
          ),
        },
        _invoice: { id: invoice.id },
      },
    });
    await refetchCurrentInvoice();
  };

  const updateInvoiceItem = (invoiceItemData: Partial<InvoiceItem>) => {
    putInvoiceItemApiActionCreator?.({
      params: { invoiceItemId: invoiceItemData.id || 0 },
      body: invoiceItemData,
    });
  };

  const updateReimbursementInvoiceItem = async (
    invoiceItemId: number,
    invoiceItemData: Partial<InvoiceItem>,
  ) => {
    await putInvoiceItemApiActionCreator?.({
      params: { invoiceItemId },
      body: invoiceItemData,
    });
    await refetchCurrentInvoice();
  };

  const uploadInvoiceItemFile = async (invoiceItemId: number, formData: FormData) => {
    const invoiceItemFiles = invoice?._invoiceItems?.find((item) => item.id === invoiceItemId)
      ?.files;
    const response = await uploadInvoiceItemFileApiActionCreator({
      params: { invoiceItemId },
      body: formData,
    });
    const updatedFiles = [...(invoiceItemFiles || []), ...response?.payload?.data];
    await updateReimbursementInvoiceItem(invoiceItemId, { files: updatedFiles });
    return updatedFiles;
  };

  const removeInvoiceItemFile = async (invoiceItemId: number, updatedFiles: File[]) => {
    await updateReimbursementInvoiceItem(invoiceItemId, { files: updatedFiles });
  };

  const removeInvoiceItem = async (invoiceItemId: number) => {
    await deleteInvoiceItemApiActionCreator({ params: { invoiceItemId } });
    await refetchCurrentInvoice();
  };

  const updateBankInfoReducer = (accountingData: Partial<AccountingData>) => ({
    user: { ...state.user, _accountingData: { ...accountingData } },
  });

  const updateBankInfo = (bankInfo: Partial<DataFields>) => {
    const info = omit(bankInfo, ['notes', 'paymentType']);
    const accountingData: AccountingData = {
      ...user._accountingData,
      info,
      notes: bankInfo.notes,
      _paymentType: bankInfo.paymentType,
    };
    setState({ ...state, ...updateBankInfoReducer(accountingData) });
  };

  const createInvoiceItemsForHarvestProjects = (harvestData: HarvestAPIDataType[]) => {
    const matchingProjects = matchProjectsWithHarvestData(companyActiveAssignments, harvestData);
    handleProjectsAssignments(harvestAssignments, matchingProjects, {
      onRemove: ({ invoiceItem }: { invoiceItem: InvoiceItem }) => {
        removeInvoiceItem(invoiceItem.id);
      },
      onUpdate: ({ invoiceItem, quantity }: { invoiceItem: InvoiceItem; quantity: number }) => {
        updateInvoiceItem({ ...invoiceItem, quantity });
      },
      onCreate: (data: Partial<InvoiceItem>) => {
        createInvoiceItem({
          ...data,
          _category: hoursInvoiceCategory,
        });
      },
    });
  };

  const getHarvestAPITimeEntries = async (data?: HarvestAPITimeEntriesParams) => {
    setState((state) => ({ ...state, isLoadingHarvestData: true }));
    const harvestId = data?.harvestId || user.harvestId;
    const start = data?.value?.start || invoice?.dateFrom;
    const end = data?.value?.end || invoice?.dateTo;
    const parsedStart = formatDate(normalizeISODate(new Date(String(start))), 'yyyy-MM-dd');
    const parsedEnd = formatDate(normalizeISODate(new Date(String(end))), 'yyyy-MM-dd');

    if (user.rate !== null) {
      try {
        const response = await getHarvestTimeEntriesActionCreator({
          body: {
            harvestId,
            start: parsedStart,
            end: parsedEnd,
          },
        });
        const harvestData = response.payload.data;
        if (harvestData) {
          setState((state) => ({
            ...state,
            harvestAPIData: harvestData,
            isLoadingHarvestData: false,
          }));
          createInvoiceItemsForHarvestProjects(harvestData);
        } else {
          setState((state) => ({ ...state, harvestFetchError: 'Harvest API fetch() failed!' }));
        }
      } catch (error: any) {
        setState((state) => ({ ...state, harvestFetchError: error.message }));
      }
      return;
    }

    setState((state) => ({
      ...state,
      harvestFetchError:
        'The user.rate is null. Please contact your account manager to add your rate to the database.',
    }));
  };

  const createInvoiceItemsForProductiveProjects = (harvestData: ProductiveAPIDataType[]) => {
    if (Array.isArray(companyActiveAssignments) && companyActiveAssignments.length > 0) {
      if (!hasCompanyAssignments) {
        setHasCompanyAssignments(true);
      }
      const matchingProjects = matchProjectsWithProductiveData(
        companyActiveAssignments.map((assignment) => assignment._project),
        harvestData,
      );
      handleProductiveProjectsAssignmentsNewInvoice(productiveAssignments, matchingProjects, {
        onRemove: ({ invoiceItem }: { invoiceItem: InvoiceItem }) => {
          removeInvoiceItem(invoiceItem.id);
        },
        onUpdate: ({ invoiceItem, quantity }: { invoiceItem: InvoiceItem; quantity: number }) => {
          updateInvoiceItem({ ...invoiceItem, quantity });
        },
        onCreate: (data: Partial<InvoiceItem>) => {
          createInvoiceItem({ ...data, _category: hoursInvoiceCategory });
        },
      });
    }
  };

  const getProductiveAPITimeEntries = async (
    data?: ProductiveAPITimeEntriesParams,
    hasChangedDatesStepOne = false,
  ) => {
    setState((state) => ({ ...state, isLoadingProductiveData: true }));
    const productiveId = data?.productiveId || user.productiveId;
    const start = data?.value?.start || invoice?.dateFrom;
    const end = data?.value?.end || invoice?.dateTo;
    const parsedStart = formatDate(normalizeISODate(new Date(String(start))), 'yyyy-MM-dd');
    const parsedEnd = formatDate(normalizeISODate(new Date(String(end))), 'yyyy-MM-dd');

    if (user.rate !== null) {
      try {
        const response = await getProductiveTimeEntriesActionCreator({
          body: {
            productiveId,
            start: parsedStart,
            end: parsedEnd,
          },
        });
        const data = response.payload.data;
        if (data) {
          setState((state) => ({
            ...state,
            productiveAPIData: data,
            isLoadingProductiveData: false,
          }));
          if (hasChangedDatesStepOne) {
            createInvoiceItemsForProductiveProjects(data);
          }
        } else {
          setState((state) => ({ ...state, harvestFetchError: 'Productive API fetch() failed!' }));
        }
      } catch (error: any) {
        setState((state) => ({ ...state, harvestFetchError: error.message }));
      }
      return;
    }

    setState((state) => ({
      ...state,
      harvestFetchError:
        'The user.rate is null. Please contact your account manager to add your rate to the database.',
    }));
  };

  const removeHarvestInvoiceItems = () => {
    if (invoice?._invoiceItems?.length) {
      invoice?._invoiceItems.forEach(async (invoiceItem) => {
        const hasActiveHarvestInvoiceItem = harvestAssignments.some(
          (project) =>
            project?._project?.id === invoiceItem?._project?.id &&
            (project?.taskBreakdownSummedByCategory || project?.taskBreakdownItemized),
        );
        if (!hasActiveHarvestInvoiceItem && invoiceItem?._project?.harvestId) {
          removeInvoiceItem(invoiceItem.id);
        }
      });
    }
  };

  const removeProductiveInvoiceItems = () => {
    if (!invoice?._invoiceItems?.length) {
      return;
    }
    invoice?._invoiceItems.forEach(async (invoiceItem) => {
      const hasActiveProductiveInvoiceItem = productiveAssignments.some(
        (project) =>
          project?._project?.id === invoiceItem?._project?.id &&
          (project?.taskBreakdownSummedByCategory || project?.taskBreakdownItemized),
      );
      if (hasActiveProductiveInvoiceItem && invoiceItem?._project?.productiveId) {
        removeInvoiceItem(invoiceItem.id);
      }
    });
  };

  const header = (
    <SubmitInvoiceHeader
      title={TITLE}
      subtitle={subtitle}
      currentProgress={CURRENT_PROGRESS}
      progressIndicatorData={PROGRESS_INDICATOR_DATA}
    />
  );
  const sideContent = <AmountTotal value={totalAmount} currency={currency} />;

  useEffect(() => {
    if (hasProductiveAssignments && state.productiveAPIData) {
      createInvoiceItemsForProductiveProjects(state.productiveAPIData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProductiveAssignments]);

  useEffect(() => {
    if (isMounted.current) {
      updateInvoiceApiActionCreator({
        params: { invoiceId: state.invoice.id },
        body: state.invoice,
      });
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.invoice]);

  const acceptedPrivacyPolicy = (accepted: boolean) => {
    setState((state) => ({ ...state, showPrivacyPolicyModal: false }));
    if (accepted) {
      updateUserDataActionCreator({ ...user, acceptedPrivacyPolicy: true }, 'accounting');
    }
  };

  return (
    <InnerPage stickySideContent title={header} sideContent={sideContent}>
      {!user.acceptedPrivacyPolicy && state.showPrivacyPolicyModal && (
        <PrivacyPolicyModal isOpen={true} onClose={acceptedPrivacyPolicy}></PrivacyPolicyModal>
      )}
      <Step0
        {...stepHandlers(1)}
        companyId={invoice._companyId}
        assignments={activeAssignments}
        updateInvoice={updateInvoice}
      />
      <Step1
        {...stepHandlers(2)}
        invoice={invoice}
        updateInvoice={updateInvoice}
        submittedInvoicesDateRanges={submittedInvoicesDateRanges}
      />
      <Step2
        {...stepHandlers(3)}
        amount={totalAmount}
        assignments={companyActiveAssignments}
        harvestAssignments={harvestAssignments}
        productiveAssignments={productiveAssignments}
        invoice={invoice}
        setAmount={setAmount(3)}
        currency={currency}
        invoiceItems={hoursWorkedItemsWithIntegrations}
        harvestEnabled={harvestEnabled}
        harvestFetchError={state.harvestFetchError}
        hoursInvoiceCategory={hoursInvoiceCategory}
        createInvoiceItem={createInvoiceItem}
        updateInvoiceItem={updateInvoiceItem}
        getHarvestAPITimeEntries={getHarvestAPITimeEntries}
        getProductiveAPITimeEntries={getProductiveAPITimeEntries}
        removeHarvestInvoiceItems={removeHarvestInvoiceItems}
        productiveEnabled={productiveEnabled}
        productiveFetchError={state.productiveFetchError}
        removeProductiveInvoiceItems={removeProductiveInvoiceItems}
      />
      <Step3
        {...stepHandlers(4)}
        setAmount={setAmount(4)}
        currency={currency}
        assignments={reimbursementAssignments}
        reimbursements={reimbursementInvoiceItems}
        unleashBudget={unleashBudget}
        unleashPlusInvoiceCategory={unleashPlusInvoiceCategory}
        createReimbursement={createReimbursementInvoiceItem}
        updateReimbursement={updateReimbursementInvoiceItem}
        removeReimbursement={removeInvoiceItem}
        uploadReimbursementFile={uploadInvoiceItemFile}
        removeReimbursementFile={removeInvoiceItemFile}
      />
      <Step4
        {...stepHandlers(5)}
        bankingInfo={bankingInfo()}
        shouldSkipSurvey={shouldSkipSurvey}
        updateBankInfo={updateBankInfo}
      />
      <Step5 {...stepHandlers(6)} notes={invoice.notes ?? ''} updateInvoice={updateInvoice} />
    </InnerPage>
  );
});

export default withSubmitData(SubmitInvoice);
