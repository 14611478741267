import { Fragment, MouseEventHandler } from 'react';

import cx from 'classnames';

import { ProgressIndicator } from 'design-system/Atoms/ProgressIndicator';

import styles from './ProgressIndicatorGroup.module.scss';

type ProgressIndicatorGroupDataProps = {
  label: string;
  numberStep?: number;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
};

export type ProgressIndicatorGroupProps = {
  currentProgress: number;
  data: ProgressIndicatorGroupDataProps[];
  interactive?: boolean;
};

export const ProgressIndicatorGroup = (props: ProgressIndicatorGroupProps) => {
  const formattedProgress =
    props.currentProgress > props.data.length ? props.data.length : props.currentProgress;

  const getProgressIndicatorState = (currentProgress: number, number: number) => {
    if (number === currentProgress) return 'active';
    if (number > currentProgress) return 'inactive';

    return 'filled';
  };

  const getSeparatorClass = (currentProgress: number, number: number) => {
    return cx(styles.Separator, {
      [styles.Inactive]: number >= currentProgress,
    });
  };

  return (
    <div data-testid="TestId__PROGRESSINDICATORGROUP__WRAPPER" className={styles.Wrapper}>
      {props.data.map((progress, index) => {
        const numberStep = progress.numberStep ?? index + 1;
        return (
          <Fragment key={`fragment-${index}`}>
            <ProgressIndicator
              data-testid={`TestId__PROGRESSINDICATORGROUP__ITEM__${numberStep}`}
              label={progress.label}
              numberStep={numberStep}
              interactive={props.interactive}
              wrapperClassName={styles.ItemWrapper}
              onClick={progress.onClick}
              state={getProgressIndicatorState(formattedProgress, numberStep)}
            />
            {props.data.length - 1 !== index && (
              <span
                data-testid={`TestId__PROGRESSINDICATORGROUP__SEPARATOR__${numberStep}`}
                className={getSeparatorClass(formattedProgress, numberStep)}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
