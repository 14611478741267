import { ComponentProps, memo } from 'react';

import { countries } from 'countries-list';

import Select from '../Select';

export const COUNTRIES_OPTIONS = Object.entries(countries).map(([key, value]) => {
  return { value: key, label: `${value.emoji} ${value.name}` };
});

const CountrySelect = (props: Props) => (
  <Select placeholder="Select a Country" options={COUNTRIES_OPTIONS} {...props} />
);

export default memo(CountrySelect);

type Props = Omit<ComponentProps<typeof Select>, 'onChange'> & {
  onChange: (value: string) => void;
};
