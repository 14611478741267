import { memo, ReactNode, useState } from 'react';

import cx from 'classnames';

import { Button } from 'design-system/Atoms/Button';

import styles from './RightSideControl.module.scss';

const TEST_ID = 'TestId_RightSideControl';

type ConfirmMap = {
  edit?: boolean;
  delete?: boolean;
  download?: boolean;
};

type ConfirmKeys = keyof ConfirmMap;

type ConfirmHandlerMap = Record<ConfirmKeys, (() => void) | undefined>;

export interface RightSideControlProps {
  children?: ReactNode;
  className?: string;
  confirm?: ConfirmMap;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
}

export const RightSideControl = memo<RightSideControlProps>(
  ({ className, children, confirm, onEditClick, onDownloadClick, onDeleteClick }) => {
    const [open, setOpen] = useState(false);
    const [confirmMapKey, setConfirmMapKey] = useState<ConfirmKeys>('edit');

    const confirmHandlerMap: ConfirmHandlerMap = {
      edit: onEditClick,
      delete: onDeleteClick,
      download: onDownloadClick,
    };

    const confirmHandler = (
      confirmMapKey: ConfirmKeys,
      defaultValue: boolean,
      callback?: () => void,
    ) => {
      const skipConfirm = confirm?.[confirmMapKey] ?? defaultValue;

      if (!skipConfirm) return callback?.();

      setConfirmMapKey(confirmMapKey);
      setOpen(true);
    };

    return (
      <div data-testid={TEST_ID} className={cx(styles.RightSideControl, className)}>
        {open ? (
          <div
            data-testid={`${TEST_ID}_Confirm-${confirmMapKey}`}
            className={styles.ConfirmPopover}
          >
            <Button
              as="button"
              iconName="check"
              iconPosition="start"
              layout="autolayout"
              variant="icon-primary"
              onClick={() => {
                confirmHandlerMap[confirmMapKey]?.();
                setOpen(false);
              }}
              aria-label="Confirm Delete"
            />
            <Button
              as="button"
              onClick={() => setOpen(false)}
              iconName="close-big"
              iconPosition="start"
              layout="autolayout"
              variant="icon-secondary"
              aria-label="Cancel Delete"
            />
          </div>
        ) : (
          <>
            {onEditClick && (
              <Button
                as="button"
                aria-label="Edit"
                data-testid={`${TEST_ID}_Action-edit`}
                iconName="edit"
                iconPosition="start"
                layout="autolayout"
                variant="icon-tertiary"
                onClick={() => confirmHandler('edit', false, onEditClick)}
              />
            )}
            {onDownloadClick && (
              <Button
                as="button"
                aria-label="Download CSV"
                data-testid={`${TEST_ID}_Action-download`}
                iconName="download-csv"
                iconPosition="start"
                layout="autolayout"
                variant="icon-tertiary"
                onClick={() => confirmHandler('download', false, onDownloadClick)}
              />
            )}
            {children}
            {onDeleteClick && (
              <Button
                as="button"
                aria-label="Delete"
                data-testid={`${TEST_ID}_Action-delete`}
                iconName="trash-outline"
                iconPosition="start"
                layout="autolayout"
                variant="icon-tertiary"
                onClick={() => confirmHandler('delete', true, onDeleteClick)}
              />
            )}
          </>
        )}
      </div>
    );
  },
);
