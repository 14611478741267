import { memo } from 'react';

import ReactAvatar from 'react-avatar';

import { User } from 'types/types';

type Props = {
  size?: number;
  user: ({ name?: string } & User) | { name?: string; avatarUrl?: string } | null;
};

const Avatar = ({ user, size }: Props) =>
  user && (
    <ReactAvatar
      name={user.name}
      src={user.avatarUrl}
      round
      size={`${size || 168}`}
      style={{ minWidth: size + 'px' }}
      maxInitials={2}
    />
  );

export default memo(Avatar);
