import { Fragment, useState, useMemo, useCallback, useEffect, useRef } from 'react';

import { brandConfig } from 'brands';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';

import Label from 'components/Label';
import Row from 'components/Row';
import Button from 'components/buttons/Button';
import EditStep from 'components/steps/EditStep';
import Footer from 'components/steps/StepFooter';
import Title from 'components/steps/StepTitle';
import Wrap from 'components/steps/StepWrap';
import Step from 'components/steps/SubmitStep';
import { Messages } from 'consts/Messages';
import { DEBOUNCE_WAIT_INTERVAL, EXPENSE_CATEGORY } from 'consts/constants';
import { getUnleashLeftValueFormatted } from 'helpers/currency';
import OverUnleashConfirmModal from 'pages/Submit/OverUnleashConfirmModal/OverUnleashConfirmModal';
import { UnleashBudget } from 'store/storeTypes';
import {
  User,
  Currency,
  Assignment,
  InvoiceItem,
  DateRange,
  UnleashCategory,
  UnleashSetting,
} from 'types/types';
import { useGetUserUnleashInvoiceItemsOfMonth } from 'utils/apiQueryHooks';
import { formatCurrency, roundMoney } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import { isNewProjectExpensesEnabled } from 'utils/netsuiteIntegration';

import Reimbursement from './Reimbursement';
import ReimbursementItem, { ReimbursementItemProps } from './ReimbursementItem';

import styles from './Step3.module.scss';

const STEP = 3;

type Step3Props = Omit<ReimbursementItemProps, 'item'> & {
  currentStep: number;
  selectStep: (step: number, dateRange?: DateRange) => () => Promise<void>;
  hoursInvoiceCategory: Currency[];
  assignments: Assignment[];
  createInvoiceItem: (newInvoice: Partial<InvoiceItem>) => void;
  user: User;
  unleashBudget: UnleashBudget;
  removeInvoiceItem: (id: number) => void;
  updateInvoiceItem: (id: number, newInvoice: Partial<InvoiceItem>) => void;
};

export enum DATA_TEST_ID {
  BUDGET_ERROR = 'budgetError',
  OVER_UNLEASH_MODAL = 'overUnleashModal',
}

function Step3({
  hoursInvoiceCategory,
  assignments,
  referralInvoiceCategory,
  unleashPlusInvoiceCategory,
  user,
  users,
  unleashBudget,
  invoice,
  selectStep,
  currentStep,
  createInvoiceItem,
  removeInvoiceItem,
  updateInvoiceItem,
}: Step3Props) {
  const isNewExpensesEnabled = isNewProjectExpensesEnabled();
  const didMount = useRef(false);
  const [adding, setAdding] = useState(false);
  const [assignmentError, setAssignmentError] = useState(false);
  const [cancelExceedConfirm, setCancelExceedConfirm] = useState(false);
  const [cancelZeroHourUnleashConfirm, setCancelZeroHourUnleashConfirm] = useState(false);
  const [unleashBudgetError, setUnleashBudgetError] = useState(false);
  const [exceededCategory, setExceededCategory] = useState<UnleashCategory | null>(null);
  const [
    openUnleashMonthlyLimitConfirmationModal,
    setOpenUnleashMonthlyLimitConfirmationModal,
  ] = useState(false);

  const [loadingChanges, setLoadingChanges] = useState(false);

  const [nextButtonClick, setNextButtonClick] = useState({
    count: 0,
    name: '',
  });

  const isReimbursementItem = useCallback(
    (item: InvoiceItem) => {
      return isNewExpensesEnabled
        ? item._expenseCategory?.name !== EXPENSE_CATEGORY.HOURS_WORKED_BILLABLE &&
            item._expenseCategory?.name !== EXPENSE_CATEGORY.HOURS_WORKED_NON_BILLABLE
        : item._category?.id !== hoursInvoiceCategory[0]?.id;
    },
    [hoursInvoiceCategory, isNewExpensesEnabled],
  );

  const unleashSetting = useSelector((state) => {
    const settings = state.getSettings?.response?.data ?? [];
    const unleashSetting = settings.find(({ id }) => id === 'unleash-plus-setting')?.values ?? {};
    return unleashSetting as UnleashSetting;
  });

  const currentMonthUnleashInvoiceItems = useGetUserUnleashInvoiceItemsOfMonth(
    invoice.dateFrom ? new Date(invoice.dateFrom) : new Date(),
  );
  const reimbInvoiceItems = invoice?._invoiceItems?.filter(isReimbursementItem);
  const unleashCurrentInvoiceItems = reimbInvoiceItems?.filter(
    (item) => item._category?.id === unleashPlusInvoiceCategory.id,
  );

  const parseUnleashInvoiceItems = useCallback(
    (invoiceItems: InvoiceItem[]) =>
      invoiceItems.reduce(
        (
          acc: { [key: string]: { id: number; used: number; limit: number; name: string } },
          item,
        ) => {
          const categoryId = item._unleashCategory?.id;
          const { _unleashCategory, quantity, rate } = item;
          const isValid = [quantity, rate, _unleashCategory?.limit, _unleashCategory?.name].every(
            Boolean,
          );
          if (categoryId && isValid) {
            acc[categoryId] = {
              id: categoryId,
              limit: item._unleashCategory?.limit || 0,
              name: item._unleashCategory?.name || '',
              used: (acc[categoryId]?.used || 0) + item?.rate * item?.quantity,
            };
          }
          return acc;
        },
        {},
      ),
    [],
  );

  const unleashInvoiceItemsCreatedThisMonthByCategory = useMemo(
    () => parseUnleashInvoiceItems(currentMonthUnleashInvoiceItems),
    [currentMonthUnleashInvoiceItems, parseUnleashInvoiceItems],
  );

  const currentUnleashInvoiceItemsByCategory = useMemo(
    () => parseUnleashInvoiceItems(unleashCurrentInvoiceItems as InvoiceItem[]),
    [parseUnleashInvoiceItems, unleashCurrentInvoiceItems],
  );

  const addReimbursement = async () => {
    const getAllowedReimbursementId = (assignment: Assignment): number => {
      const allowedReimbursements =
        (isNewExpensesEnabled
          ? assignment._project?._allowedExpenses?.filter(
              (expense) => !expense.name.includes('Hours Worked'),
            )
          : assignment._project?._allowedReimbursements) || [];
      const reimbursementId = allowedReimbursements[0]?.id;
      return reimbursementId;
    };
    setAdding(true);
    const assignmentWithReimbursement = assignments.find((assignment) => {
      const isActive = assignment.isActive;
      return isActive && getAllowedReimbursementId(assignment);
    });
    const allowedReimbursementId =
      assignmentWithReimbursement && getAllowedReimbursementId(assignmentWithReimbursement);

    if (assignmentWithReimbursement && allowedReimbursementId) {
      const projectId = assignmentWithReimbursement._project?.id;
      if (projectId) {
        await createInvoiceItem({
          quantity: 1,
          rate: 0,
          _project: { id: projectId },
          _category: { id: allowedReimbursementId },
        });
      }
    } else {
      setAssignmentError(true);
    }

    setAdding(false);
  };

  const isUnleashPlusItem = useCallback(
    (item: InvoiceItem) => {
      if (isNewExpensesEnabled) {
        const itemCategoryName = item._expenseCategory?.name;
        return itemCategoryName === EXPENSE_CATEGORY.UNLEASH_NON_BILLABLE;
      }
      const itemCategoryId = item._category?.id;
      return itemCategoryId === unleashPlusInvoiceCategory.id;
    },
    [unleashPlusInvoiceCategory, isNewExpensesEnabled],
  );

  const isBonusError = useCallback(
    (item: InvoiceItem): boolean => {
      const bonuses = unleashBudget.bonuses;
      const categoryBonus = bonuses.find(
        (bonus) => bonus.unleashCategory.id === item._unleashCategory?.id,
      );

      if (!categoryBonus) {
        return false;
      }

      const totalUnleashValue = item.rate * item.quantity;
      const selectedUnleashLimit = item?._unleashCategory?.limit;
      const isUnleash = isUnleashPlusItem(item);
      const bonusRemaining = categoryBonus.total - categoryBonus.used;
      const isBonusCategory = categoryBonus?.unleashCategory?.isBonusCategory;

      return !!(
        isBonusCategory &&
        isUnleash &&
        isNil(selectedUnleashLimit) &&
        totalUnleashValue > bonusRemaining
      );
    },
    [isUnleashPlusItem, unleashBudget],
  );

  const isValidBonusCategory = useCallback(
    (unleashCategory: UnleashCategory) => {
      if (!unleashCategory.isBonusCategory) {
        return true;
      }

      const bonuses = unleashBudget.bonuses;
      const categoryBonus = bonuses.find(
        (bonus) => bonus.unleashCategory.id === unleashCategory.id,
      );
      if (!categoryBonus) {
        return false;
      }

      const bonusRemaining = categoryBonus.total - categoryBonus.used;
      if (bonusRemaining > 0) {
        return true;
      }

      return false;
    },
    [unleashBudget],
  );

  const unleashItems = useMemo(() => {
    const isNewExpensesEnabled = isNewProjectExpensesEnabled();
    const categoryKey = isNewExpensesEnabled ? '_expenseCategory' : '_category';
    return invoice?._invoiceItems?.filter((item) => {
      return (
        item[categoryKey]?.id === unleashBudget?.category?.id &&
        item._unleashCategory &&
        'isBonusCategory' in item._unleashCategory &&
        !item._unleashCategory.isBonusCategory
      );
    });
  }, [invoice?._invoiceItems, unleashBudget]);

  const exceededMontlyLimit = useMemo(() => {
    const currentUnleashItems = unleashItems ?? [];
    if (unleashSetting.hasMonthlyTotalLimit) {
      const total = [...currentUnleashItems, ...currentMonthUnleashInvoiceItems].reduce(
        (acc, item) => {
          return acc + item.rate * item.quantity;
        },
        0,
      );

      const hasApproverName = currentUnleashItems.every((item) => !!item.overTimeApprover);

      if (total > Number(unleashSetting.monthlyTotalLimit) && !hasApproverName) {
        return true;
      }
    }
    return false;
  }, [unleashItems, unleashSetting, currentMonthUnleashInvoiceItems]);

  useEffect(() => {
    if (exceededMontlyLimit) {
      setOpenUnleashMonthlyLimitConfirmationModal(true);
    } else {
      setOpenUnleashMonthlyLimitConfirmationModal(false);
    }
  }, [exceededMontlyLimit, unleashItems]);

  const renderReimbursementFields = useCallback(
    (reimbursements: InvoiceItem[]) => {
      const assignmentsFiltered = assignments.filter((a) => {
        const allowedReimbursments =
          (isNewExpensesEnabled
            ? a._project?._allowedExpenses
            : a._project?._allowedReimbursements) || [];
        return a.isActive && allowedReimbursments.length > 0;
      });
      const availableUnleashBudget = (unleashBudget?.total || 0) - (unleashBudget?.used || 0);

      return reimbursements.map((reimbursement) => (
        <Reimbursement
          key={reimbursement.id}
          availableUnleashBudget={availableUnleashBudget}
          reimbursement={reimbursement}
          setReimbursements={setItems}
          assignments={assignmentsFiltered}
          user={user}
          invoice={invoice}
          isReferralItem={(categoryId?: number) => categoryId === referralInvoiceCategory.id}
          isUnleashPlusItem={(categoryId?: number) => categoryId === unleashPlusInvoiceCategory.id}
          onExceedConfirm={setCancelExceedConfirm}
          onZeroHourUnleashConfirm={setCancelZeroHourUnleashConfirm}
          updateInvoiceItem={updateInvoiceItem}
          removeInvoiceItem={removeInvoiceItem}
          isBonusError={isBonusError}
          isValidBonusCategory={isValidBonusCategory}
          setUnleashBudgetError={setUnleashBudgetError}
          exceededCategory={exceededCategory}
          exceededMonthlyLimit={exceededMontlyLimit}
          onLoadingChanges={setLoadingChanges}
          onNextButtonClick={nextButtonClick ?? 0}
        />
      ));
    },
    [
      assignments,
      unleashBudget?.total,
      unleashBudget?.used,
      user,
      invoice,
      updateInvoiceItem,
      removeInvoiceItem,
      isBonusError,
      isValidBonusCategory,
      exceededCategory,
      referralInvoiceCategory.id,
      unleashPlusInvoiceCategory.id,
      exceededMontlyLimit,
      nextButtonClick,
      isNewExpensesEnabled,
    ],
  );

  const renderInvoiceItemsSummary = useCallback(() => {
    if (isEmpty(reimbInvoiceItems)) {
      return <Row noPadding>No reimbursements added.</Row>;
    }

    return reimbInvoiceItems?.map((item) => (
      <ReimbursementItem
        key={item.id}
        item={item}
        unleashPlusInvoiceCategory={unleashPlusInvoiceCategory}
        referralInvoiceCategory={referralInvoiceCategory}
        users={users}
        invoice={invoice}
      />
    ));
  }, [reimbInvoiceItems, unleashPlusInvoiceCategory, referralInvoiceCategory, users, invoice]);

  const isReferralItem = useCallback(
    (item: InvoiceItem) => {
      const itemCategoryId = item._category?.id;
      return itemCategoryId === referralInvoiceCategory.id;
    },
    [referralInvoiceCategory],
  );

  const confirmUnleashMonthyLimitExceeded = async (name: string) => {
    await Promise.all(
      unleashItems?.map(async (item) => {
        return updateInvoiceItem(item.id, { overTimeApprover: name });
      }) ?? [],
    );
  };

  const invoiceUnleashItems = useMemo(() => {
    return invoice._invoiceItems?.filter(isReimbursementItem).sort((a, b) => a.id - b.id) || [];
  }, [isReimbursementItem, invoice._invoiceItems]);

  useEffect(() => {
    setItems(invoiceUnleashItems);
  }, [invoiceUnleashItems]);

  const [items, setItems] = useState(invoiceUnleashItems);

  const reimbursementsFillErrorNormal = useMemo(() => {
    return items.some((item) => {
      if (!item._category?.id && !item._expenseCategory?.id) return true;
      if (!item._project?.id) return true;
      if (!item.quantity) return true;
      if (!item.rate) return true;
      if (
        (item._category?.name === 'Unleash+' ||
          item._expenseCategory?.name === EXPENSE_CATEGORY.UNLEASH_NON_BILLABLE) &&
        !item.notes
      )
        return true;
      if (isUnleashPlusItem(item) && isEmpty(item.files)) return true;
      if (isReferralItem(item) && !item.name) return true;
      if (isUnleashPlusItem(item) && !item._unleashCategory?.id) return true;
      return false;
    });
  }, [items, isUnleashPlusItem, isReferralItem]);

  const reimbursementsFillError = useDebounce(
    reimbursementsFillErrorNormal,
    DEBOUNCE_WAIT_INTERVAL,
  );

  const bonusError = useMemo(() => {
    return items.some((item) => {
      if (isBonusError(item)) return true;
      return false;
    });
  }, [items, isBonusError]);

  const categoryBonuses = unleashBudget?.bonuses
    ?.filter((bonus) => bonus.total > bonus.used)
    ?.map((bonus) => ({ id: bonus.unleashCategory.id, available: bonus.total - bonus.used }));

  const availableUnleashBudget = useMemo(() => {
    const availableBonus = categoryBonuses
      ?.filter(({ id }) => unleashItems?.some((item) => item._unleashCategory?.id === id))
      ?.reduce((acc, bonus) => acc + bonus.available, 0);
    return roundMoney(availableBonus + unleashBudget.total - unleashBudget.used);
  }, [categoryBonuses, unleashBudget.total, unleashBudget.used, unleashItems]);

  const planningUnleashValue = useMemo(() => {
    const unleashBudgetUsed = unleashItems?.reduce((acc, item) => {
      return acc + ((item?.usdPrice || 0) * 100 || item.rate * 100 || 0) * item.quantity;
    }, 0);

    if (!unleashBudgetUsed) {
      return 0;
    }

    return unleashBudgetUsed / 100;
  }, [unleashItems]);

  const reimbursementsBudgetError = useMemo(
    () => planningUnleashValue > 0 && planningUnleashValue > availableUnleashBudget,
    [planningUnleashValue, availableUnleashBudget],
  );

  const emptyInvoice = useMemo(() => {
    const totalQuantity = invoice._invoiceItems?.reduce((acc, item) => acc + item.quantity, 0);

    return totalQuantity === 0 && reimbInvoiceItems?.length === 0 && currentStep === 3;
  }, [reimbInvoiceItems, invoice, currentStep]);

  const reimbursementsStepError = useMemo(
    () =>
      reimbursementsFillError ||
      reimbursementsBudgetError ||
      cancelExceedConfirm ||
      unleashBudgetError ||
      bonusError ||
      exceededMontlyLimit ||
      emptyInvoice,
    [
      reimbursementsFillError,
      reimbursementsBudgetError,
      cancelExceedConfirm,
      unleashBudgetError,
      bonusError,
      exceededMontlyLimit,
      emptyInvoice,
    ],
  );

  const exceededUnleashLimitCategoryId = useMemo(() => {
    const unleashCategoriesIds = Object.keys(currentUnleashInvoiceItemsByCategory);
    if (!unleashCategoriesIds) return null;
    return unleashCategoriesIds?.find((id, index) => {
      const category = unleashInvoiceItemsCreatedThisMonthByCategory[id]
        ? unleashInvoiceItemsCreatedThisMonthByCategory[id]
        : null;
      const categoryUsed = category?.used || 0;
      const unleashCategory = currentUnleashInvoiceItemsByCategory?.[id];
      const approver = items[index].overTimeApprover;

      if (unleashCategory.limit) {
        return (
          categoryUsed + (unleashCategory.used || 0) > unleashCategory.limit &&
          items[index]._unleashCategory?.id?.toString() === id &&
          isEmpty(approver)
        );
      }
      return undefined;
    });
  }, [unleashInvoiceItemsCreatedThisMonthByCategory, currentUnleashInvoiceItemsByCategory, items]);

  const handleNextButtonClick = useCallback(() => {
    const notApproved = items.some((item) => !item.overTimeApprover);

    if (cancelZeroHourUnleashConfirm && notApproved) {
      setNextButtonClick((prevCount) => ({ count: prevCount.count + 1, name: 'zeroHoursUnleash' }));

      return;
    } else if (unleashBudgetError && notApproved) {
      setNextButtonClick((prevCount) => ({ count: prevCount.count + 1, name: 'unleashExceed' }));
      return;
    }

    setNextButtonClick({ count: 0, name: '' });
    return selectStep(STEP + 1)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelZeroHourUnleashConfirm, unleashBudgetError]);

  useEffect(() => {
    if (didMount.current && items.length > 0) {
      setAdding(reimbursementsStepError);
    } else {
      setAdding(false);
      didMount.current = true;
    }
  }, [reimbursementsStepError, items]);

  const isActive = useMemo(() => currentStep === STEP, [currentStep]);

  useEffect(() => {
    if (!!exceededUnleashLimitCategoryId && currentUnleashInvoiceItemsByCategory && isActive) {
      setExceededCategory(currentUnleashInvoiceItemsByCategory[exceededUnleashLimitCategoryId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceededUnleashLimitCategoryId, isActive, items?.length]);

  return (
    <Step>
      <Title step={STEP} isActive={isActive}>
        Reimbursements
        <EditStep
          isSubmit
          onClick={selectStep(STEP)}
          showSortUpIcon={isActive}
          showEditIcon={currentStep > STEP}
        />
      </Title>
      {isActive && (
        <Wrap isHidden={!isActive}>
          <Label unleashBudget>
            <span>
              Use this step to get reimbursed for things like{' '}
              {brandConfig.flags.unleashEnabled ? 'Unleash+ or ' : ''} partner travel expenses, or
              get paid for a Referral fee.
            </span>
          </Label>
          {brandConfig.flags.unleashEnabled ? (
            <Label unleashBudget data-testid="unleashBudgetAvailable">
              <span>Unleash+ budget available:</span>{' '}
              {getUnleashLeftValueFormatted(unleashBudget, true)}
              {unleashBudget.bonuses?.map((bonus, i) => (
                <Fragment key={i}>
                  <br />
                  <span>{bonus.unleashCategory.name} Unleash+ bonus:</span>{' '}
                  {getUnleashLeftValueFormatted(bonus, true)}
                </Fragment>
              ))}
            </Label>
          ) : null}
          <Row noPadding isHidden={!items.length} className={styles.ReimHeader}>
            <Label reimburseLabel className={styles.LabelProject}>
              Project
            </Label>
            <Label reimburseLabel className={styles.LabelReimburse}>
              Reimbursement type
            </Label>
            <Label reimburseLabel className={styles.LabelQuantity}>
              Quantity
            </Label>
            <Label reimburseLabel className={styles.LabelUnit}>
              Unit price
            </Label>
          </Row>
          {renderReimbursementFields(items)}
          <Row noPadding>
            <Button
              className={styles.AddReimbursementButton}
              isFormBtn
              addReimburseStep
              onClick={addReimbursement}
              disabled={adding || loadingChanges}
            >
              <span>Add new reimbursement</span>
              <i className={styles.plusIcon} />
            </Button>
          </Row>
          {reimbursementsBudgetError && (
            <div className={styles.UnleashBudgetError} data-testid={DATA_TEST_ID.BUDGET_ERROR}>
              You can only reimburse up to{' '}
              {formatCurrency(unleashBudget.total, unleashBudget.currency, true)} (excluding any
              Unleash+ bonuses) per year in Unleash+ expenses. Please reduce the amount you're
              requesting to continue.
            </div>
          )}
          {reimbursementsFillError && (
            <div className={styles.UnleashBudgetError}>
              Please make sure that Type, Project, Quantity, Notes and Files fields are correct.
              Confirm the details of any referral with your account manager before submitting.
            </div>
          )}
          {assignmentError && (
            <div className={styles.UnleashBudgetError}>
              {Messages.ToastMessages.Invoice.addReimbursementItem.text}
            </div>
          )}
          {bonusError && (
            <div className={styles.UnleashBudgetError}>
              {Messages.ToastMessages.Invoice.overBonusValue.text}
            </div>
          )}
          {emptyInvoice && (
            <div className={styles.UnleashBudgetError}>
              {Messages.ToastMessages.Invoice.emptyInvoice.text}
            </div>
          )}
          <Footer step6>
            <Row noPadding>
              <Button
                isFormBtn
                submitNext
                disabled={reimbursementsStepError || loadingChanges}
                onClick={handleNextButtonClick}
              >
                Next
              </Button>
            </Row>
          </Footer>
        </Wrap>
      )}
      <Wrap isHidden={isActive}>{renderInvoiceItemsSummary()}</Wrap>
      {isActive && openUnleashMonthlyLimitConfirmationModal && exceededMontlyLimit && (
        <OverUnleashConfirmModal
          data-testid={DATA_TEST_ID.OVER_UNLEASH_MODAL}
          categoryName="Unleash+ Monthly Limit"
          monthlyLimit={Number(unleashSetting.monthlyTotalLimit)}
          availableBudget={availableUnleashBudget}
          onCancel={() => {
            setOpenUnleashMonthlyLimitConfirmationModal(false);
          }}
          onConfirm={confirmUnleashMonthyLimitExceeded}
        />
      )}
    </Step>
  );
}

export type { Step3Props };
export default Step3;
