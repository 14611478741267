import { INVOICE_STATUS } from 'consts/constants';
import { Button, Card, Heading, Paragraph } from 'design-system/Atoms';
import { CurrencyName, InvoiceStatusName } from 'types/types';

import styles from './AmountTotal.module.scss';

export interface AmountTotalProps {
  value: number | string;
  currency: CurrencyName;
  status?: InvoiceStatusName;
  onDownloadPDF?: () => void;
}

export const AmountTotal = ({ value, currency, status, onDownloadPDF }: AmountTotalProps) => {
  return (
    <Card className={styles.InvoiceTotalContainer}>
      <Paragraph variant="ui-bold" className={styles.Label}>
        Invoice total
      </Paragraph>
      <Heading level="h6" className={styles.Price} data-testid="TestId__AMOUNTTOTAL">
        {value} {currency}
      </Heading>
      {status === INVOICE_STATUS.PAID && (
        <Button
          data-testid="TestId__AMMOUNTTOTAL_DOWNLOADPDFBUTTON"
          variant={'primary'}
          iconName="cloud-down"
          iconPosition="end"
          layout={'autolayout'}
          as={'button'}
          onClick={onDownloadPDF}
        >
          Download PDF
        </Button>
      )}
    </Card>
  );
};
