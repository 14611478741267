import { filter, identity } from 'lodash';

import checkedBlackIcon from 'assets/checked-black.svg';
import checkedWhiteIcon from 'assets/checked-white.svg';
import referralsWhiteIcon from 'assets/icons/referrals-active.svg';
import referralsBlackIcon from 'assets/icons/referrals.svg';
import surveyWhiteIcon from 'assets/icons/survey-active.svg';
import surveyBlackIcon from 'assets/icons/survey.svg';
import plusWhiteIcon from 'assets/plus-white.svg';

import ProgressBarStepper from '../ProgressBarStepper';
import { ProgressBarStepProps } from '../ProgressBarStepper/ProgressBarStep';

type SubmitStepperProps = {
  activeStep: number;
  shouldSkipSurvey?: boolean;
  shouldSkipReferrals?: boolean;
  stepProgress: number;
};

const SubmitStepper = ({
  activeStep,
  shouldSkipSurvey,
  shouldSkipReferrals,
  stepProgress,
}: SubmitStepperProps) => {
  const progressParam: Array<ProgressBarStepProps> = [
    {
      title: 'SUBMIT INVOICE',
      active: activeStep >= 0,
      icon: plusWhiteIcon,
    },
    ...(!shouldSkipSurvey
      ? [
          {
            title: 'SURVEY',
            active: activeStep >= 1,
            icon: activeStep >= 1 ? surveyWhiteIcon : surveyBlackIcon,
          },
        ]
      : []),
    ...(!shouldSkipReferrals
      ? [
          {
            title: 'REFERRALS',
            active: activeStep >= 2,
            icon: activeStep >= 2 ? referralsWhiteIcon : referralsBlackIcon,
          },
        ]
      : []),
    {
      title: 'CONFIRMATION',
      active: activeStep >= 3,
      icon: activeStep >= 3 ? checkedWhiteIcon : checkedBlackIcon,
    },
  ];

  function getStepConfig(): Array<ProgressBarStepProps> {
    const progressConfig = filter<ProgressBarStepProps>(progressParam, identity);

    if (progressConfig?.[0] && activeStep === 1) progressConfig[0].href = '/submit';

    return progressConfig;
  }

  const getProgress = (current: number, total: number, stepProgress = 0) => {
    const global = current / (total - 1);
    const progress = 0.5 * stepProgress;
    return (global + progress) * 100;
  };

  const stepsConfig = getStepConfig();

  return (
    <ProgressBarStepper
      steps={stepsConfig}
      progress={getProgress(activeStep, stepsConfig.length, stepProgress)}
    />
  );
};

SubmitStepper.defaultProps = {
  shouldSkipSurvey: false,
};

export default SubmitStepper;
