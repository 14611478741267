import { useState } from 'react';

import { ActiveState, Question, StepState } from 'types/types';

import { StateAnswers } from './useAnswerHandlerHook';

export interface StepHandler {
  step: number;
  next: () => void;
  goTo: (step: number) => void;
  getState: (step: number) => ActiveState;
}

export const useStepHook = (stepStates: StepState[], finishHandler?: () => void): StepHandler => {
  const [currentStep, setCurrentStep] = useState(1);

  return {
    step: currentStep,
    next: () => {
      const nextStep = recognizeNextStep(stepStates, currentStep);

      if (nextStep >= 0) {
        setCurrentStep(nextStep);
        return;
      }

      finishHandler?.();
    },
    goTo: (step) => {
      setCurrentStep(step);
    },
    getState: (step: number) => {
      if (step === currentStep) {
        return 'active';
      }

      return step > currentStep ? 'pending' : 'inactive';
    },
  };
};

export const produceStateFromQuestions = (
  questions: Question[] = [],
  answers: StateAnswers = {},
): StepState[] => {
  return questions?.map((question) => {
    if (!question?.id) {
      throw new Error('Question id is required for data: ' + JSON.stringify(question));
    }

    if (answers[question?.id]) {
      return 'answered';
    }

    if (question.isRequired) {
      return 'pending';
    }

    return 'optional';
  });
};

const guardStepRecognition = () => null;

const getClosestNext = (pending: number, optional: number) => {
  if (pending === -1) {
    return optional;
  }

  if (optional === -1) {
    return pending;
  }

  return pending < optional ? pending : optional;
};

export const recognizeNextStep = (states: StepState[], inputStep: number): number => {
  const currentStep = inputStep - 1;

  guardStepRecognition();

  const alternateStates = states.map((state, index) => {
    if (index > currentStep) {
      return state;
    }

    return state === 'optional' ? 'answered' : state;
  });

  const pendingQuestionIndex = states.indexOf('pending');
  const optionalQuestionIndex = alternateStates.indexOf('optional');

  if (pendingQuestionIndex === -1 && optionalQuestionIndex === -1) {
    return -1;
  }

  let nextIndex = getClosestNext(pendingQuestionIndex, optionalQuestionIndex);

  if (alternateStates[currentStep] === 'pending') {
    // don't change if the current step is pending await it fill
    nextIndex = currentStep;
  }

  return nextIndex === -1 ? -1 : nextIndex + 1;
};
