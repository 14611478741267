import { HTMLProps, forwardRef, ChangeEventHandler } from 'react';

import cx from 'classnames';

import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './Checkbox.module.scss';

export type CheckboxProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
  noVerticalMargin?: boolean;
};

export const Checkbox = forwardRef<
  HTMLInputElement,
  CheckboxProps & {
    onChange?: ChangeEventHandler<HTMLInputElement>;
  }
>(({ labelClassName, ...props }, ref) => {
  const containerClassName = cx(styles.CheckboxContainer, labelClassName);
  const spanClassName = cx({ [styles.CheckboxLabel]: props.defaultChecked });

  const { children, ...inputProps } = props;

  return (
    <label data-testid="TestId__CHECKBOXWRAPPER" className={containerClassName}>
      <div className={styles.CheckboxIconContainer}>
        <input
          data-testid="TestId__CHECKBOXINPUT"
          ref={ref}
          id={props.id}
          type="checkbox"
          defaultChecked={props.defaultChecked}
          {...inputProps}
        />
        <span className={styles.Checkbox} />
      </div>
      {props.label && (
        <Paragraph
          data-testid="TestId__CHECKBOXPARAGRAPH"
          variant="ui-regular"
          className={spanClassName}
          noVerticalMargin={props.noVerticalMargin}
        >
          {props.label}
        </Paragraph>
      )}
      {children}
    </label>
  );
});
