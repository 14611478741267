import { AnchorHTMLAttributes, memo } from 'react';

import cs from 'classnames';

import styles from './Link.module.scss';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  isBlock?: boolean;
}

const Link = memo<LinkProps>(({ children, className = '', isBlock = false, ...props }) => {
  const classes = cs(className, styles.Link, {
    [styles.LinkBlock]: isBlock,
  });

  return (
    <a className={classes} {...props}>
      {children}
    </a>
  );
});

export default Link;
