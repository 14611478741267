import { USER_ROLE } from 'consts/constants';
import { definitions } from 'types/apiTypes';
import { File, InvoiceItem } from 'types/types';

// eslint-disable-next-line import/no-cycle
import { apiFactory } from './apiReduxHelper';

export const { actionCreator: getUsersApiActionCreator, reducer: getUsers } = apiFactory({
  method: 'GET',
  endpoint: '/users/basic',
});

export const {
  actionCreator: getFilteredUsersApiActionCreator,
  reducer: getFilteredUsers,
} = apiFactory(
  {
    method: 'GET',
    endpoint: '/users',
    userRoleEndpoint: [
      { userRole: USER_ROLE.USER, endpoint: '/users' },
      { userRole: USER_ROLE.ADMIN, endpoint: '/users/specific/admin' },
      { userRole: USER_ROLE.SUPER_ADMIN, endpoint: '/users/specific/superadmin' },
      { userRole: USER_ROLE.COMMUNITY_TEAM, endpoint: '/users/specific/communityteam' },
      { userRole: USER_ROLE.MANAGER, endpoint: '/users/specific/manager' },
      { userRole: USER_ROLE.PEOPLE_OPS, endpoint: '/users/specific/peopleops' },
      { userRole: USER_ROLE.RECRUITER, endpoint: '/users/specific/recruiter' },
      { userRole: USER_ROLE.SUPER_WIZARD, endpoint: '/users/specific/superwizard' },
    ],
  },
  {
    tag: 'usersFilter',
  },
);

export const {
  actionCreator: getUserCSMsApiActionCreator,
  reducer: getClientSuccessManagers,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/csms',
});

export const {
  actionCreator: getUsersAssignedXTCRepsApiActionCreator,
  reducer: getUsersAssignedxtcreps,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/assigned-xtc-reps',
});

export const { actionCreator: getAdminUsersApiActionCreator, reducer: getAdminUsers } = apiFactory({
  method: 'GET',
  endpoint: '/users',
  baseQuery: {
    admin: true,
    isActive: true,
  },
});

export const {
  actionCreator: getUsersForAppreciationCreator,
  reducer: getUsersForAppreciation,
} = apiFactory({
  method: 'GET',
  endpoint: '/users',
  baseQuery: {
    isForAppreciation: true,
  },
});

export const { actionCreator: getUserApiActionCreator, reducer: getUser } = apiFactory(
  {
    method: 'GET',
    endpoint: '/users/:userId',
  },
  {
    preventLogoutOnUnauthorized: true,
    showToastOnUnauthorized: true,
  },
);

export const { actionCreator: getUsersDocumentTransactionHistory } = apiFactory({
  method: 'GET',
  endpoint: '/users/document-transaction-history',
  headers: { 'Content-Type': 'text/csv; charset=utf-8' },
});

export const { actionCreator: getShirtSizesApiActionCreator, reducer: getShirtSizes } = apiFactory({
  method: 'GET',
  endpoint: '/shirt-sizes',
});

export const { actionCreator: getCurrenciesApiActionCreator, reducer: getCurrencies } = apiFactory({
  method: 'GET',
  endpoint: '/currencies',
});

export const { actionCreator: getUserTypesApiActionCreator, reducer: getUserTypes } = apiFactory({
  method: 'GET',
  endpoint: '/user-types',
});

export const { actionCreator: getGeneratedAccountingDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/accounting',
});

export const { actionCreator: getGeneratedUserInvoicesDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/user-invoices',
});

export const { actionCreator: getGeneratedXeroAccountingDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/xero-accounting',
});

export const { actionCreator: getGeneratedApprovalsDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/approvals',
});

export const { actionCreator: getGeneratedBountyUsersDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/bounty-users',
});
export const { actionCreator: getGeneratedBountyCollectionUsersDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/bounty-collection-users',
});

export const { actionCreator: getGeneratedBountiesDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/bounties',
});

export const { actionCreator: getGeneratedVaultUsersDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/vault-users',
});

export const { actionCreator: getGeneratedProjectsDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/projects',
});

export const { actionCreator: getGeneratedUsersDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/users',
});

export const { actionCreator: getGeneratedQuestionsDocument } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/questions',
});

export const { actionCreator: getGeneratedUnleashExpenses } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/unleash-expenses',
});

export const { actionCreator: getGeneratedVaultCollectionCollectedUsers } = apiFactory({
  method: 'GET',
  endpoint: '/generate-document/vault-collection-collected-users',
});

export const { actionCreator: getNewsApiActionCreator, reducer: getNews } = apiFactory({
  method: 'GET',
  endpoint: '/news',
});

export const { actionCreator: deleteNewsApiActionCreator, reducer: deleteNews } = apiFactory({
  method: 'DELETE',
  endpoint: '/news/:newsId',
});

export const { actionCreator: updateNewsApiActionCreator, reducer: editNews } = apiFactory({
  method: 'PUT',
  endpoint: '/news/:newsId',
});

export const { actionCreator: addNewsApiActionCreator, reducer: addNews } = apiFactory({
  method: 'POST',
  endpoint: '/news',
});

export const { actionCreator: updateUserApiActionCreator, reducer: updateUser } = apiFactory({
  method: 'PUT',
  endpoint: '/users/:userId',
});

// INVOICE START

export const {
  actionCreator: getAllInvoicesApiActionCreator,
  reducer: getAllInvoices,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoices',
});

export const {
  actionCreator: getFullInvoicesApiActionCreator,
  reducer: getFullInvoices,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoices/full',
});

export const {
  actionCreator: getCurrentInvoiceApiActionCreator,
  reducer: getCurrentInvoice,
} = apiFactory({
  method: 'POST',
  endpoint: '/invoices/current',
});

export const {
  actionCreator: getUserCurrentInvoiceApiActionCreator,
  reducer: getUserCurrentInvoice,
} = apiFactory<definitions['postUsersUserIdCurrentInvoiceResponse']>({
  method: 'POST',
  endpoint: '/users/:userId/current-invoice',
});

export const { actionCreator: patchUserCurrentInvoiceApiActionCreator } = apiFactory({
  method: 'PATCH',
  endpoint: '/users/:userId/current-invoice',
});

export const { actionCreator: patchCurrentInvoiceApiActionCreator } = apiFactory({
  method: 'PATCH',
  endpoint: '/invoices/current',
});

export const { actionCreator: updateInvoiceApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/invoices/:invoiceId',
});

export const { actionCreator: deleteInvoiceApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/invoices/:invoiceId',
});

// STATUSES START

export const {
  actionCreator: getInvoiceStatusesApiActionCreator,
  reducer: getInvoiceStatuses,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-statuses',
});

export const {
  actionCreator: getApprovedInvoiceStatusApiActionCreator,
  reducer: getApprovedInvoiceStatus,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-statuses/approved',
});

export const {
  actionCreator: getSubmittedInvoiceStatusApiActionCreator,
  reducer: getSubmittedInvoiceStatus,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-statuses/submitted',
});

export const {
  actionCreator: getQueuedInvoiceStatusApiActionCreator,
  reducer: getQueuedInvoiceStatus,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-statuses/queued',
});

// STATUSES END

export const {
  actionCreator: getUserInvoicesApiActionCreator,
  reducer: getUserInvoices,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/invoices',
});

export const {
  actionCreator: getUserCompletedInvoicesApiActionCreator,
  reducer: getUserCompletedInvoices,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/invoices',
  baseQuery: {
    skipTemporary: true,
  },
});

export const { actionCreator: getInvoiceApiActionCreator, reducer: getInvoice } = apiFactory<
  definitions['getInvoicesInvoiceIdResponse']
>(
  {
    method: 'GET',
    endpoint: '/invoices/:invoiceId',
  },
  {
    preventLogoutOnUnauthorized: true,
  },
);

// INVOICE END

export const { actionCreator: createSessionApiActionCreator, reducer: createSession } = apiFactory(
  {
    method: 'POST',
    endpoint: '/sessions/:providerName',
  },
  {
    preventLogoutOnUnauthorized: true,
  },
);

export const { actionCreator: renewSessionActionCreator } = apiFactory(
  {
    method: 'GET',
    endpoint: '/sessions/renew',
  },
  {
    preventLogoutOnUnauthorized: true,
  },
);

export const {
  actionCreator: getUserVaultStashApiActionCreator,
  reducer: getUserVaultStash,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/vault-stash',
});

export const { actionCreator: setAllNotificationsAsReadActionCreator } = apiFactory({
  method: 'PATCH',
  endpoint: '/feed/notifications/read',
});

export const { actionCreator: deleteCurrentSessionApiActionCreator } = apiFactory(
  {
    method: 'DELETE',
    endpoint: '/sessions/me',
  },
  {
    preventLogoutOnUnauthorized: true,
  },
);

export const { actionCreator: getQuestionsApiActionCreator, reducer: getQuestions } = apiFactory({
  method: 'GET',
  endpoint: '/questions',
});

export const { actionCreator: deleteQuestionApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/questions/:questionId',
});

export const { actionCreator: updateQuestionApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/questions/:questionId',
});

export const { actionCreator: addQuestionApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/questions',
});

export const {
  actionCreator: getQuestionTypesApiActionCreator,
  reducer: getQuestionTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/question-types',
});

// ANSWERS START

export const {
  actionCreator: getAnswersWithInvoicesApiActionCreator,
  reducer: getAnswersWithInvoices,
} = apiFactory({
  method: 'GET',
  endpoint: '/answers-invoices',
});

export const {
  actionCreator: getAnswersAppeciationsApiActionCreator,
  reducer: getAnswersAppeciations,
} = apiFactory({
  method: 'GET',
  endpoint: '/answers-appreciations',
});

export const {
  actionCreator: getCurrentInvoiceAnswersApiActionCreator,
  reducer: getCurrentInvoiceAnswers,
} = apiFactory({
  method: 'GET',
  endpoint: '/answers/current',
});

export const { actionCreator: postAnswersApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/answers',
});

export const { actionCreator: putAnswersApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/answers/:answerId',
});

export const { actionCreator: postAnswerPublishApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/answers/:answerId/publish',
});

export const {
  actionCreator: getUserMoodAnswersApiActionCreator,
  reducer: getUserMoodAnswers,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/answers/mood',
});

export const {
  actionCreator: getUserAnswersApiActionCreator,
  reducer: getUserAnswers,
} = apiFactory(
  {
    method: 'GET',
    endpoint: '/users/:userId/answers',
  },
  {
    preventLogoutOnUnauthorized: true,
    showToastOnUnauthorized: true,
  },
);

// ANSWERS END

// UNLEASH START

export const {
  actionCreator: getMyUnleashBudgetApiActionCreator,
  reducer: getMyUnleashBudget,
} = apiFactory({
  method: 'GET',
  endpoint: '/me/unleash-budget',
});

export const {
  actionCreator: getUnleashBudgetApiActionCreator,
  reducer: getUnleashBudget,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/unleash-budget',
});

export const { actionCreator: unleashCorrectionApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/unleash-budget/correction',
});

export const { actionCreator: unleashGroupBonusApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/groups/:groupId/unleash-budget/bonus',
});

export const {
  actionCreator: getUnleashCategoriesApiActionCreator,
  reducer: getUnleashCategories,
} = apiFactory({
  method: 'GET',
  endpoint: '/unleash-categories',
});

export const { actionCreator: addUnleashCategoryApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/unleash-categories',
});

export const { actionCreator: deleteUnleashCategoryApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/unleash-categories/:unleashCategoryId',
});

export const { actionCreator: updateUnleashCategoryApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/unleash-categories/:unleashCategoryId',
});

export const { actionCreator: reorderUnleashCategoriesApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/unleash-categories/reorder',
});

export const { actionCreator: getUnleashCategoryApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/unleash-categories/:unleashCategoryId',
});

export const { actionCreator: uploadUnleashCategoryImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/unleash-categories/files',
});

// UNLEASH END
// PROJECTS START

export const {
  actionCreator: getProjectStatusesApiActionCreator,
  reducer: getProjectStatuses,
} = apiFactory({
  method: 'GET',
  endpoint: '/project-statuses',
});

export const { actionCreator: getProjectsApiActionCreator, reducer: getProjects } = apiFactory({
  method: 'GET',
  endpoint: '/projects',
});

export const {
  actionCreator: getSimplifiedProjectsApiActionCreator,
  reducer: getSimplifiedProjects,
} = apiFactory({
  method: 'GET',
  endpoint: '/projects-simplified',
});

export const {
  actionCreator: getProjectByIdApiActionCreator,
  reducer: getProjectById,
} = apiFactory({
  method: 'GET',
  endpoint: '/projects/:projectId',
});

export const {
  actionCreator: getProjectsOwnersApiActionCreator,
  reducer: getProjectsOwners,
} = apiFactory({
  method: 'GET',
  endpoint: '/projects/admins',
});

export const {
  actionCreator: getProjectsCSMsApiActionCreator,
  reducer: getProjectsCSMs,
} = apiFactory({
  method: 'GET',
  endpoint: '/projects/csms',
});

export const { actionCreator: updateProjectApiActionCreator, reducer: updateProject } = apiFactory({
  method: 'PUT',
  endpoint: '/projects/:projectId',
});

export const { actionCreator: createProjectApiActionCreator, reducer: createProject } = apiFactory({
  method: 'POST',
  endpoint: '/projects',
});

// PROJECTS END
// COMPANIES START

export const { actionCreator: getCompaniesApiActionCreator, reducer: getCompanies } = apiFactory({
  method: 'GET',
  endpoint: '/companies',
});

export const {
  actionCreator: getCompanyByIdApiActionCreator,
  reducer: getCompanyById,
} = apiFactory({
  method: 'GET',
  endpoint: '/companies/:companyId',
});

export const {
  actionCreator: getCompanyProjectsApiActionCreator,
  reducer: getCompanyProjects,
} = apiFactory({
  method: 'GET',
  endpoint: '/companies/:companyId/projects',
});

export const { actionCreator: updateCompanyApiActionCreator, reducer: updateCompany } = apiFactory({
  method: 'PUT',
  endpoint: '/companies/:companyId',
});

export const { actionCreator: createCompanyApiActionCreator, reducer: createCompany } = apiFactory({
  method: 'POST',
  endpoint: '/companies',
});

// COMPANIES END
// ASSIGNMENTS START

export const {
  actionCreator: getAssignmentsApiActionCreator,
  reducer: getAssignments,
} = apiFactory({
  method: 'GET',
  endpoint: '/assignments',
});

export const { actionCreator: deleteAssignmentApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/assignments/:assignmentId',
});

export const { actionCreator: putAssignmentApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/assignments/:assignmentId',
});

export const { actionCreator: postAssignmentApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/assignments',
});

export const { actionCreator: postUpdateAssignmentHistory } = apiFactory({
  method: 'POST',
  endpoint: '/assignment/history',
});

export const { actionCreator: postUpdateUserRateHistory } = apiFactory({
  method: 'POST',
  endpoint: '/user/rate/history',
});

export const {
  actionCreator: getMyAssignmentsApiActionCreator,
  reducer: getMyAssignments,
} = apiFactory({
  method: 'GET',
  endpoint: '/assignments/my',
});

export const {
  actionCreator: getUserAssignmentsApiActionCreator,
  reducer: getUserAssignments,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/assignments',
});

export const { actionCreator: syncUserAssignmentsFromHarvestApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/harvest/sync',
});

export const { actionCreator: globalHarvestSyncActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/tools/trigger-harvest-sync',
});

export const { actionCreator: syncUserAssignmentsFromProductiveApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/productive/sync',
});

export const { actionCreator: globalProductiveSyncActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/tools/trigger-productive-sync',
});

export const { actionCreator: usersServicesProductiveSyncActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/tools/trigger-productive-users-services-sync',
});

// ASSIGNMENTS END

// INVOICE ITEM START

export const { actionCreator: postInvoiceItemApiActionCreator } = apiFactory<{ data: InvoiceItem }>(
  {
    method: 'POST',
    endpoint: '/invoice-items',
  },
);

export const {
  actionCreator: getInvoiceItemsApiActionCreator,
  reducer: getInvoiceItems,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-items',
});

export const { actionCreator: uploadInvoiceItemFileApiActionCreator } = apiFactory<{
  data: File[];
}>({
  method: 'POST',
  endpoint: '/invoice-items/files',
});

export const { actionCreator: deleteInvoiceItemApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/invoice-items/:invoiceItemId',
});

export const { actionCreator: putInvoiceItemApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/invoice-items/:invoiceItemId',
});

// INVOICE ITEM END

export const {
  actionCreator: getInvoiceCategoriesApiActionCreator,
  reducer: getInvoiceCategories,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-categories',
});

export const {
  actionCreator: getHoursInvoiceCategoryApiActionCreator,
  reducer: getHoursInvoiceCategory,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-categories/hours',
});

export const { actionCreator: getBountiesApiActionCreator, reducer: getBounties } = apiFactory({
  method: 'GET',
  endpoint: '/bounties',
});

export const {
  actionCreator: getUnlockableBountiesForMeActionCreator,
  reducer: getUnlockableBountiesForMe,
} = apiFactory({
  method: 'GET',
  endpoint: '/me/unlockable-bounties',
});

export const {
  actionCreator: getBountiesSeasonsApiActionCreator,
  reducer: getBountiesSeasons,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounties/seasons',
});

export const { actionCreator: updateBountyApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bounties/:bountyId',
});

export const { actionCreator: reorderBountiesApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties/reorder',
});

export const { actionCreator: deleteBountyApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/bounties/:bountyId',
});

export const { actionCreator: addBountiesApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties',
});

export const { actionCreator: uploadBountyImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties/files',
});

export const { actionCreator: addUnlockedBountiesApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/unlocked-bounties',
});

// start bounty list

export const {
  actionCreator: getUserBountyListApiActionCreator,
  reducer: getUserBountyList,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/bounty-list',
});

export const { actionCreator: addUserBountyListApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/bounty-list',
});

// end user bounty list
export const {
  actionCreator: getReferralInvoiceCategoryApiActionCreator,
  reducer: getReferralInvoiceCategory,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-categories/referral',
});

export const {
  actionCreator: getUnleashPlusInvoiceCategoryApiActionCreator,
  reducer: getUnleashPlusInvoiceCategory,
} = apiFactory({
  method: 'GET',
  endpoint: '/invoice-categories/unleash-plus',
});

export const {
  actionCreator: getBountiesPathTypesApiActionCreator,
  reducer: getBountiesPathTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounties-path-types',
});

export const {
  actionCreator: getUserUnlockedBountiesApiActionCreator,
  reducer: getUserUnlockedBounties,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/unlocked-bounties',
});

export const {
  actionCreator: getUserUnlockedVaultItemsApiActionCreator,
  reducer: getUserUnlockedVaultItems,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/unlocked-vault-items',
});

export const {
  actionCreator: getUserWeeklyBountiesApiActionCreator,
  reducer: getUserWeeklyBounties,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/weekly-bounties',
});

export const {
  actionCreator: putWeeklyBountiesRerollActionCreator,
  reducer: putWeeklyBountiesReroll,
} = apiFactory({
  method: 'PUT',
  endpoint: '/weekly-bounties/reroll',
});

export const {
  actionCreator: getFavoriteBountiesApiActionCreator,
  reducer: getFavoriteBounties,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/favorite-bounties',
});

export const {
  actionCreator: addFavoriteBountiesApiActionCreator,
  reducer: addFavoriteBounties,
} = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/favorite-bounties',
});

export const { actionCreator: deleteFavoriteBountiesApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/users/:userId/favorite-bounties/:bountyId',
});

export const {
  actionCreator: getFavoriteBountiesCollectionApiActionCreator,
  reducer: getFavoriteBountiesCollection,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/favorite-bounties-collection',
});

export const {
  actionCreator: addFavoriteBountiesCollectionApiActionCreator,
  reducer: addFavoriteBountiesCollection,
} = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/favorite-bounties-collection',
});

export const { actionCreator: deleteFavoriteBountiesCollectionApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/users/:userId/favorite-bounties-collection/:bountyCollectionId',
});

export const {
  actionCreator: getHiddenBountiesCollectionApiActionCreator,
  reducer: getHiddenBountiesCollection,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/hidden-bounties-collection',
});

export const { actionCreator: addHiddenBountiesCollectionApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/hidden-bounties-collection',
});

export const { actionCreator: deleteHiddenBountiesCollectionApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/users/:userId/hidden-bounties-collection/:bountyCollectionId',
});

// BOUNTIES END

// Bounties Tags START

export const {
  actionCreator: getbountyCategoriesApiActionCreator,
  reducer: getBountyCategories,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounty-categories',
});

export const {
  actionCreator: createBountyCategoryApiActionCreator,
  reducer: createBountyCategory,
} = apiFactory({
  method: 'POST',
  endpoint: '/bounty-category',
});

export const {
  actionCreator: updateBountyCategoryApiActionCreator,
  reducer: updateBountyCategory,
} = apiFactory({
  method: 'PUT',
  endpoint: '/bounty-category/:bountyCategoryId',
});

export const {
  actionCreator: updateBountySubCategoryApiActionCreator,
  reducer: updateBountySubCategory,
} = apiFactory({
  method: 'PUT',
  endpoint: '/bounty-sub-category/:bountySubCategoryId',
});

export const {
  actionCreator: deleteBountyCategoryApiActionCreator,
  reducer: deleteBountyCategory,
} = apiFactory({
  method: 'DELETE',
  endpoint: '/bounty-category/:bountyCategoryId',
});

export const {
  actionCreator: createBountySubCategoriesApiActionCreator,
  reducer: createBountySubCategories,
} = apiFactory({
  method: 'POST',
  endpoint: '/bounty-sub-category',
});

export const {
  actionCreator: deleteBountySubCategoryApiActionCreator,
  reducer: deleteBountySubCategory,
} = apiFactory({
  method: 'DELETE',
  endpoint: '/bounty-sub-category/:bountySubCategoryId',
});

// Bounties Tags END

export const { actionCreator: getVaultItemsApiActionCreator, reducer: getVaultItems } = apiFactory({
  method: 'GET',
  endpoint: '/vault-items',
});

export const {
  actionCreator: getVaultItemBuyersActionCreator,
  reducer: getVaultItemBuyers,
} = apiFactory({
  method: 'GET',
  endpoint: '/vault-items/:vaultItemId/buyers',
});

export const {
  actionCreator: getVaultItemsSimplifiedApiActionCreator,
  reducer: getVaultItemsSimplified,
} = apiFactory({
  method: 'GET',
  endpoint: '/vault-items/simplified',
  baseQuery: {
    isActive: true,
  },
});

export const {
  actionCreator: getVaultItemTypesApiActionCreator,
  reducer: getVaultItemTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/vault-item-types',
});

export const {
  actionCreator: getWhenStockIsZeroApiActionCreator,
  reducer: getWhenStockIsZero,
} = apiFactory({
  method: 'GET',
  endpoint: '/when-stock-is-zero',
});

export const {
  actionCreator: getVaultItemGoalsApiActionCreator,
  reducer: getVaultItemGoals,
} = apiFactory({
  method: 'GET',
  endpoint: '/vault-item-goals',
});

export const { actionCreator: addVaultItemApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault-items',
});

export const { actionCreator: deleteVaultItemApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/vault-items/:vaultItemId',
});

export const { actionCreator: updateVaultItemApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vault-items/:vaultItemId',
});

export const { actionCreator: getVaultItemApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vault-items/:vaultItemId',
});

export const { actionCreator: uploadVaultImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault-items/files',
});

export const { actionCreator: uploadVaultItemSizeGuideApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault-items/size-guide',
});

export const { actionCreator: reorderVaultItemsApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vault-items/reorder',
});

export const {
  actionCreator: getRarityTypesApiActionCreator,
  reducer: getRarityTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/rarity-types',
});

export const { actionCreator: purchaseVaultItemApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault-items/:vaultItemId/purchase',
});

export const { actionCreator: getVaultItemSizeGuideApiActionCreator } = apiFactory({
  method: 'GET',
  endpoint: '/vault-items/:vaultItemId/size-guide',
});

export const { actionCreator: getPrintfulProductAvailabilityActionCreator } = apiFactory({
  method: 'GET',
  endpoint: '/printful/products/:productId/availability',
});

export const { actionCreator: getEventsApiActionCreator, reducer: getEvents } = apiFactory({
  method: 'GET',
  endpoint: '/events',
});

export const { actionCreator: addEventApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/events',
});

export const { actionCreator: updateEventApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/events/:eventId',
});

export const { actionCreator: deleteEventApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/events/:eventId',
});

export const {
  actionCreator: getClubsChannelsApiActionCreator,
  reducer: getClubsChannels,
} = apiFactory({
  method: 'GET',
  endpoint: '/clubs-channels',
});

// SETTINGS START

export const { actionCreator: getSettingsApiActionCreator, reducer: getSettings } = apiFactory({
  method: 'GET',
  endpoint: '/settings',
});

export const { actionCreator: updateSettingApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/settings/:settingId',
});

export const { actionCreator: uploadSettingsImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/settings/files',
});

export const { actionCreator: grantStarsApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/settings/stars/grant',
});

export const { actionCreator: grantUnleashPlusBonusActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/settings/unleash-plus-bonus/grant',
});

// SETTINGS END

export const { actionCreator: grantBountyApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/unlocked-bounties/grant-bounty',
});

export const { actionCreator: undoBountyApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/unlocked-bounties/undo-grant-bounty',
});

export const { actionCreator: postReferralApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/referral',
});

export const {
  actionCreator: getVacationRequestsApiActionCreator,
  reducer: getVacationRequests,
} = apiFactory({
  method: 'GET',
  endpoint: '/vacation-requests',
});

export const { actionCreator: postVacationRequestApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vacation-requests',
});

export const { actionCreator: updateVacationRequestApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vacation-requests/:id',
});

export const {
  actionCreator: getVacationRequestStatusesApiActionCreator,
  reducer: getVacationRequestStatuses,
} = apiFactory({
  method: 'GET',
  endpoint: '/vacation-request-statuses',
});

export const { actionCreator: getApiKeysApiActionCreator, reducer: getApiKeys } = apiFactory({
  method: 'GET',
  endpoint: '/api-keys',
});

export const { actionCreator: createApiKeyApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/api-keys',
});

export const { actionCreator: deleteApiKeyApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/api-keys/:apiKey',
});

// ADMIN TOOLS

export const { actionCreator: listInvoiceIssuesActionCreator } = apiFactory<
  definitions['getToolsInvoiceIssuesResponse']
>({
  method: 'GET',
  endpoint: '/tools/invoice-issues',
});

export const { actionCreator: fixInvoiceIssuesActionCreator } = apiFactory<
  definitions['postToolsInvoiceIssuesResponse']
>({
  method: 'POST',
  endpoint: '/tools/invoice-issues',
});

export const { actionCreator: executeToolByNameApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/tools/:toolName',
});

export const {
  actionCreator: getSwaggerJsonApiActionCreator,
  reducer: getSwaggerJson,
} = apiFactory({
  method: 'GET',
  endpoint: '/swagger.json',
});

// HARVEST API (XWP Users)
export const {
  actionCreator: getHarvestTimeEntriesActionCreator,
  reducer: getHarvestData,
} = apiFactory({
  method: 'POST',
  endpoint: '/harvest_time_entries',
});

export const {
  actionCreator: getProductiveTimeEntriesActionCreator,
  reducer: getProductiveData,
} = apiFactory({
  method: 'POST',
  endpoint: '/productive_time_entries',
});

export const { actionCreator: updateUserTimezone } = apiFactory({
  method: 'PUT',
  endpoint: '/me/timezone',
});

export const {
  actionCreator: getMyBountiesCountApiActionCreator,
  reducer: getMyBountiesCount,
} = apiFactory({
  method: 'GET',
  endpoint: '/me/bounties-count',
});

export const {
  actionCreator: patchLockBountyByIdApiActionCreator,
  reducer: patchLockBountyById,
} = apiFactory({
  method: 'PATCH',
  endpoint: '/me/lock-bounty/:bountyId',
});

// DOCUMENTS START

export const {
  actionCreator: getUserDocumentsToSignActionCreator,
  reducer: getUserDocumentsToSign,
} = apiFactory({
  method: 'GET',
  endpoint: '/me/documents/unsigned',
});

export const { actionCreator: createDocumentApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/documents',
});

export const { actionCreator: signDocumentApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/documents/signing',
});

export const { actionCreator: deleteDocumentApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/documents/:documentId',
});

export const { actionCreator: updateDocumentApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/documents/:documentId',
});

export const { actionCreator: uploadDocumentFileApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/documents/files',
});

export const {
  actionCreator: getUserSignedDocumentsApiActionCreator,
  reducer: getUserSignedDocuments,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/documents',
});

// DOCUMENTS END

// NOTES START

export const { actionCreator: getUserNotesApiActionCreator, reducer: getUserNotes } = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/notes',
});

export const { actionCreator: createUserNoteApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/notes',
});

export const { actionCreator: updateUserNoteApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/users/notes/:noteId',
});

export const { actionCreator: deleteUserNoteApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/users/notes/:noteId',
});

export const {
  actionCreator: getUserNoteTypesApiActionCreator,
  reducer: getUserNoteTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/notes/types',
});

export const { actionCreator: uploadUserNotesFileApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/notes/files',
});

// NOTES END

// TEAMS START

export const { actionCreator: getTeamsApiActionCreator, reducer: getTeams } = apiFactory({
  method: 'GET',
  endpoint: '/teams',
});

export const { actionCreator: getTeamApiActionCreator, reducer: getTeam } = apiFactory({
  method: 'GET',
  endpoint: '/teams/:teamId',
});

export const { actionCreator: createTeamApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/teams',
});

export const { actionCreator: updateTeamApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/teams/:teamId',
});

export const { actionCreator: deleteTeamApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/teams/:teamId',
});

export const { actionCreator: uploadTeamIconApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/teams/files',
});

// TEAMS END

// COLLECTION START

export const {
  actionCreator: getVaultCollectionsApiActionCreator,
  reducer: getVaultItemsCollections,
} = apiFactory({
  method: 'GET',
  endpoint: '/vault/collections',
});

export const { actionCreator: deleteVaultCollectionApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/vault/collections/:collectionId',
});

export const { actionCreator: updateVaultCollectionVaultItemsOrderApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vault/collections/:collectionId/reorder',
});

export const { actionCreator: createVaultCollectionApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault/collections',
});

export const { actionCreator: uploadVaultCollectionIconApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault/collections/files',
});

export const { actionCreator: updateVaultCollectionApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/vault/collections/:collectionId',
});

export const { actionCreator: reorderVaultCollectionsApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault/collections/reorder',
});

export const { actionCreator: reorderVaultCollectionVaultApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/vault/collections/vault/reorder',
});

export const {
  actionCreator: getHiddenBountiesApiActionCreator,
  reducer: getHiddenBounties,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/hidden-bounties',
});

export const { actionCreator: addHiddenBountiesApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/users/:userId/hidden-bounties',
});

export const { actionCreator: deleteHiddenBountiesApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/users/:userId/hidden-bounties/:bountyId',
});

// COLLECTION END

export const { actionCreator: uploadLegendImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/legends/files',
});

export const { actionCreator: uploadLegacyImageApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/legacy/files',
});

export const { actionCreator: reorderLegendsApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/legends/reorder',
});

export const { actionCreator: reorderLegacyApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/legacy/reorder',
});

export const { actionCreator: deleteVacationRequestApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/vacation-requests/:id',
});

export const {
  actionCreator: sendManagerSlackNotificationVacationRequestApiActionCreator,
} = apiFactory({
  method: 'POST',
  endpoint: '/vacation-requests/notification/:id',
});

export const { actionCreator: getAirwallexTokenStatus } = apiFactory({
  method: 'GET',
  endpoint: '/invoices/airwallex-token',
});

export const { actionCreator: saveAirwallexToken } = apiFactory({
  method: 'POST',
  endpoint: '/invoices/airwallex-token',
});

// BOUNTIES COLLECTION START

export const {
  actionCreator: getBountiesCollectionsApiActionCreator,
  reducer: getBountiesCollections,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounties/collections',
});

export const {
  actionCreator: getUserBountiesCollectionsApiActionCreator,
  reducer: getUserBountiesCollections,
} = apiFactory({
  method: 'GET',
  endpoint: '/users/:userId/bounties-collections',
});

export const {
  actionCreator: getBountiesCollectionsBountiesApiActionCreator,
  reducer: getBountiesCollectionsBounties,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounties/collections/bounties',
});

export const {
  actionCreator: getBountiesCollectionRewardTypesApiActionCreator,
  reducer: getBountiesCollectionRewardTypes,
} = apiFactory({
  method: 'GET',
  endpoint: '/bounties/collection/rewards/types',
});

export const {
  actionCreator: deleteBountiesCollectionApiActionCreator,
  reducer: deleteBountiesCollection,
} = apiFactory({
  method: 'DELETE',
  endpoint: '/bounties/collections/:id',
});

export const {
  actionCreator: createBountiesCollectionApiActionCreator,
  reducer: createBountiesCollection,
} = apiFactory({
  method: 'POST',
  endpoint: '/bounties/collections',
});

export const { actionCreator: uploadBountiesCollectionIconApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties/collections/files',
});

export const { actionCreator: reorderBountiesCollectionsApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties/collections/reorder',
});

export const { actionCreator: reorderBountiesCollectionBountiesApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bounties/collections/bounties/reorder',
});

// BOUNTIES COLLECTION END

// BETS START

export const { actionCreator: getBetsApiActionCreator, reducer: getBets } = apiFactory({
  method: 'GET',
  endpoint: '/bets',
});

export const { actionCreator: createBetApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/bets',
});

export const { actionCreator: getBetDocumentApiActionCreator } = apiFactory({
  method: 'GET',
  endpoint: '/bets/:id/download-results',
});

export const { actionCreator: updateBetApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bets/:id',
});

export const { actionCreator: startBetApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bets/:id/launch',
});

export const { actionCreator: finishBetApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bets/:id/end',
});

export const { actionCreator: deleteBetApiActionCreator } = apiFactory({
  method: 'DELETE',
  endpoint: '/bets/:id',
});

export const { actionCreator: pauseBetApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bets/:id/pause',
});

export const { actionCreator: resumeBetApiActionCreator } = apiFactory({
  method: 'PUT',
  endpoint: '/bets/:id/resume',
});

// BETS END

// GROUPS START

export const { actionCreator: getUserGroupsApiActionCreator, reducer: getUserGroups } = apiFactory({
  method: 'GET',
  endpoint: '/user-groups',
});

export const { actionCreator: uploadSlackMessageFileApiActionCreator } = apiFactory({
  method: 'POST',
  endpoint: '/user-groups/files',
});

export const {
  actionCreator: getUsersGroupsProjectCreator,
  reducer: getUsersGroupsProject,
} = apiFactory({
  method: 'GET',
  endpoint: '/user-groups/:groupId/projects',
});

// GROUPS END

// USERS START

export const { actionCreator: getManagersApiActionCreator, reducer: getManagers } = apiFactory({
  method: 'GET',
  endpoint: '/users/managers',
});

// USERS END

// DEPARTMENTS START

export const {
  actionCreator: getDepartmentsApiActionCreator,
  reducer: getDepartments,
} = apiFactory({
  method: 'GET',
  endpoint: '/departments',
});

// DEPARTMENTS END

// DEPARTMENT EXPENSE CATEGORIES START

export const {
  actionCreator: getDepartmentExpenseCategoriesApiActionCreator,
  reducer: getDepartmentExpenseCategories,
} = apiFactory({
  method: 'GET',
  endpoint: '/departments/:departmentId/expense-categories',
});

// END DEPARTMENT EXPENSE CATEGORIES
