import { memo } from 'react';

import Checkbox from 'components/inputs/Checkbox';
import Label from 'components/table/Label';
import Row from 'components/table/Row';
import Table from 'components/table/Table';
import Value from 'components/table/Value';
import { Document } from 'types/types';
import isKeyboardEvent from 'utils/keyboardEvent';

import styles from './DocumentSigningItem.module.css';

export type DocumentToSign = Omit<Document, '_project'> & { accepted: boolean };

type DocumentSigningItemProps = {
  document: DocumentToSign;
  acceptTerm: (documentId: number, checked: boolean) => void;
};

const DocumentSigningItem = ({ document, acceptTerm }: DocumentSigningItemProps) => {
  return (
    <div className={styles.DocumentWrapper}>
      <Table isRaw>
        <Row className={styles.DocumentName}>
          <Label>Document name</Label>
          <Value>{document.title}</Value>
        </Row>
        <Row>
          <Label>Description</Label>
          <Value>{document.description}</Value>
        </Row>
        <Row>
          <Label>File/URL</Label>
          <Value>
            {document.url?.length ? (
              <a
                href={document.url}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.DocumentLink}
              >
                {document.url}
              </a>
            ) : null}
            {document.file ? (
              <a
                href={document.file?.location}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.DocumentLink}
              >
                {document.file?.originalName}
              </a>
            ) : null}
          </Value>
        </Row>
        <Row>
          <div className={styles.TermCheckbox}>
            <Checkbox
              checked={document.accepted}
              onChange={(value: boolean) => acceptTerm(document.id, value)}
            />
            <span
              className={styles.TermText}
              onClick={() => acceptTerm(document.id, !document.accepted)}
              onKeyUp={(e) => isKeyboardEvent(e) && acceptTerm(document.id, !document.accepted)}
              role="button"
              tabIndex={0}
            >
              I have read, understand and accept
            </span>
          </div>
        </Row>
      </Table>
    </div>
  );
};

export default memo(DocumentSigningItem);
