import { useEffect, useRef, memo, PropsWithChildren } from 'react';

import { Transition, TransitionStatus } from 'react-transition-group';

export type TransitionStyle = { opacity: number };

const transitionStyles: TransitionStyles = {
  entering: {
    opacity: 1,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

type TransitionStyles = {
  entering: TransitionStyle;
  entered: TransitionStyle;
  exiting: TransitionStyle;
  exited: TransitionStyle;
  mounted?: TransitionStyle;
  unmounted?: TransitionStyle;
};

export type FadeProps = {
  shouldShow: boolean;
  onClose?: null | (() => void); // passing this will trigger "autoHide"
  transitionMS?: number;
  autoCloseDelayMS?: number;
};

export const fadeDefaultProps = {
  autoCloseDelayMS: 5000,
  transitionMS: 500,
};

export const Fade = memo(
  ({
    children,
    shouldShow,
    onClose, // Passing this prop will trigger auto hide.
    autoCloseDelayMS = fadeDefaultProps.autoCloseDelayMS,
    transitionMS = fadeDefaultProps.transitionMS,
  }: PropsWithChildren<FadeProps>) => {
    const toastRef = useRef(null);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const transition = `opacity ${transitionMS}ms linear`;

    useEffect(() => {
      if (shouldShow && onClose && timer.current === null) {
        timer.current = setTimeout(() => {
          onClose();
        }, autoCloseDelayMS);
      } else if (onClose && timer.current !== null) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    }, [shouldShow, autoCloseDelayMS, onClose]);

    const defaultStyle = {
      opacity: 1,
      transition,
    };

    return (
      <Transition in={shouldShow} timeout={transitionMS} nodeRef={toastRef}>
        {(state: TransitionStatus) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={toastRef}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  },
);
