import { PropsWithChildren, useRef, useEffect } from 'react';

import classNames from 'classnames';

import styles from './Wrap.module.scss';

const displayShadows = (target: HTMLElement) => {
  const { scrollLeft, offsetWidth, scrollWidth } = target;
  if (scrollLeft > 0) {
    target.classList.add(styles.Wrap_shadow_left);
  } else {
    target.classList.remove(styles.Wrap_shadow_left);
  }
  if (scrollLeft + offsetWidth + 1 >= scrollWidth) {
    target.classList.remove(styles.Wrap_shadow_right);
  } else {
    target.classList.add(styles.Wrap_shadow_right);
  }
};

type Props = {
  isFlex?: boolean;
  isAligned?: boolean;
  isFlexCentered?: boolean;
  isWide?: boolean;
  isScrollable?: boolean;
  isFullWidth?: boolean;
  isMobile?: boolean;
  withBottomButton?: boolean;
  className?: string;
  innerWrapClassName?: string;
  disableShadow?: boolean;
};

/** @deprecated for Page wrapping, use DS Templates/Page component. */
const Wrap = (props: PropsWithChildren<Props>) => {
  const shadowContainer = useRef<HTMLDivElement>(null);
  const {
    children,
    isFlex,
    isAligned,
    isFlexCentered,
    isWide,
    isScrollable,
    isFullWidth,
    isMobile,
    withBottomButton,
    className,
    innerWrapClassName,
    disableShadow,
  } = props;

  const scrollboxClasses = classNames(isScrollable && styles.Wrap_table, innerWrapClassName);
  const wrapContainerClasses = classNames(
    styles.Wrap,
    isFlex && styles.Wrap_flex,
    isAligned && styles.Wrap_aligned,
    isFlexCentered && styles.Wrap_flex_centered,
    isWide && styles.Wrap_wide,
    isFullWidth && styles.Wrap_fullwidth,
    isMobile && styles.Wrap_mobile,
    withBottomButton && styles.Wrap_with_bottom_button,
    className,
  );

  useEffect(() => {
    if (isScrollable && shadowContainer.current && !disableShadow) {
      displayShadows(shadowContainer.current);
    }
  }, [isScrollable, disableShadow]);

  return (
    <div className={wrapContainerClasses}>
      <div
        className={scrollboxClasses}
        onScroll={(evt) => {
          const target = evt.target as HTMLElement;
          if (isScrollable && !disableShadow) {
            displayShadows(target);
          }
        }}
        ref={shadowContainer}
      >
        {children}
      </div>
    </div>
  );
};

export default Wrap;
