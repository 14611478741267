import { forwardRef, memo, ReactNode, useState, useEffect } from 'react';

import classNames from 'classnames';

import { Button, TableRow, TableRowProps } from 'design-system/Atoms';
import { useForwardRef } from 'design-system/utils/hooks';

import styles from './TableExpandableRow.module.scss';

export interface TableExpandableRowProps extends TableRowProps {
  show?: boolean;
  expandableColSpan?: number;
  expandableClassName?: string;
  expandableChildren?: ReactNode;
  expandableControl?: ReactNode;
}

export const TableExpandableRow = memo(
  forwardRef<HTMLTableRowElement, TableExpandableRowProps>((props, forwardedRef) => {
    const {
      className,
      children,
      expandableColSpan = 100,
      expandableClassName,
      expandableChildren,
      expandableControl,
      show: showProp,
      ...rest
    } = props;

    const ref = useForwardRef(forwardedRef);

    const [show, setShow] = useState(showProp ?? false);

    useEffect(() => {
      setShow(showProp ?? false);
    }, [showProp]);

    const toggleShow = () => setShow((prev) => !prev);

    return (
      <>
        <TableRow className={className} {...rest} ref={ref}>
          {expandableChildren ? (
            <td>
              <Button
                data-testid="TestId__EXPANDABLE__BUTTON"
                key="expandable-button"
                className={styles.Button}
                as="button"
                onClick={toggleShow}
                iconName={show ? 'chevron-up' : 'chevron-down'}
                aria-label={show ? 'Hide' : 'Expand'}
                iconPosition="start"
                layout="autolayout"
                variant="icon"
              />
            </td>
          ) : (
            <td>
              <div key="expandable-empty-cell" data-testid="TestId_EMPTY_CELL" />
            </td>
          )}
          {children}
        </TableRow>
        {show && expandableChildren && (
          <TableRow
            className={classNames(styles.TableExpandableRow, expandableClassName)}
            control={expandableControl}
          >
            <td colSpan={expandableColSpan}>{expandableChildren}</td>
          </TableRow>
        )}
      </>
    );
  }),
);
