import { Link } from 'react-router-dom';

import { ADMIN } from './routing';

const ToastMessages = {
  VacationRequests: {
    noActiveAssignments: {
      text:
        "You can't submit your request since you don't have any active assignments. " +
        'Please contact your Manager.',
      autoClose: 5000,
    },
    invalidDate: {
      text: 'Vacation request can only be created for current or future dates',
      autoClose: 4000,
    },
    success: {
      text: 'Vacation request was added successfully!',
      autoClose: 3000,
    },
    catchError: {
      text: 'Vacation request error!',
    },
    delete: {
      text: 'Vacation Request was successfully deleted',
    },
    changeSuccess: {
      text: 'Vacation status changed successfully',
      autoClose: 3000,
    },
  },
  Invoice: {
    invoiceSubmitted: (dueDate) => ({
      text: `Your invoice was successfully submitted on ${dueDate}.`,
      autoClose: 3000,
    }),
    totalInvoiced: (currency, value) => ({
      text: `You have invoiced for a total of ${currency} ${value}.`,
      autoClose: 3000,
    }),
    paymentsCompleted: () => ({
      text: `If your invoice was submitted by the end of the month, expect payment in 3-7 days.`,
      autoClose: 3000,
    }),
    onTimePayment: () => ({
      text: `To ensure on time payment, please submit by the last day of the current month.`,
      autoClose: 3000,
    }),
    currentSubmitting: (userDisplayName) => ({
      text: `You are currently submitting an invoice for ${userDisplayName}`,
      autoClose: 5000,
    }),
    incompleteBankingInfo: (userDisplayName) => ({
      text: `${userDisplayName} doesn't have the Banking Info set.`,
      autoClose: 5000,
    }),
    rateNotSet: {
      text: `It seems you don't have a rate set in Admin panel. Please contact your account manager to add your rate to the database.`,
      autoClose: 10000,
    },
    addReimbursementItem: {
      text:
        'Please confirm with your account manager that you have active assignments that accepts reimbursements before adding a reimbursement item.',
    },
    overBonusValue: {
      text:
        'You are exceding the available bonus value. Please reduce the amount you are requesting to continue.',
    },
    emptyInvoice: {
      text: `It appears you haven't added any hours or reimbursements to the invoice. Please include at least one of those to continue.`,
    },
    failedSubmitProjectHour: (reason) => ({
      text: `There was an error submitting the hours: ${reason}.`,
      autoClose: 10000,
    }),
  },
  Accounting: {
    invalidCsvData: (reason) => ({
      text: `There was a problem generating the csv: ${reason}`,
      autoClose: 10000,
    }),
    noMatchingInvoices: {
      text: `There are no invoices matching current filters.`,
      autoClose: 5000,
    },
    creatingInvoicesZipFile: {
      text: `Creating invoices zip file, please wait.`,
      autoClose: 10000,
    },
    failedValidation: (reason) => ({
      text: reason,
      autoClose: 10000,
    }),
  },
  Vault: {
    catchError: (reason) => ({
      text: `Unable to place order: ${reason}`,
      autoClose: 5000,
      type: 'error',
    }),
  },
  Assignments: {
    noRate: (userId) => ({
      text: (
        <>
          <span>
            User's rate is missing in the User Details tab. Add the rate in User Details in order to
            add a new assignment.&nbsp;
          </span>
          <Link to={`${ADMIN.USER_PATH}/${userId}/user-details`}>Add Rate</Link>
        </>
      ),
      autoClose: 0,
    }),
  },
  Document: {
    successAdd: {
      text: 'Document was added successfully!',
      type: 'success',
    },
    successUpdate: {
      text: 'Document was updated successfully!',
      type: 'success',
    },
    successDelete: {
      text: 'Document was deleted successfully!',
      type: 'success',
    },
    failAdd: (reason) => ({
      text: `Failed adding the document: ${reason}`,
      type: 'error',
    }),
    failUpdate: (reason) => ({
      text: `Failed updating the document: ${reason}`,
      type: 'error',
    }),
    failDelete: (reason) => ({
      text: `Failed deleting the document: ${reason}`,
      type: 'error',
    }),
    badTitle: {
      text: 'Title should be defined',
      autoClose: 3000,
    },
    badUrl: {
      text: 'Please enter a valid URL',
      autoClose: 3000,
    },
    catchError: (reason) => ({
      text: `Unable to create document: ${reason}`,
      autoClose: 5000,
    }),
  },
  UserGroups: {
    addToChannel: {
      text: "You were successfully added to the group's existing Slack channel",
      type: 'success',
    },
    channelExists: {
      text: 'Slack channel already exists and you are already a member',
      type: 'warning',
    },
    createChannel: {
      text: 'Slack channel created successfully',
      type: 'success',
    },
    createChannelFailed: {
      text: 'Error while creating Slack channel',
      type: 'error',
    },
    createChannelFailed_NoMembers: {
      text: "Can't create a Slack channel for a group with no members",
      type: 'error',
    },
    createEvent: {
      text: 'Group event created successfully',
      type: 'success',
    },
    createEventFailed: {
      text: 'Error while creating group event',
      type: 'error',
    },
    createGroup: {
      text: 'Group created successfully',
      type: 'success',
    },
    createGroupFailed: {
      text: 'Error while creating a new group',
      type: 'error',
    },
    deleteGroup: {
      text: 'Group deleted successfully',
      type: 'success',
    },
    deleteGroupFailed: {
      text: 'Error while deleting group',
      type: 'error',
    },
    sendMessage: {
      text: 'Group message sent successfully',
      type: 'success',
    },
    sendMessageFailed: {
      text: 'Error while sending group message',
      type: 'error',
    },
    updateGroup: {
      text: 'Group updated successfully',
      type: 'success',
    },
    updateGroupFailed: {
      text: 'Error while updating group',
      type: 'error',
    },
  },
};

export const Messages = {
  ToastMessages,
};
