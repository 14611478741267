import { BrandConfig } from './brand-config';
import { config as xteamConfig } from './xteam-config';
import { config as xwpConfig } from './xwp-config';

export const brandDictionary = {
  xteam: 'X-Team',
  xwp: 'XWP',
};

export type BRAND_KEY = keyof typeof brandDictionary;

const DEFAULT_BRAND = 'xteam';

export const brand = process.env.REACT_APP_BRAND || DEFAULT_BRAND;

export const brands: Record<BRAND_KEY, BrandConfig> = {
  xteam: xteamConfig,
  xwp: xwpConfig,
};

export const brandConfig = brands[brand as BRAND_KEY];
