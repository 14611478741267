import { memo } from 'react';

import classNames from 'classnames';

import styles from './Legend.module.scss';

const Legend = memo(
  ({
    submitStep,
    noPadding,
    className,
    message,
  }: {
    submitStep?: boolean;
    noPadding?: boolean;
    className?: string;
    message?: string;
  }) => {
    const Classes = classNames(
      styles.Legend,
      noPadding && styles.Legend_noPadding,
      submitStep && styles.Legend_submitStep,
      className,
    );

    return <legend className={Classes}>{message}</legend>;
  },
);

export default Legend;
