import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  memo,
  PropsWithChildren,
  useState,
  useCallback,
} from 'react';

import cx from 'classnames';
import { SortDirection, TableHeader, TableRow } from 'design-system';

import { useForwardRef } from 'design-system/utils';

import styles from './Table.module.scss';

export interface Header extends HTMLAttributes<HTMLSpanElement> {
  sortColumnName?: string;
  sortActive?: boolean;
  sortDirection?: SortDirection;
}

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  adminStyle?: boolean;
  containerClassName?: string;
  headers?: Header[];
  sortHandlerClick?: (
    e: React.MouseEvent<HTMLSpanElement>,
    sortColumnName: string,
    sortDirection: SortDirection,
  ) => void;
}

export const Table = memo(
  forwardRef<HTMLTableElement, PropsWithChildren<TableProps>>((props, forwardedRef) => {
    const {
      children,
      className,
      containerClassName,
      headers,
      adminStyle = false,
      sortHandlerClick,
      tabIndex = -1,
      ...rest
    } = props;

    const ref = useForwardRef(forwardedRef);

    const initialSortActiveState =
      headers?.reduce((init, header) => {
        if (header.sortActive && header.sortColumnName) {
          init.push(header.sortColumnName);
        }
        return init;
      }, new Array<string>()) || [];

    const initialColumnSortDirection =
      headers?.reduce((hash, header) => {
        const sortColumn = header.sortColumnName ?? '';
        if (sortColumn !== '') {
          hash.set(sortColumn, header.sortDirection || 'desc');
        }
        return hash;
      }, new Map<string, SortDirection>()) || new Map<string, SortDirection>();

    const [currentSortActive, setCurrentSortActive] = useState<string[]>(initialSortActiveState);
    const [columnsSortDirection, setColumnsSortDirection] = useState(initialColumnSortDirection);

    const handleHeaderClick = useCallback(
      (
        event: React.MouseEvent<HTMLSpanElement>,
        sortColumnName: string,
        sortDirection: SortDirection,
      ) => {
        setColumnsSortDirection(columnsSortDirection.set(sortColumnName, sortDirection));
        setCurrentSortActive([sortColumnName]);
        ref?.current?.focus({ preventScroll: true });
        sortHandlerClick?.(event, sortColumnName, sortDirection);
      },
      [sortHandlerClick, setColumnsSortDirection, columnsSortDirection, setCurrentSortActive, ref],
    );

    return (
      <div
        className={cx(styles.TableContainer, containerClassName)}
        data-testid="TestId__TABLE_CONTAINER"
      >
        <table
          className={cx(styles.Table, className, { [styles.TableAdmin]: adminStyle })}
          data-testid="TestId__TABLE"
          ref={forwardedRef}
          role="table"
          tabIndex={tabIndex}
          {...rest}
        >
          <thead>
            <TableRow header>
              {
                headers?.map((headerProp, i) => {
                  const { children, sortColumnName, ...rest } = headerProp;
                  return (
                    <TableHeader
                      {...rest} // need to came before the sortActive, and sortDirection
                      {...(sortColumnName && {
                        sortClick: (e, direction) =>
                          handleHeaderClick(e, sortColumnName, direction),
                        sortColumnName,
                        sortActive: currentSortActive.includes(sortColumnName),
                        sortDirection: columnsSortDirection.get(sortColumnName),
                      })}
                      key={`header_${i}`}
                    >
                      {children}
                    </TableHeader>
                  );
                }) as ReactNode[]
              }
            </TableRow>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  }),
);
