import { useQuery } from 'react-query';

import { apiUrl } from 'config';
import currentStore from 'store/currentStore';

const ENDPOINT = '/unlocked-bounties/';

export const useUnlockedByQuery = (bountyId: number) => {
  return useQuery(`unlocked-by-${bountyId}`, () => fetchUnlockedBy(bountyId));
};

export const fetchUnlockedBy = async (bountyId: number) => {
  const { getState } = currentStore();
  const token = getState().session?.data?.id;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(`${apiUrl}${ENDPOINT}${bountyId}`, {
    method: 'GET',
    mode: 'cors',
    headers,
  });

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }

  return await response.json();
};
