import { memo, MouseEventHandler, PropsWithChildren } from 'react';

import { Button } from 'design-system/Atoms';
import { ButtonActiveState } from 'types/types';

import styles from './StepCardActions.module.scss';

export type StepCardActionsProps = PropsWithChildren<{
  left?: {
    label: string;
    state: ButtonActiveState;
    onClick: MouseEventHandler;
  };
  right?: {
    label: string;
    state: ButtonActiveState;
    onClick: MouseEventHandler;
  };
}>;

export const StepCardActions = memo((props: StepCardActionsProps) => {
  return (
    <div data-testid="TestId__STEPCARDACTIONS__WRAPPER" className={styles.Container}>
      {props.left && (
        <Button
          data-testid="TestId__STEPCARDACTIONS__BTN__LEFT"
          variant={'secondary'}
          layout={'autolayout'}
          as={'button'}
          onClick={props.left.onClick}
          className={styles.FocusReset}
          disabled={props.left.state === 'disabled' || props.left.state === 'loading'}
          isLoading={props.left.state === 'loading'}
        >
          {props.left.label}
        </Button>
      )}
      {props.right && (
        <Button
          data-testid="TestId__STEPCARDACTIONS__BTN__RIGHT"
          onClick={props.right.onClick}
          variant={'primary'}
          layout={'autolayout'}
          as={'button'}
          className={styles.FocusReset}
          disabled={props.right.state === 'disabled' || props.right.state === 'loading'}
          isLoading={props.right.state === 'loading'}
        >
          {props.right.label}
        </Button>
      )}
    </div>
  );
});
