import { memo, DragEvent, ChangeEvent } from 'react';

import cs from 'classnames';
import { Icon, Label, Droppable, Paragraph } from 'design-system';

import styles from './FilesUploader.module.scss';

export type FilesUploaderProps = {
  isEditable?: boolean;
  uploadedFiles: string[];
  onFileDrop?: (event: DragEvent<HTMLDivElement>) => void;
  onFileInputChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  removeFile: (index: number) => void;
  fileContainerClassName?: string;
  dropSectionTitle?: string;
  dropSectionClassName?: string;
  uploadedSectionTitle?: string;
};

export const FilesUploader = memo<FilesUploaderProps>((props) => {
  return (
    <>
      <div className={cs(styles.Row, props.dropSectionClassName)}>
        <Label
          type={'common'}
          paragraphClassName={styles.Label}
          interactive={false}
          showBackground={false}
          text={props.dropSectionTitle}
        />
        <Droppable onDrop={props.onFileDrop} onFileInputChange={props?.onFileInputChange}>
          <div className={styles.DroppableContent} />
        </Droppable>
      </div>
      {props.uploadedFiles?.length ? (
        <div data-testid="TestId__FILESUPLOADER__UPLOADED__FILE__AREA" className={styles.Row}>
          {props.uploadedSectionTitle && (
            <Label
              data-testid="TestId__FILESUPLOADER__UPLOADED__SECTION__TITLE"
              type={'common'}
              paragraphClassName={styles.Label}
              interactive={false}
              showBackground={false}
              text={props.uploadedSectionTitle}
            />
          )}
          <div className={styles.Files}>
            {props.uploadedFiles.map((item: string, index: number) => (
              <div
                data-testid={`TestId__FILESUPLOADER__UPLOADED__ITEM__${index}`}
                className={cs(styles.FileContainer, props.fileContainerClassName)}
                key={`${item}-${index}`}
              >
                <Paragraph className={styles.FileName} variant={'ui-bold'}>
                  {item}
                </Paragraph>
                {props.isEditable && (
                  <div
                    className={styles.IconContainer}
                    data-testid={`TestId__FILESUPLOADER__UPLOADED__DELETE__${index}`}
                    onClick={() => props.removeFile(index)}
                    onKeyPress={() => props.removeFile(index)}
                    role={'button'}
                    tabIndex={0}
                  >
                    <Icon name={'trash-outline'} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
});
