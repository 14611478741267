/**
 * Parse a string numeric value returning the number
 * representation of it.
 *
 * @param {string} value - string value
 * @param {boolean} abs - parse using the Math.abs value
 * @param {number} fixed - number of decimal places (default: 2 i.e. 2.5698 => 2.57)
 * @returns {string} - number as string of the conversion if isNaN will return '0'
 */
function parseNumber(value: string | number, abs = true, fixed = 2): string {
  const number = abs ? Math.abs(Number(value)) : Number(value);
  if (isNaN(number)) {
    return '0';
  }
  if (!isNaN(fixed)) {
    return number.toFixed(2);
  }
  return String(number);
}

export { parseNumber };
