import { lazy } from 'react';

import { brandConfig } from 'brands';
import { Route, RouteProps, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components/Auth/authRedirect';
import { USER_ROLE } from 'consts/constants';
import { APP, INVOICE, PROFILE } from 'consts/routing';
import HomePage from 'pages/HomePage';
import Legacy from 'pages/Legacy/Legacy';
import { ToggleablePages } from 'types/types';
import FEATURE_FLAGS, { isFeatureEnabled } from 'utils/featureFlags';
import { useAdminSetting, useIsPageEnabled } from 'utils/hooks';

const Submit = lazy(
  () => import(/* webpackChunkName: "Submit", webpackPrefetch: true */ 'pages/Submit'),
);

const NewSubmit = lazy(
  () => import(/* webpackChunkName: "Submit", webpackPrefetch: true */ 'pages/SubmitInvoice'),
);
const NewSurvey = lazy(
  () =>
    import(/* webpackChunkName: "SurveyInvoice", webpackPrefetch: true */ 'pages/SurveyInvoice'),
);
const NewReferrals = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralsInvoice", webpackPrefetch: true */ 'pages/ReferralsInvoice'
    ),
);
const NewConfirmation = lazy(
  () =>
    import(
      /* webpackChunkName: "SurveyInvoice", webpackPrefetch: true */ 'pages/InvoiceConfirmation'
    ),
);
const NewPastInvoice = lazy(
  () => import(/* webpackChunkName: "SurveyInvoice", webpackPrefetch: true */ 'pages/PastInvoice'),
);
const Confirmation = lazy(
  () => import(/* webpackChunkName: "Confirmation" */ 'pages/Confirmation/containers/Confirmation'),
);
const Help = lazy(() => import(/* webpackChunkName: "Help" */ 'pages/Help'));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ 'pages/Profile/Profile'));
const ProfileInvoices = lazy(
  () => import(/* webpackChunkName: "ProfileInvoices" */ 'pages/ProfileInvoices'),
);
const ProfileBanking = lazy(
  () => import(/* webpackChunkName: "ProfileBanking" */ 'pages/ProfileBanking/ProfileBanking'),
);
const Unleash = lazy(() => import(/* webpackChunkName: "Unleash" */ 'pages/Unleash/Unleash'));
const UnleashGuide = lazy(
  () => import(/* webpackChunkName: "UnleashGuide" */ 'pages/UnleashGuide'),
);
const Survey = lazy(
  () => import(/* webpackChunkName: "Survey", webpackPrefetch: true */ 'pages/Survey'),
);
const InvoiceReferrals = lazy(
  () =>
    import(
      /* webpackChunkName: "InvoiceReferrals", webpackPrefetch: true */ 'pages/InvoiceReferrals/InvoiceReferrals'
    ),
);
const Refer = lazy(() => import(/* webpackChunkName: "Refer" */ 'pages/Refer'));
const VacationRequest = lazy(
  () => import(/* webpackChunkName: "VacationRequest" */ 'pages/VacationRequest/VacationRequest'),
);
const Vault = lazy(() => import(/* webpackChunkName: "Vault" */ 'pages/Vault/Vault'));
const NewBounties = lazy(
  () => import(/* webpackChunkName: "NewBounties" */ 'pages/Bounties/NewBounties'),
);
const OldBountiesUI = lazy(
  () => import(/* webpackChunkName: "NewBounties" */ 'pages/BountiesOldUI/NewBounties'),
);
const UserBounties = lazy(
  () => import(/* webpackChunkName: "UserBounties" */ 'pages/UserBounties/UserBounties'),
);
const UserDocuments = lazy(
  () => import(/* webpackChunkName: "UserDocuments" */ 'components/user/UserDocuments'),
);
const UnleashRemaining = lazy(
  () =>
    import(/* webpackChunkName: "UnleashRemaining" */ 'pages/UnleashRemaining/UnleashRemaining'),
);
const Legends = lazy(() => import(/* webpackChunkName: "Legends" */ 'pages/Legends/Legends'));
const Houses = lazy(() => import(/* webpackChunkName: "Houses" */ 'pages/Houses'));

const adminCommunityPeople = [
  USER_ROLE.ADMIN,
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.COMMUNITY_TEAM,
  USER_ROLE.PEOPLE_OPS,
];
const allUserRoles = Object.values(USER_ROLE);

const EmptyRoute = () => <div />;

const ToggleableRoute = ({ page, ...routeProps }: ToggleableRouteType) => {
  const isPageEnabled = useIsPageEnabled(page);
  return <Route {...routeProps} component={isPageEnabled ? routeProps.component : EmptyRoute} />;
};

const AppPage = () => {
  const legends = useAdminSetting('legends');
  const isNewInvoiceUIEnabled = isFeatureEnabled(FEATURE_FLAGS.newInvoice);

  return (
    <Switch>
      <Route exact path={APP.HELP_PATH} component={Help} />
      <Route
        exact
        path={INVOICE.SUBMIT_PATH}
        component={isNewInvoiceUIEnabled ? NewSubmit : Submit}
      />
      <Route
        path={`${INVOICE.SUBMIT_PATH}/:userId`}
        component={isNewInvoiceUIEnabled ? NewSubmit : Submit}
      />
      {brandConfig.flags.invoiceSurveyEnabled ? (
        <Route
          path={`${INVOICE.SURVEY_PATH}/:invoiceId`}
          component={isNewInvoiceUIEnabled ? NewSurvey : Survey}
        />
      ) : null}
      {brandConfig.flags.invoiceSurveyEnabled ? (
        <Route path={INVOICE.SURVEY_PATH} component={isNewInvoiceUIEnabled ? NewSurvey : Survey} />
      ) : null}
      <Route
        path={`${INVOICE.REFERRALS_PATH}/:invoiceId`}
        component={isNewInvoiceUIEnabled ? NewReferrals : InvoiceReferrals}
      />
      <Route path={PROFILE.PROFILE_INVOICES_PATH} component={ProfileInvoices} />
      <Route path={PROFILE.BANKING_PATH} component={ProfileBanking} />
      <Route path={PROFILE.DOCUMENTS_PATH} component={UserDocuments} />
      {brandConfig.flags.unleashEnabled ? (
        <Route path={PROFILE.UNLEASH_REMAINING_PATH} component={UnleashRemaining} />
      ) : null}
      {brandConfig.flags.unleashEnabled ? (
        <Route path={APP.UNLEASH_PATH} component={Unleash} />
      ) : null}
      {brandConfig.flags.unleashEnabled ? (
        <Route path={APP.UNLEASH_GUIDE_PATH} component={UnleashGuide} />
      ) : null}
      <Route path={APP.VACATION_REQUEST_PATH} component={VacationRequest} />
      <Route
        path={`${INVOICE.CONFIRMATION_PATH}/:invoiceId`}
        component={isNewInvoiceUIEnabled ? NewConfirmation : Confirmation}
      />
      <Route path={`${INVOICE.PAST_INVOICE}/:invoiceId`} component={NewPastInvoice} />
      <Route exact path={APP.HOME_PATH} component={HomePage} />
      <Route path={PROFILE.PROFILE_PATH} component={Profile} />
      {brandConfig.flags.referEnabled ? <Route path={APP.REFER_PATH} component={Refer} /> : null}
      {brandConfig.flags.bountiesEnabled ? (
        <Route path={PROFILE.BOUNTIES_PATH} component={UserBounties} />
      ) : null}
      {brandConfig.flags.bountiesEnabled ? (
        <Route
          path={APP.BOUNTIES_PATH}
          component={isFeatureEnabled(FEATURE_FLAGS.newBountiesUI) ? NewBounties : OldBountiesUI}
        />
      ) : null}
      {brandConfig.flags.vaultEnabled ? <Route path={APP.VAULT_PATH} component={Vault} /> : null}
      {brandConfig.flags.housesEnabled ? (
        <ToggleableRoute page="houses" path={APP.HOUSES_PATH} component={Houses} />
      ) : null}
      {brandConfig.flags.legendsEnabled ? (
        <PrivateRoute
          allowedRoles={legends?.values.legendsPageForUsers ? allUserRoles : adminCommunityPeople}
          path={APP.LEGENDS_PATH}
          component={Legends}
        />
      ) : null}
      {brandConfig.flags.legacyEnabled ? (
        <PrivateRoute allowedRoles={allUserRoles} path={APP.LEGACY_PATH} component={Legacy} />
      ) : null}
    </Switch>
  );
};

type ToggleableRouteType = RouteProps & { page: ToggleablePages };

export default AppPage;
