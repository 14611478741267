import { memo, PropsWithChildren, HTMLAttributes } from 'react';

import cx from 'classnames';

import { Button } from 'design-system/Atoms';

import styles from './MobileModalBox.module.scss';

export interface MobileModalBoxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  modalOpen?: boolean;
  openClose?: () => void;
}

export const MobileModalBox = memo<PropsWithChildren<MobileModalBoxProps>>(
  ({
    children,
    modalOpen,
    openClose,
    className,
    ...props
  }: PropsWithChildren<MobileModalBoxProps>) => {
    return (
      <div
        data-testid="TestId__MOBILE__MODAL__BOX"
        {...props}
        className={cx(styles.MobileModalBox, className, {
          [styles.ModalOpen]: modalOpen,
          [styles.ModalClose]: !modalOpen,
        })}
      >
        <div className={styles.Content}>
          {modalOpen && (
            <Button
              variant="secondary"
              layout="autolayout"
              as="button"
              iconName="thin-long-left"
              iconPosition="start"
              className={styles.ButtonGoBack}
              onClick={openClose}
            >
              Go back
            </Button>
          )}
          {children}
        </div>
      </div>
    );
  },
);
