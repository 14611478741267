import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './StepTitle.module.scss';

type Props = {
  step?: number | string;
  isActive?: boolean;
  isRed?: boolean;
  noPadding?: boolean;
  isFaq?: boolean;
};

const StepTitle = (props: PropsWithChildren<Props>) => {
  const { step, isActive, isRed, noPadding, isFaq, children } = props;
  const titleClasses = classNames(
    styles.StepTitle,
    isActive && styles.StepTitle_active,
    isRed && styles.StepTitle_red,
    noPadding && styles.StepTitle_nopadding,
    isFaq && styles.StepTitle_faq,
  );

  return (
    <h4 className={titleClasses}>
      {step ? <span className={styles.Number}>Step {step}. </span> : ''}
      {children}
    </h4>
  );
};

export default StepTitle;
