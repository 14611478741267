import { memo } from 'react';

import c from 'classnames';
import { Link } from 'react-router-dom';

import styles from './ProgressBarStepTitle.module.scss';

function ProgressBarStepTitle({ classes, title, href }: ProgressBarStepTitleProps) {
  const linkClasses = c(styles.ProgressBarStep_Title, classes);

  if (href) {
    return (
      <Link className={linkClasses} to={href}>
        {title}
      </Link>
    );
  }
  return <span className={linkClasses}>{title}</span>;
}

export default memo(ProgressBarStepTitle);

type ProgressBarStepTitleProps = {
  href?: string;
  title: string;
  classes?: string;
};
