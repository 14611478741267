import { memo, ReactNode, Children, isValidElement, cloneElement } from 'react';

import classNames from 'classnames';

import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  id?: string;
  text: string;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  children: ReactNode;
  disabled?: boolean;
  className?: string;
}

const isRight = (placement: string) => placement === 'right';
const isLeft = (placement: string) => placement === 'left';
const isTop = (placement: string) => placement === 'top';
const isBottom = (placement: string) => placement === 'bottom';

export const Tooltip = memo<TooltipProps>(
  ({ placement = 'top', disabled, className, ...props }: TooltipProps) => {
    if (disabled) {
      return <>{props.children}</>;
    }
    const TooltipContent = () => {
      const tooltipClasses = classNames(
        styles.Tooltip,
        {
          [styles.PlacementBottom]: isBottom(placement),
          [styles.PlacementTop]: isTop(placement),
          [styles.PlacementRight]: isRight(placement),
          [styles.PlacementLeft]: isLeft(placement),
        },
        className,
      );

      return (
        <div id={props.id} className={tooltipClasses} data-testid="TestId__TOOLTIP">
          <Paragraph className={styles.TooltipText} variant="ui-regular">
            {props.text}
          </Paragraph>
        </div>
      );
    };

    const childrenWithTooltip = Children.map(props.children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(
          <div>
            <TooltipContent />
            {child}
          </div>,
          {
            className: styles.TooltipParent,
          },
        );
      }

      return child;
    });

    return <>{childrenWithTooltip}</>;
  },
);
