import { Mood } from 'design-system';

import { ActiveState, ButtonActiveState } from 'types/types';

export const getButtonState = (
  state: ActiveState,
  isRequired: boolean,
  answer?: Mood | string,
): ButtonActiveState => {
  if (state !== 'active') {
    return state;
  }

  if (isRequired && !answer) {
    return 'disabled';
  }

  return state;
};
