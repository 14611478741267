import { memo, MouseEventHandler, SyntheticEvent, MouseEvent } from 'react';

import cx from 'classnames';
import { Paragraph, Avatar, AvatarProps, AvatarVariant } from 'design-system';

import styles from './AvatarGroup.module.scss';

type AvatarGroupHandler = (
  e: SyntheticEvent<HTMLDivElement, Event> | undefined,
) => MouseEventHandler<HTMLDivElement> | undefined | void;

export interface AvatarGroupProps {
  data: AvatarProps[];
  type: 'group' | 'individual';
  amountVisible: number;
  interactive?: boolean;
  onClickMore?: AvatarGroupHandler;
  textMoreIndicator?: boolean;
  extraText?: string;
  avatarVariant?: AvatarVariant;
}

export const AvatarGroup = memo<AvatarGroupProps>((props: AvatarGroupProps) => {
  if (!props.data || props.data.length === 0) {
    return (
      <Avatar id="nothing" accessibleName="No Avatar" name="N/A" variant={props.avatarVariant} />
    );
  }

  const handleOnClickAvatarItem = (event: MouseEvent<HTMLDivElement>, avatarItem: AvatarProps) => {
    event.preventDefault();
    avatarItem?.onClick?.(event);
  };

  const handleOnClickMore = (e: SyntheticEvent<HTMLDivElement, Event>) => {
    e.preventDefault();
    props.onClickMore?.(e);
  };

  const extraProps = props.interactive && {
    role: 'button',
    onClick: handleOnClickMore,
  };

  const avatarGroupClasses = cx(
    { [styles.AvatarGroupGroup]: props.type === 'group' },
    { [styles.AvatarGroupIndividual]: props.type === 'individual' },
    { [styles.AvatarGroupClickable]: props.interactive },
  );

  const avatarItemClasses = cx(
    { [styles.AvatarItemGroup]: props.type === 'group' },
    { [styles.AvatarItemIndividual]: props.type === 'individual' },
  );

  return (
    <div data-testid="TestId__AVATARGROUP__WRAPPER" className={avatarGroupClasses}>
      {props.data.map((avatarItem, index) => {
        if (index + 1 > props.amountVisible) {
          return null;
        }

        return (
          <Avatar
            key={index}
            id={avatarItem.id}
            className={avatarItemClasses}
            accessibleName={avatarItem.accessibleName}
            interactive={!!(avatarItem.interactive && props.interactive)}
            imgSrc={avatarItem.imgSrc}
            name={avatarItem.name}
            onClick={(e) => handleOnClickAvatarItem(e, avatarItem)}
            variant={props.avatarVariant}
          />
        );
      })}

      {props.textMoreIndicator ? (
        <TextMoreIndicator
          extraText={props.extraText}
          data={props.data}
          amountVisible={props.amountVisible}
        />
      ) : (
        <AvatarMoreIndicator
          data={props.data}
          amountVisible={props.amountVisible}
          type={props.type}
          interactive={props.interactive}
          extraProps={extraProps}
          variant={props.avatarVariant}
        />
      )}
    </div>
  );
});

type AvatarMoreIndicatorProps = {
  data: AvatarProps[];
  amountVisible: number;
  type: 'group' | 'individual';
  interactive?: boolean;
  variant?: AvatarVariant;
  extraProps?:
    | false
    | {
        role: string;
        onClick: (e: SyntheticEvent<HTMLDivElement, Event>) => void;
      };
};

const AvatarMoreIndicator = (props: AvatarMoreIndicatorProps) => {
  if (props.data.length <= props.amountVisible) {
    return null;
  }

  const avatarMoreIndicatorClasses = cx({ [styles.AvatarItemGroup]: props.type === 'group' });
  return (
    <Avatar
      id="more"
      className={avatarMoreIndicatorClasses}
      accessibleName="More Content"
      name={`+${props.data.length - props.amountVisible}`}
      interactive={props.interactive}
      variant={props.variant}
      {...props.extraProps}
    />
  );
};

type TextMoreIndicatorProps = {
  data: AvatarProps[];
  amountVisible: number;
  extraText?: string;
};

const TextMoreIndicator = (props: TextMoreIndicatorProps) => {
  if (props.data.length <= props.amountVisible) {
    return null;
  }

  return (
    <Paragraph variant="ui-regular" className={styles.TextMoreIndicator}>{`+${
      props.data.length - props.amountVisible
    } ${props.extraText}`}</Paragraph>
  );
};
