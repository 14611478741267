import { useState } from 'react';

import { Badge, Heading, Image, Modal } from 'design-system';

import defaultBackgroundImg from 'assets/legends/legends.jpg';
import { File } from 'types/types';

import styles from './LegacyCard.module.scss';

export const LegacyCard = ({ title, name = '', backgroundImage, crown, description }: Props) => {
  const background = backgroundImage?.location ?? defaultBackgroundImg;
  const [modalOpen, setModalOpen] = useState(false);
  const spotlightStyles = {
    background: `url(${background}) no-repeat center`,
    backgroundSize: 'cover',
  };

  const onClick = () => setModalOpen(true);

  return (
    <>
      <div
        className={styles.Card}
        onClick={onClick}
        role="link"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick();
          }
        }}
      >
        <div className={styles.SpotlightBackground} style={spotlightStyles}>
          <div className={styles.CrowIconGroup}>
            {crown && (
              <img className={styles.CrownIcon} src={crown.location} alt="Champion Crown Icon" />
            )}
            <div className={styles.Title}>{title}</div>
          </div>
        </div>
        <div className={styles.CrownGroup}>
          <div className={styles.Username}>{name}</div>
        </div>
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} modalClassName={styles.Modal}>
          <div className={styles.ModalContent}>
            <Image variant="squared" src={background} alt={title} />
            <div className={styles.Content}>
              <Heading className={styles.ModalHeading} level="h4">
                {name}
              </Heading>
              <Badge
                type="legacy"
                text={title}
                showBackground={true}
                showIcon={true}
                iconSrc={crown?.location}
                interactive={false}
              />
              <Description content={description} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export type Props = {
  title: string;
  description?: string;
  name?: string;
  backgroundImage?: File;
  crown?: File;
};

const Description = ({ content }: { content?: string }) => {
  if (!content) return null;

  const splitLines = content.split(/(?:\r\n|\r|\n)/g);

  return (
    <>
      {splitLines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};
