import { DetailedHTMLProps, HTMLAttributes, memo } from 'react';

import { Button, Input } from 'design-system/Atoms';
import { swapPositions } from 'utils/arraysHelper';

import styles from './OrderingInput.module.scss';

export type OrderingInputProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  value: string[];
  onItemsChange?: (items: string[]) => void;
};

export const OrderingInput = memo<OrderingInputProps>(({ value, onItemsChange }) => {
  const MAX_NUM_OF_ITEMS = 10;
  const canAddMoreItems = value && !!value[value.length - 1] && value.length < MAX_NUM_OF_ITEMS;

  return (
    <div>
      {value.map((item, index) => (
        <div
          key={`ordering-input-${index}`}
          className={styles.InputContainer}
          data-testid={`ordering-input-${index}`}
        >
          <Input
            value={item}
            onChange={(e) => {
              const newValue = value.map((item, i) => {
                if (i === index) {
                  return e.currentTarget.value;
                }

                return item;
              });

              if (onItemsChange) {
                onItemsChange(newValue);
              }
            }}
            state="inactive"
            layout="fluid"
            mode="writable"
            noMargins
            data-testid={`ordering-input-input-${index}`}
          />
          <Button
            className={styles.InputButton}
            as="button"
            variant="secondary"
            layout="autolayout"
            iconName={'chevron-up'}
            data-testid={`ordering-input-moveup-button-${index}`}
            disabled={index === 0}
            disableSvg
            onClick={() => {
              const newValue = swapPositions(value, index, index - 1);

              if (onItemsChange) {
                onItemsChange(newValue);
              }
            }}
          />
          <Button
            className={styles.InputButton}
            as="button"
            variant="secondary"
            layout="autolayout"
            iconName={'chevron-down'}
            data-testid={`ordering-input-movedown-button-${index}`}
            disabled={index === value.length - 1}
            disableSvg
            onClick={() => {
              const newValue = swapPositions(value, index, index + 1);

              if (onItemsChange) {
                onItemsChange(newValue);
              }
            }}
          />
          <Button
            className={styles.InputButton}
            as="button"
            variant="secondary"
            layout="autolayout"
            iconName={'trash-outline'}
            data-testid={`ordering-input-delete-button-${index}`}
            disableSvg
            onClick={() => {
              if (value.length > 1) {
                const newValue = value.filter((_, i) => i !== index);
                onItemsChange?.(newValue);
              } else {
                onItemsChange?.(['']);
              }
            }}
          />
        </div>
      ))}
      <Button
        as="button"
        variant="secondary"
        layout="fluid"
        disabled={!canAddMoreItems}
        data-testid="add-new-item-button"
        onClick={() => {
          if (canAddMoreItems) {
            const newValue = [...value, ''];
            onItemsChange?.(newValue);
          }
        }}
      >
        <div className={styles.ButtonContent}>
          Add new list item <i className={styles.PlusIcon} />
        </div>
      </Button>
    </div>
  );
});
