import { Fragment, memo } from 'react';

import { EventModel } from 'types/types';
import { useGetEvents } from 'utils/apiQueryHooks';
import { formatDate } from 'utils/helpers';

import styles from './HomeEvents.module.scss';

export const HomeEvents = memo(({ ongoing }: { ongoing?: boolean }) => {
  const { resolvedData: events } = useGetEvents({ query: { isActive: true } }) as {
    resolvedData: { data: EventModel[] };
  };
  const now: number = Date.now();
  const filteredEvents = events?.data.filter((event) => {
    const startTime: number = new Date(event.startDate).getTime();
    const finishTime: number = new Date(event.finishDate).getTime();

    return ongoing ? startTime < now && now < finishTime : now < startTime;
  });

  return (
    <div className={styles.Events}>
      <h4 className={styles.EventsTitle}>{ongoing ? 'Ongoing Events' : 'Upcoming Events'}</h4>
      <ol className={styles.EventsList}>
        {filteredEvents?.map((event) => (
          <EventItem key={`event_${event.id}`} event={event} />
        ))}
      </ol>
    </div>
  );
});

const EventItem = memo(({ event }: { event: EventModel }) => {
  const dateRange = `${formatDate(event.startDate)} - ${formatDate(event.finishDate)}`;
  const body = (
    <Fragment>
      <span>
        <span className={styles.Event_title}>{event.name}</span>
        <span className={styles.Event_date}>{dateRange}</span>
      </span>
      <span className={styles.Arrow}></span>
    </Fragment>
  );

  if (event.link) {
    return (
      <li className={styles.Event}>
        <a
          className={styles.Event_link}
          href={event.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {body}
        </a>
      </li>
    );
  }

  return <li className={styles.Event}>{body}</li>;
});
