import styles from './Error.module.css';

type DisplayErrorProps = {
  error?: string;
};

type ErrorMessageProps = {
  touched: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: string;
  };
  value: string;
};

export const DisplayError = ({ error }: DisplayErrorProps) => (
  <div data-testid="error" className={styles.Error}>
    {error}
  </div>
);

const ErrorMessage = ({ touched, errors, value }: ErrorMessageProps) => (
  <div>{touched[value] && errors[value] && <DisplayError error={errors[value]} />}</div>
);

export default ErrorMessage;
