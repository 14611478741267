import { ReactNode, Suspense, SyntheticEvent, useState, useCallback, useEffect } from 'react';

import { brandConfig } from 'brands';
import c from 'classnames';
import { get } from 'lodash';
import { connect, HandleThunkActionCreator, DefaultRootState } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';

import {
  isActivated,
  isAdmin,
  isSuperAdmin,
  isAuthenticated,
  isCommunityTeam,
  isManager,
  isPeopleOps,
  isRecruiter,
  isSuperWizard,
} from 'components/Auth/authHelpers';
import { withApiData, waitForApi } from 'components/Database';
import Logo from 'components/Logo';
import { Notifications } from 'components/notifications/Notifications';
import {
  getProjectsOwnersApiActionCreator,
  getSubmittedInvoiceStatusApiActionCreator,
  getApprovedInvoiceStatusApiActionCreator,
  getQueuedInvoiceStatusApiActionCreator,
} from 'store/reduxRestApi';
import { InvoiceStatus, Settings, User, UserRole } from 'types/types';
import { formatSeasonNumber } from 'utils/formattingHelpers';
import { getSettingByName } from 'utils/helpers';
import { useCurrentUser, useIsDashboardFeedEnabled, useDisabledMenuItems } from 'utils/hooks';
import isKeyboardEvent from 'utils/keyboardEvent';

import { ProfileMenu } from '../ProfileMenu';
import { Search } from '../Search';
import images, { Menu as MenuImg, External } from '../images';
import { getAdminMenuItemsData, getMainMenuItemsData, RenderLinkOptions } from '../menuHelpers';

import styles from './Menu.module.scss';

type openedLinksType = {
  [key: string]: boolean;
};

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    active,
    children,
    showAdminMenu,
    showCommunityMenu,
    showSettingsMenu,
    showSearch,
    settings,
  } = props;

  const seasonSettings = getSettingByName(settings, 'season');
  const privacyPolicy = getSettingByName(settings, 'privacy-policy');
  const privacyPolicyUrl = get(privacyPolicy, 'url', '');
  const seasonNumber = get(seasonSettings, 'number', '');
  const seasonMotto = get(seasonSettings, 'motto', '');

  const [opened, setOpened] = useState(false);
  const [mobileOpened, setMobileOpened] = useState(false);
  const [openedLinks, setOpenedLinks] = useState<openedLinksType>({});
  const disabledItems = useDisabledMenuItems();

  const menuItems = getMainMenuItemsData({
    seasonNumber,
    disabledItems,
    privacyPolicyUrl,
  });

  const currentUser = useCurrentUser();

  useEffect(() => {
    setOpenedLinks({});
  }, [currentUser]);

  const toggleLink = useCallback(
    (key: string) => {
      setOpenedLinks({
        ...openedLinks,
        [key]: !openedLinks[key],
      });
    },
    [openedLinks],
  );

  const toggleMenu = useCallback((value: boolean) => {
    setOpened(value);
  }, []);

  const showHideMobile = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      setMobileOpened(!mobileOpened);
    },
    [mobileOpened],
  );

  const showFeed = useIsDashboardFeedEnabled();

  const getPathname = useCallback(() => {
    const routesWithNewBackground = ['/bounties'];
    const currentRoute = window.location?.pathname;
    return routesWithNewBackground.includes(currentRoute);
  }, []);

  const hideMobile = useCallback(() => setMobileOpened(false), []);

  const renderLink = useCallback(
    (options: RenderLinkOptions) => {
      const {
        link,
        text,
        ImageEl,
        sublinks = null,
        external = false,
        sublink = false,
        onClickHandler = null,
        key = `${link}_${text}`,
        excludedFrom = [],
      } = options;
      // TODO: improve type https://x-team-internal.atlassian.net/browse/XHQ-2894
      if (excludedFrom.includes(props.user?._role?.name as UserRole)) {
        return null;
      }

      const inner = [
        <span className={styles.nav__ico} key="img">
          <ImageEl />
        </span>,
        <span className={styles.nav__text} key="text">
          {text}
        </span>,
      ];

      return (
        <li
          key={key}
          className={c(styles.nav__item, {
            [styles['nav__item_with-inner']]: !!sublinks,
            [styles['nav__item_inner']]: sublink,
            [styles['nav__item_active']]: openedLinks[key],
          })}
        >
          {link && typeof link === 'string' && external && (
            <a
              href={link}
              onClick={() => {
                onClickHandler?.();
                toggleLink(key);
              }}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={0}
              role="button"
            >
              <span className={styles.nav__item_inner_wrapper}>
                <span className={styles.nav__item_inner_content}>{inner}</span>
                <External />
              </span>
            </a>
          )}

          {link && !external && (
            <NavLink
              to={link}
              exact
              onClick={hideMobile}
              activeClassName={styles.a_active}
              tabIndex={0}
              role="menuitem"
            >
              {inner}
            </NavLink>
          )}

          {!link && (
            <a
              href="/"
              onClick={(ev) => {
                ev.preventDefault();
                onClickHandler?.();
                toggleLink(key);
              }}
              tabIndex={0}
              role="menu"
            >
              <span className={styles.nav__item_inner_wrapper}>
                <span className={styles.nav__item_inner_content}>{inner}</span>
                {!!sublinks && <span className={styles['nav__item_sublinks_marker']} />}
              </span>
            </a>
          )}

          {sublinks && (
            <ul>
              {sublinks.map((sublink) => sublink && renderLink({ ...sublink, sublink: true }))}
            </ul>
          )}
        </li>
      );
    },
    [hideMobile, openedLinks, props.user?._role?.name, toggleLink],
  );

  if (!active) return <>{children}</>;

  return (
    <div
      data-testid="navigation"
      className={c(
        styles['outer-wrapper'],
        { [styles['nav_mobile-active']]: mobileOpened },
        getPathname() && styles.CustomBackground,
      )}
    >
      <div className={c(styles.nav, { [styles.nav_hover]: opened })}>
        <nav onMouseEnter={() => toggleMenu(true)} onMouseLeave={() => toggleMenu(false)}>
          <ul>
            <li>
              <Logo darkMode={props.user?.darkMode || false} />
            </li>
            {brandConfig.flags.seasonsEnabled ? (
              <li className={c(styles.nav__item, styles.nav__item_mobile_and_fullscreen)}>
                <NavLink className={styles.Menu_a_season2} to="/" onClick={hideMobile}>
                  <div className={styles.nav__text__season2}>
                    Season {formatSeasonNumber(seasonNumber)}
                    <span className={styles.nav__text__season2__wired}>{seasonMotto}.</span>
                  </div>
                </NavLink>
              </li>
            ) : null}
            {showAdminMenu &&
              renderLink({
                link: '',
                text: 'Admin',
                ImageEl: images.Admin,
                sublinks: getAdminMenuItemsData('admin', { ...props }),
                external: true,
                onClickHandler: null,
                key: 'admin',
              })}
            {showCommunityMenu &&
              renderLink({
                link: '',
                text: 'Community',
                ImageEl: images.Community,
                sublinks: getAdminMenuItemsData('community', props),
                external: true,
                onClickHandler: null,
                key: 'community',
              })}
            {showSettingsMenu && getAdminMenuItemsData('settings', props).map(renderLink)}
            {(showAdminMenu || showCommunityMenu || showSettingsMenu) && (
              <li className={styles.nav_item_separator} />
            )}
            {menuItems.map(renderLink)}
          </ul>
          <ul>
            {renderLink({
              link: '/help',
              text: 'Help',
              ImageEl: images.Help,
            })}
          </ul>
        </nav>
      </div>
      <div
        className={styles.wrapper}
        onClick={hideMobile}
        onKeyUp={(e) => isKeyboardEvent(e) && hideMobile()}
        role="button"
        tabIndex={0}
      >
        <header id="site-header" className={styles['site-header']}>
          <div
            className={styles['site-header__mobile-nav']}
            onClick={showHideMobile}
            onKeyUp={(e) => isKeyboardEvent(e) && showHideMobile(e)}
            role="button"
            tabIndex={0}
          >
            <MenuImg />
          </div>
          <div className={styles['site-header_mobile-logo']}>
            <Logo darkMode={props.user?.darkMode || false} />
          </div>
          <div>
            <Suspense fallback={null}>
              <div className={styles['suspense-content']}>
                {showSearch && (
                  <div data-testid="globalSearch">
                    <Search />
                  </div>
                )}
                {showFeed && <Notifications />}
                <ProfileMenu />
              </div>
            </Suspense>
          </div>
        </header>
        <div className={styles['inner-wrapper']}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: DefaultRootState) => ({
  settings: get(state.getSettings, ['response', 'data'], []),
  active: isActivated(state.session?.data) && isAuthenticated(state.session?.data),
  communityTeam: isCommunityTeam(state.session?.data),
  manager: isManager(state.session?.data),
  superAdmin: isSuperAdmin(state.session?.data),
  admin: isAdmin(state.session?.data),
  peopleOps: isPeopleOps(state.session?.data),
  recruiter: isRecruiter(state.session?.data),
  superWizard: isSuperWizard(state.session?.data),
  user: isAuthenticated(state.session?.data) ? state.session?.data._user : null,
  showSearch:
    isSuperAdmin(state.session?.data) ||
    isAdmin(state.session?.data) ||
    isPeopleOps(state.session?.data) ||
    isManager(state.session?.data) ||
    isSuperWizard(state.session?.data),
  showAdminMenu:
    isSuperAdmin(state.session?.data) ||
    isAdmin(state.session?.data) ||
    isManager(state.session?.data) ||
    isCommunityTeam(state.session?.data) ||
    isPeopleOps(state.session?.data) ||
    isRecruiter(state.session?.data) ||
    isSuperWizard(state.session?.data),
  showCommunityMenu:
    isSuperAdmin(state.session?.data) ||
    isSuperWizard(state.session?.data) ||
    isAdmin(state.session?.data) ||
    isCommunityTeam(state.session?.data) ||
    isManager(state.session?.data) ||
    isPeopleOps(state.session?.data),
  showSettingsMenu:
    isSuperAdmin(state.session?.data) ||
    isAdmin(state.session?.data) ||
    isSuperWizard(state.session?.data) ||
    isPeopleOps(state.session?.data) ||
    isManager(state.session?.data) ||
    isCommunityTeam(state.session?.data),
  submittedInvoiceStatus: state.getSubmittedInvoiceStatus,
  approvedInvoiceStatus: state.getApprovedInvoiceStatus,
  queuedInvoiceStatus: state.getQueuedInvoiceStatus,
  projectsOwners: get(state.getProjectsOwners, ['response', 'data'], []),
});

const mapDispatchToProps = {
  getSubmittedInvoiceStatusApiActionCreator,
  getApprovedInvoiceStatusApiActionCreator,
  getQueuedInvoiceStatusApiActionCreator,
  getProjectsOwnersApiActionCreator,
};

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withApiData([
    (props: MenuProps) => props.getSubmittedInvoiceStatusApiActionCreator({ useCache: true }),
    (props: MenuProps) => props.getApprovedInvoiceStatusApiActionCreator({ useCache: true }),
    (props: MenuProps) => props.getQueuedInvoiceStatusApiActionCreator({ useCache: true }),
    (props: MenuProps) =>
      props.admin && props.getProjectsOwnersApiActionCreator({ useCache: true }),
  ]),
  waitForApi(['submittedInvoiceStatus', 'approvedInvoiceStatus', 'queuedInvoiceStatus']),
)(Menu);

export interface MenuProps {
  user: User | null;
  settings?: Settings[] | null;
  active: boolean;
  admin: boolean;
  superAdmin: boolean;
  communityTeam: boolean;
  manager: boolean;
  peopleOps: boolean;
  recruiter: boolean;
  superWizard: boolean;
  approvedInvoiceStatus?: InvoiceStatus | null;
  submittedInvoiceStatus?: InvoiceStatus | null;
  queuedInvoiceStatus?: InvoiceStatus | null;
  showAdminMenu: boolean;
  showCommunityMenu: boolean;
  showSettingsMenu: boolean;
  showSearch: boolean;
  projectsOwners?: User[] | null;
  children?: ReactNode;
  getSubmittedInvoiceStatusApiActionCreator: HandleThunkActionCreator<
    typeof getSubmittedInvoiceStatusApiActionCreator
  >;
  getApprovedInvoiceStatusApiActionCreator: HandleThunkActionCreator<
    typeof getApprovedInvoiceStatusApiActionCreator
  >;
  getQueuedInvoiceStatusApiActionCreator: HandleThunkActionCreator<
    typeof getQueuedInvoiceStatusApiActionCreator
  >;
  getProjectsOwnersApiActionCreator: HandleThunkActionCreator<
    typeof getProjectsOwnersApiActionCreator
  >;
}
