import { FormikErrors, withFormik } from 'formik';
import { get, isEmpty, pick } from 'lodash';

import { paymentMethodsMap } from 'consts/constants';
import { validatePaymentFields } from 'utils/paymentHelper';

import EditPayment from '../EditPayment';
import { IPaymentValues } from './Payment.types';

type Props = {
  cancelButton?: React.ReactElement;
  buttonWrapperStyles?: string;
  skipValidation?: boolean;
  formValidated?: (errors: errorType, values: IPaymentValues) => void;
  payment?: IPaymentValues;
  onSave: (param1: Record<string, any>, setSubmitting: (isSubmitting: boolean) => void) => void;
};

type errorType = {
  method?: string;
};

export const Payment = withFormik<Props, IPaymentValues>({
  validateOnMount: true,
  mapPropsToValues: ({ payment }) => ({ ...payment }),
  validate: (values, { skipValidation, formValidated }) => {
    if (skipValidation) {
      return {};
    }

    let errors: FormikErrors<IPaymentValues> = {};

    const method = values.method || get(values, '_paymentType.name');

    if (!method) {
      errors.method = 'Payment method needs to be chosen. Please choose your payment method';
    }

    /**
     * In the case user is setting payment information for the first time, when selecting method,
     * without this check, the component breaks, leaving the user locked out of XHQ.
     */
    if (!values.info) {
      errors.payments = `Values do not contain payment info: ${JSON.stringify(values)}`;
    } else {
      const paymentMethodErrors = validatePaymentFields(method, values.info);
      if (!isEmpty(paymentMethodErrors)) {
        errors = { ...paymentMethodErrors };
      }
    }

    if (formValidated) {
      formValidated(errors, values);
    }

    return errors;
  },
  handleSubmit: ({ info, notes, _paymentType }, { props: { onSave }, setSubmitting }) => {
    const name = _paymentType?.name;

    if (name) {
      const paymentMethodFieldNames = Object.keys(paymentMethodsMap[name].fields);
      const selectedFields = pick(info, paymentMethodFieldNames);
      onSave({ info: selectedFields, notes, _paymentType }, setSubmitting);
    }
  },
})(EditPayment);
