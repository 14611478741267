import { useState } from 'react';

import { Modal, Input, Paragraph, Heading } from 'design-system';

import { UnleashCategory } from 'types/types';

import styles from './OverUnleashConfirmModal.module.scss';

export function OverUnleashConfirmModal({
  categoryName,
  categoryLimit,
  availableBudget,
  onCancel,
  onConfirm,
  approvedBy = '',
  isFocusOnEnabled,
}: OverUnleashConfirmModalProps) {
  const [approverName, setApproverName] = useState<string>(approvedBy);
  const handleFormSubmit = () => approverName && onConfirm(approverName);

  return (
    <Modal
      onClose={onCancel}
      isFocusOnEnabled={isFocusOnEnabled}
      onSubmit={handleFormSubmit}
      isButtonDisabled={!approverName}
    >
      <Heading level="h5" className={styles.Heading}>
        The Unleash+ category limit has been exceeded!
      </Heading>
      <Paragraph className={styles.MainText}>
        {categoryLimit
          ? `I acknowledge that the total Unleash+ reimbursement amount for the ${categoryName} exceeds
              the monthly limit of ${categoryLimit}. This has been authorized by`
          : `I acknowledge that the total Unleash+ reimbursement amount for the ${categoryName} exceeds
              the available budget of ${availableBudget}. This has been authorized by`}
      </Paragraph>
      <Input
        placeholder="Enter approver name"
        state="inactive"
        mode="writable"
        layout="fluid"
        name="approverName"
        className={styles.ApproverInput}
        value={approverName}
        onChange={(e) => setApproverName(e.target.value)}
        required
        data-testid="name-input"
        onEnterUpEvent={handleFormSubmit}
      />
    </Modal>
  );
}

export type OverUnleashConfirmModalProps = {
  categoryName: UnleashCategory['name'];
  categoryLimit?: UnleashCategory['limit'];
  availableBudget?: number;
  onCancel: () => void;
  onConfirm: (name: string) => void;
  approvedBy: string;
  isFocusOnEnabled?: boolean;
};
