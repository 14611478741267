import {
  forwardRef,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';

import { Icon } from 'design-system/Atoms/Icon';
import { useForwardRef, useClickable } from 'design-system/utils/hooks';

import styles from './TableHeader.module.scss';

export type SortDirection = 'asc' | 'desc';

export interface TableHeaderProps extends HTMLAttributes<HTMLSpanElement> {
  sortActive?: boolean;
  sortColumnName?: string;
  sortDirection?: SortDirection;
  sortClick?: (e: React.MouseEvent<HTMLSpanElement>, sortDirection: SortDirection) => void;
}

export const TableHeader = memo(
  forwardRef<HTMLSpanElement, PropsWithChildren<TableHeaderProps>>((props, forwardedRef) => {
    const {
      className,
      onKeyDown,
      sortClick,
      sortColumnName,
      sortActive = false,
      sortDirection = 'desc',
      children,
      tabIndex = -1,
      ...rest
    } = props;
    const [getSortDirection, setSortDirection] = useState<SortDirection>(sortDirection);
    const [iconDirection, setIconDirection] = useState<'caret-up' | 'caret-down'>('caret-down');
    const ref = useForwardRef(forwardedRef);

    useEffect(() => {
      setIconDirection(getSortDirection === 'asc' ? 'caret-up' : 'caret-down');
    }, [setIconDirection, getSortDirection]);

    const { onKeyDown: handleOnKeyDown } = useClickable<HTMLSpanElement>({
      onKeyDown,
      tabIndex,
    });

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLSpanElement>) => {
        ref?.current?.focus({ preventScroll: true });
        let sortDirection = getSortDirection;
        if (sortActive) {
          sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }

        setSortDirection(sortDirection);
        sortClick?.(event, sortDirection);
      },
      [sortClick, getSortDirection, sortActive, setSortDirection, ref],
    );

    const iconClasses = cx(styles.Icon, sortActive ? styles.IconActive : styles.IconInactive);

    return (
      <span
        className={cx(styles.Header, className, {
          [styles.HeaderClickable]: !!sortColumnName,
        })}
        data-testid="TestId__HEADER"
        onClick={!!sortColumnName ? handleClick : undefined}
        onKeyDown={handleOnKeyDown}
        ref={forwardedRef}
        role="columnheader"
        tabIndex={tabIndex}
        {...rest}
      >
        {children}
        {!!sortColumnName && (
          <Icon
            data-testid="TestId__HEADERICON"
            data-testdirection={getSortDirection}
            className={iconClasses}
            name={iconDirection}
          />
        )}
      </span>
    );
  }),
);
