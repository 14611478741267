import { memo, useState } from 'react';

import {
  StepCard,
  StepCardButtons,
  MonthDateRanges,
  DateRangesData,
  SelectedCardType,
} from 'design-system';

import { SUBMIT_INVOICE, SUBMIT_INVOICE_PERIODS } from 'consts/constants';
import { DateRangeAlt, Invoice, StepActions } from 'types/types';
import { formatDate, getInvoiceStep1MonthDateRanges } from 'utils/datesHelper';
import { getDay } from 'utils/helpers';

export interface Step1Props extends StepActions {
  invoice: Invoice;
  submittedInvoicesDateRanges?: DateRangeAlt[];
  updateInvoice: (invoiceData: Partial<Invoice>) => void;
}

const { FIRST_HALF, SECOND_HALF, FULL_MONTH } = SUBMIT_INVOICE_PERIODS;

const dateRangesData: DateRangesData[] = [
  {
    type: FIRST_HALF,
    disabled: false,
  },
  {
    type: SECOND_HALF,
    disabled: false,
  },
  {
    type: FULL_MONTH,
    disabled: false,
  },
];

export const dateRangesSubmitted = (start: string, end: string) => {
  const startDay = getDay(start);
  const endDay = getDay(end);
  const month = formatDate(new Date(end), 'MMMM yyyy');

  let rangeType: string;
  if (startDay >= 1 && endDay <= 15) {
    rangeType = FIRST_HALF;
  } else if (startDay === 1 && endDay >= 28) {
    rangeType = FULL_MONTH;
  } else {
    rangeType = SECOND_HALF;
  }
  return {
    key: `${month}-${rangeType}`,
    rangeType,
    month,
  };
};

export const Step1 = memo<Step1Props>((props) => {
  const [stepCompleted, setStepCompleted] = useState(false);
  const [dateRange, setDateRange] = useState<{
    dateFrom: Date | string;
    dateTo: Date | string;
  }>({ dateFrom: props?.invoice?.dateFrom || '', dateTo: props?.invoice?.dateTo || '' });
  const [selectedCard, setSelectedCard] = useState<SelectedCardType>(() => {
    if (props.invoice.dateFrom && props.invoice.dateTo) {
      return dateRangesSubmitted(props.invoice.dateFrom, props.invoice.dateTo);
    }
    return {
      key: '',
      rangeType: '',
      month: '',
    };
  });

  const months = getInvoiceStep1MonthDateRanges(props?.submittedInvoicesDateRanges || []);

  const { CONTENT } = SUBMIT_INVOICE;
  const step = CONTENT[1];

  const onNextButtonClick = async () => {
    setStepCompleted(true);
    const parsedDateRange = {
      dateFrom: String(dateRange.dateFrom),
      dateTo: String(dateRange.dateTo),
    };
    await props.updateInvoice(parsedDateRange);
    props.activeHandler?.();
  };

  const onEditButtonClick = () => {
    setStepCompleted(false);
    props.inactiveHandler?.();
  };

  return (
    <StepCard
      step={step.step}
      title={step.title}
      description={
        ((props.state === 'active' || stepCompleted) &&
          `${step.description}${props.invoice.number}`) ||
        ''
      }
      state={props.state}
      bottomContent={
        props.state === 'active' || (props.state === 'inactive' && stepCompleted) ? (
          <StepCardButtons
            state={selectedCard.key === '' ? 'disabled' : props.state}
            text={props.state === 'active' ? 'Next' : 'Edit'}
            onClick={props.state === 'active' ? onNextButtonClick : onEditButtonClick}
          />
        ) : (
          <></>
        )
      }
      singleColumn
    >
      {props.state === 'active' ? (
        months.map((month) => (
          <MonthDateRanges
            key={month.label}
            dateRangesData={month.dateRangesData}
            month={month.label}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            setDateRange={setDateRange}
          />
        ))
      ) : (props.state === 'inactive' && stepCompleted) || props.state === 'pending' ? (
        <MonthDateRanges
          dateRangesData={dateRangesData.filter((range) => range.type === selectedCard.rangeType)}
          month={selectedCard.month}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          setDateRange={setDateRange}
        />
      ) : (
        <></>
      )}
    </StepCard>
  );
});
