import { memo, useEffect, useState, useRef, useMemo } from 'react';

import { Checkbox, Select, Label, Paragraph, Textarea } from 'design-system';

import coinAnimation from 'assets/coin.gif';
import { SelectOption } from 'design-system/Atoms/Select/Select.helper';
import { UserVaultStash } from 'types/types';

import styles from './Appreciation.module.scss';

interface AppreciationParams {
  giftCoin: boolean;
  message: string;
  userIds: number[];
}

interface AppreciationProps {
  onAppreciationUpdate?: (appreciation: AppreciationParams) => void;
  userCoins: UserVaultStash['coins'];
  users: SelectOption[];
}

export const Appreciation = memo((props: AppreciationProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const [giftCoin, setGiftCoin] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedUsers, setSelectedUser] = useState<number[]>([]);

  const giftCoinDisabled = useMemo(() => {
    if (props.userCoins < 1) return true;
    return props.userCoins < selectedUsers.length;
  }, [props.userCoins, selectedUsers]);

  useEffect(() => {
    props.onAppreciationUpdate?.({ userIds: selectedUsers, giftCoin, message });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, message, giftCoin]);

  useEffect(() => {
    if (!selectedUsers.length) {
      setMessage('');
    }
  }, [selectedUsers.length]);

  useEffect(() => {
    if (checkboxRef.current?.checked) {
      if (giftCoinDisabled || !selectedUsers?.length) {
        checkboxRef.current.checked = false;
        setGiftCoin(false);
        return;
      }
      setGiftCoin(true);
    }
  }, [giftCoinDisabled, selectedUsers?.length]);

  return (
    <div>
      <Paragraph variant="ui-bold">Who would you like to appreciate this week?</Paragraph>
      <Select
        isMulti
        placeholder="Enter X-Teamer name"
        options={props.users}
        onChange={(selectedOptions) => {
          setSelectedUser(
            (((selectedOptions as unknown) as { value: number }[]) || [])?.map((so) => so.value),
          );
        }}
      />
      <Label
        type="dark"
        wrapperClassName={styles.LabelWrapper}
        paragraphClassName={styles.Label}
        interactive={false}
        showBackground={false}
      >
        Write the reason why you're thanking these people
      </Label>

      <Textarea
        value={message}
        handleChange={(value) => selectedUsers?.length && setMessage(value)}
      />

      <div className={styles.Checkbox}>
        <Checkbox
          ref={checkboxRef}
          disabled={giftCoinDisabled || !selectedUsers.length}
          defaultChecked={giftCoin}
          onChange={() => setGiftCoin(!giftCoin)}
        >
          <Paragraph variant="ui-regular">
            Gift each of these people 1 coin{' '}
            <span>
              <img src={coinAnimation} alt="coin" />
            </span>
            {giftCoinDisabled && <span className={styles.CoinWarning}>(not enough coins)</span>}
          </Paragraph>
        </Checkbox>
      </div>
    </div>
  );
});
