import { useEffect, useState } from 'react';

import { startOfDay } from 'date-fns';

import Select from 'components/inputs/Select';
import {
  DateRangeSelectOption,
  getRangeOfDateRanges,
  mapDateRangesToSelectOptions,
} from 'utils/datesHelper';

export interface InvoiceDatePickerProps {
  onChange: any;
  isFullWidth?: boolean;
  value?: string;
  isOptionDisabled?: any;
}

const InvoiceDatePicker = (props: InvoiceDatePickerProps) => {
  const { onChange } = props;
  const [options, setOptions] = useState<DateRangeSelectOption[]>([]);

  const handleChange = (id: string) => {
    const currentValue = options.find(({ value }) => value === id);
    currentValue && onChange(currentValue.dateRange);
  };

  useEffect(() => {
    const currentInvoicingDate = startOfDay(new Date());
    const dateRanges = getRangeOfDateRanges(currentInvoicingDate);
    setOptions(mapDateRangesToSelectOptions(dateRanges));
  }, []);

  return <Select {...props} onChange={handleChange} options={options} />;
};

export default InvoiceDatePicker;
