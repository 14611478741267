/* eslint-disable jsx-a11y/no-autofocus */
import cx from 'classnames';
import { MultimediaViewer } from 'design-system';
import { marked } from 'marked';
import Avatar from 'react-avatar';
import sanitizeHtml from 'sanitize-html';

import { FeedActivityProps, POST_TYPES, RefreshFeedCallbackFunction } from '../Feed.types';
import PostReactions from '../PostReactions';

import styles from './SinglePostBody.module.scss';

export const SinglePostBody = (props: SinglePostBodyProps) => {
  const {
    medias,
    feedProps: { activity },
  } = props;
  const { message = '', title = '', publisherAvatar, publisherName, type } = activity;
  const isPinnedPost = type === POST_TYPES.PINNED_POST;

  const className = cx({
    [styles.NoMediaContainer]: medias.length === 0,
    [styles.HasMediaContainer]: medias.length > 0,
  });

  return (
    <div className={className} data-testid="TestId_SINGLEBODYPOST">
      {medias.length ? (
        <div className={styles.MediaContainer}>
          <MultimediaViewer media={medias} />
        </div>
      ) : null}

      <div className={styles.PostBody}>
        <div className={styles.ScrollSection}>
          <div className={styles.AuthorContainer}>
            <span>
              <Avatar
                round
                src={publisherAvatar}
                name={publisherName}
                maxInitials={2}
                size="32px"
              />
              {publisherName}
            </span>
          </div>
          {isPinnedPost && title && (
            <div className={styles.PostBodyTitle} dangerouslySetInnerHTML={{ __html: title }} />
          )}
          <div
            className={styles.PostBodyText}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(marked(message, { breaks: true })) }}
          />
          <div>
            <PostReactions activity={activity} showText={!medias.length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export interface SinglePostBodyProps {
  feedProps: FeedActivityProps;
  medias: { type: 'video' | 'image'; publicId: string }[];
  refreshFeed?: RefreshFeedCallbackFunction;
}
