import { MouseEventHandler } from 'react';

import cx from 'classnames';
import { useIsMobile } from 'design-system';

import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './ProgressIndicator.module.scss';

export type ProgressIndicatorProps = {
  label: string;
  numberStep: number;
  interactive?: boolean;
  wrapperClassName?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  state?: 'filled' | 'active' | 'inactive';
} & JSX.IntrinsicElements['div'];

const isFilled = (state: string) => state === 'filled';
const isActive = (state: string) => state === 'active';
const isInactive = (state: string) => state === 'inactive';

export const ProgressIndicator = ({
  label,
  numberStep,
  interactive = false,
  state = 'filled',
  wrapperClassName,
  onClick,
  ...props
}: ProgressIndicatorProps) => {
  const isMobile = useIsMobile();

  const extraProps = interactive && {
    role: 'button',
    tabIndex: 0,
    onClick: onClick,
  };

  const wrapperClasses = cx(styles.Wrapper, wrapperClassName, {
    [styles.Clickable]: interactive,
  });

  const spanClasses = cx(styles.Span, {
    [styles.SpanFilled]: isFilled(state),
    [styles.SpanActive]: isActive(state),
    [styles.SpanInactive]: isInactive(state),
  });

  const numberStepClasses = cx(styles.NumberStep, {
    [styles.NumberStepFilled]: isFilled(state),
    [styles.NumberStepActive]: isActive(state),
    [styles.NumberStepInactive]: isInactive(state),
  });

  const labelClasses = cx(styles.Label, {
    [styles.LabelFilled]: isFilled(state),
    [styles.LabelActive]: isActive(state),
    [styles.LabelInactive]: isInactive(state),
  });

  return (
    <div
      data-testid="TestId__PROGRESSINDICATOR__WRAPPER"
      className={wrapperClasses}
      {...extraProps}
      {...props}
    >
      <span
        data-testid="TestId__PROGRESSINDICATOR__PARAGRAPHNUMBER__WRAPPER"
        className={spanClasses}
      >
        <Paragraph
          data-testid="TestId__PROGRESSINDICATOR__PARAGRAPHNUMBER"
          variant="ui-bold"
          className={numberStepClasses}
        >
          {numberStep}
        </Paragraph>
      </span>
      {!isMobile && (
        <Paragraph
          data-testid="TestId__PROGRESSINDICATOR__PARAGRAPHNUMBER__LABEL"
          variant="ui-bold"
          className={labelClasses}
        >
          {label}
        </Paragraph>
      )}
    </div>
  );
};
