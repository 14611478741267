import { memo } from 'react';

import classNames from 'classnames';

import styles from './Row.module.scss';

type RowProps = {
  children: React.ReactNode;
  className?: string;
  isHidden?: boolean;
  noPadding?: boolean;
  withPadding?: boolean;
  isReferral?: boolean;
  isCollapsable?: boolean;
  reimbursement?: boolean;
  reimbursementNotes?: boolean;
  reimbursementReferral?: boolean;
  reimbursementUnleashCategory?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
  withMargin?: boolean;
  withLeftMargin?: boolean;
  reimburseHorizontal?: boolean;
  reimburseVertical?: boolean;
  isModal?: boolean;
};

const Row: React.FC<RowProps> = ({
  children,
  isHidden,
  noPadding,
  reimbursement,
  reimbursementReferral,
  reimbursementNotes,
  reimbursementUnleashCategory,
  withPadding,
  className,
  isCollapsable,
  horizontal,
  vertical,
  reimburseHorizontal,
  reimburseVertical,
  withMargin,
  withLeftMargin,
  isModal,
  ...restProps
}) => {
  const RowClasses = classNames(
    className,
    styles.Row,
    isHidden && styles.Row_hidden,
    noPadding && styles.Row_noPadding,
    withPadding && styles.Row_padding,
    reimbursement && styles.Row_reimbursement,
    isCollapsable && styles.Row_collapsable,
    reimbursementReferral && styles.Row_reimbursementReferral,
    reimbursementNotes && styles.Row_reimbursementNotes,
    reimbursementUnleashCategory && styles.Row_reimbursementUnleashCategory,
    horizontal && styles.Row_horizontal,
    vertical && styles.Row_vertical,
    withMargin && styles.Row_withMargin,
    withLeftMargin && styles.Row_withLeftMargin,
    reimburseHorizontal && styles.Row_reimburseHorizontal,
    reimburseVertical && styles.Row_reimburseVertical,
    isModal && styles.Row_modal,
  );

  return (
    <span className={RowClasses} {...restProps}>
      {children}
    </span>
  );
};

export default memo(Row);
