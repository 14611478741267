import { useCallback } from 'react';

import classnames from 'classnames';
import { Icon, useIsMobile } from 'design-system';

import { Paragraph } from 'design-system/Atoms';
import isKeyboardEvent from 'utils/keyboardEvent';
import { getPageItems, PaginationItem } from 'utils/pagination';

import styles from './Pagination.module.scss';

export type PaginationProps = {
  className?: string;
  page?: number;
  pageCount?: number;
  onPageChange: (page: number) => void;
  autoScrollToTop?: boolean;
};

export function getPage(
  item: PaginationItem,
  currentPage: number,
  pageCount: number,
): number | null {
  const newPage =
    item.page === 'next' ? currentPage + 1 : item.page === 'prev' ? currentPage - 1 : item.page;

  if (newPage === null || newPage > pageCount || newPage <= 0) {
    return null;
  }
  return newPage;
}

export const Pagination = ({
  className,
  page = 1,
  pageCount = 1,
  onPageChange,
  autoScrollToTop = false,
}: PaginationProps) => {
  const isMobile = useIsMobile();
  const items = getPageItems(page, pageCount, {
    backwardArrow: <Icon name="caret-left" data-testid="backwardArrow" />,
    forwardArrow: <Icon name="caret-right" data-testid="forwardArrow" />,
    ellipsis: '...',
    isMobile,
  });

  const handlePageChange = useCallback(
    (page: number | null) => {
      if (!page) {
        return;
      }
      onPageChange(page);
      if (autoScrollToTop) {
        window.scrollTo(0, 0);
      }
    },
    [onPageChange, autoScrollToTop],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <div data-testid="pagination" className={classnames(styles.Pagination, className)}>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={classnames(styles.Item, {
              [styles.Border]: item.text !== '...',
              [styles.ItemActive]: item.page === page,
            })}
            onClick={() => {
              handlePageChange(getPage(item, page, pageCount));
            }}
            onKeyUp={(e) => isKeyboardEvent(e) && handlePageChange(getPage(item, page, pageCount))}
            role="button"
            tabIndex={0}
          >
            <Paragraph variant="ui-regular">{item.text}</Paragraph>
          </div>
        );
      })}
    </div>
  );
};
