import { memo } from 'react';

import { Link, Modal } from 'design-system';

import { useAdminSetting, useToast } from 'utils/hooks';

import styles from './PrivacyPolicyModal.module.scss';

export interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: (accepted: boolean) => void;
}

export const PrivacyPolicyModal = memo<PrivacyPolicyModalProps>(({ isOpen, onClose }) => {
  const toast = useToast();

  const handleClose = (accepted = false, dismissable = false) => {
    if (!accepted && !dismissable) {
      toast({ type: 'error', text: 'You cannot continue without accepting the privacy policy' });
      return;
    }
    onClose(accepted);
  };

  const acceptPolicy = () => {
    handleClose(true);
  };

  const { url: privacyPolicyUrl, dismissible } = useAdminSetting('privacy-policy')?.values || {};

  return (
    <div data-testid="privacy-policy-modal-container">
      {isOpen && (
        <Modal
          data-testid="privacy-policy-modal"
          modalClassName={styles.Modal}
          contentClassName={styles.ModalContent}
          footerClassName={styles.ModalFooter}
          closeButtonClassName={styles.HideCloseButton}
          onClose={() => handleClose(false, dismissible)}
          title="X-Team Privacy Policy"
          titleClassName={styles.Title}
          primaryButtonContent="I have read and agree to the Privacy Policy"
          primaryButtonClassName={styles.Button}
          onSubmit={acceptPolicy}
          hideSecondaryButton={true}
        >
          <p className={styles.Paragraph}>
            We take the security of our community seriously. By proceeding, you acknowledge that you
            have read and agree to abide by our X-Team Privacy Policy. Your commitment to
            maintaining a secure environment is invaluable to us.
          </p>
          <p className={styles.Paragraph}>
            Please read our{' '}
            <Link href={privacyPolicyUrl} target="_blank">
              Privacy Policy
            </Link>{' '}
            for detailed information on how we handle your data and information.
          </p>
          <p className={styles.Paragraph}>
            By clicking <strong>'I have read and agree to the Privacy Policy'</strong> below, you
            affirm your understanding and acceptance of our security practices.
          </p>
        </Modal>
      )}
    </div>
  );
});
