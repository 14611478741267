import { memo } from 'react';

import { PageHeader } from 'design-system/Atoms/PageHeader';
import { ProgressIndicatorGroup } from 'design-system/Molecules/ProgressIndicatorGroup';

import styles from './SubmitInvoiceHeader.module.scss';

export interface SubmitInvoiceHeaderProps {
  title: string;
  subtitle?: string;
  currentProgress: number;
  progressIndicatorData: {
    label: string;
    numberStep: number;
  }[];
}

export const SubmitInvoiceHeader = memo<SubmitInvoiceHeaderProps>(
  ({ title, subtitle, currentProgress, progressIndicatorData }) => {
    return (
      <div className={styles.HeaderWrapper}>
        <PageHeader title={title} subtitle={subtitle} alignment="start" layout="fluid" />
        <ProgressIndicatorGroup
          currentProgress={currentProgress}
          interactive={false}
          data={progressIndicatorData}
        />
      </div>
    );
  },
);
