import { memo } from 'react';

import styles from './Toggle.module.scss';

export type ToggleProps = JSX.IntrinsicElements['input'];

export const Toggle = memo<ToggleProps>(({ ...props }: ToggleProps) => {
  return (
    <label className={styles.Toggle}>
      <input data-testid="TestId__TOGGLE" type="checkbox" {...props} />
      <span className={styles.Slider}></span>
    </label>
  );
});
