import {
  ReactNode,
  PropsWithChildren,
  MouseEvent,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  ElementType,
  ComponentType,
} from 'react';

import { EnrichedUser, UR, OGAPIResponse } from 'getstream';
import { MutateFunction, MutationConfig } from 'react-query';

import { UserApiModel, BountyApiModel } from 'types/types';

export interface StreamUser {
  created_at: string;
  updated_at: string;
  id: string;
  data: {
    name: string;
    slackId: string;
    avatarUrl: string;
  };
}

export interface ActivityActor extends StreamUser {}

export interface Reaction {
  activity_id: string;
  children_counts: Record<string, string>;
  created_at: string;
  data: Record<string, string>;
  id: string;
  kind: string;
  parent: string;
  updated_at: string;
  user: StreamUser;
  user_id: string;
}

export interface ReactionCounts {
  like?: number;
  comment?: number;
}

export interface MediaMeta {
  bytes: number;
  format: string;
  height: number;
  width: number;
}
type Attachments = {
  files?: Array<{ mimeType: string; name: string; url: string }>;
  images?: string[];
  og?: OGAPIResponse;
};
export type DefaultAT = UR & { attachments?: Attachments; text?: string };

type BaseActivity = {
  verb: string;
  target?: string;
  to?: string[];
};
export type SimpleActivity = Activity<DefaultAT> & {
  actor: string;
  origin?: string;
};

export type Activity<ActivityType extends UR = UR> = ActivityType &
  BaseActivity & {
    actor: ActivityActor;
    publisherAvatar: string;
    publisherName: string;
    foreign_id: string;
    id: string;
    object: string | unknown;
    time: string;
    analytics?: Record<string, number>;
    extra_context?: UR;
    score?: number;
    bountyId?: number;
    bountyTitle?: string;
    bountyCoinValue?: number | null;
    teamName?: string | null;
    teamIconLocation?: string | null;
    mediaImages?: string[];
    mediaPreset: string;
    mediaVideos?: string[];
    mediaImagesMeta?: MediaMeta[];
    mediaVideosMeta?: MediaMeta[];
    origin?: string | null;
    own_reactions: {
      like?: Reaction[];
      comment?: Reaction[];
    };
    reaction_counts?: ReactionCounts;
    target: string;
    verb: string;
    type: POST_TYPES;
    message?: string;
    title?: string;
    participants?: string[];
  };

export type EnrichOptions = {
  enrich?: boolean;
  recentReactionsLimit?: number;
  withOwnChildren?: boolean;
  withOwnReactions?: boolean;
  withReactionCounts?: boolean;
  withRecentReactions?: boolean;
};
export type DefaultUT = UR & { name: string; id?: string; profileImage?: string };
export type UserFromActivityProps = EnrichedUser<DefaultUT>;

export type FeedPaginationOptions = {
  id_gt?: string;
  id_gte?: string;
  id_lt?: string;
  id_lte?: string;
  limit?: number;
};

export type RankedFeedOptions = {
  offset?: number;
  ranking?: string;
  session?: string;
};

type NotificationBroadcastingOptions = {
  targetFeeds?: string[];
};

export type GetFeedOptions = FeedPaginationOptions &
  EnrichOptions &
  RankedFeedOptions &
  NotificationBroadcastingOptions;

export type SelectedActivity = {
  editedData?: UploaderOptions;
  activity?: Activity;
  operation: POST_OPERATION_TYPES;
};

export interface FeedWrapperProps {
  user: Partial<UserApiModel>;
  selectedActivity?: SelectedActivity;
  shouldRefresh: boolean;
  toggleFeedRefresh: (shouldRefresh: boolean) => void;
  resetActivity: () => void;
  handlePostUpdate: (data: UploaderOptions) => void;
  editPost: (activity: Activity) => void;
  handlePostRemove: (activity: Activity) => void;
  onClickPost: (activity: Activity, refreshFeed?: RefreshFeedCallbackFunction) => void;
  limit?: number;
  showMyFeed?: boolean;
}

export interface FeedProps extends FeedWrapperProps {}

export type FeedCtx = {
  refresh?: RefreshFeedCallbackFunction;
};

export type RefreshFeedCallbackFunction = (options?: GetFeedOptions) => Promise<unknown>;
// The following Props comes from the react-activity-feed library types and utils
export type ElementOrComponentOrLiteralType<P extends UR = UR> =
  | string
  | number
  | boolean
  | null
  | ReactElement<P>
  | ElementType<P>
  | ComponentType<P>;

export type LoadMorePaginatorChildrenType = {
  size: number;
};

export type LoadMorePaginatorProps = {
  children: ReactNode | LoadMorePaginatorChildrenType;
  hasNextPage: boolean;
  loadNextPage: LoadMoreButtonProps['onClick'];
  LoadMoreButton?: ElementOrComponentOrLiteralType<LoadMoreButtonProps>;
  refreshing?: boolean;
  reverse?: boolean;
};

export type PropsWithElementAttributes<
  T extends UR = UR,
  E extends HTMLElement = HTMLDivElement
> = T & Pick<DetailedHTMLProps<HTMLAttributes<E>, E>, 'className' | 'style'>;

export type LoadMoreButtonProps = PropsWithElementAttributes<
  PropsWithChildren<{
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    refreshing?: boolean;
  }>
>;

export enum POST_TYPES {
  USER_POST = 'user_post',
  PINNED_POST = 'pinned_post',
}

export enum POST_OPERATION_TYPES {
  DELETE = 'delete',
  EDIT = 'edit',
  EDIT_CONFIRM = 'edit_confirm',
  UPDATE = 'update',
}

// Emojis types taken from node_modules/react-activity-feed/src/components/EmojiPicker.tsx

export type EmojiSkin = 1 | 2 | 3 | 4 | 5 | 6;
export interface BaseEmoji {
  id: string;
  name: string;
  colons: string;
  emoticons: string[];
  unified: string;
  skin: EmojiSkin | null;
  native: string;
}
export interface CustomEmoji {
  id?: string;
  colons?: string;
  name: string;
  short_names: string[];
  emoticons?: string[];
  keywords?: string[];
  imageUrl: string;
  native?: string;
}
export type Emoji = BaseEmoji | CustomEmoji;

export type BountySelectedOption = {
  value: number;
  bounty: boolean;
  label: string;
};

export interface NotifierProps {
  shouldRefresh?: boolean;
  adds?: Array<any>;
  onClick?: (event?: any) => void;
  triggerFeedRefresh?: (shouldRefresh: boolean) => void;
}

export interface UploaderOptions {
  post: { title: string; text: string };
  bounty?: Partial<BountyApiModel & { bounty: boolean }> | null;
  isPinned: boolean;
  mediaFiles?: File[];
  onMediaProgress?: (progress: MediaProgress[]) => void;
  generateCloudinaryAuthSignature?: any;
  submitFeedPost: MutateFunction<any, any, any>;
  updatePinnedFeedPost: MutateFunction<any, any, any>;
  mutationOptions: MutationConfig<any>;
  activity?: Activity;
}

export interface MediaProgress {
  file: File;
  total: number;
  loaded: number;
  progress: number;
}

export interface Comment {
  comment: {
    created_at: string;
    data: {
      publisherAvatar?: string;
      text?: string;
      teamName?: string | null;
      teamIconLocation?: string | null;
    };
    user?: EnrichedUser<DefaultUT>;
  };
}

export interface FeedActivityProps {
  activity: Activity<UR>;
  feedGroup?: string;
}
