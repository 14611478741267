import { ForwardedRef, useRef, useEffect } from 'react';

/**
 * This hook is used to get the ref of a component that is forwarded using the forwardRef function.
 * It is used in conjunction with the forwardRef function.
 *
 * @param ref - The ref to forward
 * @param initialValue - The initial value of the ref
 *
 * @returns The `React.MutableRefObject<T>` object
 */
export const useForwardRef = <T,>(ref: ForwardedRef<T>, initialValue: any = null) => {
  const targetRef = useRef<T>(initialValue);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      targetRef.current = ref.current as T;
    }
  }, [ref]);

  return targetRef;
};
