import { memo, PropsWithChildren, ReactNode } from 'react';

import cx from 'classnames';

import { Heading } from 'design-system/Atoms';

import styles from './InnerPage.module.scss';

export const InnerPage = memo(
  (
    props: PropsWithChildren<{
      title?: ReactNode;
      sideContent?: ReactNode;
      stickySideContent?: boolean;
    }>,
  ) => {
    return (
      <div className={styles.InnerPage}>
        <div className={styles.Content}>
          {props.title && (
            <div className={styles.PageTitle}>
              {typeof props.title === 'string' ? (
                <Heading level="h3">{props.title}</Heading>
              ) : (
                props.title
              )}
            </div>
          )}
          <div className={cx(styles.MainContent, { [styles.MainContentOnly]: !props.sideContent })}>
            {props.children}
          </div>
          {props.sideContent && (
            <div
              className={cx(styles.SideContent, {
                [styles.SideContentSticky]: props.stickySideContent,
              })}
            >
              {props.sideContent}
            </div>
          )}
        </div>
      </div>
    );
  },
);
