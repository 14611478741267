import { HTMLProps, memo, PropsWithChildren } from 'react';

import cx from 'classnames';

import styles from './Card.module.scss';

export const cardTypes = ['default', 'highlighted'] as const;
export type CardType = typeof cardTypes[number];

export type CardProps = {
  type?: CardType;
  className?: string;
} & HTMLProps<HTMLDivElement>;

export const Card = memo<PropsWithChildren<CardProps>>(
  ({ children, className, type = 'default', ...props }: PropsWithChildren<CardProps>) => {
    const cardClasses = cx(styles.Card, className, {
      [styles.Highlighted]: type === 'highlighted',
    });

    return (
      <div data-testid="TestId__CARDWRAPPER" className={cardClasses} {...props}>
        {children}
      </div>
    );
  },
);
