import { HTMLProps, forwardRef } from 'react';

import cx from 'classnames';

import { Paragraph } from 'design-system/Atoms/Paragraph';

import styles from './RadioButton.module.scss';

export type RadioButtonProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, defaultChecked, label, labelClassName, ...props }, ref) => {
    const containerClassName = cx(styles.RadioButtonContainer, labelClassName);
    const spanClassName = cx({ [styles.RadioButtonLabel]: defaultChecked });

    return (
      <label data-testid="TestId__RADIOBUTTON__LABEL" className={containerClassName}>
        <div
          data-testid="TestId__RADIOBUTTON__INPUTWRAPPER"
          className={styles.RadioButtonIconContainer}
        >
          <input
            data-testid="TestId__RADIOBUTTON__INPUT"
            ref={ref}
            id={id}
            type="radio"
            defaultChecked={defaultChecked}
            {...props}
          />
          <span className={styles.RadioButton} />
        </div>
        <Paragraph
          data-testid="TestId__RADIOBUTTON__PARAGRAPH"
          variant="ui-regular"
          className={spanClassName}
        >
          {label}
        </Paragraph>
      </label>
    );
  },
);
