import { memo, ReactNode, useState } from 'react';

import cx from 'classnames';

import { useIsMobile } from 'design-system/utils/hooks';

import { Button } from '../Button';

import styles from './Footer.module.scss';

export interface FooterProps {
  className?: string;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  button?: ReactNode;
  errorMessage?: string;
  fixed?: boolean;
  hasPageMenu?: boolean;
  hideButtonOnOpen?: boolean;
  ignoreMobile?: boolean;
  displayAsRow?: boolean;
}

export const Footer = memo<FooterProps>(
  ({
    className,
    leftComponent,
    rightComponent,
    button,
    errorMessage,
    fixed = true,
    hasPageMenu = true,
    hideButtonOnOpen = false,
    ignoreMobile = false,
    displayAsRow = false,
  }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const hasChildren = leftComponent || rightComponent;

    const toggleOpen = () => setOpen((prev) => !prev);

    const classes = cx(
      styles.Footer,
      {
        [styles.Fixed]: fixed || isMobile,
        [styles.PageMenu]: hasPageMenu,
        [styles.Open]: open,
        [styles.FooterRow]: displayAsRow,
      },
      className,
    );

    if (isMobile && !ignoreMobile) {
      return (
        <>
          {open && <div data-testid="TestId_Footer_Backdrop" className={styles.BackDrop} />}
          <div data-testid="TestId_Footer_Mobile" className={classes}>
            {open && (
              <div className={styles.Popover}>
                {leftComponent && <div>{leftComponent}</div>}
                {rightComponent && <div>{rightComponent}</div>}
              </div>
            )}
            {errorMessage && <p className={styles.ErrorMessage}>{errorMessage}</p>}
            <div
              className={cx(styles.ActionsWrapper, {
                [styles.AdditionalInfo]: hasChildren,
              })}
            >
              {hideButtonOnOpen && (!open ? button : <div />)}
              {!hideButtonOnOpen && (button ?? <div />)}
              {open ? (
                <Button
                  as="button"
                  iconName="close-big"
                  iconPosition="start"
                  layout="autolayout"
                  variant="icon-tertiary"
                  onClick={toggleOpen}
                  aria-label="Close popover"
                />
              ) : (
                hasChildren && (
                  <Button
                    as="button"
                    iconName="more-vertical"
                    iconPosition="start"
                    layout="autolayout"
                    variant="icon-tertiary"
                    onClick={toggleOpen}
                    aria-label="Open popover"
                  />
                )
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <div data-testid="TestId_Footer" className={classes}>
        <div className={styles.LeftComponent}>{leftComponent}</div>
        <div className={styles.RightComponent}>
          {errorMessage && <p className={styles.ErrorMessage}>{errorMessage}</p>}
          {rightComponent}
          {button}
        </div>
      </div>
    );
  },
);
