import { Suspense } from 'react';

import { orderBy } from 'lodash';

import Heading from 'components/Heading';
import { LegacyCard } from 'components/Legacy/LegacyCard';
import { InlineLoading } from 'components/Loading';
import { useGetLegacy } from 'utils/apiQueryHooks';

import styles from './Legacy.module.scss';

const Legacy = () => {
  const { resolvedData: { data: legacy = [] } = {} } = useGetLegacy({
    query: { isActive: true },
  });

  const orderedLegacy = orderBy(legacy, 'order', 'desc');

  return (
    <div className={styles.LegacyContainer}>
      <div className={styles.Wrap}>
        <Heading Level="h2" className={styles.Heading}>
          Legacy
        </Heading>
      </div>

      <Suspense fallback={<InlineLoading />}>
        <div className={styles.LegacyGrid}>
          {orderedLegacy.map((legacy) => (
            <LegacyCard
              key={legacy.id}
              title={legacy.title}
              name={legacy.name}
              backgroundImage={legacy.backgroundImage}
              crown={legacy.crownImage}
              description={legacy.description}
            />
          ))}
        </div>
      </Suspense>
    </div>
  );
};

export default Legacy;
