import classNames from 'classnames';
import { Icon, Paragraph } from 'design-system';

import styles from './EditableParagraph.module.scss';

export type EditableParagraphProps = {
  onEdit: () => void;
  containerClassName?: string;
};

export const EditableParagraph: React.FC<EditableParagraphProps> = (props) => {
  const containerClassName = classNames(styles.Container, props.containerClassName);

  return (
    <Paragraph
      variant="ui-regular"
      className={containerClassName}
      data-testid="TestId__EDITABLEPARAGRAPH"
    >
      {props.children}
      <Icon name="edit" onClick={props.onEdit} data-testid="TestId__EDITABLEPARAGRAPH__ICON" />
    </Paragraph>
  );
};
