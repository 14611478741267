import ScrollMenu from 'react-horizontal-scrolling-menu';

import dashboardModulesDefault from 'assets/grand-epic/grand-epic-default.png';
import CardModule from 'components/CardModule';
import { useAdminSetting } from 'utils/hooks';

import styles from './HomeCards.module.scss';

const HomeCards = () => {
  const moduleSettings = useAdminSetting('dashboard-modules');
  const moduleOneImage = moduleSettings?.values.image?.location || dashboardModulesDefault;
  const moduleOneLink = moduleSettings?.values.cta_url || '';
  const moduleTwoLink = moduleSettings?.values.cta_url_module || '';
  const moduleTwoImage = moduleSettings?.values.image_module?.location || dashboardModulesDefault;

  const isEmpty = (title?: string, text?: string) => {
    return !title || !text;
  };

  const cards = [];
  if (!isEmpty(moduleSettings?.values.title, moduleSettings?.values.text)) {
    cards.push(
      <CardModule
        title={moduleSettings?.values.title ?? ''}
        text={moduleSettings?.values.text ?? ''}
        bottomLink={moduleOneLink}
        image={moduleOneImage}
        imagePosition={'right'}
        key={'CardModule-1'}
      />,
    );
  }
  if (!isEmpty(moduleSettings?.values.title_module, moduleSettings?.values.text_module)) {
    cards.push(
      <CardModule
        title={moduleSettings?.values.title_module ?? ''}
        text={moduleSettings?.values.text_module ?? ''}
        bottomLink={moduleTwoLink}
        image={moduleTwoImage}
        imagePosition={'right'}
        key={'CardModule-2'}
      />,
    );
  }

  if (!cards.length) return null;

  return (
    <ScrollMenu
      data={cards}
      alignCenter={false}
      itemClass={styles.Card}
      innerWrapperClass={styles.InnerWrapper}
      scrollToSelected={true}
      useButtonRole={false}
      wheel={false}
      wrapperClass={styles.HomeWrapper}
    />
  );
};

export default HomeCards;
