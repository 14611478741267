import {
  useRef,
  useEffect,
  DependencyList,
  useMemo,
  useState,
  RefObject,
  useCallback,
} from 'react';

import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';

import { BREAKPOINTS, USER_ROLE } from 'consts/constants';
import { addModalToast, addToast, removeAllModalToasts } from 'store/actionCreators';
import {
  InvoiceStatus,
  InvoiceStatusName,
  ToastMessage,
  SessionModel,
  ToggleablePages,
  DisabledMenuItems,
} from 'types/types';

export const useCurrentUser = () => useSelector((state) => state.session?.data?._user);
export const useCurrentSession = () => useSelector((state) => state.session);

export const useAdminSetting = (settingId: string) =>
  useSelector((state) =>
    state.getSettings?.response?.data?.find((setting) => setting.id === settingId),
  );

export const useGetInvoiceStatus = (statusName: InvoiceStatusName): InvoiceStatus =>
  useSelector((state) =>
    state.getInvoiceStatuses?.response?.data?.find(
      (status: InvoiceStatus) => status.name === statusName,
    ),
  );

export const useToast = (args?: { modal?: boolean }) => {
  const dispatch = useDispatch();
  return useCallback(
    (options: ToastMessage) => {
      const action = args?.modal ? addModalToast : addToast;
      return dispatch(action(options));
    },
    [dispatch, args],
  );
};

export const useRemoveAllModalToast = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(removeAllModalToasts()), [dispatch]);
};

export const useLatest = <T>(value: T) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref as RefObject<T>;
};

const events = ['mouseup', 'touchstart'] as const;
type PossibleOutsideEvent = HTMLElementEventMap[typeof events[number]];
type Handler = (event: PossibleOutsideEvent) => void;
export const useOnClickOutside = (ref: RefObject<HTMLElement>, callback?: Handler | null) => {
  const callbackRef = useLatest(callback);

  useEffect(() => {
    if (!callback) {
      return;
    }

    const handleClickOutside = (event: PossibleOutsideEvent) => {
      if (!ref.current || !callbackRef.current || ref.current.contains(event.target as Node)) {
        return;
      }

      callbackRef.current(event);
    };

    events.forEach((name) =>
      document.addEventListener(name, handleClickOutside, { passive: true }),
    );
    return () => {
      events.forEach((name) =>
        document.removeEventListener(name, handleClickOutside, {
          passive: true,
        } as EventListenerOptions),
      );
    };
  }, [callback, ref, callbackRef]);
};

const useCustomCompareMemoize = (
  deps: DependencyList,
  depsAreEqual: (a: DependencyList, b: DependencyList) => boolean,
) => {
  const ref = useRef<DependencyList>();

  if (!ref.current || !depsAreEqual(deps, ref.current)) {
    ref.current = deps;
  }

  return ref.current;
};

export const useCustomCompareMemo = <T>(
  factory: () => T,
  deps: DependencyList,
  depsAreEqual: (a: DependencyList, b: DependencyList) => boolean,
  // eslint-disable-next-line react-hooks/exhaustive-deps
): T => useMemo(factory, useCustomCompareMemoize(deps, depsAreEqual));

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const useWindowWidth = () => {
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  const handleResize = useCallback(
    () => debounce((window) => setWidthSize(window.innerWidth), 30),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize(); // Get an initial value on mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return widthSize;
};

/** @deprecated use design-system/utils/hooks/useIsMobile instead as it is using the same scss breakpoints. */
export const useIsMobile = () => {
  return useWindowWidth() < BREAKPOINTS.LG;
};

export const useIsMobileAboveMd = () => {
  return useWindowWidth() < BREAKPOINTS.MD;
};

export const useAllowedRoles = (fns: Array<(session?: SessionModel) => boolean>) =>
  useSelector(
    (state) => state.session && fns.map((check) => check(state.session?.data)).some(Boolean),
  );

export const useIsDashboardFeedEnabled = () =>
  Boolean(useAdminSetting('feed')?.values.dashboardFeedEnabled);

export const useDisabledMenuItems = (): DisabledMenuItems => {
  const housesSettings = useAdminSetting('house');
  const legendsSettings = useAdminSetting('legends');
  const [disabledItems, setDisabledItems] = useState<DisabledMenuItems>();

  useEffect(() => {
    setDisabledItems({
      houses: !housesSettings?.values?.enableHouses,
      legends: !legendsSettings?.values?.legendsPageForUsers,
    });
  }, [housesSettings, legendsSettings]);

  return disabledItems ?? {};
};

export const useIsPageEnabled = (page: ToggleablePages) => {
  const disabledMenuItems = useDisabledMenuItems();
  const role = useCurrentUser()?._role?.name;
  if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
    return true;
  }

  switch (page) {
    case 'houses':
      return !disabledMenuItems?.houses;
    case 'legends':
      return !disabledMenuItems?.legends;
  }
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type UseCountdown = {
  running: boolean;
  remaining: number;
  start: (counting: number) => void;
  stop: () => void;
};

export const useCountdown = (): UseCountdown => {
  const timer = useRef<{
    endAt?: number;
    intervalId?: number;
    timeoutId?: number;
  }>({});

  const [running, setRunning] = useState(false);
  const [remaining, setRemaining] = useState(0);

  const stop = useCallback(() => {
    clearInterval(timer.current.intervalId);
    clearInterval(timer.current.timeoutId);
    setRunning(false);
    setRemaining(0);
  }, []);

  const start = useCallback(
    (counting: number) => {
      if (counting <= 0) {
        throw new TypeError('counting must be >= 0');
      }

      stop();
      setRunning(true);
      setRemaining(counting);

      timer.current.endAt = Date.now() + counting;

      timer.current.intervalId = +setInterval(() => {
        setRemaining(Math.max(0, (timer.current.endAt || 0) - Date.now()));
      }, 50);

      timer.current.timeoutId = +setTimeout(() => {
        clearInterval(timer.current.intervalId);
        setRemaining(0);
        setRunning(false);
      }, counting);
    },
    [stop],
  );

  return { running, remaining, start, stop };
};
